import {
  FETCH_DRAFTS_REQUEST,
  FETCH_DRAFTS_SUCCESS,
  FETCH_DRAFTS_FAILURE,
  ADD_DRAFT_REQUEST,
  ADD_DRAFT_SUCCESS,
  ADD_DRAFT_FAILURE,
  UPDATE_DRAFT_REQUEST,
  UPDATE_DRAFT_SUCCESS,
  UPDATE_DRAFT_FAILURE,
  UPDATE_POSITIONS_DRAFT_REQUEST,
  UPDATE_POSITIONS_DRAFT_SUCCESS,
  UPDATE_POSITIONS_DRAFT_FAILURE,
  DELETE_DRAFT_REQUEST,
  DELETE_DRAFT_SUCCESS,
  DELETE_DRAFT_FAILURE,
} from "../actionTypes";

import { draftService } from "../services/draftService";

export const fetchDraftsSuccess = (drafts) => {
  return {
    type: FETCH_DRAFTS_SUCCESS,
    payload: drafts,
  };
};

export const getAllDrafts = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_DRAFTS_REQUEST });
    try {
      const drafts = await draftService.getAllDrafts();
      dispatch({ type: FETCH_DRAFTS_SUCCESS, payload: drafts });
    } catch (err) {
      dispatch({ type: FETCH_DRAFTS_FAILURE, payload: err.message });
    }
  };
};

export const createDraft = (data) => {
  return async (dispatch) => {
    dispatch({ type: ADD_DRAFT_REQUEST });
    try {
      const newDraft = await draftService.createDraft(data);
      dispatch({ type: ADD_DRAFT_SUCCESS, payload: newDraft });
    } catch (err) {
      dispatch({ type: ADD_DRAFT_FAILURE, payload: err.message });
    }
  };
};

export const updateDraft = (id) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_DRAFT_REQUEST });
    try {
      const editedDraft = await draftService.updateDraft(id);
      dispatch({ type: UPDATE_DRAFT_SUCCESS, payload: editedDraft });
    } catch (err) {
      dispatch({ type: UPDATE_DRAFT_FAILURE, payload: err.message });
    }
  };
};

export const deleteDraft = (id) => {
  console.log(id);
  return async (dispatch) => {
    dispatch({ type: DELETE_DRAFT_REQUEST });
    try {
      await draftService.deleteDraft(id);
      dispatch({ type: DELETE_DRAFT_SUCCESS, payload: id });
    } catch (err) {
      dispatch({ type: DELETE_DRAFT_FAILURE, payload: err.message });
    }
  };
};

export const updateDraftsPositions = (positions) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_POSITIONS_DRAFT_REQUEST });
    try {
      await draftService.updateDraftsPositions(positions);
      dispatch({ type: UPDATE_POSITIONS_DRAFT_SUCCESS });
    } catch (err) {
      dispatch({ type: UPDATE_POSITIONS_DRAFT_FAILURE, payload: err.message });
    }
  };
};
