import React from "react";
import { faArrowRightFromBracket, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ExternalItem = ({ theme, _id, priority }) => {
  const handleDragStart = (event) => {
    event.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        _id,
        theme,
        priority,
      })
    );
  };
  return (
    <div
      key={_id}
      className={`card card-body px-2 py-1 bg-gray-300 mb-3`}
      role="button"
      draggable={true}
      onDragStart={handleDragStart}
    >
      <div className="flex gap-1 justify-content-between">
        <div className="task-name px-3">
          <div>{theme}</div>
        </div>
      </div>
    </div>
  );
};

const UnDraggedPosts = ({ setShowPosts, showPosts, undraggedPosts, height }) => {
  return (
    <div
      className="task-container d-grid bg-white p-3 rounded-3"
      style={{ height: `calc(${height}px - 150px)` }}
    >
      {!showPosts && (
        <span
          onClick={() => setShowPosts(!showPosts)}
          className="p-2 text-end"
          role="button"
        >
          {<FontAwesomeIcon icon={faArrowRightFromBracket} />}
        </span>
      )}
      {showPosts && (
        <>
          <div className="tasks">
            <div className="mb-4">
              {showPosts && (
                <div
                  onClick={() => setShowPosts(!showPosts)}
                  className="px-2 text-end"
                  role="button"
                >
                  {<FontAwesomeIcon icon={faXmark} />}
                </div>
              )}
              <h6 className="flex gap-1">
                <span
                  className="product-title bg-primary px-2 flex align-item-center text-white rounded-1"
                  role="button"
                >
                  Posts
                </span>
              </h6>
            </div>
            <div className="mb-5">
              <div className="mb-5">
                {undraggedPosts?.map((post) => (
                  <ExternalItem
                    key={post._id}
                    theme={post.theme}
                    priority={post.priority}
                    _id={post._id}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UnDraggedPosts;
