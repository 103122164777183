import {
  FETCH_BRIEF_QUESTIONS_REQUEST,
  FETCH_BRIEF_QUESTIONS_SUCCESS,
  FETCH_BRIEF_QUESTIONS_FAILURE,
  FETCH_BRIEF_QUESTION_REQUEST,
  FETCH_BRIEF_QUESTION_SUCCESS,
  FETCH_BRIEF_QUESTION_FAILURE,
  ADD_BRIEF_QUESTION_REQUEST,
  ADD_BRIEF_QUESTION_SUCCESS,
  ADD_BRIEF_QUESTION_FAILURE,
  UPDATE_BRIEF_QUESTION_REQUEST,
  UPDATE_BRIEF_QUESTION_SUCCESS,
  UPDATE_BRIEF_QUESTION_FAILURE,
  DELETE_BRIEF_QUESTION_REQUEST,
  DELETE_BRIEF_QUESTION_SUCCESS,
  DELETE_BRIEF_QUESTION_FAILURE,
} from "../actionTypes";
import { toast } from "react-toastify";
import { briefQuestionService } from "../services/briefQuestionService";

// Action creator to fetch all brief questions
export const fetchBriefQuestions = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_BRIEF_QUESTIONS_REQUEST });
    try {
      const briefQuestions = await briefQuestionService.getAllBriefQuestions();
      dispatch({
        type: FETCH_BRIEF_QUESTIONS_SUCCESS,
        payload: briefQuestions,
      });
    } catch (error) {
      dispatch({
        type: FETCH_BRIEF_QUESTIONS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchBriefQuestion = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_BRIEF_QUESTION_REQUEST });
    try {
      const briefQuestion = await briefQuestionService.getBriefQuestionById(id);
      dispatch({
        type: FETCH_BRIEF_QUESTION_SUCCESS,
        payload: briefQuestion,
      });
    } catch (error) {
      dispatch({
        type: FETCH_BRIEF_QUESTION_FAILURE,
        payload: error.message,
      });
    }
  };
};

// Action creator to add a new brief question
export const addBriefQuestion = (questionData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_BRIEF_QUESTION_REQUEST });
    try {
      const newQuestion = await briefQuestionService.createBriefQuestion(questionData);
      dispatch({
        type: ADD_BRIEF_QUESTION_SUCCESS,
        payload: newQuestion,
      });
      toast.success("question ajouté avec succès");

      return Promise.resolve(newQuestion);
    } catch (error) {
      dispatch({
        type: ADD_BRIEF_QUESTION_FAILURE,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};

// Action creator to update an existing brief question
export const updateBriefQuestion = (questionId, questionData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_BRIEF_QUESTION_REQUEST });
    try {
      const updatedQuestion = await briefQuestionService.updateBriefQuestion(
        questionId,
        questionData
      );
      dispatch({
        type: UPDATE_BRIEF_QUESTION_SUCCESS,
        payload: updatedQuestion,
      });
      toast.success("Question mise à jours avec succès");

      return Promise.resolve(updatedQuestion);
    } catch (error) {
      dispatch({
        type: UPDATE_BRIEF_QUESTION_FAILURE,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};

// Action creator to delete an existing brief question
export const deleteBriefQuestion = (questionId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_BRIEF_QUESTION_REQUEST });
    try {
      const deletedQuestion = await briefQuestionService.deleteBriefQuestion(questionId);
      dispatch({
        type: DELETE_BRIEF_QUESTION_SUCCESS,
        payload: deletedQuestion,
      });
      return Promise.resolve(deletedQuestion);
    } catch (error) {
      dispatch({
        type: DELETE_BRIEF_QUESTION_FAILURE,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};
