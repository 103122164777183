import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const PieChartGraph = ({ info, dataType, data }) => {
  const processedData = data.map((item) => ({
    ...item,
    [dataType]: Number(item[dataType]),
  }));
  const COLORS = ["#227c9d", "#17c3b2", "#ffcb77", "#fe6d73"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
    payload,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const labelRadius = outerRadius + 25;
    const labelX = cx + labelRadius * Math.cos(-midAngle * RADIAN);
    const labelY = cy + labelRadius * Math.sin(-midAngle * RADIAN);

    const color = COLORS[index % COLORS.length];

    return (
      <g>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          fontSize="14"
        >
          {Number(parseFloat(payload[dataType]).toFixed(2))}
        </text>
        <text
          x={labelX}
          y={labelY}
          fill={color}
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {payload[info]}
        </text>
      </g>
    );
  };

  return (
    <PieChart width={300} height={250}>
      <Pie
        dataKey={dataType}
        isAnimationActive={false}
        data={processedData}
        cx="50%"
        cy="50%"
        outerRadius={80}
        label={renderCustomizedLabel}
      >
        {processedData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default PieChartGraph;
