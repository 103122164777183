import {
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
} from "../actionTypes";

const initialState = {
  dashboardInfo: {},
  isFetching: false,
  error: null,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardInfo: action.payload,
        isFetching: false,
        error: null,
      };
    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
