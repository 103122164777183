import {
  FETCH_MEETING_BY_ID_REQUEST,
  FETCH_MEETING_BY_ID_SUCCESS,
  FETCH_MEETING_BY_ID_FAILURE,
  FETCH_MEETINGS_REQUEST,
  FETCH_MEETINGS_SUCCESS,
  FETCH_MEETINGS_FAILURE,
} from "../actionTypes";

export default function meetingReducer(
  state = { loading: false, meeting: false, meetings: [], error: "" },
  action
) {
  switch (action.type) {
    case FETCH_MEETING_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_MEETING_BY_ID_SUCCESS:
      return {
        ...state,
        meeting: action.payload,
        loading: false,
        error: "",
      };
    case FETCH_MEETING_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_MEETINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_MEETINGS_SUCCESS:
      return {
        ...state,
        meetings: action.payload,
        loading: false,
        error: "",
      };
    case FETCH_MEETINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
