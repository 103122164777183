import {
  CLI_FETCH_CLIENTS_REQUEST,
  CLI_FETCH_CLIENTS_SUCCESS,
  CLI_FETCH_CLIENTS_FAILURE,
  CLI_FETCH_CLIENT_ORDERS_REQUEST,
  CLI_FETCH_CLIENT_ORDERS_SUCCESS,
  CLI_FETCH_CLIENT_ORDERS_FAILURE,
  CLI_UPLOAD_ORDER_DOCUMENT_REQUEST,
  CLI_UPLOAD_ORDER_DOCUMENT_SUCCESS,
  CLI_UPLOAD_ORDER_DOCUMENT_FAILURE,
  CLI_FETCH_CLIENT_NOTES_REQUEST,
  CLI_FETCH_CLIENT_NOTES_SUCCESS,
  CLI_FETCH_CLIENT_NOTES_FAILURE,
  CLI_FETCH_CLIENT_CALENDAR_REQUEST,
  CLI_FETCH_CLIENT_CALENDAR_SUCCESS,
  CLI_FETCH_CLIENT_CALENDAR_FAILURE,
  CLI_ADD_CLIENT_TICKET_REQUEST,
  CLI_ADD_CLIENT_TICKET_SUCCESS,
  CLI_ADD_CLIENT_TICKET_FAILURE,
  CLI_ADD_NOTE_REQUEST,
  CLI_ADD_NOTE_SUCCESS,
  CLI_FETCH_NOTES_REQUEST,
  CLI_ADD_NOTE_FAILURE,
  CLI_UPDATE_CLIENT_TICKET_REQUEST,
  CLI_UPDATE_CLIENT_TICKET_SUCCESS,
  CLI_UPDATE_CLIENT_TICKET_FAILURE,
  CLI_FETCH_TICKET_REQUEST,
  CLI_FETCH_TICKET_SUCCESS,
  CLI_FETCH_TICKET_FAILURE,
  CLI_FETCH_TINY_CLIENT_REQUEST,
  CLI_FETCH_TINY_CLIENT_SUCCESS,
  CLI_FETCH_TINY_CLIENT_FAILURE,
  CLI_DOWNLOADING_DOCUMENT_REQUEST,
  CLI_DOWNLOADING_DOCUMENT_SUCCESS,
  CLI_DOWNLOADING_DOCUMENT_FAILURE,
} from "../../clientActionTypes";

import { clientsService } from "../../services/cli/clientsService";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

// Très important !!  fetch tiny_client without orders !!

export const fetchClientEntities = () => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_CLIENTS_REQUEST });
    try {
      clientsService.fetchClientEntities().then((data) => {
        dispatch({ type: CLI_FETCH_CLIENTS_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_CLIENTS_FAILURE, payload: err.message });
    }
  };
};

export const fetchCliClientOrders = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_CLIENT_ORDERS_REQUEST });
    try {
      clientsService.fetchCliClientOrders(clientId).then((data) => {
        dispatch({ type: CLI_FETCH_CLIENT_ORDERS_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_CLIENT_ORDERS_FAILURE, payload: err.message });
    }
  };
};

export const fetchCliTinyClient = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_TINY_CLIENT_REQUEST });
    try {
      clientsService.fetchCliTinyClient(clientId).then((data) => {
        dispatch({ type: CLI_FETCH_TINY_CLIENT_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_TINY_CLIENT_FAILURE, payload: err.message });
    }
  };
};

export const fetchCliClientNotes = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_CLIENT_NOTES_REQUEST });
    try {
      clientsService.fetchCliClientNotes(clientId).then((data) => {
        dispatch({ type: CLI_FETCH_CLIENT_NOTES_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_CLIENT_NOTES_FAILURE, payload: err.message });
    }
  };
};

export const fetchCliClientCalendar = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_CLIENT_CALENDAR_REQUEST });
    try {
      clientsService.fetchCliClientCalendar(clientId).then((data) => {
        dispatch({ type: CLI_FETCH_CLIENT_CALENDAR_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_CLIENT_CALENDAR_FAILURE, payload: err.message });
    }
  };
};

export const addClientNote = (clientId, orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: CLI_ADD_NOTE_REQUEST });
    try {
      const newNote = await clientsService.addClientNote(clientId, orderId, data);
      console.log(newNote);
      dispatch({ type: CLI_ADD_NOTE_SUCCESS, payload: newNote });
      // dispatch({ type: CLI_FETCH_NOTES_REQUEST });
    } catch (err) {
      dispatch({ type: CLI_ADD_NOTE_FAILURE, payload: err.message });
    }
  };
};

export const addNewTicketForCliOrder = (clientId, orderId, ticketData) => {
  return async (dispatch) => {
    dispatch({ type: CLI_ADD_CLIENT_TICKET_REQUEST });
    try {
      const ticket = await clientsService.addNewTicketForCliOrder(
        clientId,
        orderId,
        ticketData
      );
      dispatch({ type: CLI_ADD_CLIENT_TICKET_SUCCESS, payload: ticket });
      toast.success("Demande crée avec succès");
    } catch (err) {
      dispatch({ type: CLI_ADD_CLIENT_TICKET_FAILURE, payload: err.message });
    }
  };
};

export const uploadDocument = (clientId, orderId, documentData) => {
  return async (dispatch) => {
    dispatch({ type: CLI_UPLOAD_ORDER_DOCUMENT_REQUEST });
    try {
      const document = await clientsService.uploadDocument(
        clientId,
        orderId,
        documentData
      );
      dispatch({ type: CLI_UPLOAD_ORDER_DOCUMENT_SUCCESS, payload: document });
      toast.success("Document ajouté avec succès");
    } catch (err) {
      console.log(err);
      dispatch({ type: CLI_UPLOAD_ORDER_DOCUMENT_FAILURE, payload: err.message });
    }
  };
};

export function downloadDocument(clientId, orderId, docId) {
  return async (dispatch) => {
    dispatch({ type: CLI_DOWNLOADING_DOCUMENT_REQUEST });
    clientsService
      .downloadDocument(clientId, orderId, docId)
      .then((response) => {
        dispatch({ type: CLI_DOWNLOADING_DOCUMENT_SUCCESS });
        const contentType =
          response.headers["content-type"] || "application/octet-stream";
        const contentDisposition = response.headers["content-disposition"];
        let filename = "telechargement";

        if (contentDisposition) {
          const matches = contentDisposition.match(/filename="([^"]+)"/);
          if (matches.length === 2) {
            filename = matches[1];
          }
        }

        const blob = new Blob([response.data], { type: contentType });
        saveAs(blob, filename);
      })
      .catch((error) => {
        dispatch({ type: CLI_DOWNLOADING_DOCUMENT_FAILURE });
        console.error("Error downloading document:", error);
        // Handle the error here
      });
  };
}

export const fetchCliTicket = (clientId, orderId, ticketId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_TICKET_REQUEST });
    try {
      console.log("here");
      const ticket = await clientsService.fetchCliTicket(clientId, orderId, ticketId);
      dispatch({ type: CLI_FETCH_TICKET_SUCCESS, payload: ticket });
    } catch (err) {
      dispatch({ type: CLI_FETCH_TICKET_FAILURE, payload: err.message });
    }
  };
};

export const updateCliTicket = (clientId, orderId, ticketId, ticketData) => {
  return async (dispatch) => {
    dispatch({ type: CLI_UPDATE_CLIENT_TICKET_REQUEST });
    try {
      const new_discussion = await clientsService.updateCliTicket(
        clientId,
        orderId,
        ticketId,
        ticketData
      );
      dispatch({ type: CLI_UPDATE_CLIENT_TICKET_SUCCESS, payload: new_discussion });
      // dispatch(fetchOrderById(clientId, orderId));
      // dispatch(fetchTicket(clientId, orderId, ticketId));
      toast.success("Demande mise à jours avec succès");
    } catch (err) {
      dispatch({ type: CLI_UPDATE_CLIENT_TICKET_FAILURE, payload: err.message });
    }
  };
};
