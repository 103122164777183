import { useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addEmail, updateEmail, fetchEmail } from "../../../redux/actions/emailActions";
import { Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../NavContext";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EmailForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const [editor, setEditor] = useState(null);

  const { activeNav, setActiveNav } = useContext(NavContext);
  const email = useSelector((state) => state.emailReducer.email);

  useEffect(() => {
    document.title = "ProdOTop - Email - " + email?.title;
  }, [email]);

  useEffect(() => {
    setActiveNav("emails");
  }, [setActiveNav]);

  const variableMapping = {
    CLIENT_NAME: "Nom du client",
    PRODUCT_NAME: "Nom du produit",
    ORDER_START_DATE: "Date de début de commande",
    ORDER_END_DATE: "Date de fin de commande",
    PROD_RESPONSIBLE: "Responsable Prod",
    RC_RESPONSIBLE: "Responsable RC",
  };

  const insertVariable = (variable) => {
    if (editor && editor.model) {
      editor.model.change((writer) => {
        writer.insertText(
          `[${variable}]`,
          editor.model.document.selection.getFirstPosition()
        );
      });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchEmail(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (email) {
      reset({
        title: email.title,
        subject: email.subject,
        text: email.text,
      });
    }
  }, [dispatch, email, reset]);

  const onSubmit = (data) => {
    if (email) {
      dispatch(updateEmail(email._id, data));
    } else {
      dispatch(addEmail(data));
    }
    navigate("/emails");
    reset();
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/emails">Emails</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              {email ? "Modification de l'email" : "Nouvel email"}
            </li>
          </ol>
        </nav>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex flex-column mx-auto">
            <div className="card mb-4 pb-2">
              <div className="card-header text-left">
                {email ? (
                  <h6>
                    <span className="email-title px-3 text-white">
                      Modification de l'email : {email.subject}
                    </span>
                  </h6>
                ) : (
                  <h6>
                    <span className="email-title px-3 text-white">Nouvel email</span>
                  </h6>
                )}
              </div>
              <div className="card-body">
                <Row>
                  <Col md={12} className="mb-3">
                    <div className="mb-3">
                      <label htmlFor="title" className="form-label">
                        Titre de l'email :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        {...register("title", { required: true })}
                      />
                      {errors.title && (
                        <div className="text-danger">Titre de l'email requis</div>
                      )}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label htmlFor="subject" className="form-label">
                        Objet de l'email :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        {...register("subject", { required: true })}
                      />
                      {errors.subject && (
                        <div className="text-danger">Objet de l'email requis</div>
                      )}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-1">
                      <label>Variables disponibles : </label>
                      <div className="flex gap-2">
                        {Object.entries(variableMapping).map(
                          ([variable, displayName]) => (
                            <span
                              key={variable}
                              onClick={() => insertVariable(variable)}
                              className="btn btn-outline-primary me-2 ms-2"
                            >
                              {displayName}
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label htmlFor="text" className="form-label">
                        Texte de l'email :
                      </label>
                      <Controller
                        name={`text`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <CKEditor
                            editor={ClassicEditor}
                            data={field.value}
                            onReady={(editorInstance) => {
                              console.log("CKEditor is ready");
                              setEditor(editorInstance);
                            }}
                            onChange={(event, editorInstance) => {
                              const data = editorInstance.getData();
                              field.onChange(data);
                            }}
                            placeholder="Nouveau Email ..."
                          />
                        )}
                      />
                      {errors.text && (
                        <div className="text-danger">Texte de l'email requis</div>
                      )}
                    </div>
                  </Col>

                  <div className="mb-3">
                    <button type="submit" className="btn bg-gradient-dark w-30 mt-4 mb-0">
                      Enregistrer
                    </button>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </form>

      <Row className="mb-3">
        <Col className="mb-3">
          <Link to="/emails" className="link-action back">
            Retour
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default EmailForm;
