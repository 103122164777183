import axios from "axios";

const API_URL = "/api/cli/meeting";

const fetchMeetingById = (id) => {
  return axios.get(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

const fetchMeetings = () => {
  return axios.get(`${API_URL}/`).then((response) => {
    return response.data;
  });
};

export const meetingService = {
  fetchMeetingById,
  fetchMeetings,
};
