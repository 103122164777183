import {
  FETCH_CALENDAR_REQUEST,
  FETCH_CALENDAR_SUCCESS,
  FETCH_CALENDAR_FAILURE,
  UPDATE_CALENDAR_REQUEST,
  UPDATE_CALENDAR_FAILURE,
  FETCH_CALENDAR_TODOS_SUCCESS,
  DEVIDE_TODOS_SUCCESS,
  ADD_TODO_SUCCESS,
  DELETE_CALENDARTODO_SUCCESS,
  UNDRAG_CALENDARTODO_SUCCESS,
} from "../actionTypes";
import { calendarService } from "../services/calendarService";
import { toast } from "react-toastify";

// react-query

export const scheduleTodos = async (selectedTodos, selectedDate) => {
  console.log(selectedTodos, selectedDate);
  const response = await calendarService.scheduleTodos({ selectedTodos, selectedDate });
  return response;
};

export const fetchCalendarRequest = () => {
  return {
    type: FETCH_CALENDAR_REQUEST,
  };
};

export const addToDraggedEvents = (data) => {
  return {
    type: "ADD_TO_DRAGGED_EVENTS",
    payload: data,
  };
};

export const UpdateDraggedEvent = (eventId, updatedData) => {
  return {
    type: "UPDATE_DRAGGED_EVENT",
    payload: {
      eventId,
      updatedData,
    },
  };
};

export const addToUndraggedEvents = (data) => {
  return {
    type: "ADD_TO_UNDRAGGED_EVENTS",
    payload: data,
  };
};

export const removeFromUndraggedEvents = (data) => {
  return {
    type: "REMOVE_FROM_UNDRAGGED_EVENTS",
    payload: data,
  };
};

export const addTodo = (data) => {
  return {
    type: ADD_TODO_SUCCESS,
    payload: data,
  };
};

export const delTodo = (id) => {
  return {
    type: DELETE_CALENDARTODO_SUCCESS,
    payload: id,
  };
};

export const undragTodo = (event) => {
  return {
    type: UNDRAG_CALENDARTODO_SUCCESS,
    payload: event,
  };
};

export const fetchCalendarSuccess = (data) => {
  return {
    type: FETCH_CALENDAR_SUCCESS,
    payload: data,
  };
};

export const fetchCalendarTodosSuccess = (data) => {
  return {
    type: FETCH_CALENDAR_TODOS_SUCCESS,
    payload: data,
  };
};

export const devideSuccess = (data) => {
  return {
    type: DEVIDE_TODOS_SUCCESS,
    payload: data,
  };
};

export const fetchCalendarFailure = (error) => {
  return {
    type: FETCH_CALENDAR_FAILURE,
    payload: error,
  };
};
export const updateCalendarRequest = () => {
  return {
    type: UPDATE_CALENDAR_REQUEST,
  };
};

export const updateCalendarFailure = (error) => {
  return {
    type: UPDATE_CALENDAR_FAILURE,
    payload: error,
  };
};

//  dragged
export const fetchCalendarInfo = (actAsData) => {
  return (dispatch) => {
    dispatch(fetchCalendarRequest());
    calendarService
      .getCalendarInfo(actAsData)
      .then((data) => {
        dispatch(fetchCalendarSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchCalendarFailure(error));
      });
  };
};

// undragged
export const fetchTodosInfo = (actAsData) => {
  return (dispatch) => {
    calendarService
      .getTodos(actAsData)
      .then((data) => {
        dispatch(fetchCalendarTodosSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const createTask = (data) => {
  return (dispatch) => {
    calendarService
      .createTask(data)
      .then((data) => {
        dispatch(addTodo(data.new_todo));
        toast.success("Tâche ajouté avec succès");
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const deleteTask = (todoId) => {
  return (dispatch) => {
    calendarService
      .deleteTask(todoId)
      .then((data) => {
        dispatch(delTodo(todoId));
        toast.success("Tâche supprimé avec succès");
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const duplicateTask = (todoId) => {
  return (dispatch) => {
    calendarService
      .duplicateTask(todoId)
      .then((data) => {
        dispatch(addToUndraggedEvents(data.duplicatedTodo));
        toast.success("Tâche dupliqué avec succès");
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const undragTask = (event) => {
  return (dispatch) => {
    calendarService
      .undragTask(event._id)
      .then((data) => {
        dispatch(undragTodo(event));
        toast.success("Tâche retiré avec succès");
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const unpdateTask = (id, data) => {
  return (dispatch) => {
    calendarService
      .unpdateTask(id, data)
      .then((response) => {
        toast.success("Tâche modifié avec succès");
        dispatch(UpdateDraggedEvent(id, data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

//devideTask
export const devideTask = (taskId, data) => {
  return (dispatch) => {
    calendarService
      .devideTask(taskId, data)
      .then((data) => {
        dispatch(devideSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const updateTodoEvent = (id, updatedData) => {
  return (dispatch) => {
    dispatch(updateCalendarRequest());
    calendarService
      .updateTodoEvent(id, updatedData)
      .then((data) => {
        dispatch(UpdateDraggedEvent(id, updatedData));
      })
      .catch((error) => {
        dispatch(updateCalendarFailure(error));
      });
  };
};
