import { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  addECalendar,
  updateECalendar,
  fetchECalendars,
  fetchOrderById,
} from "../../../redux/actions/eCalendarActions";
import { fetchClientOrders } from "../../../redux/actions/clientActions";

import { Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../NavContext";
import OrderNavigation from "../clients/orders/order/orderNavigation";

const ECalendarForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    setActiveNav("eCalendars");
  }, [setActiveNav]);

  const { clientId, orderId, id } = useParams();

  useEffect(() => {
    document.title = "ProdOTop - Calendrier editorial";
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const eCalendars = useSelector((state) => state.eCalendarReducer.eCalendars);
  const isFetching = useSelector((state) => state.eCalendarReducer.isFetching);
  const eCalendar = eCalendars.find((eCalendar) => eCalendar._id === id);
  const order = useSelector((state) => state.eCalendarReducer.order);
  const client = useSelector((state) => state.clientReducer.client);

  useEffect(() => {
    if (!isFetching) {
      dispatch(fetchECalendars(orderId));
    }
    if (eCalendar) {
      reset({
        title: eCalendar.title,
        objectives: eCalendar.objectives,
        tone_of_voice: eCalendar.tone_of_voice,
        target: eCalendar.target,
        strategy: eCalendar.strategy,
        communication_plan: eCalendar.communication_plan,
      });
    }
  }, [dispatch, eCalendar, reset, isFetching]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientOrders(clientId));
    }
  }, [dispatch, clientId]);

  const onSubmit = (data) => {
    if (eCalendar) {
      dispatch(updateECalendar(orderId, eCalendar._id, data));
    } else {
      dispatch(addECalendar(orderId, data));
    }
    navigate(`/clients/${clientId}/orders/${orderId}/editorial_calendars`);
    reset();
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/clients">clients</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/clients/${order?.client?._id}/orders`}>
                {order?.client?.companyName}
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {order?.product?.name}
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              <Link
                to={`/clients/${order?.client?._id}/orders/${orderId}/editorial_calendars`}
              >
                Calendriers éditoriaux
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              {eCalendar
                ? "Modifier le calendrier éditorial"
                : "Nouveau calendrier éditorial"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="order-container">
        {order && <OrderNavigation order={order} client={client} currentTab="ce" />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <section>
            <Row>
              <Col className="d-flex flex-column mx-auto">
                <div className="card medium-card mb-4 pb-2 purple-border">
                  <div className="card-header text-left">
                    <Col md={6}>
                      {eCalendar ? (
                        <h6>
                          <span className="product-title px-3 text-white">
                            Modification du calendrier éditorial : {eCalendar.title}{" "}
                          </span>
                        </h6>
                      ) : (
                        <h6>
                          <span className="product-title px-3 text-white">
                            Nouveau calendrier éditorial
                          </span>
                        </h6>
                      )}
                    </Col>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <label htmlFor="title" className="form-label">
                            Titre
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="title"
                            {...register("title", { required: true })}
                          />
                          {errors.title && (
                            <div className="text-danger">Titre requis</div>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label htmlFor="objectives" className="form-label">
                            Objectifs
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="objectives"
                            {...register("objectives")}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label htmlFor="tone_of_voice" className="form-label">
                            Tone of voice
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="tone_of_voice"
                            {...register("tone_of_voice")}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label htmlFor="target" className="form-label">
                            Cible
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="target"
                            {...register("target")}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label htmlFor="communication_plan" className="form-label">
                            Plan de communication
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="communication_plan"
                            {...register("communication_plan")}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <label htmlFor="strategy" className="form-label">
                            Strategy, sujets ...
                          </label>
                          <textarea
                            className="form-control"
                            id="strategy"
                            {...register("strategy")}
                          />
                        </div>
                      </Col>
                      <div className="">
                        <button
                          type="submit"
                          className="btn bg-gradient-dark w-30 mt-4 mb-0"
                        >
                          Valider
                        </button>
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Link
                  to={`/clients/${order?.client?._id}/orders/${orderId}/editorial_calendars`}
                  className="link-action back"
                >
                  Retour
                </Link>
              </Col>
            </Row>
          </section>
        </form>
      </div>
    </>
  );
};

export default ECalendarForm;
