import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faPen,
  faCommentDots,
  faEnvelope,
  faListCheck,
  faStar,
  faMedal,
  faFileContract,
  faCalendarDays,
  faChartSimple,
  faBook,
} from "@fortawesome/free-solid-svg-icons";

const OrderNavigation = ({ client, order, currentTab }) => {
  const clientId = client._id;
  return (
    <div className="card">
      <div className="order-navigation flex flex-wrap flex-row gap-3 py-2 px-3 justify-content-between">
        <div className={`${currentTab === "edit" ? "active-tab" : ""}`}>
          <Link to={`/clients/${clientId}/orders/${order._id}`}>
            <FontAwesomeIcon icon={faPen} />
            <span className="mx-2">Modifier</span>
          </Link>
        </div>
        <div className={`${currentTab === "tickets" ? "active-tab" : ""}`}>
          <Link to={`/clients/${clientId}/orders/${order._id}/tickets`} className="">
            <FontAwesomeIcon icon={faCommentDots} />
            <span className="mx-2">Demandes</span>
          </Link>
        </div>

        <div className={`${currentTab === "notes" ? "active-tab" : ""}`}>
          <Link to={`/clients/${clientId}/orders/${order._id}/notes`} className="">
            <FontAwesomeIcon icon={faBook} />
            <span className="mx-2">Notes</span>
          </Link>
        </div>

        {order.product.include_documents && (
          <div className={`${currentTab === "documents" ? "active-tab" : ""}`}>
            <Link to={`/clients/${clientId}/orders/${order._id}/documents`} className="">
              <FontAwesomeIcon icon={faFile} />
              <span className="mx-2">Documents</span>
            </Link>
          </div>
        )}
        <div className={`${currentTab === "emails" ? "active-tab" : ""}`}>
          <Link to={`/clients/${clientId}/orders/${order._id}/emails`} className="">
            <FontAwesomeIcon icon={faEnvelope} />
            <span className="mx-2">E-mails</span>
          </Link>
        </div>

        {(((client.facebook_key || order.facebook_key) && order.product.fbInsight) ||
          ((client.gads_key || order.gads_key) && order.product.gadsInsight)) && (
          <div className={`${currentTab === "insight" ? "active-tab" : ""}`}>
            <Link to={`/clients/${clientId}/orders/${order._id}/insight`} className="">
              <FontAwesomeIcon icon={faChartSimple} />
              <span className="mx-2">Rapports</span>
            </Link>
          </div>
        )}

        <div className={`${currentTab === "surveys" ? "active-tab" : ""}`}>
          <Link to={`/clients/${clientId}/orders/${order._id}/survey`} className="">
            <FontAwesomeIcon icon={faStar} />
            <span className="mx-2">Satisfactions</span>
          </Link>
        </div>
        {order.product.include_e_calendars && (
          <div className={`${currentTab === "ce" ? "active-tab" : ""}`}>
            <Link
              to={`/clients/${clientId}/orders/${order._id}/editorial_calendars`}
              className=""
            >
              <FontAwesomeIcon icon={faCalendarDays} />
              <span className="mx-2">Calendrier E.</span>
            </Link>
          </div>
        )}
        <div className={`${currentTab === "todos" ? "active-tab" : ""}`}>
          <Link to={`/clients/${clientId}/orders/${order._id}/todos`} className="">
            <FontAwesomeIcon icon={faListCheck} />
            <span className="mx-2">Todos</span>
          </Link>
        </div>
        <div className={`${currentTab === "brief_responses" ? "active-tab" : ""}`}>
          <Link
            to={`/clients/${clientId}/orders/${order._id}/brief_responses`}
            className=""
          >
            <FontAwesomeIcon icon={faFileContract} />
            <span className="mx-2">Cahier de charge</span>
          </Link>
        </div>
        <div className={`${currentTab === "quality_checks" ? "active-tab" : ""}`}>
          <Link
            to={`/clients/${clientId}/orders/${order._id}/quality_checks`}
            className=""
          >
            <FontAwesomeIcon icon={faMedal} />
            <span className="mx-2">Suivi de qualité</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderNavigation;
