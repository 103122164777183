import { useEffect, useContext, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClientOrders,
  deleteClientOrder,
  fetchOrderById,
  addClientOrder,
  updateClientOrder,
  estimateOrderCost,
} from "../../../../redux/actions/clientActions";
import { fetchUsers } from "../../../../redux/actions/userActions";
import { fetchProducts } from "../../../../redux/actions/productActions";
import { Row, Col, Dropdown } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { NavContext } from "../../../NavContext";
import Select from "react-select";
import OrderNavigation from "./order/orderNavigation";
import DepartmentName from "../../../shared/departments/departmentName";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleXmark } from "@fortawesome/free-solid-svg-icons";

const OrderForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId, orderId } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
    getValues,
    setError,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      team2Responsible: "",
      team3Responsible: "",
    },
  });
  const { activeNav, setActiveNav } = useContext(NavContext);
  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);

  const products = useSelector((state) => state.productReducer.products);
  const users = useSelector((state) => state.userReducer.users);
  const isFetching = useSelector((state) => state.productReducer.isFetching);
  const currentUser = useSelector((state) => state.authReducer.user.user);

  const [show, setShow] = useState(false);

  const BOSS = currentUser.role === "boss";
  const MANAGER = currentUser.role === "manager";

  const TEAM1 = currentUser.departement === "Team1";
  const TEAM2 = currentUser.departement === "Team2";
  const TEAM3 = currentUser.departement === "Team3";
  const TEAM4 = currentUser.departement === "Team4";
  const TEAM5 = currentUser.departement === "Team5";

  const order = useSelector((state) => state.clientReducer.order);
  const client = useSelector((state) => state.clientReducer.client);

  const order_estimation = useSelector((state) => state.clientReducer.order_estimation);
  const estimation_loading = useSelector(
    (state) => state.clientReducer.estimation_loading
  );
  const estimation_unauthorized = useSelector(
    (state) => state.clientReducer.estimation_unauthorized
  );

  useEffect(() => {
    document.title = order
      ? `ProdOTop - ${order?.product?.name}  du client ${client?.companyName}`
      : "Nouvelle commande ";
  }, [client, order]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientOrders(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (!isFetching) {
      dispatch(fetchUsers());
      dispatch(fetchProducts());
    }
  }, []);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(clientId, orderId));
    }
  }, [dispatch, orderId, clientId]);

  useEffect(() => {
    if (!orderId) {
      setValue("product", "");
      setValue("contractDuration", "");
      setValue("team1Responsible", "");
      setValue("team2Responsible", "");
      setValue("team3Responsible", "");
      setValue("team4Responsible", "");
      setValue("team5Responsible", "");
      setValue("startDate", "");
      setValue("endDate", "");
    }
  }, []);
  const productsList = useMemo(() => {
    return products
      ? products.map((option) => ({
          value: option._id,
          label: option?.name,
        }))
      : [];
  }, [products]);

  const validateTeams = () => {
    const responsibleFields = [
      getValues("team1Responsible"),
      getValues("team2Responsible"),
      getValues("team3Responsible"),
      getValues("team4Responsible"),
      getValues("team5Responsible"),
    ];
    const filled = responsibleFields.filter(Boolean).length;
    if (filled < 2) {
      setError("teamResponsible", {
        type: "manual",
        message: "Au moins deux responsables sont requis",
      });
    } else {
      clearErrors("teamResponsible");
    }

    return filled;
  };

  const onSubmit = (data) => {
    const filled = validateTeams();
    if (filled >= 2) {
      const formData = {
        ...data,
        product: data.product.value,
      };

      if (orderId) {
        formData.team1Accepted = data.team1Accepted;
        formData.team2Accepted = data.team2Accepted;
        formData.team3Accepted = data.team3Accepted;
        formData.team4Accepted = data.team4Accepted;
        formData.team5Accepted = data.team5Accepted;
        formData.produced = data.produced;
        formData.orderCompleted = data.orderCompleted;

        dispatch(updateClientOrder(clientId, orderId, formData));
      } else {
        dispatch(addClientOrder(clientId, formData));
      }
      navigate(`/clients/${clientId}/orders`);
      reset();
    }
  };

  useEffect(() => {
    if (order && order.product && productsList) {
      const defaultProduct = productsList.find(
        (product) => product.value === order.product?._id
      );

      reset({
        contractDuration: order.contractDuration,
        team1Responsible: order.team1Responsible?._id,
        team2Responsible: order.team2Responsible?._id,
        team3Responsible: order.team3Responsible?._id,
        team4Responsible: order.team4Responsible?._id,
        team5Responsible: order.team5Responsible?._id,
        team1Accepted: order.team1Accepted,
        team2Accepted: order.team2Accepted,
        team3Accepted: order.team3Accepted,
        team4Accepted: order.team4Accepted,
        team5Accepted: order.team5Accepted,
        shared: order.shared,

        produced: order.produced,
        orderCompleted: order.orderCompleted,
        startDate: order.startDate ? moment(order.startDate).format("YYYY-MM-DD") : "",
        endDate: order.endDate ? moment(order.endDate).format("YYYY-MM-DD") : "",
        product: defaultProduct,
      });
    }
  }, [orderId, order, productsList, reset]);

  // Filter users by departement
  const team1Users = users.filter(
    (user) => user.departement === "Team1" && user.role !== "boss"
  );
  const team2Users = users.filter((user) => user.departement === "Team2");
  const team3Users = users.filter((user) => user.departement === "Team3");
  const team4Users = users.filter((user) => user.departement === "Team4");
  const team5Users = users.filter((user) => user.departement === "Team5");

  const handleDelete = (orderId) => {
    dispatch(deleteClientOrder(clientId, orderId));
    navigate(`/clients/${clientId}/orders`);
  };

  const handleShowModal = () => {
    dispatch(estimateOrderCost(clientId, orderId));
    setShow(true);
  };

  const team1Responsible = watch("team1Responsible");
  const team2Responsible = watch("team2Responsible");
  const team3Responsible = watch("team3Responsible");
  const team4Responsible = watch("team4Responsible");
  const team5Responsible = watch("team5Responsible");

  return (
    <>
      <div className="pb-4">
        <Row>
          <Col md={9}>
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  <Link to="/clients">clients</Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/clients/${client?._id}/orders`}>{client?.companyName}</Link>
                </li>

                {order && (
                  <li
                    className="breadcrumb-item text-sm text-dark flex p-0"
                    aria-current="page"
                  >
                    <Dropdown className="dropdown-orders">
                      <Dropdown.Toggle id="dropdown-basic">
                        {order.product?.name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {client?.orders?.map((cl_order) => (
                          <Dropdown.Item
                            as={Link}
                            to={`/clients/${clientId}/orders/${cl_order._id}`}
                            key={cl_order._id}
                          >
                            {cl_order.product?.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                )}

                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  {orderId ? "Modification de la commande" : "Nouvelle commande"}
                </li>
              </ol>
            </nav>
          </Col>
          <Col md={3}>
            <div className="text-end">
              {(BOSS || MANAGER) && (
                <button
                  className="btn btn-link text-danger text-gradient mb-0 px-2 text-end"
                  type="button"
                  onClick={() => handleDelete(order._id)}
                >
                  Supprimer commande
                </button>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="order-container">
        {order && <OrderNavigation order={order} client={client} currentTab="edit" />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="row">
            <div className="col-12">
              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-header">
                  <Row className="mb-3">
                    <Col md={12} className="flex gap-3 justify-content-between">
                      <h6>
                        <span className="product-title px-3 text-white">
                          {orderId ? "Modifier la commande" : "Ajouter une commande"} pour{" "}
                          {client && client.companyName}
                        </span>
                      </h6>

                      {orderId && (
                        <span
                          className="btn btn-outline-primary mb-0 mx-3"
                          onClick={handleShowModal}
                        >
                          Estimation de coût
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="card-body">
                  <Row className="mb-3">
                    <Col md={9}>
                      <label htmlFor="product" className="form-label">
                        Produit :
                      </label>

                      <Controller
                        name="product"
                        control={control}
                        rules={{ required: "Ce champ est obligatoire" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={productsList}
                            isClearable={true}
                            placeholder="Sélectionnez un produit"
                          />
                        )}
                      />

                      {errors.product && (
                        <div className="text-danger">
                          Le choix d'un produit est requis
                        </div>
                      )}
                    </Col>
                    <Col md={3}>
                      <label htmlFor="duration" className="form-label">
                        Durée du contrat (en mois) :
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="duration"
                        {...register("contractDuration", { required: true })}
                        defaultValue={order ? order.contractDuration : ""}
                      />
                      {errors.contractDuration && (
                        <div className="text-danger">La durée du contrat est requise</div>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <label htmlFor="start" className="form-label">
                        Date de début :
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="start"
                        {...register("startDate")}
                      />
                    </Col>
                    <Col md={6}>
                      <label htmlFor="end" className="form-label">
                        Date de fin :
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="end"
                        {...register("endDate")}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={3}>
                      <label htmlFor="team1Responsible" className="form-label">
                        Responsable <DepartmentName code="Team1" />
                      </label>
                      <select
                        id="team1Responsible"
                        className="form-control"
                        {...register("team1Responsible")}
                        onChange={(e) => {
                          setValue("team1Responsible", e.target.value);
                          clearErrors("teamResponsible");
                        }}
                      >
                        <option value="">Choisissez un responsable Team1...</option>
                        {team1Users.map((user) => (
                          <option key={user._id} value={user._id}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md={3}>
                      <label htmlFor="team2Responsible" className="form-label">
                        Responsable <DepartmentName code="Team2" />
                      </label>
                      <select
                        id="team2Responsible"
                        className="form-control"
                        {...register("team2Responsible")}
                        onChange={(e) => {
                          setValue("team2Responsible", e.target.value);
                          clearErrors("teamResponsible");
                        }}
                      >
                        <option value="">Choisissez un responsable Team2...</option>
                        {team2Users.map((user) => (
                          <option key={user._id} value={user._id}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md={3}>
                      <label htmlFor="team3Responsible" className="form-label">
                        Responsable <DepartmentName code="Team3" />
                      </label>
                      <select
                        id="team3Responsible"
                        className="form-control"
                        {...register("team3Responsible")}
                        onChange={(e) => {
                          setValue("team3Responsible", e.target.value);
                          clearErrors("teamResponsible");
                        }}
                      >
                        <option value="">Choisissez un responsable Team3...</option>
                        {team3Users.map((user) => (
                          <option key={user._id} value={user._id}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md={3}>
                      <label htmlFor="team4Responsible" className="form-label">
                        Responsable <DepartmentName code="Team4" />
                      </label>
                      <select
                        id="team4Responsible"
                        className="form-control"
                        {...register("team4Responsible", { required: false })}
                        onChange={(e) => {
                          setValue("team5Responsible", e.target.value);
                          clearErrors("teamResponsible");
                        }}
                      >
                        <option value="">Choisissez un responsable Team4...</option>
                        {team4Users.map((user) => (
                          <option key={user._id} value={user._id}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md={3}>
                      <label htmlFor="team5Responsible" className="form-label">
                        Responsable <DepartmentName code="Team5" />
                      </label>
                      <select
                        id="team5Responsible"
                        className="form-control"
                        {...register("team5Responsible", { required: false })}
                        onChange={(e) => {
                          setValue("team5Responsible", e.target.value);
                          clearErrors("teamResponsible");
                        }}
                      >
                        <option value="">Choisissez un responsable Team5...</option>
                        {team5Users.map((user) => (
                          <option key={user._id} value={user._id}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>

                  {errors.teamResponsible && (
                    <p className="text-danger">{errors.teamResponsible.message}</p>
                  )}
                  {orderId && (
                    <>
                      <Row className="mb-2">
                        {team1Responsible && (
                          <Col md={3}>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="team1Accepted"
                                {...register("team1Accepted")}
                                disabled={!TEAM1}
                              />
                              <label className="form-check-label" htmlFor="team1Accepted">
                                Accepté par Team1 ?
                              </label>
                            </div>
                          </Col>
                        )}

                        {team2Responsible && (
                          <Col md={3}>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="team2Accepted"
                                {...register("team2Accepted")}
                                disabled={!TEAM2}
                              />
                              <label className="form-check-label" htmlFor="team2Accepted">
                                Accepté par Team2 ?
                              </label>
                            </div>
                          </Col>
                        )}
                        {team3Responsible && (
                          <Col md={3}>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="team3Accepted"
                                {...register("team3Accepted")}
                                disabled={!TEAM3}
                              />
                              <label className="form-check-label" htmlFor="team3Accepted">
                                Accepté par Team3 ?
                              </label>
                            </div>
                          </Col>
                        )}

                        {team4Responsible && (
                          <Col md={3}>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="team4Accepted"
                                {...register("team4Accepted")}
                                disabled={!TEAM4}
                              />
                              <label className="form-check-label" htmlFor="team4Accepted">
                                Accepté par Team4 ?
                              </label>
                            </div>
                          </Col>
                        )}
                        {team5Responsible && (
                          <Col md={3}>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="team5Accepted"
                                {...register("team5Accepted")}
                                disabled={!TEAM5}
                              />
                              <label className="form-check-label" htmlFor="team5Accepted">
                                Accepté par Team5 ?
                              </label>
                            </div>
                          </Col>
                        )}
                      </Row>
                      <hr />
                      <Row className="mb-2">
                        <Col md={6}>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="produced"
                              {...register("produced")}
                            />
                            <label className="form-check-label" htmlFor="produced">
                              Commande en production
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={6}>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="orderCompleted"
                              {...register("orderCompleted")}
                            />
                            <label className="form-check-label" htmlFor="orderCompleted">
                              Clôturer la commande
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}

                  <hr />
                  <Row>
                    <Col md={12}>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="shared"
                          {...register("shared")}
                        />
                        <label className="form-check-label" htmlFor="shared">
                          Partager avec le client
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} sm={6} md={4}>
                      <button type="submit" className="btn bg-gradient-dark mt-4 mb-0">
                        Enregistrer
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Row>
          <Row className="mb-3">
            <Col>
              <Link to={`/clients/${clientId}/orders`} className="link-action back">
                Retour
              </Link>
            </Col>
          </Row>
        </form>
      </div>
      {show && !estimation_loading && (
        <CostModal
          handleClose={() => setShow(false)}
          order_estimation={order_estimation}
          estimation_unauthorized={estimation_unauthorized}
        />
      )}
    </>
  );
};

const CostModal = ({ handleClose, order_estimation, estimation_unauthorized }) => {
  return (
    <AnimatePresence key={1}>
      <motion.div
        key={2}
        className="expanded-modal"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.7,
        }}
      >
        <button className="close" onClick={handleClose}>
          <FontAwesomeIcon icon={faRectangleXmark} color="#344866" />
        </button>
        <motion.div
          key={1}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.7,
          }}
        >
          <motion.div className="expanded-content">
            <div className="container-fluid py-4">
              <div className="row">
                <div className="col-12">
                  <div className=" mb-4">
                    <div className="card-body px-0 pt-0 pb-2">
                      {estimation_unauthorized && (
                        <p className="text-danger">{estimation_unauthorized}</p>
                      )}
                      {order_estimation && order_estimation?.taskDetails && (
                        <div className="table-responsive p-0">
                          <table className="table align-items-center mb-5">
                            <thead>
                              <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Tâche
                                </th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Responsable
                                </th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Temps alloué
                                </th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Répétition
                                </th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Coût
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {order_estimation?.taskDetails.map((td, index) => (
                                <tr key={index}>
                                  <td>{td.taskName}</td>
                                  <td>{td.responsibleName}</td>
                                  <td>{td.taskTime} min</td>
                                  <td>{td.quantity}</td>
                                  <td>{td.taskCost} €</td>
                                </tr>
                              ))}
                              <tr key="uniq_1">
                                <td className="text-lg font-weight-bolder">
                                  <b>Coût d'estimation</b>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="text-uppercase text-lg font-weight-bolder">
                                  {order_estimation.totalCost} €
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <hr />
                          <div className="flex flex-wrap gap-3 justify-content-between ">
                            <div className="card bg-ligth-primary p-5 shadow-none text-center">
                              <div className="text-lg font-weight-bolder">
                                Coût presté
                              </div>
                              <span className="">
                                {order_estimation.reality.totalCostDone} €
                              </span>
                            </div>

                            <div className="card bg-ligth-primary p-5 shadow-none text-center">
                              <div className="text-lg font-weight-bolder">
                                Coût à prester
                              </div>
                              <span className="">
                                {order_estimation.reality.totalCostNotDone} €
                              </span>
                            </div>

                            <div className="card bg-ligth-primary p-5 shadow-none text-center">
                              <div className="text-lg font-weight-bolder">Coût total</div>
                              <span className="">
                                {order_estimation.reality.totalCostDone +
                                  order_estimation.reality.totalCostNotDone}{" "}
                                €
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default OrderForm;
