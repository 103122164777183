import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USER_SURVEY_STATS_REQUEST,
  FETCH_USER_SURVEY_STATS_SUCCESS,
  FETCH_USER_SURVEY_STATS_FAILURE,
  FETCH_USER_EFFICIENCY_STATS_REQUEST,
  FETCH_USER_EFFICIENCY_STATS_SUCCESS,
  FETCH_USER_EFFICIENCY_STATS_FAILURE,
  FETCH_USER_WORKLOAD_STATS_REQUEST,
  FETCH_USER_WORKLOAD_STATS_SUCCESS,
  FETCH_USER_WORKLOAD_STATS_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from "../actionTypes";

import { userService } from "../services/userService";
import { toast } from "react-toastify";
// Action Creator pour récupérer la liste des utilisateurs
export const fetchUsers = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_USERS_REQUEST });
    try {
      const users = await userService.getAllUsers();
      dispatch({ type: FETCH_USERS_SUCCESS, payload: users });
    } catch (err) {
      dispatch({ type: FETCH_USERS_FAILURE, payload: err.message });
    }
  };
};

export const fetchsUserSurveyStat = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_USER_SURVEY_STATS_REQUEST });
    try {
      const users = await userService.fetchsUserSurveyStat(id);
      dispatch({ type: FETCH_USER_SURVEY_STATS_SUCCESS, payload: users });
    } catch (err) {
      dispatch({ type: FETCH_USER_SURVEY_STATS_FAILURE, payload: err.message });
    }
  };
};

export const fetchsUserEfficiencyStat = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_USER_EFFICIENCY_STATS_REQUEST });
    try {
      const users = await userService.fetchsUserEfficiencyStat(id);
      dispatch({ type: FETCH_USER_EFFICIENCY_STATS_SUCCESS, payload: users });
    } catch (err) {
      dispatch({ type: FETCH_USER_EFFICIENCY_STATS_FAILURE, payload: err.message });
    }
  };
};
export const fetchsUserWorkloadStat = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_USER_WORKLOAD_STATS_REQUEST });
    try {
      const users = await userService.fetchsUserWorkloadStat(id);
      dispatch({ type: FETCH_USER_WORKLOAD_STATS_SUCCESS, payload: users });
    } catch (err) {
      dispatch({ type: FETCH_USER_WORKLOAD_STATS_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour ajouter un utilisateur
export const addUser = (userData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_USER_REQUEST });
    try {
      const newUser = await userService.createUser(userData);
      toast.success("Employé ajouté avec succès");

      dispatch({ type: ADD_USER_SUCCESS, payload: newUser });
    } catch (err) {
      console.log(err);
      dispatch({ type: ADD_USER_FAILURE, payload: err.response.data.error_message });
    }
  };
};

// Action Creator pour modifier un utilisateur
export const updateUser = (userId, userData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_USER_REQUEST });
    try {
      const updatedUser = await userService.updateUser(userId, userData);
      toast.success("Employé mis à jours avec succès");
      dispatch({ type: UPDATE_USER_SUCCESS, payload: updatedUser.user });
    } catch (err) {
      dispatch({ type: UPDATE_USER_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour modifier un utilisateur
export const updatePassword = (userId, userData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_USER_REQUEST });
    try {
      const updatedUser = await userService.updateUserPassword(userId, userData);
      toast.success("Employé mis à jours avec succès");
      dispatch({ type: UPDATE_USER_SUCCESS, payload: updatedUser.user });
    } catch (err) {
      dispatch({ type: UPDATE_USER_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour supprimer un utilisateur
export const deleteUser = (userId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_USER_REQUEST });
    try {
      await userService.deleteUser(userId);
      dispatch({ type: DELETE_USER_SUCCESS, payload: userId });
    } catch (err) {
      dispatch({ type: DELETE_USER_FAILURE, payload: err.message });
    }
  };
};
