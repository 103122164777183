import {
  FETCH_ECALENDARS_REQUEST,
  FETCH_ECALENDARS_SUCCESS,
  FETCH_ECALENDARS_FAILURE,
  ADD_ECALENDAR_REQUEST,
  ADD_ECALENDAR_SUCCESS,
  ADD_ECALENDAR_FAILURE,
  UPDATE_ECALENDAR_REQUEST,
  UPDATE_ECALENDAR_SUCCESS,
  UPDATE_ECALENDAR_FAILURE,
  DELETE_ECALENDAR_REQUEST,
  DELETE_ECALENDAR_SUCCESS,
  DELETE_ECALENDAR_FAILURE,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  FETCH_ECALENDAR_REQUEST,
  FETCH_ECALENDAR_SUCCESS,
  FETCH_ECALENDAR_FAILURE,
  FETCH_POSTS_REQUEST,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE,
  FETCH_COMMUNITY_POSTS_REQUEST,
  FETCH_COMMUNITY_POSTS_SUCCESS,
  FETCH_COMMUNITY_POSTS_FAILURE,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILURE,
  POST_ADD_REQUEST,
  POST_ADD_SUCCESS,
  POST_ADD_FAILURE,
  POST_UPDATE_REQUEST,
  POST_UPDATE_SUCCESS,
  POST_UPDATE_FAILURE,
  FETCH_POST_REQUEST,
  FETCH_POST_SUCCESS,
  FETCH_POST_FAILURE,
  FETCH_IA_SUGGESTIONS_REQUEST,
  FETCH_IA_SUGGESTIONS_SUCCESS,
  FETCH_IA_SUGGESTIONS_FAILURE,
  REMOVE_IA_SUGGESTIONS_SUCCESS,
  FETCH_IA_SUGGESTION_SUCCESS,
  FETCH_IA_SUGGESTION_REQUEST,
  FETCH_IA_SUGGESTION_FAILURE,
  FETCH_IA_SUGGESTION_SUBJECT_SUCCESS,
  FETCH_IA_SUGGESTION_SUBJECT_REQUEST,
  FETCH_IA_SUGGESTION_SUBJECT_FAILURE,
  REMOVE_IA_SUGGESTION_SUBJECT_SUCCESS,
  FETCH_CALENDAR_POSTS_SUCCESS,
} from "../actionTypes";

import { toast } from "react-toastify";

import { eCalendarService } from "../services/eCalendarService";

export const fetchECalendars = (orderId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_ECALENDARS_REQUEST });
    try {
      eCalendarService.getECalendars(orderId).then((data) => {
        dispatch({
          type: FETCH_ECALENDARS_SUCCESS,
          payload: data,
        });
      });
    } catch (err) {
      console.log("error", err);
      dispatch({
        type: FETCH_ECALENDARS_FAILURE,
        payload: err.message,
      });
    }
  };
};

export const fetchECalendarById = (orderId, eCalendarId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_ECALENDAR_REQUEST });
    try {
      eCalendarService.getECalendar(orderId, eCalendarId).then((data) => {
        dispatch({
          type: FETCH_ECALENDAR_SUCCESS,
          payload: data,
        });
      });
    } catch (err) {
      console.log("error", err);
      dispatch({
        type: FETCH_ECALENDAR_FAILURE,
        payload: err.message,
      });
    }
  };
};

export const addECalendar = (orderId, eCalendarData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_ECALENDAR_REQUEST });
    try {
      const newECalendar = await eCalendarService.createECalendar(orderId, eCalendarData);
      dispatch({ type: ADD_ECALENDAR_SUCCESS, payload: newECalendar });
      toast.success("Calendrier éditorial créé avec succès");
    } catch (err) {
      dispatch({ type: ADD_ECALENDAR_FAILURE, payload: err.message });
    }
  };
};

export const updateECalendar = (orderId, eCalendarId, eCalendarData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_ECALENDAR_REQUEST });
    try {
      const updatedECalendar = await eCalendarService.updateECalendar(
        orderId,
        eCalendarId,
        eCalendarData
      );
      dispatch({
        type: UPDATE_ECALENDAR_SUCCESS,
        payload: updatedECalendar,
      });
      toast.success("Calendrier éditorial modifié avec succès");
    } catch (err) {
      dispatch({ type: UPDATE_ECALENDAR_FAILURE, payload: err.message });
    }
  };
};

export const deleteECalendar = (orderId, eCalendarId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_ECALENDAR_REQUEST });
    try {
      await eCalendarService.deleteECalendar(orderId, eCalendarId);
      dispatch({
        type: DELETE_ECALENDAR_SUCCESS,
        payload: eCalendarId,
      });
    } catch (err) {
      dispatch({ type: DELETE_ECALENDAR_FAILURE, payload: err.message });
    }
  };
};

export const fetchOrderById = (orderId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_ORDER_REQUEST });
    try {
      eCalendarService.getOrderById(orderId).then((data) => {
        dispatch({ type: FETCH_ORDER_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: FETCH_ORDER_FAILURE, payload: err.message });
    }
  };
};

export const fetchPostById = (orderId, eCalendarId, postId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_POST_REQUEST });
    try {
      eCalendarService.getPostById(orderId, eCalendarId, postId).then((data) => {
        dispatch({ type: FETCH_POST_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: FETCH_POST_FAILURE, payload: err.message });
    }
  };
};

export const fetchPostsSuccess = (data) => {
  return {
    type: FETCH_POSTS_SUCCESS,
    payload: data,
  };
};

export const fetchCalendarPostsSuccess = (data) => {
  const dragged_posts = data.filter((post) => post.date);
  const undragged_posts = data.filter((post) => !post.date);
  return {
    type: FETCH_CALENDAR_POSTS_SUCCESS,
    payload: { dragged_posts, undragged_posts },
  };
};

export const addToDraggedPosts = (data) => {
  return {
    type: "ADD_TO_DRAGGED_POSTS",
    payload: data,
  };
};
export const removeFromUndraggedPosts = (data) => {
  return {
    type: "REMOVE_FROM_UNDRAGGED_POSTS",
    payload: data,
  };
};

export const fetchPosts = (orderId, eCalendarId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_POSTS_REQUEST });
    try {
      eCalendarService.getPosts(orderId, eCalendarId).then((data) => {
        dispatch(fetchPostsSuccess(data));
      });
    } catch (err) {
      console.log("error", err);
      dispatch({
        type: FETCH_POSTS_FAILURE,
        payload: err.message,
      });
    }
  };
};
export const fetchCalendarPosts = (orderId, eCalendarId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_POSTS_REQUEST });
    try {
      eCalendarService.getPosts(orderId, eCalendarId).then((data) => {
        dispatch(fetchCalendarPostsSuccess(data));
      });
    } catch (err) {
      console.log("error", err);
      dispatch({
        type: FETCH_POSTS_FAILURE,
        payload: err.message,
      });
    }
  };
};

export const setIaSuggestions = (data) => {
  return {
    type: FETCH_IA_SUGGESTIONS_SUCCESS,
    payload: data,
  };
};

export const removeSuggestion = (index) => {
  return {
    type: REMOVE_IA_SUGGESTIONS_SUCCESS,
    payload: index,
  };
};
export const removeSuggestionSubject = (index) => {
  return {
    type: REMOVE_IA_SUGGESTION_SUBJECT_SUCCESS,
    payload: index,
  };
};

export const iaSuggestions = (orderId, eCalendarId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_IA_SUGGESTIONS_REQUEST });
    try {
      eCalendarService.iaSuggestions(orderId, eCalendarId).then((data) => {
        dispatch(
          setIaSuggestions(data.ai_response.split("\n").filter((item) => item !== ""))
        );
      });
    } catch (err) {
      dispatch({ type: FETCH_IA_SUGGESTIONS_FAILURE });
      console.log("error", err);
    }
  };
};

export const setIaSuggestionSubjects = (data) => {
  return {
    type: FETCH_IA_SUGGESTION_SUBJECT_SUCCESS,
    payload: data,
  };
};

export const iaSuggestionSubjects = (orderId, eCalendarId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_IA_SUGGESTION_SUBJECT_REQUEST });
    try {
      eCalendarService.iaSuggestionSubjects(orderId, eCalendarId).then((data) => {
        const suggestions = data.ai_response.split("\n").filter((item) => item !== "");

        const cleanedSuggestions = suggestions.map((suggestion) => {
          return suggestion.replace(/^\d+\.\s*/, "");
        });
        dispatch(setIaSuggestionSubjects(cleanedSuggestions));
      });
    } catch (err) {
      dispatch({ type: FETCH_IA_SUGGESTION_SUBJECT_FAILURE });
      console.log("error", err);
    }
  };
};

export const setIaSuggestion = (data) => {
  return {
    type: FETCH_IA_SUGGESTION_SUCCESS,
    payload: data,
  };
};

export const iaSuggestion = (orderId, eCalendarId, data) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_IA_SUGGESTION_REQUEST });
    try {
      eCalendarService.iaSuggestion(orderId, eCalendarId, data).then((data) => {
        dispatch(
          setIaSuggestion(data.ai_response.split("\n").filter((item) => item !== ""))
        );
      });
    } catch (err) {
      dispatch({ type: FETCH_IA_SUGGESTION_FAILURE });
      console.log("error", err);
    }
  };
};

export const communityPosts = (weekDates) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_COMMUNITY_POSTS_REQUEST });
    try {
      eCalendarService.getCommunityPosts(weekDates).then((data) => {
        dispatch(fetchPostsSuccess(data));
        dispatch({
          type: FETCH_COMMUNITY_POSTS_SUCCESS,
          payload: data,
        });
      });
    } catch (err) {
      console.log("error", err);
      dispatch({
        type: FETCH_COMMUNITY_POSTS_FAILURE,
        payload: err.message,
      });
    }
  };
};

export const deletePost = (orderId, eCalendarId, postId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_POST_REQUEST });
    try {
      await eCalendarService.deletePost(orderId, eCalendarId, postId);
      dispatch({
        type: DELETE_POST_SUCCESS,
        payload: postId,
      });
    } catch (err) {
      dispatch({ type: DELETE_POST_FAILURE, payload: err.message });
    }
  };
};
export const deleteImagePost = (orderId, eCalendarId, postId, photoId) => {
  return async (dispatch) => {
    try {
      eCalendarService
        .deleteImagePost(orderId, eCalendarId, postId, photoId)
        .then((data) => {
          dispatch({ type: POST_UPDATE_SUCCESS, payload: data });
        });
    } catch (err) {}
  };
};

export const addPost = (orderId, eCalendarId, formData) => {
  return async (dispatch) => {
    dispatch({ type: POST_ADD_REQUEST });

    eCalendarService
      .addPost(orderId, eCalendarId, formData)
      .then((data) => {
        dispatch({ type: POST_ADD_SUCCESS, payload: data });
        toast.success("Post créé avec succès");
      })
      .catch((error) => {
        dispatch({
          type: POST_ADD_FAILURE,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const updatePost = (orderId, eCalendarId, postId, formData) => {
  return async (dispatch) => {
    dispatch({ type: POST_UPDATE_REQUEST });
    eCalendarService
      .updatePost(orderId, eCalendarId, postId, formData)
      .then((data) => {
        dispatch({ type: POST_UPDATE_SUCCESS, payload: data });
        toast.success("Post modifié avec succès");
      })
      .catch((error) => {
        dispatch({
          type: POST_UPDATE_FAILURE,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};
export const updatePriorities = (orderId, eCalendarId, data) => {
  return async (dispatch) => {
    eCalendarService
      .updatePriorities(orderId, eCalendarId, data)
      .then((data) => {
        console.log("Success");
      })
      .catch((error) => {
        console.log("Failure", error);
      });
  };
};
