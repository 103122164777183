import React from "react";

function Homepage() {
  return (
    <div className="jumbotron">
      <h1>Page not found</h1>
    </div>
  );
}

export default Homepage;
