import "bootstrap/dist/css/bootstrap.min.css";
import "@stream-io/video-react-sdk/dist/css/styles.css";

import React from "react";
import { render } from "react-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import configureStore from "./redux/configureStore";
import { Provider as ReduxProvider } from "react-redux";

import App from "./App.js";
import "./css/style.scss";

import "./redux/axios/interceptor.request";
import "./redux/axios/interceptor.response";

const store = configureStore();
const queryClient = new QueryClient();

render(
  <>
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ReduxProvider>
  </>,
  document.getElementById("root")
);
