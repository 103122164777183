import {
  FETCH_PRODUCT_QUALITY_CRITERIONS_REQUEST,
  FETCH_PRODUCT_QUALITY_CRITERIONS_SUCCESS,
  FETCH_PRODUCT_QUALITY_CRITERIONS_FAILURE,
  ADD_PRODUCT_QUALITY_CRITERION_REQUEST,
  ADD_PRODUCT_QUALITY_CRITERION_SUCCESS,
  ADD_PRODUCT_QUALITY_CRITERION_FAILURE,
  UPDATE_PRODUCT_QUALITY_CRITERION_REQUEST,
  UPDATE_PRODUCT_QUALITY_CRITERION_SUCCESS,
  UPDATE_PRODUCT_QUALITY_CRITERION_FAILURE,
  DELETE_PRODUCT_QUALITY_CRITERION_REQUEST,
  DELETE_PRODUCT_QUALITY_CRITERION_SUCCESS,
  DELETE_PRODUCT_QUALITY_CRITERION_FAILURE,
} from "../actionTypes";

import { qualityCriterionService } from "../services/qualityCriterionService";

// Action Creator pour récupérer la liste des critères de qualité
export const fetchQualityCriterions = (productId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PRODUCT_QUALITY_CRITERIONS_REQUEST });
    try {
      const qualityCriterions = await qualityCriterionService.getProductQualityCriterions(
        productId
      );
      dispatch({
        type: FETCH_PRODUCT_QUALITY_CRITERIONS_SUCCESS,
        payload: qualityCriterions,
      });
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCT_QUALITY_CRITERIONS_FAILURE,
        payload: err.message,
      });
    }
  };
};

// Action Creator pour ajouter un critère de qualité à un produit
export const addQualityCriterion = (productId, qualityCriterionData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_PRODUCT_QUALITY_CRITERION_REQUEST });
    try {
      const newQualityCriterion = await qualityCriterionService.createQualityCriterion(
        productId,
        qualityCriterionData
      );
      dispatch({
        type: ADD_PRODUCT_QUALITY_CRITERION_SUCCESS,
        payload: newQualityCriterion,
      });
    } catch (err) {
      dispatch({ type: ADD_PRODUCT_QUALITY_CRITERION_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour modifier un critère de qualité d'un produit
export const updateQualityCriterion = (productId, criterionId, qualityCriterionData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_PRODUCT_QUALITY_CRITERION_REQUEST });
    try {
      const updatedQualityCriterion =
        await qualityCriterionService.updateQualityCriterion(
          productId,
          criterionId,
          qualityCriterionData
        );
      dispatch({
        type: UPDATE_PRODUCT_QUALITY_CRITERION_SUCCESS,
        payload: updatedQualityCriterion,
      });
    } catch (err) {
      dispatch({ type: UPDATE_PRODUCT_QUALITY_CRITERION_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour supprimer un critère de qualité d'un produit
export const deleteQualityCriterion = (productId, criterionId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_PRODUCT_QUALITY_CRITERION_REQUEST });
    try {
      await qualityCriterionService.deleteQualityCriterion(productId, criterionId);
      dispatch({
        type: DELETE_PRODUCT_QUALITY_CRITERION_SUCCESS,
        payload: { productId, criterionId },
      });
    } catch (err) {
      dispatch({ type: DELETE_PRODUCT_QUALITY_CRITERION_FAILURE, payload: err.message });
    }
  };
};
