import { useState, useEffect } from "react";
import axios from "axios";

const useDepartmentName = (code) => {
  const [departmentName, setDepartmentName] = useState(null);

  useEffect(() => {
    const fetchDepartmentName = async () => {
      try {
        const response = await axios.post(`/api/departments`, code);
        const data = response.data;
        setDepartmentName(data);
      } catch (error) {
        console.error("Error fetching department name:", error);
      }
    };

    fetchDepartmentName();

    return () => {
      setDepartmentName(null);
    };
  }, []);

  return departmentName;
};

export default useDepartmentName;
