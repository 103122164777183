export const getGroupedOptions = (employees, clientEmployees) => {
  const departments = {};
  employees.forEach((employee) => {
    const { departement } = employee;
    if (!departments[departement]) {
      departments[departement] = [];
    }
    departments[departement].push({
      value: employee._id,
      label: employee.name,
    });
  });

  const groupedDepartments = Object.keys(departments).map((departement) => ({
    label: departement,
    options: departments[departement],
  }));

  const clientOptions = {
    label: "Clients",
    options: clientEmployees.map((clientEmployee) => ({
      value: clientEmployee._id,
      label: clientEmployee.name,
    })),
  };

  return [clientOptions, ...groupedDepartments];
};
