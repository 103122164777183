import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchECalendars,
  deleteECalendar,
  fetchOrderById,
} from "../../../redux/actions/eCalendarActions";
import { fetchClientOrders } from "../../../redux/actions/clientActions";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPen, faClipboard } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import config from "../../../config";
import OrderNavigation from "../clients/orders/order/orderNavigation";

const ECalendarsPage = () => {
  const dispatch = useDispatch();
  const { orderId, clientId } = useParams();

  const eCalendars = useSelector((state) => state.eCalendarReducer.eCalendars);
  const order = useSelector((state) => state.eCalendarReducer.order);
  const client = useSelector((state) => state.clientReducer.client);

  useEffect(() => {
    document.title = "ProdOTop - Liste calendrier editoriaux";
  }, []);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(orderId));
      dispatch(fetchECalendars(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientOrders(clientId));
    }
  }, [dispatch, clientId]);

  const handleDelete = (eCalendarId) => {
    dispatch(deleteECalendar(orderId, eCalendarId));
  };

  if (!order?.product?.include_e_calendars) {
    return <div>Accés non autorisé...</div>;
  }

  return (
    <>
      <div className="pb-2">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/clients">clients</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/clients/${order?.client?._id}/orders`}>
                {order?.client?.companyName}
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {order?.product?.name}
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Calendriers éditoriaux
            </li>
          </ol>
        </nav>
      </div>
      <div className="order-container">
        {order && <OrderNavigation order={order} client={client} currentTab="ce" />}
        <div>
          <Row className="mb-3">
            <Col className="text-end" md={12}>
              <Link
                to={`/clients/${clientId}/orders/${orderId}/editorial_calendars/new`}
                className="btn bg-gradient-dark-inverse mb-0 "
              >
                Ajouter un calendrier
              </Link>
            </Col>
          </Row>
          <Row>
            {eCalendars.map((eCalendar) => (
              <Col md={12} className="mb-3" key={eCalendar._id}>
                <div>
                  <div className="card purple-border card-order eCalendar justify-content-center">
                    <div className="card-header py-3 flex justify-content-between">
                      <h6 className="eCalendar-title">
                        <span className="product-title text-dark ">
                          {eCalendar.title}
                        </span>
                      </h6>
                      <div className="text-end flex">
                        <Link
                          to={`/clients/${clientId}/orders/${orderId}/editorial_calendars/${eCalendar._id}`}
                          className="btn btn-link text-white text-gradient px-1 mb-0 py-0"
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            className="px-2"
                            color="#ffb300"
                          />
                        </Link>
                        <button
                          className="btn btn-link text-danger text-gradient px-1 mb-0 py-0"
                          type="button"
                          onClick={() => handleDelete(eCalendar._id)}
                        >
                          <FontAwesomeIcon icon={faTrashCan} className="px-2" />
                        </button>
                      </div>
                    </div>
                    <div className="card-body py-4 px-3">
                      <div className="">
                        <Row>
                          <Col md={3}>
                            <div className="mb-3">
                              <label htmlFor="objectives" className="form-label">
                                Objectifs :
                              </label>
                              <p>{eCalendar.objectives}</p>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label htmlFor="tone_of_voice" className="form-label">
                                Tone of voice :
                              </label>
                              <p>{eCalendar.tone_of_voice}</p>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label htmlFor="target" className="form-label">
                                Cible :
                              </label>
                              <p>{eCalendar.target}</p>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label htmlFor="communication_plan" className="form-label">
                                Plan de communication :
                              </label>
                              <p>{eCalendar.communication_plan}</p>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <label htmlFor="strategy" className="form-label">
                                Strategy :
                              </label>
                              <p>{eCalendar.strategy}</p>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="z-index-1">
                              <Link
                                to={`/clients/${clientId}/orders/${orderId}/editorial_calendars/${eCalendar._id}/posts`}
                                className="btn bg-gradient-dark-inverse mb-0 "
                              >
                                <span className="span-btn">Posts</span>
                              </Link>
                            </div>
                          </Col>
                          <Col md={8} className="text-end">
                            <small
                              className="btn copy"
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  `${config.base_url}/cli/${clientId}/orders/${orderId}/editorial_calendars/${eCalendar._id}/posts`
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faClipboard}
                                className="px-2"
                                size="2x"
                              />
                            </small>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}

            <Row className="mb-3">
              <Col>
                <Link
                  className="link-action back"
                  to={`/clients/${order?.client?._id}/orders`}
                >
                  Retour
                </Link>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ECalendarsPage;
