import React, { useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

import { fetchOrderById } from "../../../../redux/actions/cli/orderActions";
import {
  fetchCliClientOrders,
  addNewTicketForCliOrder,
} from "../../../../redux/actions/cli/clientsActions";

import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import OrderNavigation from "../orderNavigation";

const CliNewTicketForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId, orderId } = useParams();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const order = useSelector((state) => state.cliClientsReducer.order);
  const client = useSelector((state) => state.cliClientsReducer.client);

  const currentUser = useSelector((state) => state.authReducer.user.user);

  useEffect(() => {
    document.title =
      "ProdOTop -  Nouvelle demande de " +
      order?.product?.name +
      " du client " +
      client?.companyName;
  }, [client, order]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliClientOrders(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (clientId && orderId) {
      dispatch(fetchOrderById(orderId));
    }
  }, [dispatch, clientId, orderId]);

  const onSubmit = (data) => {
    dispatch(addNewTicketForCliOrder(clientId, orderId, data));
    navigate(`/client/${clientId}/orders/${orderId}/tickets`);
  };

  const ISSUES = [
    { REQAM: "Demande d’amélioration" },
    { INFINC: "Information incompléte" },
    { PAYPRO: "Probléme de paiement" },
    { CONMAN: "Contenu manquant" },
    { PROEXT: "Problème externe" },
    { RDV: "Demande de rendez-vous" },
    { DVR: "Divers" },
  ];
  return (
    <>
      {order && (
        <>
          <div className="pb-4">
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/client/${client?._id}/orders`}>{client?.companyName}</Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  {order?.product.name}
                </li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/client/${clientId}/orders/${orderId}/tickets`}>
                    Demandes
                  </Link>
                </li>

                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  Nouvelle demande
                </li>
              </ol>
            </nav>
          </div>

          <div className="order-container">
            {order && (
              <OrderNavigation order={order} client={client} currentTab="tickets" />
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <section>
                <Row>
                  <Col className="d-flex flex-column mx-auto">
                    <div className="card medium-card mb-4 pb-2 purple-border">
                      <div className="card-header text-left">
                        <Col md={6}>
                          <h6>
                            <span className="product-title px-3 text-white">
                              Ajouter une demande
                            </span>
                          </h6>
                        </Col>
                      </div>
                      <div className="card-body">
                        <Row className="mb-3">
                          <Col md={6}>
                            <label htmlFor="subject" className="form-label">
                              Sujet :
                            </label>
                            <input
                              {...register("subject", { required: true })}
                              className="form-control"
                              id="subject"
                            />
                            {errors.subject && (
                              <div className="text-danger">Le sujet est requis</div>
                            )}
                          </Col>
                          <Col md={6}>
                            <label htmlFor="subject" className="form-label">
                              Type:
                            </label>

                            <select
                              className="form-control"
                              id="role"
                              {...register("issue", { required: true })}
                            >
                              <option value="">-- Choisir type de la demande --</option>
                              {ISSUES.map((issue, index) => {
                                const key = Object.keys(issue)[0];
                                const value = Object.values(issue)[0];
                                return (
                                  <option key={key} value={key}>
                                    {value}
                                  </option>
                                );
                              })}
                            </select>

                            {errors.subject && (
                              <div className="text-danger">Le champ est requis</div>
                            )}
                          </Col>
                        </Row>

                        <div className="mb-3">
                          <label htmlFor="subject" className="form-label">
                            Demande :
                          </label>
                          <Controller
                            name="question"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Ce champ est obligatoire" }}
                            disabled
                            render={({ field }) => (
                              <CKEditor
                                editor={ClassicEditor}
                                data={field.value}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  field.onChange(data);
                                }}
                              />
                            )}
                          />
                          {errors.question && <p>{errors.question.message}</p>}
                        </div>

                        <div className="">
                          <button
                            type="submit"
                            className="btn bg-gradient-dark w-30 mt-4 mb-0"
                          >
                            Enregistrer
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Link
                      to={`/client/${clientId}/orders/${orderId}/tickets`}
                      className="link-action back"
                    >
                      Retour
                    </Link>
                  </Col>
                </Row>
              </section>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default CliNewTicketForm;
