import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { contentBlockVariants } from "./variants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleXmark } from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";

const AnimatedContentBlock = ({ children }) => (
  <motion.div variants={contentBlockVariants}>{children}</motion.div>
);

export default function ExpandedCard({ data, setData, layoutId }) {
  if (!data) {
    return <></>;
  }

  return (
    <AnimatePresence key={1}>
      <motion.div
        key={1}
        onClick={() => setData(null)}
        className="modal-bg"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.7,
        }}
      ></motion.div>
      <motion.div
        key={2}
        className="expanded-modal"
        layoutId={layoutId}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.7,
        }}
      >
        <button className="close" onClick={() => setData(null)}>
          <FontAwesomeIcon icon={faRectangleXmark} color="#344866" />
        </button>
        <motion.div
          key={1}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.7,
          }}
        >
          <motion.div className="expanded-content">
            <AnimatedContentBlock>
              <div className="container-fluid py-4">
                <div className="row">
                  <div className="col-12">
                    <div className=" mb-4">
                      <div className="card-body px-0 pt-0 pb-2">
                        <div className="flex gap-5 flex-wrap">
                          {data.map((photo, index) => (
                            <img
                              key={index}
                              alt="CE gallerie"
                              src={photo}
                              style={{ width: "240px" }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AnimatedContentBlock>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}
