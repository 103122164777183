import { useEffect, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientAdsReports } from "../../../redux/actions/clientActions";

import { useParams, Link } from "react-router-dom";
import { NavContext } from "../../NavContext";
import { Triangle } from "react-loader-spinner";

import ReportsTable from "../../shared/reports/ReportsTable";
import ErrorBoundary from "../../ErrorBoundry";

const ClientAdsReports = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { activeNav, setActiveNav } = useContext(NavContext);
  useEffect(() => {
    setActiveNav("reports");
  }, [setActiveNav]);

  const ads_reports = useSelector((state) => state.clientReducer.client_ads_reports);
  const fetchingReports = useSelector((state) => state.clientReducer.fetchingReports);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientAdsReports(clientId));
    }
  }, [dispatch, clientId]);

  return (
    <ErrorBoundary fallback="Error code: #001ClientAdsReports001">
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/Ads-reports">Ads reports</Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="order-container">
        {fetchingReports && (
          <div className="loading-reports flex">
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#333"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass="primary-color"
            />
            <span className="primary-color">Loading Reports</span>
          </div>
        )}

        <div className="card medium-card mb-4 pb-2 purple-border reports-card">
          <div className="card-header">
            {" "}
            <h6>
              <span>Historiques des rapports</span>
            </h6>
          </div>
          {!fetchingReports && ads_reports && ads_reports.length > 0 && (
            <div className="card-body">
              <ReportsTable ads_reports={ads_reports} details={true} />
            </div>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ClientAdsReports;
