import axios from "axios";

const API_URL = "/api/users";

const login = async (data) => {
  const response = await axios.post(API_URL + "/login", data);

  return response.data;
};
const register = async (data) => {
  const response = await axios.post(API_URL + "/register", data);

  return response.data;
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const authService = {
  login,
  register,
  logout,
  getCurrentUser,
};
