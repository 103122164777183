import axios from "axios";

const BASE_URL = "/api/cli/orders";

export const orderService = {
  getOrderById: async (orderId) => {
    const response = await axios.get(`${BASE_URL}/${orderId}`);
    return response.data;
  },
  fetchOrderWithDocumentsById: async (orderId) => {
    const response = await axios.get(`${BASE_URL}/${orderId}/documents`);
    return response.data;
  },
  fetchOrderWithSurveysById: async (orderId) => {
    const response = await axios.get(`${BASE_URL}/${orderId}/surveys`);
    return response.data;
  },
  fetchOrderWithNotesById: async (orderId) => {
    const response = await axios.get(`${BASE_URL}/${orderId}/notes`);
    return response.data;
  },
  getOrderFbInsight: async (orderId, data) => {
    const response = await axios.post(`${BASE_URL}/${orderId}/fb_insight`, data);
    return response.data;
  },
  getOrderGAInsight: async (orderId, data) => {
    const response = await axios.post(`${BASE_URL}/${orderId}/ga_insight`, data);
    return response.data;
  },
  getOrderGAdsInsight: async (orderId, data) => {
    const response = await axios.post(`${BASE_URL}/${orderId}/gads_insight`, data);
    return response.data;
  },
  fetchOrderWithTicketsById: async (orderId) => {
    const response = await axios.get(`${BASE_URL}/${orderId}/tickets`);
    return response.data;
  },
  fetchSurveyById: async (orderId, surveyId) => {
    const response = await axios.get(`${BASE_URL}/${orderId}/survey/${surveyId}`);
    return response.data;
  },
  updateSurvey: async (orderId, surveyId, data) => {
    const response = await axios.put(`${BASE_URL}/${orderId}/survey/${surveyId}`, data);
    return response.data;
  },
};
