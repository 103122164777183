import axios from "axios";

const API_URL = "/api/agency";

const loadAgencyInfo = () => {
  return axios.get(`${API_URL}`).then((response) => {
    return response.data;
  });
};

const updateAgencyInfo = (data) => {
  return axios.put(`${API_URL}`, data).then((response) => {
    return response.data;
  });
};

export const agencyService = {
  loadAgencyInfo,
  updateAgencyInfo,
};
