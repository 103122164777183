import React, { useEffect } from "react";
import FacebookLogin from "react-facebook-login";
import { Row, Col } from "react-bootstrap";

const FacebookAuth = () => {
  const responseFacebook = (response) => {
    console.log(response);
  };
  function logoutFromFacebook() {
    window.FB.logout(function (response) {
      // L'utilisateur est maintenant déconnecté de Facebook
      console.log("Déconnecté de Facebook");
      // Ici, vous pouvez également gérer la déconnexion côté serveur si nécessaire
    });
  }

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Connexion facebook
            </li>
          </ol>
        </nav>
      </div>
      <Row className="row">
        <div className="col-12">
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header">
              <Row className="xmb-5">
                <Col md={6}>
                  <h6>
                    <span className="product-title px-3 text-white">
                      Connexion facebook
                    </span>
                  </h6>
                </Col>
                <Col className="text-end" md={6}>
                  <FacebookLogin
                    appId={process.env.FB_APP_ID}
                    fields="name,email,picture"
                    callback={responseFacebook}
                  />
                </Col>
                <button onClick={logoutFromFacebook} className="prodotop-link">
                  Se Déconnecter
                </button>
              </Row>
            </div>
            <div className="card-body px-0 pt-0 pb-2">
              <div className="table-responsive p-0">xxxx</div>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
};

export default FacebookAuth;
