import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addProduct,
  updateProduct,
  fetchProducts,
  deleteTodo,
} from "../../../redux/actions/productActions";
import {
  fetchSurveyQuestionsByProduct,
  deleteSurveyQuestionFromProduct,
} from "../../../redux/actions/surveyQuestionActions";

import { deleteQualityCriterion } from "../../../redux/actions/qualityCriterionsActions";
import { Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPen } from "@fortawesome/free-solid-svg-icons";
import { NavContext } from "../../NavContext";
import { BlockPicker } from "react-color";
import { teamFormat } from "../../utils/screen";
import DepartmentName from "../../shared/departments/departmentName";
const ProductForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [color, setColor] = useState();
  const [showPicker, setShowPicker] = useState();
  // const [surveyQuestions, setSurveyQuestions] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const { activeNav, setActiveNav } = useContext(NavContext);
  useEffect(() => {
    setActiveNav("products");
  }, [setActiveNav]);

  const products = useSelector((state) => state.productReducer.products);
  const isFetching = useSelector((state) => state.productReducer.isFetching);
  const product = products && products.find((product) => product._id === id);

  const surveyQuestions = useSelector(
    (state) => state.surveyQuestionReducer.productSurveyQuestions
  );

  const currentUser = useSelector((state) => state.authReducer.user.user);
  const BOSS = currentUser.role === "boss";
  const MANAGER = currentUser.role === "manager";

  const [qualityCriterions, setQualityCriterions] = useState([]);
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    document.title = "ProdOTop - Produit - " + product?.name;
  }, [product]);

  useEffect(() => {
    if (!isFetching) {
      dispatch(fetchProducts());
    }
    if (product) {
      setColor(product.color);
      reset({
        name: product.name,
        workHours: product.workHours,
        include_e_calendars: product.include_e_calendars,
        profitability: product.profitability,
        include_documents: product.include_documents,
        color: product.color,
        rcProduct: product.rcProduct,
        fbInsight: product.fbInsight,
        gadsInsight: product.gadsInsight,
        qualityCriterions: product.qualityCriterions.map((criterion) => criterion._id),
      });
      setQualityCriterions(product.qualityCriterions);
      setTodos(product.todos);
    }
  }, [dispatch, product, reset, isFetching]);

  useEffect(() => {
    if (product) {
      dispatch(fetchSurveyQuestionsByProduct(product._id));
    }
  }, [dispatch, product]);

  const onSubmit = (data) => {
    if (product) {
      dispatch(updateProduct(product._id, data));
    } else {
      dispatch(addProduct(data));
    }
    navigate("/products");
    reset();
  };

  const handleDelete = (productId, criterionId) => {
    dispatch(deleteQualityCriterion(productId, criterionId));
    setQualityCriterions(
      qualityCriterions.filter((criterion) => criterion._id !== criterionId)
    );
  };

  const handleDeleteTodo = (productId, todoId) => {
    dispatch(deleteTodo(productId, todoId));
    // AYB todos must be changed in reducer
    setTodos(todos.filter((todo) => todo._id !== todoId));
  };

  const popover = {
    position: "absolute",
    zIndex: "2",
    marginTop: "10px",
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/products">Produits</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              {product ? "Modification du produit" : "Nouveau produit"}
            </li>
          </ol>
        </nav>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex flex-column mx-auto">
            <div className="card  mb-4 pb-2">
              <div className="card-header text-left">
                {product ? (
                  <h6>
                    <span className="product-title px-3 text-white">
                      Modification du produit : {product.name}
                    </span>
                  </h6>
                ) : (
                  <h6>
                    <span className="product-title px-3 text-white">Nouveau produit</span>
                  </h6>
                )}
              </div>
              <div className="card-body">
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Nom du produit :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        {...register("name", { required: true })}
                      />
                      {errors.name && (
                        <div className="text-danger">Nom du produit requis</div>
                      )}
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="mb-3">
                      <label htmlFor="workHours" className="form-label">
                        Heures de travail :
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="workHours"
                        {...register("workHours", { required: true })}
                      />
                      {errors.name && <div className="text-danger">champs requis</div>}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <label htmlFor="workHours" className="form-label">
                        couleur :
                      </label>
                      <div>
                        <span
                          className="btn"
                          onClick={() => setShowPicker(!showPicker)}
                          style={{ background: color, borderColor: color }}
                        >
                          Choisir une couleur
                        </span>
                      </div>
                      {showPicker ? (
                        <div style={popover}>
                          <BlockPicker
                            color={color}
                            onChange={(color) => {
                              setValue("color", color.hex);
                              setColor(color.hex);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="rcProduct"
                        {...register("rcProduct")}
                      />
                      <label className="form-check-label" htmlFor="rcProduct">
                        Produit pour les RC ?
                      </label>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="include_e_calendars"
                        {...register("include_e_calendars")}
                      />
                      <label className="form-check-label" htmlFor="include_e_calendars">
                        Activer le calendrier éditorial ?
                      </label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="profitability"
                        {...register("profitability")}
                      />
                      <label className="form-check-label" htmlFor="profitability">
                        Activer la rentabilité ?
                      </label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="include_documents"
                        {...register("include_documents")}
                      />
                      <label className="form-check-label" htmlFor="include_documents">
                        Activer l'ajout des documents ?
                      </label>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="fbInsight"
                        {...register("fbInsight")}
                      />
                      <label className="form-check-label" htmlFor="fbInsight">
                        Facebook statistiques ?
                      </label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gadsInsight"
                        {...register("gadsInsight")}
                      />
                      <label className="form-check-label" htmlFor="gadsInsight">
                        Google ads statistiques ?
                      </label>
                    </div>
                  </Col>
                  <div className="mb-3">
                    <button type="submit" className="btn bg-gradient-dark w-30 mt-4 mb-0">
                      Enregistrer
                    </button>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </form>
      {product && (
        <>
          <hr className="pb-3" />
          <Row className="row">
            <div className="col-12">
              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-header">
                  <Row className="xmb-5">
                    <Col md={6}>
                      <h6>
                        <span className="product-title px-3 text-white">
                          Tâches du produits
                        </span>
                      </h6>
                    </Col>
                    <Col className="text-end" md={6}>
                      <Link
                        to={`/products/${product._id}/todos/new`}
                        className="btn bg-gradient-dark-inverse mb-0"
                      >
                        Ajouter une tâche
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className="card-body px-0 pt-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Nom
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Pour
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Minutes
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            répétitif
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {product &&
                          todos.map((todo) => (
                            <tr key={todo._id}>
                              <td className="align-middle text-sm px-4">
                                <div>{todo.task}</div>
                              </td>
                              <td className="align-middle text-sm px-4">
                                <div>{<DepartmentName code={teamFormat(todo)} />}</div>
                              </td>

                              <td className="align-middle text-sm px-4">
                                <div>{todo.minutes}</div>
                              </td>
                              <td className="align-middle text-sm px-4">
                                <div>{todo.repetitive ? "Oui" : "Non"}</div>
                              </td>

                              <td className="align-middle px-2 text-sm text-end">
                                <Link
                                  to={`/products/${product._id}/todos/${todo._id}`}
                                  className="btn btn-link text-warning text-gradient px-3 mb-0"
                                >
                                  <FontAwesomeIcon icon={faPen} className="px-2" />
                                </Link>
                                {(BOSS || MANAGER) && (
                                  <>
                                    <button
                                      className="btn btn-link text-danger text-gradient px-3 mb-0"
                                      type="button"
                                      onClick={() =>
                                        handleDeleteTodo(product._id, todo._id)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrashCan}
                                        className="px-2"
                                      />
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-header">
                  <Row className="xmb-5">
                    <Col md={6}>
                      <h6>
                        <span className="product-title px-3 text-white">
                          Questions d'enquête
                        </span>
                      </h6>
                    </Col>
                    <Col className="text-end" md={6}>
                      <Link
                        to={`/products/${product._id}/survey-questions/new`}
                        className="btn bg-gradient-dark-inverse mb-0"
                      >
                        Ajouter une question
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className="card-body px-0 pt-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Titre
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {surveyQuestions.map((question) => (
                          <tr key={question._id}>
                            <td className="align-middle text-sm px-4">
                              <div>{question.title}</div>
                            </td>
                            <td className="align-middle px-2 text-sm text-end">
                              {(BOSS || MANAGER) && (
                                <button
                                  className="btn btn-link text-danger text-gradient px-3 mb-0"
                                  type="button"
                                  onClick={() =>
                                    dispatch(
                                      deleteSurveyQuestionFromProduct(
                                        product._id,
                                        question._id
                                      )
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrashCan} className="px-2" />
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-header">
                  <Row className="xmb-5">
                    <Col md={6}>
                      <h6>
                        <span className="product-title px-3 text-white">
                          Critères de qualité
                        </span>
                      </h6>
                    </Col>
                    <Col className="text-end" md={6}>
                      <Link
                        to={`/products/${product._id}/quality_criterions/new`}
                        className="btn bg-gradient-dark-inverse mb-0"
                      >
                        Ajouter un critère de qualité
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className="card-body px-0 pt-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Nom
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {product &&
                          qualityCriterions.map((criterion) => (
                            <tr key={criterion._id}>
                              <td className="align-middle text-sm px-4">
                                <div>{criterion.name}</div>
                                <small>{criterion.description}</small>
                              </td>
                              <td className="align-middle px-2 text-sm text-end">
                                {(BOSS || MANAGER) && (
                                  <>
                                    <Link
                                      to={`/products/${product._id}/quality_criterions/${criterion._id}`}
                                      className="btn btn-link text-warning text-gradient px-3 mb-0"
                                    >
                                      <FontAwesomeIcon icon={faPen} className="px-2" />
                                    </Link>
                                    <button
                                      className="btn btn-link text-danger text-gradient px-3 mb-0"
                                      type="button"
                                      onClick={() =>
                                        handleDelete(product._id, criterion._id)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrashCan}
                                        className="px-2"
                                      />
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </>
      )}
      <Row className="mb-3">
        <Col className="mb-3">
          <Link to="/products" className="link-action back">
            Retour
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default ProductForm;
