import axios from "axios";

const API_URL = "/api/quality-checks";

const getAllQualityChecks = () => {
  return axios.get(API_URL).then((response) => {
    return response.data;
  });
};

const getQualityCheckById = (id) => {
  return axios.get(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

const createQualityCheck = (data) => {
  return axios.post(API_URL, data).then((response) => {
    return response.data;
  });
};

const updateQualityCheck = (id, data) => {
  return axios.put(`${API_URL}/${id}`, data).then((response) => {
    return response.data;
  });
};

const deleteQualityCheck = (id) => {
  return axios.delete(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

export const qualityCheckService = {
  getAllQualityChecks,
  getQualityCheckById,
  createQualityCheck,
  updateQualityCheck,
  deleteQualityCheck,
};
