import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  deleteImagePost,
  fetchPostById,
  fetchECalendarById,
  addPost,
  updatePost,
  fetchOrderById,
  iaSuggestion,
  setIaSuggestion,
} from "../../../../redux/actions/eCalendarActions";
import { fetchClientOrders } from "../../../../redux/actions/clientActions";
import { Row, Col } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBahai, faRectangleXmark } from "@fortawesome/free-solid-svg-icons";
import { Bars } from "react-loader-spinner";
import { motion, AnimatePresence } from "framer-motion";
import OrderNavigation from "../../clients/orders/order/orderNavigation";

const PostForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId, orderId, eCalendarId, postId } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue,
    watch,
    control,
  } = useForm();

  const post = useSelector((state) => state.eCalendarReducer.post);
  const order = useSelector((state) => state.eCalendarReducer.order);
  const eCalendar = useSelector((state) => state.eCalendarReducer.eCalendar);
  const client = useSelector((state) => state.clientReducer.client);

  const fetchingSuggestion = useSelector(
    (state) => state.eCalendarReducer.fetchingSuggestion
  );

  const ia_suggestion = useSelector((state) => state.eCalendarReducer.iaSuggestion);
  const theme = watch("theme");

  useEffect(() => {
    document.title = "ProdOTop - post du Calendrier editorial";
  }, []);

  useEffect(() => {
    if (postId) {
      dispatch(fetchPostById(orderId, eCalendarId, postId));
    }
  }, [dispatch, orderId, postId, eCalendarId]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientOrders(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (eCalendarId) {
      dispatch(fetchECalendarById(orderId, eCalendarId));
    }
  }, [dispatch, eCalendarId, orderId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    if (!postId) {
      setValue("date", "");
      setValue("theme", "");
      setValue("texte", "");
      setValue("yellow_remark", "");
      setValue("shared", false);
    }
  }, [postId, setValue]);

  useEffect(() => {
    if (post) {
      reset({
        date: post.date ? moment(post.date).format("YYYY-MM-DD") : "",
        theme: post.theme,
        validated: post.validated,
        validated_img: post.validated_img,
        texte: post.texte,
        yellow_remark: post.yellow_remark,
        customer_remark: post.customer_remark,
        shared: post.shared,
      });
    }
  }, [post, reset]);

  const onSubmit = (data) => {
    let formData = new FormData(); // Use FormData for file handling
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append("photo", data.photo[0]);

    if (postId) {
      dispatch(updatePost(orderId, eCalendarId, postId, formData));
    } else {
      dispatch(addPost(orderId, eCalendarId, formData));
      navigate(
        `/clients/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts`
      );
      reset();
    }
  };

  const deleteImage = (path) => {
    dispatch(deleteImagePost(orderId, eCalendarId, postId, path));
    navigate(
      `/clients/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts`
    );
  };

  const handleIaSuggestion = (words) => {
    dispatch(iaSuggestion(orderId, eCalendarId, { theme: getValues("theme"), words }));
  };
  const handleCloseSuggestion = () => {
    dispatch(setIaSuggestion(false));
  };

  const handleUpdatePost = (iaS) => {
    setValue("texte", iaS);
    dispatch(setIaSuggestion(false));
  };

  return (
    <>
      {fetchingSuggestion && (
        <div className="ia-loader">
          <Bars
            height={100}
            width={100}
            radius={5}
            color="#cb0c9f"
            ariaLabel="bars-loading"
            wrapperClass={"triangle-loader"}
            wrapperStyle=""
            visible={true}
          />
        </div>
      )}
      <div className="pb-2">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/clients">clients</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/clients/${order?.client?._id}/orders`}>
                {order?.client?.companyName}
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {order?.product?.name}
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to={`/clients/${clientId}/orders/${orderId}/editorial_calendars`}>
                Calendriers éditoriaux
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link
                to={`/clients/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts`}
              >
                {eCalendar.title}
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              {postId ? "Modifier post" : "Ajouter un post"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="order-container">
        {order && <OrderNavigation order={order} client={client} currentTab="ce" />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="row">
            <div className="col-12">
              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-body">
                  <Row className="mb-3">
                    <Col md={4}>
                      <label>Thème :</label>
                      <input
                        type="text"
                        {...register("theme")}
                        className="form-control"
                      />
                      {theme && (
                        <>
                          <span
                            className="btn btn-link btn-outline-primary p-0 m-0 pt-1 text-sm"
                            onClick={() => handleIaSuggestion(35)}
                          >
                            <FontAwesomeIcon
                              icon={faBahai}
                              className="px-1"
                              color="#cb0c9f"
                            />
                            AI suggestion - 35 mots,{" "}
                          </span>
                          <span
                            className="btn btn-link btn-outline-primary p-0 m-0 pt-1 text-sm"
                            onClick={() => handleIaSuggestion(70)}
                          >
                            70 mots,{" "}
                          </span>
                          <span
                            className="btn btn-link btn-outline-primary p-0 m-0 pt-1 text-sm"
                            onClick={() => handleIaSuggestion(110)}
                          >
                            110 mots
                          </span>
                        </>
                      )}
                    </Col>
                    <Col md={4}>
                      <label>Date de publication (optionel):</label>
                      <input type="date" {...register("date")} className="form-control" />
                      {errors.date && <div>La date est requise.</div>}
                    </Col>
                    <Col md={4}>
                      <div>
                        <div className="form-check form-switch mb-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="validated"
                            {...register("validated")}
                          />
                          <label className="form-check-label" htmlFor="validated">
                            Texte valider ?
                          </label>
                        </div>
                        <div className="form-check form-switch mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="validated_img"
                            {...register("validated_img")}
                          />
                          <label className="form-check-label" htmlFor="validated_img">
                            Image valider ?
                          </label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <label>Photo :</label>
                      <input
                        type="file"
                        className="form-control"
                        id="photo"
                        accept="image/*"
                        {...register("photo")}
                      />
                    </Col>
                    <Col md={12} className="flex">
                      {post &&
                        post.photo
                          .filter((x) => x)
                          .map((photo, index) => (
                            <div className="text-center" key={index}>
                              <img
                                src={photo}
                                alt={post.theme}
                                style={{
                                  height: "150px",
                                  marginBottom: "10px",
                                }}
                              />
                              <button
                                onClick={() =>
                                  deleteImage(photo.split("/").slice(-1)[0].split(".")[0])
                                }
                                className="btn btn-outline-primary w-80"
                              >
                                Supprimer
                              </button>
                            </div>
                          ))}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <label>Texte :</label>
                      <Controller
                        name="texte"
                        control={control}
                        rules={{ required: "Ce champ est obligatoire" }}
                        render={({ field }) => (
                          <CKEditor
                            editor={ClassicEditor}
                            data={field.value}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              field.onChange(data);
                              setValue("texte", data);
                            }}
                          />
                        )}
                      />
                      {errors.texte && <div>Le texte est requis.</div>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <label>Remarque Yellow:</label>
                      <Controller
                        name="yellow_remark"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <CKEditor
                            editor={ClassicEditor}
                            data={field.value}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              field.onChange(data);
                              setValue("yellow_remark", data);
                            }}
                          />
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <label>Remarque {order?.client?.companyName}:</label>
                      <Controller
                        name="customer_remark"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <CKEditor
                            editor={ClassicEditor}
                            data={field.value}
                            disabled={true}
                          />
                        )}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <div className="form-check form-switch mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="shared"
                          {...register("shared")}
                        />
                        <label className="form-check-label" htmlFor="shared">
                          Post programmé ?
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <button type="submit" className="btn bg-gradient-dark w-30 mt-4 mb-0">
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
          </Row>
          <Link
            className="link-action back"
            to={`/clients/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts`}
          >
            Retour
          </Link>
        </form>
      </div>
      {ia_suggestion && (
        <SuggestionModal
          ia_suggestions={ia_suggestion}
          handleCloseSuggestion={handleCloseSuggestion}
          handleUpdatePost={handleUpdatePost}
        />
      )}
    </>
  );
};

const SuggestionModal = ({ handleCloseSuggestion, ia_suggestions, handleUpdatePost }) => {
  return (
    <AnimatePresence key={1}>
      <motion.div
        key={2}
        className="expanded-modal"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.7,
        }}
      >
        <button className="close" onClick={handleCloseSuggestion}>
          <FontAwesomeIcon icon={faRectangleXmark} color="#344866" />
        </button>
        <motion.div
          key={1}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.7,
          }}
        >
          <motion.div className="expanded-content">
            <div className="container-fluid py-4">
              <div className="row">
                <div className="col-12">
                  <div className=" mb-4">
                    <div className="card-body px-0 pt-0 pb-2">
                      <div className="table-responsive p-0">
                        <table className="table align-items-center mb-0 wrap-table">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Suggestions
                              </th>
                              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {ia_suggestions.map((iaS, index) => (
                              <tr key={index}>
                                <td className="align-middle text-sm px-4">{iaS}</td>
                                <td className="align-middle px-2 text-sm text-end">
                                  <span
                                    className="btn btn-primary text-white px-3 mb-0"
                                    onClick={() => handleUpdatePost(iaS)}
                                  >
                                    Choisir
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PostForm;
