// src/redux/actions/surveyQuestionActions.js

import {
  FETCH_SURVEY_QUESTIONS_REQUEST,
  FETCH_SURVEY_QUESTIONS_SUCCESS,
  FETCH_SURVEY_QUESTIONS_FAILURE,
  ADD_SURVEY_QUESTION_REQUEST,
  ADD_SURVEY_QUESTION_SUCCESS,
  ADD_SURVEY_QUESTION_FAILURE,
  UPDATE_SURVEY_QUESTION_REQUEST,
  UPDATE_SURVEY_QUESTION_SUCCESS,
  UPDATE_SURVEY_QUESTION_FAILURE,
  DELETE_SURVEY_QUESTION_REQUEST,
  DELETE_SURVEY_QUESTION_SUCCESS,
  DELETE_SURVEY_QUESTION_FAILURE,
  FETCH_PRODUCT_SURVEY_QUESTIONS_REQUEST,
  FETCH_PRODUCT_SURVEY_QUESTIONS_SUCCESS,
  FETCH_PRODUCT_SURVEY_QUESTIONS_FAILURE,
  DELETE_SURVEY_QUESTION_FROM_PRODUCT_REQUEST,
  DELETE_SURVEY_QUESTION_FROM_PRODUCT_SUCCESS,
  DELETE_SURVEY_QUESTION_FROM_PRODUCT_FAILURE,
} from "../actionTypes";
import { toast } from "react-toastify";
import { surveyQuestionService } from "../services/surveyQuestionService";

export const addSurveyQuestionToProduct = (productId, data) => {
  return async (dispatch) => {
    try {
      await surveyQuestionService.addSurveyQuestionToProduct(productId, data);
      dispatch(fetchSurveyQuestionsByProduct(productId));
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const fetchSurveyQuestionsByProduct = (productId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PRODUCT_SURVEY_QUESTIONS_REQUEST });
    try {
      const productSurveyQuestions =
        await surveyQuestionService.getSurveyQuestionsByProduct(productId);
      dispatch({
        type: FETCH_PRODUCT_SURVEY_QUESTIONS_SUCCESS,
        payload: productSurveyQuestions,
      });
    } catch (error) {
      dispatch({
        type: FETCH_PRODUCT_SURVEY_QUESTIONS_FAILURE,
        payload: error.message,
      });
    }
  };
};
export const deleteSurveyQuestionFromProduct = (productId, questionId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_SURVEY_QUESTION_FROM_PRODUCT_REQUEST });
    try {
      const deletedQuestion = await surveyQuestionService.deleteSurveyQuestionFromProduct(
        productId,
        questionId
      );
      dispatch({
        type: DELETE_SURVEY_QUESTION_FROM_PRODUCT_SUCCESS,
        payload: questionId,
      });
      return Promise.resolve(deletedQuestion);
    } catch (error) {
      dispatch({
        type: DELETE_SURVEY_QUESTION_FROM_PRODUCT_FAILURE,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};

export const fetchSurveyQuestions = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SURVEY_QUESTIONS_REQUEST });
    try {
      const surveyQuestions = await surveyQuestionService.getAllSurveyQuestions();
      dispatch({
        type: FETCH_SURVEY_QUESTIONS_SUCCESS,
        payload: surveyQuestions,
      });
    } catch (error) {
      dispatch({
        type: FETCH_SURVEY_QUESTIONS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const addSurveyQuestion = (questionData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_SURVEY_QUESTION_REQUEST });
    try {
      const newQuestion = await surveyQuestionService.createSurveyQuestion(questionData);
      dispatch({
        type: ADD_SURVEY_QUESTION_SUCCESS,
        payload: newQuestion,
      });
      toast.success("Question d'enquête ajoutée avec succès");
      return Promise.resolve(newQuestion);
    } catch (error) {
      dispatch({
        type: ADD_SURVEY_QUESTION_FAILURE,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};

export const updateSurveyQuestion = (questionId, questionData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_SURVEY_QUESTION_REQUEST });
    try {
      const updatedQuestion = await surveyQuestionService.updateSurveyQuestion(
        questionId,
        questionData
      );
      dispatch({
        type: UPDATE_SURVEY_QUESTION_SUCCESS,
        payload: updatedQuestion,
      });
      toast.success("Question d'enquête mise à jour avec succès");
      return Promise.resolve(updatedQuestion);
    } catch (error) {
      dispatch({
        type: UPDATE_SURVEY_QUESTION_FAILURE,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};

export const deleteSurveyQuestion = (questionId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_SURVEY_QUESTION_REQUEST });
    try {
      const deletedQuestion = await surveyQuestionService.deleteSurveyQuestion(
        questionId
      );
      dispatch({
        type: DELETE_SURVEY_QUESTION_SUCCESS,
        payload: deletedQuestion,
      });
      return Promise.resolve(deletedQuestion);
    } catch (error) {
      dispatch({
        type: DELETE_SURVEY_QUESTION_FAILURE,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};
