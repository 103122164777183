import { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderById,
  fetchClientById,
  uploadDocument,
  downloadDocument,
} from "../../../../../redux/actions/clientActions";

import { Row, Col, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { NavContext } from "../../../../NavContext";
import { Bars } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faShareSquare } from "@fortawesome/free-solid-svg-icons";
import OrderNavigation from "../order/orderNavigation";

const DocumentPage = () => {
  const dispatch = useDispatch();
  const { activeNav, setActiveNav } = useContext(NavContext);
  const { clientId, orderId } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [showSendMessage, setShowSendMessage] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);

  const order = useSelector((state) => state.clientReducer.order);
  const client = useSelector((state) => state.clientReducer.tiny_client);
  const loadingDocument = useSelector((state) => state.clientReducer.loadingDocument);
  const downloadingDocument = useSelector(
    (state) => state.clientReducer.downloadingDocument
  );

  const tags = [
    { label: "Rapport", value: "Rapport" },
    { label: "Cahier de charges", value: "CDC" },
    { label: "Contrat", value: "Contrat" },
    { label: "Visuel", value: "Visuel" },
  ];

  useEffect(() => {
    document.title = "ProdOTop - Documents de la commande";
  }, []);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientById(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(clientId, orderId));
    }
  }, [dispatch, orderId, clientId]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("document", data.document[0]);
    formData.append("title", data.title);
    formData.append("tags", selectedTags);
    formData.append("shared", data.shared);
    dispatch(uploadDocument(clientId, orderId, formData));

    reset();
  };

  const handleDownload = async (docId) => {
    dispatch(downloadDocument(clientId, orderId, docId));
  };

  const toggleDocumentSelection = (docId) => {
    const isAlreadySelected = selectedDocs.includes(docId);
    let updatedSelectedDocs;
    if (isAlreadySelected) {
      updatedSelectedDocs = selectedDocs.filter((id) => id !== docId);
    } else {
      updatedSelectedDocs = [...selectedDocs, docId];
    }
    setSelectedDocs(updatedSelectedDocs);
    setShowSendMessage(updatedSelectedDocs.length > 0);
  };

  const handleTagChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedTags([...selectedTags, value]);
    } else {
      setSelectedTags(selectedTags.filter((tag) => tag !== value));
    }
  };

  return (
    <>
      {order && (
        <>
          <div className="pb-4">
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  <Link to="/clients">clients</Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/clients/${client?._id}/orders`}>{client?.companyName}</Link>
                </li>
                {order && (
                  <li
                    className="breadcrumb-item text-sm text-dark flex p-0"
                    aria-current="page"
                  >
                    <Dropdown className="dropdown-orders">
                      <Dropdown.Toggle id="dropdown-basic">
                        {order.product?.name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {client?.orders?.map((cl_order) => (
                          <Dropdown.Item
                            as={Link}
                            to={`/clients/${clientId}/orders/${cl_order._id}/documents`}
                            key={cl_order._id}
                          >
                            {cl_order.product?.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                )}
                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  Documents
                </li>
              </ol>
            </nav>
          </div>
          {(loadingDocument || downloadingDocument) && (
            <div className="ia-loader">
              <Bars
                height={100}
                width={100}
                radius={5}
                color="#cb0c9f"
                ariaLabel="bars-loading"
                wrapperClass={"triangle-loader"}
                wrapperStyle=""
                visible={true}
              />
            </div>
          )}
          <div className="order-container">
            {order && (
              <OrderNavigation order={order} client={client} currentTab="documents" />
            )}
            <div>
              <Row className="mb-3">
                <div className="col-12">
                  <div className="card medium-card mb-4 pb-2 purple-border">
                    <div className="card-header">
                      <Row>
                        <Col md={12} className="mb-3">
                          <h6>
                            <span className="product-title px-3 text-dark">
                              Formulaire document
                            </span>
                          </h6>
                        </Col>
                      </Row>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                          <Col md={12} className="mb-3">
                            <label htmlFor="title" className="form-label">
                              Titre du fichier :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              {...register("title", { required: true })}
                            />
                            {errors.title && (
                              <div className="text-danger">Le titre est requis</div>
                            )}
                          </Col>

                          <Col md={12}>
                            <div className="flex gap-3 bg-ligth-primary rounded-2 justify-content-around">
                              {tags.map((tag) => (
                                <div key={tag.value} className="form-check">
                                  <input
                                    type="checkbox"
                                    id={tag.value}
                                    value={tag.value}
                                    onChange={handleTagChange}
                                    checked={selectedTags.includes(tag.value)}
                                    className="form-check-input"
                                  />
                                  <label htmlFor={tag.value}>{tag.label}</label>
                                </div>
                              ))}
                            </div>
                          </Col>

                          <Col md={12} className="mb-3">
                            <label htmlFor="document" className="form-label">
                              Document
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="document"
                              // accept="application/pdf"
                              {...register("document", { required: true })}
                            />
                            {errors.pdf && (
                              <div className="text-danger">Le PDF est requis</div>
                            )}
                          </Col>
                          <Col md={12}>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="shared"
                                {...register("shared")}
                              />
                              <label className="form-check-label" htmlFor="shared">
                                Partager avec le client
                              </label>
                            </div>
                          </Col>
                          <Col>
                            <button
                              type="submit"
                              className="btn bg-gradient-dark w-30 mt-4 mb-0"
                            >
                              Charger
                            </button>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </div>
                </div>
              </Row>
              <Row className="mb-3">
                <div className="col-12">
                  <div className="card medium-card mb-4 pb-2 purple-border">
                    <div className="card-body">
                      <h6>
                        <span className="product-title px-3 text-dark">Documents</span>
                      </h6>
                      {order?.documents.map((doc) => (
                        <div className="flex gap-2 px-3 " key={doc._id}>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              checked={selectedDocs.includes(doc._id)}
                              onChange={() => toggleDocumentSelection(doc._id)}
                              className="form-check-input"
                            />
                          </div>
                          <div
                            className="btn-link primary-color text-gradient  text-end"
                            role="button"
                            title="Télécharger"
                            onClick={() => handleDownload(doc._id)}
                          >
                            <FontAwesomeIcon icon={faDownload} className="px-2" />
                          </div>

                          <div>
                            <small>{moment(doc.createdAt).format("DD-MM-YYYY")}</small>
                          </div>
                          <div>{doc?.title}</div>

                          <div className="text-end">
                            {" "}
                            {doc.tags?.length > 0 && (
                              <div className="tags-list">
                                <ul className="flex gap-2">
                                  {doc.tags.map((tag, index) => (
                                    <li key={index} className="bg-gray-100">
                                      {tag}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Link to={`/clients/${clientId}/orders`} className="link-action back">
                    Retour
                  </Link>
                </Col>
              </Row>

              {showSendMessage && (
                <div className="bottom-documents flex">
                  <span>
                    <i className="text-bold">{selectedDocs.length}</i> document(s)
                    sélectionné(s)
                  </span>
                  <Link
                    to={`/clients/${clientId}/orders/${orderId}/emails?documents=${selectedDocs.join(
                      ","
                    )}`}
                    className="btn btn-primary mb-0"
                  >
                    Envoyer par e-mail
                  </Link>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DocumentPage;
