import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileContract,
  faBarcode,
  faEnvelopeCircleCheck,
  faStar,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import { NavContext } from "../NavContext";

const Configuration = () => {
  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    setActiveNav("configuration");
  }, [setActiveNav]);

  return (
    <div>
      <div className="card mb-3">
        <div className="flex justify-content-between gap-3 ">
          <div className="p-5">
            <h2>
              <span>Bienvenue sur le tableau de bord professionnel</span>
            </h2>
            <div>
              <span>
                Configuration de vos produits, E-mails, Enquêtes de satisfactions ...
              </span>
            </div>
          </div>
          <div>
            <i className="bg-configuration img-fluid bg-header" />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="p-5">
          <div className="mb-5">
            <h2>
              <span>Configurer</span>
            </h2>
          </div>
          <div className="flex flex-wrap gap-3">
            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/products">
                <FontAwesomeIcon icon={faBarcode} className="" />
                <span className="mx-3">Produits</span>
              </Link>
            </div>

            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/emails">
                <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="" />
                <span className="mx-3">E-mails</span>
              </Link>
            </div>
            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/survey-questions">
                <FontAwesomeIcon icon={faStar} className="" />
                <span className="mx-3">Enquêtes de satisfaction</span>
              </Link>
            </div>
            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/quality-checks">
                <FontAwesomeIcon icon={faMedal} className="" />
                <span className="mx-3">Suivi de qualité</span>
              </Link>
            </div>
            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/brief-questions">
                <FontAwesomeIcon icon={faFileContract} className="" />
                <span className="mx-3">Cahier des charges</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configuration;
