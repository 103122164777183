import {
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
} from "../actionTypes";
import { getDashboardInfo } from "../services/dashboardService";

export const fetchDashboardRequest = () => {
  return {
    type: FETCH_DASHBOARD_REQUEST,
  };
};

export const fetchDashboardSuccess = (data) => {
  return {
    type: FETCH_DASHBOARD_SUCCESS,
    payload: data,
  };
};

export const fetchDashboardFailure = (error) => {
  return {
    type: FETCH_DASHBOARD_FAILURE,
    payload: error,
  };
};

export const fetchDashboardInfo = (actAsData) => {
  return (dispatch) => {
    dispatch(fetchDashboardRequest());
    getDashboardInfo(actAsData)
      .then((data) => {
        dispatch(fetchDashboardSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchDashboardFailure(error));
      });
  };
};
