import { useEffect, useContext, useState, useCallback } from "react";
import { NavContext } from "../../NavContext";
import { Row, Col, ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDashboardClients,
  estimateOrderCost,
} from "../../../redux/actions/clientActions";
import { fetchUsers } from "../../../redux/actions/userActions";
import { Bars } from "react-loader-spinner";
import { Link, useSearchParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleXmark } from "@fortawesome/free-solid-svg-icons";

const ClientsDashboard = () => {
  const dispatch = useDispatch();
  const [clientSearch, setClientSearch] = useState([]);
  const [show, setShow] = useState(false);
  const [querySearch, setQuerySearch] = useSearchParams({
    q: "",
    actAs: "",
  });
  const q = querySearch.get("q");
  const actAs = querySearch.get("actAs");

  const clients = useSelector((state) => state.clientReducer.dashboardClients);
  const loadingProfitability = useSelector(
    (state) => state.clientReducer.loadingProfitability
  );
  const users = useSelector((state) => state.userReducer.users);
  const order_estimation = useSelector((state) => state.clientReducer.order_estimation);
  const estimation_loading = useSelector(
    (state) => state.clientReducer.estimation_loading
  );
  const estimation_unauthorized = useSelector(
    (state) => state.clientReducer.estimation_unauthorized
  );

  const filtred_users = users.filter(
    (user) =>
      user.role !== "boss" &&
      user.departement !== "Team4" &&
      user.departement !== "client"
  );

  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    document.title = "ProdOTop - Clients dashboard";
  }, []);

  useEffect(() => {
    setActiveNav("client-dashboard");
  }, [setActiveNav]);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDashboardClients({ actAs: actAs }));
  }, [dispatch, actAs]);

  const handleSearch = useCallback(
    (searchTerm) => {
      const filteredClients = clients.filter((client) =>
        client.companyName.toLowerCase().includes(searchTerm)
      );
      setClientSearch(filteredClients);
    },
    [clients]
  );

  useEffect(() => {
    if (q) {
      const searchTerm = q.toLowerCase();
      handleSearch(searchTerm);
    } else {
      setClientSearch(clients);
    }
  }, [q, clients, handleSearch]);

  const RatingStars = ({ rating }) => {
    const totalStars = 5;
    let stars = [];

    for (let i = 0; i < totalStars; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<span className="star full" key={`star_${i}`}></span>);
      } else if (i < rating) {
        stars.push(<span className="star half" key={`star_${i}`}></span>);
      } else {
        stars.push(<span className="star empty" key={`star_${i}`}></span>);
      }
    }

    return <div className="rating-stars">{stars}</div>;
  };

  const handleShowModal = (clientId, orderId) => {
    dispatch(estimateOrderCost(clientId, orderId));
    setShow(true);
  };

  return (
    <>
      {loadingProfitability && (
        <div className="ia-loader">
          <Bars
            height={100}
            width={100}
            radius={5}
            color="#cb0c9f"
            ariaLabel="bars-loading"
            wrapperClass={"triangle-loader"}
            wrapperStyle=""
            visible={true}
          />
        </div>
      )}
      <div className="pb-3">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              <Link to="/clients">clients</Link>
            </li>
          </ol>
        </nav>
      </div>
      <Row>
        <div className="col-12">
          <div className="card mb-4 purple-border">
            <div className="card-body  p-0">
              <Row className="m-3">
                <Col md={6} className="mb-1">
                  <input
                    type="text"
                    className="form-control filter-client"
                    name="companyName"
                    placeholder="Chercher le client sur cette page..."
                    defaultValue={q}
                    onChange={(e) => {
                      const searchTerm = e.target.value.toLowerCase();
                      setQuerySearch({ q: searchTerm }, { replace: true });
                      handleSearch(searchTerm);
                    }}
                  />
                </Col>
                <Col md={6} className="mb-1">
                  <select
                    id="actAs"
                    className="form-control mb-0"
                    value={actAs}
                    onChange={(e) => {
                      const searchTerm = e.target.value;
                      setQuerySearch({ actAs: searchTerm }, { replace: true });
                    }}
                  >
                    <option key={0} value="">
                      Global
                    </option>
                    {filtred_users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        {clientSearch.map((client) => (
          <Col md={12} lg={{ span: 10, offset: 1 }} key={client._id} className="mb-3">
            <div className="card purple-border card-order py-3 justify-content-center">
              <div className="card-body">
                <div className="">
                  <h6 className="companyName-title">
                    <span className="product-title text-dark ">{client.companyName}</span>
                  </h6>
                </div>
                {client.orders.map(
                  (order) =>
                    order.efficiencyPercentage > 0 && (
                      <div key={order._id}>
                        <div className="pb-2 flex gap-2">
                          <div>
                            <RatingStars rating={order.averageRating} />
                          </div>

                          <span>{order.product_name}</span>
                        </div>

                        <div>
                          <>
                            <div className="pb-3 profitability">
                              <ProgressBar
                                now={Math.round(order.efficiencyPercentage)}
                                label={`${Math.round(order.efficiencyPercentage)}%`}
                                width="300"
                                className={`status-progress ${
                                  order.efficiencyPercentage > 110 ? "danger" : ""
                                }`}
                              />
                              <span
                                role="button"
                                className="text-xs text-bolder"
                                onClick={() => handleShowModal(client._id, order._id)}
                              >
                                Détails
                              </span>
                            </div>
                          </>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </Col>
        ))}

        <Row className="mb-3">
          <Col>
            <Link className="link-action back" to="/">
              Retour
            </Link>
          </Col>
        </Row>
      </Row>
      {show && !estimation_loading && (
        <CostModal
          handleClose={() => setShow(false)}
          order_estimation={order_estimation}
          estimation_unauthorized={estimation_unauthorized}
        />
      )}
    </>
  );
};

const CostModal = ({ handleClose, order_estimation, estimation_unauthorized }) => {
  return (
    <AnimatePresence key={1}>
      <motion.div
        key={2}
        className="expanded-modal"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.7,
        }}
      >
        <button className="close" onClick={handleClose}>
          <FontAwesomeIcon icon={faRectangleXmark} color="#344866" />
        </button>
        <motion.div
          key={1}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.7,
          }}
        >
          <motion.div className="expanded-content">
            <div className="container-fluid py-4">
              <div className="row">
                <div className="col-12">
                  <div className=" mb-4">
                    <div className="card-body px-0 pt-0 pb-2">
                      {estimation_unauthorized && (
                        <p className="text-danger">{estimation_unauthorized}</p>
                      )}
                      {order_estimation && order_estimation?.taskDetails && (
                        <div className="table-responsive p-0">
                          <table className="table align-items-center mb-5">
                            <thead>
                              <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Tâche
                                </th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Responsable
                                </th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Temps alloué
                                </th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Répétition
                                </th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Coût
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {order_estimation?.taskDetails.map((td, index) => (
                                <tr key={index}>
                                  <td>{td.taskName}</td>
                                  <td>{td.responsibleName}</td>
                                  <td>{td.taskTime} min</td>
                                  <td>{td.quantity}</td>
                                  <td>{td.taskCost} €</td>
                                </tr>
                              ))}
                              <tr key="uniq_1">
                                <td className="text-lg font-weight-bolder">
                                  <b>Coût d'estimation</b>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="text-uppercase text-lg font-weight-bolder">
                                  {order_estimation.totalCost} €
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <hr />
                          <div className="flex flex-wrap gap-3 justify-content-between ">
                            <div className="card bg-ligth-primary p-5 shadow-none text-center">
                              <div className="text-lg font-weight-bolder">
                                Coût presté
                              </div>
                              <span className="">
                                {order_estimation.reality.totalCostDone} €
                              </span>
                            </div>

                            <div className="card bg-ligth-primary p-5 shadow-none text-center">
                              <div className="text-lg font-weight-bolder">
                                Coût à prester
                              </div>
                              <span className="">
                                {order_estimation.reality.totalCostNotDone} €
                              </span>
                            </div>

                            <div className="card bg-ligth-primary p-5 shadow-none text-center">
                              <div className="text-lg font-weight-bolder">Coût total</div>
                              <span className="">
                                {order_estimation.reality.totalCostDone +
                                  order_estimation.reality.totalCostNotDone}{" "}
                                €
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ClientsDashboard;
