import {
  FETCH_QUALITY_CHECKS_REQUEST,
  FETCH_QUALITY_CHECKS_SUCCESS,
  FETCH_QUALITY_CHECKS_FAILURE,
  FETCH_QUALITY_CHECK_REQUEST,
  FETCH_QUALITY_CHECK_SUCCESS,
  FETCH_QUALITY_CHECK_FAILURE,
  ADD_QUALITY_CHECK_REQUEST,
  ADD_QUALITY_CHECK_SUCCESS,
  ADD_QUALITY_CHECK_FAILURE,
  UPDATE_QUALITY_CHECK_REQUEST,
  UPDATE_QUALITY_CHECK_SUCCESS,
  UPDATE_QUALITY_CHECK_FAILURE,
  DELETE_QUALITY_CHECK_REQUEST,
  DELETE_QUALITY_CHECK_SUCCESS,
  DELETE_QUALITY_CHECK_FAILURE,
} from "../actionTypes";

const initialState = {
  qualityChecks: [],
  qualityCheck: false,

  isFetching: false,
  loading: false,
  error: null,
};

const qualityCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUALITY_CHECK_REQUEST:
      return {
        ...state,
        error: null,
      };
    case FETCH_QUALITY_CHECK_SUCCESS:
      return {
        ...state,
        qualityCheck: action.payload,
        error: null,
      };
    case FETCH_QUALITY_CHECK_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_QUALITY_CHECKS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ADD_QUALITY_CHECK_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case UPDATE_QUALITY_CHECK_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case DELETE_QUALITY_CHECK_REQUEST:
      return {
        ...state,
        loading: true,
        isFetching: true,
        error: null,
      };
    case FETCH_QUALITY_CHECKS_SUCCESS:
      return {
        ...state,
        qualityChecks: action.payload,
        isFetching: true,
        loading: false,
        error: null,
      };
    case ADD_QUALITY_CHECK_SUCCESS:
      return {
        ...state,
        //qualityChecks: [...state.qualityChecks, action.payload],
        loading: false,
        isFetching: false,
        error: null,
      };
    case UPDATE_QUALITY_CHECK_SUCCESS:
      const updatedBriefQuestions = state.qualityChecks.map((qualityCheck) =>
        qualityCheck._id === action.payload._id ? action.payload : qualityCheck
      );
      return {
        ...state,
        qualityChecks: updatedBriefQuestions,
        loading: false,
        error: null,
        isFetching: false,
      };
    case DELETE_QUALITY_CHECK_SUCCESS:
      const filteredBriefQuestions = state.qualityChecks.filter(
        (qualityCheck) => qualityCheck._id !== action.payload
      );
      return {
        ...state,
        qualityChecks: filteredBriefQuestions,
        isFetching: false,
        loading: false,
        error: null,
      };
    case FETCH_QUALITY_CHECKS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case ADD_QUALITY_CHECK_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case UPDATE_QUALITY_CHECK_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case DELETE_QUALITY_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default qualityCheckReducer;
