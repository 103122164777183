import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faPen, faBorderAll } from "@fortawesome/free-solid-svg-icons";

const OrderNavigation = ({ client, currentTab }) => {
  const clientId = client._id;
  return (
    <div className="card">
      <div className="order-navigation flex flex-wrap flex-row gap-5 py-2 px-3 justify-content-center">
        <div className={`${currentTab === "edit" ? "active-tab" : ""}`}>
          <Link to={`/clients/${clientId}`}>
            <FontAwesomeIcon icon={faPen} />
            <span className="mx-2">Infos. client</span>
          </Link>
        </div>

        <div className={`${currentTab === "orders" ? "active-tab" : ""}`}>
          <Link to={`/clients/${clientId}/orders`} className="">
            <FontAwesomeIcon icon={faBorderAll} />
            <span className="mx-2">Commandes</span>
          </Link>
        </div>

        <div className={`${currentTab === "notes" ? "active-tab" : ""}`}>
          <Link to={`/clients/${clientId}/notes`} className="">
            <FontAwesomeIcon icon={faBook} />
            <span className="mx-2">Notes</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderNavigation;
