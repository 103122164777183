import { useEffect, useContext, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  deleteImagePost,
  fetchPostById,
  fetchECalendarById,
  addPost,
  updatePost,
  notifyCommunity,
} from "../../../../redux/actions/cli/eCalendarActions";
import { fetchCliTinyClient } from "../../../../redux/actions/cli/clientsActions";
import OrderNavigation from "../../clients/orderNavigation";

import { fetchOrderById } from "../../../../redux/actions/cli/orderActions";
import { Row, Col } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const PostForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId, orderId, eCalendarId, postId } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm();

  const post = useSelector((state) => state.cliECalendarReducer.post);
  const order = useSelector((state) => state.cliClientsReducer.order);
  const eCalendar = useSelector((state) => state.cliECalendarReducer.eCalendar);
  const client = useSelector((state) => state.cliClientsReducer.tiny_client);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliTinyClient(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (postId) {
      dispatch(fetchPostById(orderId, eCalendarId, postId));
    }
  }, [dispatch, orderId, postId, eCalendarId]);

  useEffect(() => {
    if (eCalendarId) {
      dispatch(fetchECalendarById(orderId, eCalendarId));
    }
  }, [dispatch, eCalendarId, orderId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    if (!postId) {
      setValue("date", "");
      setValue("theme", "");
      setValue("texte", "");
      setValue("customer_remark", "");
      setValue("shared", false);
    }
  }, [postId, setValue]);

  useEffect(() => {
    if (post) {
      reset({
        date: post.date ? moment(post.date).format("YYYY-MM-DD") : "",
        theme: post.theme,
        validated: post.validated,
        validated_img: post.validated_img,
        texte: post.texte,
        customer_remark: post.customer_remark,
        shared: post.shared,
      });
    }
  }, [post, reset]);

  const deleteImage = (path) => {
    dispatch(deleteImagePost(orderId, eCalendarId, postId, path));
    navigate(
      `/client/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts`
    );
  };

  const onSubmit = (data) => {
    let formData = new FormData(); // Use FormData for file handling
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append("photo", data.photo[0]);

    if (postId) {
      dispatch(updatePost(orderId, eCalendarId, postId, formData));
    } else {
      dispatch(addPost(orderId, eCalendarId, formData));
      navigate(
        `/client/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts`
      );
      reset();
    }
  };
  const notify_community = () => {
    dispatch(notifyCommunity(orderId, eCalendarId, postId));
  };

  return (
    <>
      <div className="pb-2">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              clients
            </li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/client/${clientId}/orders`}>{order?.client?.companyName}</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {order?.product?.name}
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to={`/client/${clientId}/orders/${orderId}/editorial_calendars`}>
                Calendriers éditoriaux
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link
                to={`/client/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts`}
              >
                {eCalendar.title}
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              {postId ? "Modifier post" : "Ajouter un post"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="order-container">
        {order && <OrderNavigation order={order} client={client} currentTab="ce" />}
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="row">
              <Col>
                <div className="text-end mx-3">
                  <span className="btn btn-outline-primary " onClick={notify_community}>
                    Notifier le community manager des changements
                  </span>
                </div>
              </Col>
              <div className="col-12">
                <div className="card medium-card mb-4 pb-2 purple-border">
                  <div className="card-body">
                    <Row className="mb-3">
                      <Col md={4}>
                        <label>Thème :</label>
                        <input
                          type="text"
                          {...register("theme")}
                          className="form-control"
                        />
                      </Col>
                      <Col md={4}>
                        <label>Date de publication (optionel):</label>
                        <input
                          type="date"
                          {...register("date")}
                          className="form-control"
                        />
                        {errors.date && <div>La date est requise.</div>}
                      </Col>
                      <Col md={4}>
                        <div>
                          <div className="form-check form-switch mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="validated"
                              {...register("validated")}
                            />
                            <label className="form-check-label" htmlFor="validated">
                              Texte valider ?
                            </label>
                          </div>
                          <div className="form-check form-switch mb-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="validated_img"
                              {...register("validated_img")}
                            />
                            <label className="form-check-label" htmlFor="validated_img">
                              Image valider ?
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12}>
                        <label>Photo :</label>
                        <input
                          type="file"
                          className="form-control"
                          id="photo"
                          accept="image/*"
                          {...register("photo")}
                        />
                      </Col>
                      <Col md={12} className="flex">
                        {post &&
                          post.photo
                            .filter((x) => x)
                            .map((photo, index) => (
                              <div className="text-center" key={index}>
                                <img
                                  src={photo}
                                  alt={post.theme}
                                  style={{
                                    height: "150px",
                                    marginBottom: "10px",
                                  }}
                                />
                                <button
                                  onClick={() =>
                                    deleteImage(
                                      photo.split("/").slice(-1)[0].split(".")[0]
                                    )
                                  }
                                  className="btn btn-outline-primary w-80"
                                >
                                  Supprimer
                                </button>
                              </div>
                            ))}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12}>
                        <label>Texte :</label>
                        <Controller
                          name="texte"
                          control={control}
                          rules={{ required: "Ce champ est obligatoire" }}
                          render={({ field }) => (
                            <CKEditor
                              editor={ClassicEditor}
                              data={field.value}
                              disabled={true}
                            />
                          )}
                        />
                        {errors.texte && <div>Le texte est requis.</div>}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12}>
                        <label>Remarque {order?.client?.companyName}:</label>
                        <Controller
                          name="customer_remark"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <CKEditor
                              editor={ClassicEditor}
                              data={field.value}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                field.onChange(data);
                                setValue("customer_remark", data);
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="shared"
                        {...register("shared")}
                        disabled
                      />
                      <label className="form-check-label" htmlFor="shared">
                        Programmé ?
                      </label>
                    </div>
                    <button type="submit" className="btn bg-gradient-dark w-30 mt-4 mb-0">
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>
            </Row>
            <Link
              className="link-action back"
              to={`/client/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts`}
            >
              Retour
            </Link>
          </form>
        </>
      </div>
    </>
  );
};

export default PostForm;
