import {
  FETCH_PRODUCT_QUALITY_CRITERIONS_REQUEST,
  FETCH_PRODUCT_QUALITY_CRITERIONS_SUCCESS,
  FETCH_PRODUCT_QUALITY_CRITERIONS_FAILURE,
  ADD_PRODUCT_QUALITY_CRITERION_REQUEST,
  ADD_PRODUCT_QUALITY_CRITERION_SUCCESS,
  ADD_PRODUCT_QUALITY_CRITERION_FAILURE,
  UPDATE_PRODUCT_QUALITY_CRITERION_REQUEST,
  UPDATE_PRODUCT_QUALITY_CRITERION_SUCCESS,
  UPDATE_PRODUCT_QUALITY_CRITERION_FAILURE,
  DELETE_PRODUCT_QUALITY_CRITERION_REQUEST,
  DELETE_PRODUCT_QUALITY_CRITERION_SUCCESS,
  DELETE_PRODUCT_QUALITY_CRITERION_FAILURE,
} from "../actionTypes";

const initialState = {
  qualityCriterions: [],
  isFetching: false,
  error: "",
};

const qualityCriterionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_QUALITY_CRITERIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ADD_PRODUCT_QUALITY_CRITERION_REQUEST:
    case UPDATE_PRODUCT_QUALITY_CRITERION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_PRODUCT_QUALITY_CRITERION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PRODUCT_QUALITY_CRITERIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        qualityCriterions: action.payload,
        error: "",
      };
    case ADD_PRODUCT_QUALITY_CRITERION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        qualityCriterions: [...state.qualityCriterions, action.payload],
        error: "",
      };
    case UPDATE_PRODUCT_QUALITY_CRITERION_SUCCESS:
      const updatedQualityCriterions = state.qualityCriterions.map((qualityCriterion) =>
        qualityCriterion._id === action.payload._id ? action.payload : qualityCriterion
      );
      return {
        ...state,
        isFetching: false,
        qualityCriterions: updatedQualityCriterions,
        error: "",
      };
    case DELETE_PRODUCT_QUALITY_CRITERION_SUCCESS:
      const filteredQualityCriterions = state.qualityCriterions.filter(
        (qualityCriterion) => qualityCriterion._id !== action.payload
      );
      return {
        ...state,
        isFetching: false,
        qualityCriterions: filteredQualityCriterions,
        error: "",
      };
    case FETCH_PRODUCT_QUALITY_CRITERIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case ADD_PRODUCT_QUALITY_CRITERION_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case UPDATE_PRODUCT_QUALITY_CRITERION_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case DELETE_PRODUCT_QUALITY_CRITERION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default qualityCriterionReducer;
