import {
  CLI_FETCH_ECALENDARS_REQUEST,
  CLI_FETCH_ECALENDARS_SUCCESS,
  CLI_FETCH_ECALENDARS_FAILURE,
  CLI_ADD_ECALENDAR_REQUEST,
  CLI_ADD_ECALENDAR_SUCCESS,
  CLI_ADD_ECALENDAR_FAILURE,
  CLI_UPDATE_ECALENDAR_REQUEST,
  CLI_UPDATE_ECALENDAR_SUCCESS,
  CLI_UPDATE_ECALENDAR_FAILURE,
  CLI_DELETE_ECALENDAR_REQUEST,
  CLI_DELETE_ECALENDAR_SUCCESS,
  CLI_DELETE_ECALENDAR_FAILURE,
  CLI_FETCH_ORDER_REQUEST,
  CLI_FETCH_ORDER_SUCCESS,
  CLI_FETCH_ORDER_FAILURE,
  CLI_FETCH_ECALENDAR_REQUEST,
  CLI_FETCH_ECALENDAR_SUCCESS,
  CLI_FETCH_ECALENDAR_FAILURE,
  CLI_FETCH_POSTS_REQUEST,
  CLI_FETCH_POSTS_SUCCESS,
  CLI_FETCH_POSTS_FAILURE,
  CLI_DELETE_POST_REQUEST,
  CLI_DELETE_POST_SUCCESS,
  CLI_DELETE_POST_FAILURE,
  CLI_POST_ADD_REQUEST,
  CLI_POST_ADD_SUCCESS,
  CLI_POST_ADD_FAILURE,
  CLI_POST_UPDATE_REQUEST,
  CLI_POST_UPDATE_SUCCESS,
  CLI_POST_UPDATE_FAILURE,
  CLI_FETCH_POST_REQUEST,
  CLI_FETCH_POST_SUCCESS,
  CLI_FETCH_POST_FAILURE,
  CLI_FETCH_CALENDAR_POSTS_SUCCESS,
} from "../../clientActionTypes";

const initialState = {
  eCalendars: [],
  eCalendar: false,
  order: false,
  posts: [],
  dragged_posts: [],
  undragged_posts: [],
  post: false,
  isFetching: false,
  errorMessage: "",
};

export default function cliECalendarReducer(state = initialState, action) {
  switch (action.type) {
    case "CLI_ADD_TO_DRAGGED_POSTS":
      const new_dragged_posts = state.dragged_posts.concat(action.payload);
      return {
        ...state,
        dragged_posts: new_dragged_posts,
      };
    case "CLI_REMOVE_FROM_UNDRAGGED_POSTS":
      const undraggedPosts = state.undragged_posts.filter(
        (event) => event._id !== action.payload
      );
      return {
        ...state,
        undragged_posts: undraggedPosts,
      };

    case CLI_FETCH_CALENDAR_POSTS_SUCCESS:
      return {
        ...state,
        dragged_posts: action.payload.dragged_posts,
        undragged_posts: action.payload.undragged_posts,
      };
    case CLI_POST_ADD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CLI_POST_ADD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        posts: [...state.posts, action.payload],
      };
    case CLI_POST_ADD_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CLI_POST_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CLI_POST_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        posts: state.posts.map((post) =>
          post._id === action.payload._id ? action.payload : post
        ),
      };
    case CLI_POST_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CLI_FETCH_POST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CLI_FETCH_POST_SUCCESS:
      return {
        ...state,
        isFetching: true,
        post: action.payload,
      };
    case CLI_FETCH_POST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CLI_FETCH_POSTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CLI_FETCH_POSTS_SUCCESS:
      return {
        ...state,
        isFetching: true,
        posts: action.payload,
      };
    case CLI_FETCH_POSTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CLI_FETCH_ECALENDARS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CLI_FETCH_ECALENDARS_SUCCESS:
      return {
        ...state,
        isFetching: true,
        eCalendars: action.payload,
      };
    case CLI_FETCH_ECALENDARS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CLI_FETCH_ECALENDAR_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CLI_FETCH_ECALENDAR_SUCCESS:
      return {
        ...state,
        isFetching: true,
        eCalendar: action.payload,
      };
    case CLI_FETCH_ECALENDAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CLI_ADD_ECALENDAR_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CLI_ADD_ECALENDAR_SUCCESS:
      return {
        ...state,
        isFetching: true,
        eCalendars: [...state.eCalendars, action.payload],
      };
    case CLI_ADD_ECALENDAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CLI_UPDATE_ECALENDAR_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CLI_UPDATE_ECALENDAR_SUCCESS:
      return {
        ...state,
        isFetching: true,
        eCalendars: state.eCalendars.map((eCalendar) =>
          eCalendar._id === action.payload._id ? action.payload : eCalendar
        ),
      };
    case CLI_UPDATE_ECALENDAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CLI_DELETE_POST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CLI_DELETE_POST_SUCCESS:
      return {
        ...state,
        isFetching: true,
        posts: state.posts.filter((post) => post._id !== action.payload),
      };
    case CLI_DELETE_POST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CLI_DELETE_ECALENDAR_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CLI_DELETE_ECALENDAR_SUCCESS:
      return {
        ...state,
        isFetching: true,
        eCalendars: state.eCalendars.filter(
          (eCalendar) => eCalendar._id !== action.payload
        ),
      };
    case CLI_DELETE_ECALENDAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CLI_FETCH_ORDER_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CLI_FETCH_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        error: null,
      };
    case CLI_FETCH_ORDER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
