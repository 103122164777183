import {
  FETCH_PTODOS_REQUEST,
  FETCH_PTODOS_SUCCESS,
  FETCH_PTODOS_FAILURE,
  ADD_PTODO_REQUEST,
  ADD_PTODO_SUCCESS,
  ADD_PTODO_FAILURE,
  UPDATE_PTODO_REQUEST,
  UPDATE_PTODO_SUCCESS,
  UPDATE_PTODO_FAILURE,
  DELETE_PTODO_REQUEST,
  DELETE_PTODO_SUCCESS,
  DELETE_PTODO_FAILURE,
} from "../actionTypes";

import { todoService } from "../services/todoService";

// Action Creator pour récupérer la liste des critères de qualité
export const fetchTodos = (productId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PTODOS_REQUEST });
    try {
      const todos = await todoService.getProductTodos(productId);
      dispatch({
        type: FETCH_PTODOS_SUCCESS,
        payload: todos,
      });
    } catch (err) {
      dispatch({
        type: FETCH_PTODOS_FAILURE,
        payload: err.message,
      });
    }
  };
};

// Action Creator pour ajouter un critère de qualité à un produit
export const addTodo = (productId, todoData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_PTODO_REQUEST });
    try {
      const newTodo = await todoService.createTodo(productId, todoData);
      dispatch({ type: ADD_PTODO_SUCCESS, payload: newTodo });
    } catch (err) {
      dispatch({ type: ADD_PTODO_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour modifier un critère de qualité d'un produit
export const updateTodo = (productId, todoId, todoData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_PTODO_REQUEST });
    try {
      const updatedTodo = await todoService.updateTodo(productId, todoId, todoData);
      dispatch({
        type: UPDATE_PTODO_SUCCESS,
        payload: updatedTodo,
      });
    } catch (err) {
      dispatch({ type: UPDATE_PTODO_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour supprimer un critère de qualité d'un produit
export const deleteTodo = (productId, todoId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_PTODO_REQUEST });
    try {
      await todoService.deleteTodo(productId, todoId);
      dispatch({
        type: DELETE_PTODO_SUCCESS,
        payload: { productId, todoId },
      });
    } catch (err) {
      dispatch({ type: DELETE_PTODO_FAILURE, payload: err.message });
    }
  };
};
