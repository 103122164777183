import { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderById,
  updateQualityCriterionChecks,
  fetchClientOrders,
  genrateQualityCriterions,
  deleteOrderQualityCheck,
  addQualityCriterionChecks,
} from "../../../../../redux/actions/clientActions";
import { motion, AnimatePresence } from "framer-motion";
import { Row, Col, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../../../NavContext";
import OrderNavigation from "../order/orderNavigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faMinus, faPlus, faRightLong } from "@fortawesome/free-solid-svg-icons";
import { Bars } from "react-loader-spinner";

const QualityChecksForm = () => {
  const dispatch = useDispatch();
  const { clientId, orderId } = useParams();
  const { activeNav, setActiveNav } = useContext(NavContext);
  const [showForm, setShowForm] = useState(false);

  const order = useSelector((state) => state.clientReducer.order);
  const client = useSelector((state) => state.clientReducer.client);
  const generating_checks = useSelector((state) => state.clientReducer.generating_checks);
  const currentUser = useSelector((state) => state.authReducer.user.user);

  // const PROD_MANAGER =
  //   currentUser.role === "manager" && currentUser.departement === "Team1";
  // const PROD_RESPONSIBLE =
  //   currentUser.departement === "Team1" && currentUser._id === order?.team1Responsible;
  const can_delete = currentUser.role !== "Team4";

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: registerQuality,
    handleSubmit: handleSubmitQuality,
    reset: resetQuality,
    formState: { errors: errorsQuality },
  } = useForm();

  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);
  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientOrders(clientId));
    }
  }, [dispatch, clientId]);
  useEffect(() => {
    dispatch(fetchOrderById(clientId, orderId));
  }, [dispatch, clientId, orderId]);

  useEffect(() => {
    if (order) {
      order.quality_criterion_checks.forEach((check) => {
        setValue(`quality_criterion_checks.${check._id}.status`, check.status);
      });
    }
  }, [order, setValue]);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const onSubmit = async (data) => {
    dispatch(updateQualityCriterionChecks(clientId, orderId, data));
  };

  const onSubmitQuality = async (data) => {
    dispatch(addQualityCriterionChecks(clientId, orderId, data));
    resetQuality();
  };

  const handleGenrateQualityCriterions = () => {
    dispatch(genrateQualityCriterions(clientId, orderId));
  };

  const handleDeleteOrderQualityCheck = (qualityId) => {
    dispatch(deleteOrderQualityCheck(clientId, orderId, qualityId));
  };

  return (
    <>
      {order && (
        <>
          {generating_checks && (
            <div className="ia-loader">
              <Bars
                height={100}
                width={100}
                radius={5}
                color="#cb0c9f"
                ariaLabel="bars-loading"
                wrapperClass={"triangle-loader"}
                wrapperStyle=""
                visible={true}
              />
            </div>
          )}
          <div className="pb-4">
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  <Link to="/clients">clients</Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/clients/${client?._id}/orders`}>{client?.companyName}</Link>
                </li>
                {order && (
                  <li
                    className="breadcrumb-item text-sm text-dark flex p-0"
                    aria-current="page"
                  >
                    <Dropdown className="dropdown-orders">
                      <Dropdown.Toggle id="dropdown-basic">
                        {order.product?.name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {client?.orders?.map((cl_order) => (
                          <Dropdown.Item
                            as={Link}
                            to={`/clients/${clientId}/orders/${cl_order._id}/quality_checks`}
                            key={cl_order._id}
                          >
                            {cl_order.product?.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                )}
                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  Suivi de qualité
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-container">
            {order && (
              <OrderNavigation
                order={order}
                client={client}
                currentTab="quality_checks"
              />
            )}

            <div className="text-center mx-5 mb-2">
              <button onClick={toggleForm} className="btn btn-primary w-50">
                <FontAwesomeIcon icon={showForm ? faMinus : faPlus} />
              </button>
            </div>

            <AnimatePresence>
              {showForm && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.5 }}
                >
                  <form onSubmit={handleSubmitQuality(onSubmitQuality)}>
                    <div className="card mb-4 purple-border">
                      <div className="card-body">
                        <Row className="xmb-5">
                          <Col md={12}>
                            <div className="mb-3">
                              <label htmlFor="duration" className="form-label">
                                Ajouter un critére de qualité *
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                id="text"
                                {...registerQuality("name", { required: true })}
                              />
                              {errorsQuality?.name && (
                                <div className="text-danger">Champs obligatoire</div>
                              )}
                            </div>
                          </Col>
                          <div>
                            <button
                              type="submit"
                              className="btn bg-gradient-dark mt-1 mb-0 px-5"
                            >
                              Ajouter
                            </button>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </form>
                </motion.div>
              )}
            </AnimatePresence>

            <form onSubmit={handleSubmit(onSubmit)}>
              <section>
                <Row>
                  <Col md={12}>
                    <div className="card medium-card mb-4 pb-2 purple-border">
                      <div className="card-header text-left">
                        <Col md={6}>
                          {order && (
                            <h6>
                              <span className="product-title px-3 text-white">
                                Vérifications des critères de qualité :{" "}
                                {order?.product?.name}{" "}
                              </span>
                            </h6>
                          )}
                        </Col>
                      </div>

                      <div className="card-body">
                        {order?.quality_criterion_checks?.length > 0 ? (
                          <>
                            {order?.quality_criterion_checks?.map((check) => (
                              <div
                                key={check.qualityCriterion?._id}
                                className="mb-3 form-check"
                              >
                                <div className="flex justify-content-between">
                                  <div>
                                    <input
                                      type="checkbox"
                                      id={`status-${check._id}`}
                                      className="form-check-input"
                                      {...register(
                                        `quality_criterion_checks.${check._id}.status`
                                      )}
                                    />
                                    <label
                                      htmlFor={`status-${check._id}`}
                                      className="form-check-label"
                                    >
                                      {check.qualityCriterion.for_an_update && (
                                        <FontAwesomeIcon
                                          icon={faRightLong}
                                          className="mx-2"
                                        />
                                      )}
                                      <span>{check.qualityCriterion?.name}</span>
                                    </label>
                                  </div>
                                  <div>
                                    {can_delete && (
                                      <span
                                        className="delete-link primary-color"
                                        onClick={() =>
                                          handleDeleteOrderQualityCheck(check._id)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </span>
                                    )}
                                  </div>
                                </div>

                                {errors.quality_criterion_checks?.[check._id]?.status && (
                                  <div className="text-danger">Vérification requise</div>
                                )}
                              </div>
                            ))}
                            <div className="">
                              <button
                                type="submit"
                                className="btn bg-gradient-dark w-30 mt-4 mb-0"
                              >
                                Enregistrer
                              </button>
                            </div>
                          </>
                        ) : (
                          <div>
                            <p>
                              Aucune liste de suivi de qualité n'a été créé pour cette
                              commande lors de la création
                            </p>
                            {order.product.qualityCriterions.length > 0 && (
                              <span
                                className="prodotop-link"
                                onClick={handleGenrateQualityCriterions}
                                role="button"
                              >
                                Génerer la liste de suivi de qualité{" "}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Link to={`/clients/${clientId}/orders`} className="link-action back">
                      Retour
                    </Link>
                  </Col>
                </Row>
              </section>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default QualityChecksForm;
