import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCalendarMinus,
  faCopy,
  faDeleteLeft,
  faPlay,
  faStop,
  faScissors,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import MeetForm from "./MeetForm";

const TaskForm = ({
  show,
  handleClose,
  event,
  onDeleteTodo,
  onUndragTodo,
  onDoneTodo,
  onDuplicateTodo,
  onStartTodo,
  onEndTodo,
  onChangeTask,
  onShareTodo,
  onCutTodo,
  employees,
}) => {
  const isBetween = moment().isBetween(event.start, event.end, undefined, "[]");

  const [task, setTask] = useState(event.task);
  const [flexibility, setFlexibility] = useState();
  const [showTaskInfo, setShowTaskInfo] = useState("actions");

  useEffect(() => {
    setTask(event.task);
    setFlexibility(event.flexibility);
    setShowTaskInfo("actions");
  }, [event]);

  const FLEXIBILITY = [
    { FLZR: "Flexibilité Zéro" },
    { FLDY: "Flexibilité Journalière" },
    { FLHB: "Flexibilité Hebdomadaire" },
  ];

  return (
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton className="pb-0">
        <Offcanvas.Title>
          <h6 className="mb-0">{event.client.companyName}</h6>
          <small>{event?.product?.name}</small>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="flex flex-column gap-3">
          {showTaskInfo === "update" && (
            <div className="">
              <div>
                <textarea
                  name="task"
                  className="form-control filter-client mb-2"
                  defaultValue={task}
                  onChange={(e) => setTask(e.target.value)}
                />

                <select
                  className="form-control mb-2"
                  id="role"
                  value={flexibility}
                  onChange={(e) => setFlexibility(e.target.value)}
                >
                  {FLEXIBILITY.map((issue, index) => {
                    const key = Object.keys(issue)[0];
                    const value = Object.values(issue)[0];
                    return (
                      <option key={key} value={key} className={key}>
                        {value}
                      </option>
                    );
                  })}
                </select>

                <button
                  className="btn text-white bg-primary mt-1 mb-0 px-5"
                  onClick={() => onChangeTask(event, task, flexibility)}
                  disabled={!task}
                >
                  Modifier
                </button>
              </div>
            </div>
          )}
          {showTaskInfo === "meet" && (
            <div>
              <MeetForm
                todoId={event._id}
                clientId={event.client._id}
                employees={employees}
              />
            </div>
          )}

          {showTaskInfo === "actions" && (
            <>
              <div className="flex flex-wrap gap-2 justify-content-between mb-3 ">
                <div className="mb-5">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input theme-color"
                      type="checkbox"
                      id="team1Accepted"
                      checked={event.shared}
                      onChange={() => onShareTodo(event)}
                    />
                    <label className="form-check-label" htmlFor="team1Accepted">
                      Partager avec le client ?
                    </label>
                  </div>
                </div>

                <div className="event-action">
                  <button
                    onClick={() => onDoneTodo(event)}
                    className="btn btn-color btn-color-color-action done-todo flex flex-row align-items-center gap-5 px-5"
                  >
                    <FontAwesomeIcon icon={faCalendarCheck} size="2x" />
                    <span>
                      {event.done ? "Marquer comme pas fait" : "Marquer comme fait"}
                    </span>
                  </button>
                </div>
                <div className="event-action">
                  <button
                    onClick={() => onUndragTodo(event)}
                    className="btn btn-color btn-color-color-action undrag-todo flex flex-row align-items-center gap-5 px-5"
                  >
                    <FontAwesomeIcon icon={faCalendarMinus} size="2x" />
                    <span>Retirer du calendrier</span>
                  </button>
                </div>
                <div className="event-action">
                  <button
                    onClick={() => onDuplicateTodo(event._id)}
                    className="btn btn-color btn-color-color-action flex flex-row align-items-center gap-5 px-5"
                  >
                    <FontAwesomeIcon icon={faCopy} size="2x" />
                    <span>Dupliquer</span>
                  </button>
                </div>
                <div className="event-action">
                  <button
                    onClick={() => onDeleteTodo(event._id)}
                    className="btn btn-color btn-color-color-action delete-todo flex flex-row align-items-center gap-5 px-5"
                  >
                    <FontAwesomeIcon icon={faDeleteLeft} size="2x" />
                    <span>Supprimer</span>
                  </button>
                </div>
              </div>

              <div>
                <div className="event-action flex gap-3">
                  <button
                    onClick={() => onStartTodo(event)}
                    className="btn btn-start flex flex-column align-items-center justify-content-center gap-2"
                  >
                    <FontAwesomeIcon icon={faPlay} size="2x" />
                    Start
                  </button>
                  {isBetween && (
                    <>
                      <button
                        onClick={() => onCutTodo(event)}
                        className="btn btn-cut flex flex-column align-items-center justify-content-center gap-2"
                      >
                        <FontAwesomeIcon icon={faScissors} size="2x" />
                        Couper
                      </button>
                      <button
                        onClick={() => onEndTodo(event)}
                        className="btn btn-stop flex flex-column align-items-center justify-content-center gap-2"
                      >
                        <FontAwesomeIcon icon={faStop} size="2x" />
                        Stop
                      </button>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="bottom pb-2 w-90 flex gap-2">
          <div className="w-75">
            {showTaskInfo !== "actions" && (
              <button
                className="btn text-white bg-dark mt-1 mb-0 px-5 "
                onClick={() => setShowTaskInfo("actions")}
              >
                Gérer la tâche
              </button>
            )}
            {showTaskInfo === "actions" && (
              <button
                className="btn text-white bg-dark mt-1 mb-0 px-5"
                onClick={() => setShowTaskInfo("update")}
              >
                Modifier la tâche
              </button>
            )}
          </div>
          <div className="w-15 ">
            {console.log()}
            <button
              className={`btn  mt-1 mb-0 px-5 ${
                event.meet ? "text-white bg-dark" : "btn-outline-primary"
              }`}
              onClick={() => setShowTaskInfo("meet")}
            >
              <FontAwesomeIcon icon={faVideo} />
            </button>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TaskForm;
