import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { fetchOrderById } from "../../../../redux/actions/cli/orderActions";
import {
  fetchCliClientOrders,
  updateCliTicket,
  fetchCliTicket,
} from "../../../../redux/actions/cli/clientsActions";

import { Link } from "react-router-dom";
import ExpandedContent from "./ExpandedContent";
import parse from "html-react-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import OrderNavigation from "../orderNavigation";

const EditTicketForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const { clientId, orderId, ticketId } = useParams();

  const currentUser = useSelector((state) => state.authReducer.user.user);

  const order = useSelector((state) => state.cliClientsReducer.order);
  const client = useSelector((state) => state.cliClientsReducer.client);
  const ticket = useSelector((state) => state.cliClientsReducer.ticket);

  const [layoutId, setLayoutId] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliClientOrders(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    document.title =
      "ProdOTop - demande :" +
      ticket?.subject +
      " de " +
      order?.product?.name +
      " du client " +
      client?.companyName;
  }, [client, order, ticket]);

  useEffect(() => {
    if (clientId && orderId) {
      dispatch(fetchOrderById(orderId));
    }
  }, [dispatch, clientId, orderId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchCliTicket(clientId, orderId, ticketId));
    }
  }, [dispatch, clientId, orderId, ticketId]);

  const onSubmit = (data) => {
    if (ticket) {
      dispatch(updateCliTicket(clientId, orderId, ticket._id, data));
    }
    reset({
      "ticket.new_discussion.question": "",
    });
    //navigate(`/clients/${clientId}/orders/${orderId}/tickets`);
  };

  const ISSUES = {
    REQAM: "Demande d’amélioration",
    INFINC: "Information incompléte",
    PAYPRO: "Probléme de paiement",
    CONMAN: "Contenu manquant",
    PROEXT: "Problème externe",
    RDV: "Demande de rendez-vous",
    DVR: "Divers",
  };

  return (
    <>
      {order && (
        <>
          <div className="pb-4">
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/client/${client?._id}/orders`}>{client?.companyName}</Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  {order?.product.name}
                </li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/client/${clientId}/orders/${orderId}/tickets`}>
                    Demandes
                  </Link>
                </li>
                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  {ticket?.subject}
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-container">
            {order && (
              <OrderNavigation order={order} client={client} currentTab="tickets" />
            )}
            <Row className="row">
              <Col md={12}>
                <div className="card medium-card mb-4 pb-2 purple-border">
                  <div className="card-header  mb-3">
                    <div className=" flex gap-2">
                      {ticket && (
                        <h6 className="mb-0 text-dark"> sujet : {ticket.subject}</h6>
                      )}
                    </div>
                    <small className="text-dark">{ISSUES[ticket.issue]}</small>
                  </div>
                  <div className="card-body px-4 pt-0 py-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {ticket &&
                        ticket.ticket_discussions.map((td, index) => {
                          return (
                            <Row className="xmb-5" key={td._id}>
                              <Col xs={12} className="mb-4 pull-rigth">
                                <div
                                  className={`border-left-3  p-4 bg-gray-100 w-50  h-220 bg-gray-100 answer-response ${
                                    td.askedBy?._id === currentUser._id
                                      ? "border-primary"
                                      : "ms-10 border-info"
                                  }`}
                                  role="button"
                                  onClick={() => {
                                    setData(td.question);
                                    setLayoutId(td._id);
                                  }}
                                >
                                  <div className="mb-2 flex justify-content-between">
                                    <h6>
                                      <span
                                        className={`text-sm text-${
                                          td.askedBy?._id === currentUser._id
                                            ? "primary"
                                            : "info"
                                        } text-gradient`}
                                      >
                                        {td.askedBy?.name}
                                      </span>
                                    </h6>
                                    <span className="text-xs">
                                      {moment(td.createdAt).format("YYYY-MM-DD HH:mm")}
                                    </span>
                                  </div>

                                  <div className="mb-2 text-xs">{parse(td.question)}</div>
                                </div>
                              </Col>
                            </Row>
                          );
                        })}

                      <Row className="my-3">
                        <Col>
                          <div>
                            <label htmlFor="subject" className="form-label"></label>
                            <Controller
                              name={`ticket.new_discussion.question`}
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={field.value}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    field.onChange(data);
                                  }}
                                  placeholder="Nouveau message ..."
                                />
                              )}
                            />
                          </div>
                          {errors?.ticket?.new_discussion?.question &&
                            errors?.ticket?.new_discussion?.question.message}
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>
                          <div className="">
                            <button
                              type="submit"
                              className="btn bg-gradient-dark w-30 mt-2 mb-0"
                            >
                              Enregistrer
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              </Col>
              <Col>
                <Link
                  to={`/client/${clientId}/orders/${orderId}/tickets`}
                  className="link-action back"
                >
                  Retour
                </Link>
              </Col>
            </Row>
            {layoutId && (
              <ExpandedContent
                key={layoutId}
                layoutId={layoutId}
                data={data}
                setData={setData}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default EditTicketForm;
