import React, { useContext, useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavContext } from "./NavContext";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { fetchNotifications } from "../redux/actions/notificationActions";
import { logout } from "../redux/actions/authActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faFileContract,
  faDashboard,
  faUsers,
  faBarcode,
  faCalendar,
  faWarehouse,
  faFolderOpen,
  faBahai,
  faRankingStar,
  faArrowRightToBracket,
  faMarker,
  faEnvelopeCircleCheck,
  faBrush,
  faStar,
  faBook,
  faMedal,
  faChartLine,
  faBuildingUser,
  faPenToSquare,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { BlockPicker } from "react-color";
import { loadAgencyInfo } from "../redux/actions/agencyActions";

function Layout({}) {
  const dispatch = useDispatch();
  const { activeNav } = useContext(NavContext);
  const [open, setOpen] = useState(false);
  const notifications = useSelector((state) => state.notificationReducer.notifications);
  const agency = useSelector((state) => state.agencyReducer.agency);
  // const isFetching = useSelector((state) => state.notificationReducer.isFetching);

  useEffect(() => {
    dispatch(loadAgencyInfo());
  }, []);

  const hasUnreadNotifications = notifications?.some(
    (notification) => !notification.readen
  );

  return (
    <>
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="layout-container">
        <Sidebar
          setOpen={setOpen}
          activeNav={activeNav}
          hasUnreadNotifications={hasUnreadNotifications}
          agency={agency}
          logout={() => dispatch(logout())}
        />
        <main className="mt-2 main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <div className="container-fluid">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
}

const Sidebar = ({ setOpen, activeNav, hasUnreadNotifications, logout, agency }) => {
  const [isNotActive, setNotActive] = useState("true");
  const [showPicker, setShowPicker] = useState();

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "purple");
  const colorToTheme = {
    "#afaae3": "purple",
    "#ffe845": "yellow",
    "#78e08f": "green",
    "#a8c1ec": "blue",
    "#9e522f": "brown",
  };

  const popover = {
    position: "fixed",
    zIndex: "2",
    marginTop: "10px",
    marginLeft: "80px",
    bottom: "20px",
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const handleChangeComplete = (color) => {
    const selectedTheme = colorToTheme[color.hex];
    setTheme(selectedTheme);
    localStorage.setItem("theme", selectedTheme);
    setShowPicker(!showPicker);
  };

  return (
    <div className="left-sidebar">
      <Link to="/">
        {agency.logo ? (
          <img src={agency.logo} alt="agency" className="prodotop-logo" />
        ) : (
          <div className="prodotop-logo logo"></div>
        )}
      </Link>

      <nav id="sidebar" className={isNotActive ? "active" : ""}>
        <button
          onClick={() => setNotActive(!isNotActive)}
          className="navbar-toggler shadow-none ms-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navigation"
          aria-controls="navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon mt-2">
            <span className="navbar-toggler-bar bar1"></span>
            <span className="navbar-toggler-bar bar2"></span>
            <span className="navbar-toggler-bar bar3"></span>
          </span>
        </button>

        <ul className="list-unstyled components">
          <li
            className={`list-item ${activeNav === "index" ? "active" : ""} ${
              [
                "dashboard",
                "calendar",
                "clients",
                "reports",
                "community",
                "meet",
              ].includes(activeNav)
                ? "visited"
                : ""
            } `}
          >
            <Link onClick={() => setOpen(false)} aria-current="page" to="/">
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="button-tooltip-2">Dashboard</Tooltip>}
              >
                <FontAwesomeIcon icon={faWarehouse} className="" />
              </OverlayTrigger>
              <span className="mx-3">Dashboard</span>
            </Link>
          </li>

          {activeNav === "dashboard" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} aria-current="page" to="/">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">suivi</Tooltip>}
                >
                  <FontAwesomeIcon icon={faDashboard} className="" />
                </OverlayTrigger>
                <span className="mx-3">suivi</span>
              </Link>
            </li>
          )}

          {activeNav === "calendar" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="calendar">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Calendrier</Tooltip>}
                >
                  <FontAwesomeIcon icon={faCalendar} className="" />
                </OverlayTrigger>
                <span className="mx-3">Calendrier</span>
              </Link>
            </li>
          )}
          {activeNav === "meet" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="calendar">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Réunion</Tooltip>}
                >
                  <FontAwesomeIcon icon={faVideo} className="" />
                </OverlayTrigger>
                <span className="mx-3">Réunion</span>
              </Link>
            </li>
          )}

          {activeNav === "clients" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="clients">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Clients</Tooltip>}
                >
                  <FontAwesomeIcon icon={faUsers} className="" />
                </OverlayTrigger>
                <span className="mx-3">Clients</span>
              </Link>
            </li>
          )}

          {activeNav === "reports" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="Ads-reports">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Rapports</Tooltip>}
                >
                  <FontAwesomeIcon icon={faChartLine} className="" />
                </OverlayTrigger>
                <span className="mx-3">Rapports</span>
              </Link>
            </li>
          )}

          {activeNav === "community" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="community">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Community</Tooltip>}
                >
                  <FontAwesomeIcon icon={faMarker} className="" />
                </OverlayTrigger>
                <span className="mx-3">Community</span>
              </Link>
            </li>
          )}
          <hr />

          <li
            className={`list-item ${activeNav === "agency" ? "active" : ""} ${
              [
                "client-dashboard",
                "users",
                "client-access",
                "departments",
                "agency-info",
              ].includes(activeNav)
                ? "visited"
                : ""
            }`}
          >
            <Link onClick={() => setOpen(false)} to="agency">
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="button-tooltip-2">Agence</Tooltip>}
              >
                <FontAwesomeIcon icon={faFolderOpen} className="" />
              </OverlayTrigger>
              <span className="mx-3">Agence</span>
            </Link>
          </li>

          {activeNav === "agency-info" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} aria-current="page" to="/agency/info">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Info</Tooltip>}
                >
                  <FontAwesomeIcon icon={faPenToSquare} className="" />
                </OverlayTrigger>
                <span className="mx-3">Info</span>
              </Link>
            </li>
          )}
          {activeNav === "client-dashboard" && (
            <li className={`list-item active`}>
              <Link
                onClick={() => setOpen(false)}
                aria-current="page"
                to="/clients/dashboard"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">rentabilité</Tooltip>}
                >
                  <FontAwesomeIcon icon={faRankingStar} className="" />
                </OverlayTrigger>
                <span className="mx-3">rentabilité</span>
              </Link>
            </li>
          )}
          {activeNav === "departments" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="users">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Departements</Tooltip>}
                >
                  <FontAwesomeIcon icon={faBuildingUser} className="" />
                </OverlayTrigger>
                <span className="mx-3">Departements</span>
              </Link>
            </li>
          )}
          {activeNav === "users" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="users">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Employées</Tooltip>}
                >
                  <FontAwesomeIcon icon={faUser} className="" />
                </OverlayTrigger>
                <span className="mx-3">Employées</span>
              </Link>
            </li>
          )}

          {activeNav === "client-access" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="users">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Accées clients</Tooltip>}
                >
                  <FontAwesomeIcon icon={faUsers} className="" />
                </OverlayTrigger>
                <span className="mx-3">Accées clients</span>
              </Link>
            </li>
          )}

          <hr />

          <li
            className={`list-item ${activeNav === "configuration" ? "active" : ""} ${
              [
                "products",
                "emails",
                "briefQuestions",
                "qualityChecks",
                "surveyQuestions",
              ].includes(activeNav)
                ? "visited"
                : ""
            }`}
          >
            <Link onClick={() => setOpen(false)} to="configuration">
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="button-tooltip-2">Configuration</Tooltip>}
              >
                <FontAwesomeIcon icon={faBahai} className="" />
              </OverlayTrigger>
              <span className="mx-3">Configuration</span>
            </Link>
          </li>

          {activeNav === "products" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="products">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Produits</Tooltip>}
                >
                  <FontAwesomeIcon icon={faBarcode} className="" />
                </OverlayTrigger>
                <span className="mx-3">Produits</span>
              </Link>
            </li>
          )}

          {activeNav === "emails" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="emails">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">E-mails</Tooltip>}
                >
                  <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="" />
                </OverlayTrigger>
                <span className="mx-3">E-mails</span>
              </Link>
            </li>
          )}

          {activeNav === "briefQuestions" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="brief-questions">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Cahier des charges</Tooltip>}
                >
                  <FontAwesomeIcon icon={faFileContract} className="" />
                </OverlayTrigger>
                <span className="mx-3">Cahier des charges</span>
              </Link>
            </li>
          )}
          {activeNav === "qualityChecks" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="quality-checks">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="button-tooltip-2">Suivi de qualité</Tooltip>}
                >
                  <FontAwesomeIcon icon={faMedal} className="" />
                </OverlayTrigger>
                <span className="mx-3">Suivi de qualité</span>
              </Link>
            </li>
          )}
          {activeNav === "surveyQuestions" && (
            <li className={`list-item active`}>
              <Link onClick={() => setOpen(false)} to="survey-questions">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="button-tooltip-2">Enquêtes de satisfactions</Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faStar} className="" />
                </OverlayTrigger>
                <span className="mx-3">Enquêtes de satisfaction</span>
              </Link>
            </li>
          )}

          <hr />

          <li className={`mb-5 list-item ${activeNav === "drafts" ? "active" : ""}`}>
            <Link onClick={() => setOpen(false)} aria-current="page" to="drafts">
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="button-tooltip-2">Notebook</Tooltip>}
              >
                <FontAwesomeIcon icon={faBook} className="" />
              </OverlayTrigger>
              <span className="mx-3">Notebook</span>
            </Link>
          </li>

          <li className="list-item">
            <div
              className="btn-outline-primary me-2 ms-2"
              target="_blank"
              onClick={logout}
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="button-tooltip-2">Se déconnecter</Tooltip>}
              >
                <FontAwesomeIcon icon={faArrowRightToBracket} className="" />
              </OverlayTrigger>
            </div>
          </li>
        </ul>
      </nav>

      {showPicker ? (
        <div style={popover}>
          <BlockPicker
            colors={Object.keys(colorToTheme)} // Utiliser les clés de l'objet pour les couleurs
            color={Object.keys(colorToTheme).find((key) => colorToTheme[key] === theme)}
            onChange={handleChangeComplete}
            triangle="hide"
          />
        </div>
      ) : null}

      <span
        onClick={() => setShowPicker(!showPicker)}
        className="ms-4 prodotop-link brush"
        // style={{ background: color, borderColor: color }}
      >
        <FontAwesomeIcon icon={faBrush} />
      </span>

      {/* <li className="list-item">
            <Link
              onClick={() => setOpen(false)}
              className={`${activeNav === "notification" ? "notification-active" : ""} ${
                hasUnreadNotifications ? "has-notification" : ""
              }`}
              aria-current="page"
              to="/notification"
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="button-tooltip-2">Notifications</Tooltip>}
              >
                <FontAwesomeIcon icon={faBell} className="" />
              </OverlayTrigger>
              <span className="mx-3">Notifications</span>
            </Link>
          </li> */}
    </div>
  );
};

export default Layout;
