import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  loadDepartmentLabels,
  updateDepartments,
} from "../../../redux/actions/departmentActions";
import { NavContext } from "../../NavContext";

const DepartmentsPage = () => {
  const dispatch = useDispatch();

  const departments = useSelector((state) => state.departmentReducer.departments);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    setActiveNav("departments");
  }, [setActiveNav]);

  useEffect(() => {
    dispatch(loadDepartmentLabels());
  }, []);

  useEffect(() => {
    departments.forEach((department, index) => {
      setValue(`departments[${index}]._id`, department._id);
      setValue(`departments[${index}].label`, department.label);
    });
  }, [departments, setValue]);

  // Fonction appelée lors de la soumission du formulaire
  const onSubmit = (data) => {
    // Dispatch de l'action pour mettre à jour les départements
    dispatch(updateDepartments(data));
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              {"Departements"}
            </li>
          </ol>
        </nav>
      </div>
      <Row>
        <Col className="d-flex flex-column mx-auto">
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header text-left">
              <h6>
                <span className="product-title px-3 text-white">Départements</span>
              </h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                {departments &&
                  departments.map((department, index) => (
                    <div key={department._id} className="department-entry mb-2">
                      <label className="form-label">{department.default_label}</label>
                      <input
                        type="hidden"
                        {...register(`departments[${index}]._id`)}
                        className="form-control"
                      />
                      <input
                        type="text"
                        {...register(`departments[${index}].label`)}
                        className="form-control"
                      />
                    </div>
                  ))}
                <div className="">
                  <button type="submit" className="btn bg-gradient-dark w-30 mt-4 mb-0">
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DepartmentsPage;
