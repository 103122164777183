import { useEffect, useContext } from "react";
import { PanelContext } from "../../panelContext";
import { Row, Col } from "react-bootstrap";
import { fetchClientEntities } from "../../../redux/actions/cli/clientsActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCircle } from "@fortawesome/free-solid-svg-icons";

const ClientsPage = () => {
  const dispatch = useDispatch();

  const clients = useSelector((state) => state.cliClientsReducer.clients);
  const { panelMessage, setPanelMessage } = useContext(PanelContext);

  useEffect(() => {
    document.title = "ProdOTop - Clients";
  }, []);

  useEffect(() => {
    dispatch(fetchClientEntities());
  }, [dispatch]);

  return (
    <>
      <div className="pb-3">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Liste des entités
            </li>
          </ol>
        </nav>
      </div>
      <Row>
        <Col md={12} className={`flex flex-wrap gap-4 justify-content-center`}>
          {clients.map((client) => (
            <div key={client._id}>
              <div className="card purple-border card-order client justify-content-center">
                <div className="card-body p-3">
                  <div className="text-center">
                    <div className="py-4">
                      <h6 className="companyName-title mb-4">
                        <span className="product-title text-dark ">
                          {client.companyName}
                          {client.client_with_open_tickets && (
                            <FontAwesomeIcon
                              icon={faCircle}
                              className="px-2 pt-1"
                              color="#FF0000"
                            />
                          )}
                        </span>
                      </h6>
                      <Link
                        to={`/client/${client._id}/orders`}
                        className="left px-2 py-1 bg-ligth-primary rounded-3"
                      >
                        <span className="span-btn btn-color info-color">
                          Accédez à cet espace
                          <FontAwesomeIcon icon={faArrowRight} className="px-2 pt-1" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default ClientsPage;
