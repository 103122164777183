import {
  FETCH_CALENDAR_REQUEST,
  FETCH_CALENDAR_SUCCESS,
  FETCH_CALENDAR_FAILURE,
  UPDATE_CALENDAR_REQUEST,
  UPDATE_CALENDAR_SUCCESS,
  UPDATE_CALENDAR_FAILURE,
  FETCH_CALENDAR_TODOS_SUCCESS,
  DEVIDE_TODOS_SUCCESS,
  ADD_TODO_SUCCESS,
  DELETE_CALENDARTODO_SUCCESS,
  UNDRAG_CALENDARTODO_SUCCESS,
} from "../actionTypes";

//replace calendarInfo nu fragged_tasks
const initialState = {
  calendarInfo: {},
  undragged_tasks: [],
  isFetching: false,
  loading: false,
  error: null,
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_DRAGGED_EVENTS":
      const new_dragged_tasks = state.calendarInfo.dragged_tasks.concat(action.payload);
      return {
        ...state,
        calendarInfo: { ...state.calendarInfo, dragged_tasks: new_dragged_tasks },
      };
    case "ADD_TO_UNDRAGGED_EVENTS":
      return {
        ...state,
        undragged_tasks: state.undragged_tasks.concat(action.payload),
      };
    case "UPDATE_DRAGGED_EVENT":
      return {
        ...state,
        calendarInfo: {
          ...state.calendarInfo,
          dragged_tasks: state.calendarInfo.dragged_tasks.map((task) =>
            task._id === action.payload.eventId
              ? { ...task, ...action.payload.updatedData }
              : task
          ),
        },
      };
    case "REMOVE_FROM_UNDRAGGED_EVENTS":
      const undraggedTasks = state.undragged_tasks.filter(
        (event) => event._id !== action.payload
      );
      return {
        ...state,
        undragged_tasks: undraggedTasks,
      };
    case FETCH_CALENDAR_REQUEST:
      return {
        ...state,
        isFetching: true,
        loading: true,
        error: null,
      };
    case FETCH_CALENDAR_SUCCESS:
      return {
        ...state,
        calendarInfo: action.payload,
        isFetching: false,
        loading: false,
        error: null,
      };
    case FETCH_CALENDAR_TODOS_SUCCESS:
      return {
        ...state,
        undragged_tasks: action.payload.undragged_tasks,
        isFetching: false,
        error: null,
      };
    case DEVIDE_TODOS_SUCCESS:
      const { todo, newTodo } = action.payload;

      const updatedUndragged_tasks = state.undragged_tasks.map((item) => {
        if (item._id === todo._id) {
          return {
            ...item,
            minutes: todo.minutes,
          };
        } else {
          return item;
        }
      });

      const updatedTodos = [...updatedUndragged_tasks, newTodo];
      return {
        ...state,
        undragged_tasks: updatedTodos,
        isFetching: false,
        error: null,
      };
    case ADD_TODO_SUCCESS:
      return action.payload.dragged
        ? {
            ...state,
            calendarInfo: {
              ...state.calendarInfo,
              dragged_tasks: state.calendarInfo.dragged_tasks.concat(action.payload),
            },
          }
        : {
            ...state,
            undragged_tasks: [...state.undragged_tasks, action.payload],
          };
    case DELETE_CALENDARTODO_SUCCESS:
      const filtred_tasks = state.calendarInfo.dragged_tasks.filter(
        (event) => event._id !== action.payload
      );
      const undragged_tasks = state.undragged_tasks.filter(
        (event) => event._id !== action.payload
      );
      return {
        ...state,
        calendarInfo: { ...state.calendarInfo, dragged_tasks: filtred_tasks },
        undragged_tasks: undragged_tasks,
      };
    case UNDRAG_CALENDARTODO_SUCCESS:
      const calendar_tasks = state.calendarInfo.dragged_tasks.filter(
        (event) => event._id !== action.payload._id
      );
      return {
        ...state,
        calendarInfo: { ...state.calendarInfo, dragged_tasks: calendar_tasks },
        undragged_tasks: [...state.undragged_tasks, action.payload],
      };
    case FETCH_CALENDAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        loading: false,
        error: action.payload,
      };
    case UPDATE_CALENDAR_REQUEST:
    case UPDATE_CALENDAR_SUCCESS:
    case UPDATE_CALENDAR_FAILURE:
    default:
      return state;
  }
};

export default calendarReducer;
