import axios from "axios";

const API_URL = "/api/users";

const getAllUsers = () => {
  return axios.get(API_URL).then((response) => {
    return response.data;
  });
};

const getUserById = (id) => {
  return axios.get(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

const fetchsUserSurveyStat = (id) => {
  return axios.get(`${API_URL}/${id}/survey-stats`).then((response) => {
    return response.data;
  });
};
const fetchsUserEfficiencyStat = (id) => {
  return axios.get(`${API_URL}/${id}/efficiency-stats`).then((response) => {
    return response.data;
  });
};
const fetchsUserWorkloadStat = (id) => {
  return axios.get(`${API_URL}/${id}/workload-stats`).then((response) => {
    return response.data;
  });
};

const createUser = (data) => {
  return axios.post(API_URL, data).then((response) => {
    return response.data;
  });
};
const updateUserPassword = (id, data) => {
  return axios.put(`${API_URL}/update_pswd/${id}`, data).then((response) => {
    return response.data;
  });
};
const updateUser = (id, data) => {
  return axios.put(`${API_URL}/${id}`, data).then((response) => {
    return response.data;
  });
};

const deleteUser = (id) => {
  return axios.delete(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

export const userService = {
  getAllUsers,
  getUserById,
  fetchsUserSurveyStat,
  fetchsUserEfficiencyStat,
  fetchsUserWorkloadStat,
  createUser,
  updateUser,
  deleteUser,
  updateUserPassword,
};
