import axios from "axios";

const API_URL = "/api/drafts";

const getAllDrafts = () => {
  return axios.get(`${API_URL}`).then((response) => {
    return response.data;
  });
};
const createDraft = (data) => {
  return axios.post(`${API_URL}/`, data).then((response) => {
    return response.data;
  });
};
const updateDraft = (id) => {
  return axios.put(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};
const deleteDraft = (id) => {
  return axios.delete(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

const updateDraftsPositions = (data) => {
  return axios.post(`${API_URL}/update_positions`, data).then((response) => {
    return response.data;
  });
};

export const draftService = {
  getAllDrafts,
  createDraft,
  updateDraft,
  updateDraftsPositions,
  deleteDraft,
};
