import { useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// import FacebookLogin from "react-facebook-login";
import {
  addClient,
  updateClient,
  deleteClient,
  fetchClientById,
  connectGoogle,
  logoutGoogle,
  fetchGoogleMccs,
  fetchGoogleAccounts,
} from "../../../redux/actions/clientActions";
import Select from "react-select";
import { Bars } from "react-loader-spinner";
import { Row, Col, Dropdown } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../NavContext";
import ClientNavigation from "./clientNavigation";
const ClientForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { activeNav, setActiveNav } = useContext(NavContext);
  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const client = useSelector((state) => state.clientReducer.tiny_client);
  const google_mccs = useSelector((state) => state.clientReducer.google_mccs);
  const fetchingMccs = useSelector((state) => state.clientReducer.fetchingMccs);
  const google_accounts = useSelector((state) => state.clientReducer.google_accounts);
  const fetchingAccounts = useSelector((state) => state.clientReducer.fetchingAccounts);
  const currentUser = useSelector((state) => state.authReducer.user.user);

  const BOSS = currentUser.role === "boss";
  const MANAGER = currentUser.role === "manager";

  const with_issue = watch("with_issue");
  const mcc_id = watch("mcc_id");

  useEffect(() => {
    document.title = "ProdOTop - client - " + client?.companyName;
  }, [client]);

  // const responseFacebook = (response) => {
  //   console.log(response);
  //   // Envoyer response.accessToken à ton serveur
  // };

  useEffect(() => {
    if (id) {
      dispatch(fetchClientById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (mcc_id && client && client.google_oauth) {
      dispatch(fetchGoogleAccounts(id, { mcc_id: mcc_id }));
    }
  }, [dispatch, client, id, mcc_id]);

  useEffect(() => {
    if (client && client.google_oauth) {
      dispatch(fetchGoogleMccs(id));
    }
  }, [dispatch, client, id]);

  useEffect(() => {
    if (client) {
      reset({
        companyName: client.companyName,
        about: client.about,
        address: client.address,
        facebook_key: client.facebook_key,
        ga_key: client.ga_key,
        gads_key: client.gads_key,
        share_calendar: client.share_calendar,
        with_issue: client.with_issue,
        issue: client.issue,
        mcc_id: client.mcc_id,

        contact: {
          name: client.contact.name,
          phone: client.contact.phone,
          email: client.contact.email,
        },
      });

      setValue("mcc_id", client.mcc_id);
    }
  }, [dispatch, setValue, client, reset]);

  const onSubmit = (data) => {
    if (client) {
      dispatch(updateClient(client._id, data));
    } else {
      dispatch(addClient(data));
      reset();
      navigate("/clients");
    }
  };

  const handleDelete = (clientId) => {
    dispatch(deleteClient(clientId));
    navigate("/clients");
  };

  const auth = () => {
    dispatch(connectGoogle(client._id));
  };
  const logout = () => {
    dispatch(logoutGoogle(client._id));
  };

  return (
    <>
      <div className="pb-3">
        <Row>
          <Col md={6}>
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  <Link to="/clients">clients</Link>
                </li>
                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  {client ? "Modification du client" : "Nouveau client"}
                </li>
              </ol>
            </nav>
          </Col>
          <Col md={3} className="text-end">
            {/* <FacebookLogin
              appId={process.env.FB_APP_ID}
              autoLoad={true}
              fields="name,email,picture"
              callback={responseFacebook}
              icon="fa-facebook"
            /> */}
          </Col>
          <Col md={3}>
            <div style={{ marginTop: "13px" }} className="text-end">
              {(BOSS || MANAGER) && client && (
                <button
                  className="btn btn-link text-danger text-gradient px-1 mb-0 py-0"
                  type="button"
                  onClick={() => handleDelete(client._id)}
                >
                  Supprimé client
                </button>
              )}
              {!client?.google_oauth ? (
                <button
                  value="Se connecter avec Google"
                  className="btn bg-gradient-info mb-0"
                  onClick={() => auth()}
                >
                  Connect with google
                </button>
              ) : (
                <Dropdown className="mb-0">
                  <Dropdown.Toggle variant="info" id="dropdown-basic">
                    <img
                      src={client?.google_oauth?.picture}
                      alt="google"
                      style={{ width: "30px", height: "30px" }}
                      className="mx-1 rounded-1"
                    />
                    {client?.google_oauth?.name}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => auth()}>
                      Changer le compte
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => logout()}>
                      Déconnecter le compte
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="order-container">
        {client && <ClientNavigation client={client} currentTab="edit" />}
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <section>
              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-body">
                  <Row>
                    <Col className="d-flex flex-column mx-auto" md={12}>
                      <div className="mb-3">
                        <label htmlFor="companyName" className="form-label">
                          Nom de l'entreprise :
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="companyName"
                          {...register("companyName", { required: true })}
                        />
                        {errors.companyName && (
                          <div className="text-danger">Nom de l'entreprise requis</div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="address" className="form-label">
                          Adresse :
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          {...register("address", { required: true })}
                        />
                        {errors.address && (
                          <div className="text-danger">Adresse requise</div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Nom du contact :
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          {...register("contact.name", { required: true })}
                        />
                        {errors.contact?.name && (
                          <div className="text-danger">Nom du contact requis</div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="phone" className="form-label">
                          Téléphone du contact :
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          {...register("contact.phone", { required: true })}
                        />
                        {errors.contact?.phone && (
                          <div className="text-danger">Téléphone du contact requis</div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Adresse email du contact :
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          {...register("contact.email", { required: true })}
                        />
                        {errors.contact?.email && (
                          <div className="text-danger">
                            Adresse email du contact requise
                          </div>
                        )}
                      </div>
                      <hr />
                    </Col>
                    <Col md={6}>
                      <label htmlFor="facebook_key" className="form-label">
                        Facebook ID (optionnel):
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="facebook_key"
                        {...register("facebook_key", { required: false })}
                      />
                    </Col>
                    <Col md={6}>
                      <label htmlFor="ga_key" className="form-label">
                        Google analytics ID (optionnel):
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ga_key"
                        {...register("ga_key", { required: false })}
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <label htmlFor="mcc_id" className="form-label">
                        Mcc (optionnel):
                      </label>
                      {fetchingMccs ? (
                        <>
                          <Bars
                            height={50}
                            width={100}
                            radius={5}
                            color="#cb0c9f"
                            ariaLabel="bars-loading"
                            wrapperStyle=""
                            visible={true}
                          />
                        </>
                      ) : (
                        <select
                          id="mcc_id"
                          className="form-control mb-0"
                          defaultValue={client?.mcc_id}
                          onChange={(e) => setValue("mcc_id", e.target.value)}
                        >
                          <option value=""></option>
                          {google_mccs.map((mcc) => (
                            <option key={mcc.value} value={mcc.value}>
                              {mcc.label}
                            </option>
                          ))}
                        </select>
                      )}
                    </Col>

                    <Col md={6} className="mb-3">
                      <label htmlFor="gads_key" className="form-label">
                        Google Ads ID (optionnel):
                      </label>

                      {fetchingAccounts ? (
                        <>
                          <Bars
                            height={50}
                            width={100}
                            radius={5}
                            color="#cb0c9f"
                            ariaLabel="bars-loading"
                            wrapperStyle=""
                            visible={true}
                          />
                        </>
                      ) : (
                        <select
                          id="gads_key"
                          className="form-control mb-0"
                          defaultValue={client?.gads_key}
                          onChange={(e) => setValue("gads_key", e.target.value)}
                        >
                          <option value=""></option>
                          {google_accounts.map((acc) => (
                            <option key={acc.value} value={acc.value}>
                              {acc.label}
                            </option>
                          ))}
                        </select>
                      )}
                    </Col>
                    <Col md={12}>
                      <hr />
                    </Col>

                    <Col md={6}>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="share_calendar"
                          {...register("share_calendar")}
                        />
                        <label className="form-check-label" htmlFor="share_calendar">
                          Partager le calendrier avec le client
                        </label>
                      </div>
                    </Col>
                    <Col md={12}>
                      <hr />
                    </Col>

                    <Col md={12}>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="with_issue"
                          {...register("with_issue")}
                        />
                        <label className="form-check-label" htmlFor="with_issue">
                          Client problématique ?
                        </label>
                      </div>
                    </Col>
                    {with_issue && (
                      <Col md={12}>
                        <div className="mb-3">
                          <label htmlFor="issue" className="form-label">
                            Probléme
                          </label>
                          <textarea
                            className="form-control"
                            id="issue"
                            {...register("issue", { required: false })}
                          ></textarea>
                        </div>
                      </Col>
                    )}

                    <Col md={12}>
                      <hr />
                    </Col>

                    <div className="mb-3">
                      <label htmlFor="about" className="form-label">
                        À propos (optionnel)
                      </label>
                      <textarea
                        className="form-control"
                        id="about"
                        {...register("about", { required: false })}
                      ></textarea>
                    </div>
                    <Col md={12}>
                      <div className="">
                        <button
                          type="submit"
                          className="btn bg-gradient-dark w-30 mt-4 mb-0"
                        >
                          Valider
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Row className="mb-3">
                <Col>
                  <Link to={`/clients`} className="link-action back">
                    Retour
                  </Link>
                </Col>
              </Row>
            </section>
          </form>
        </div>
      </div>
    </>
  );
};

export default ClientForm;
