import {
  FETCH_DRAFTS_REQUEST,
  FETCH_DRAFTS_SUCCESS,
  FETCH_DRAFTS_FAILURE,
  ADD_DRAFT_REQUEST,
  ADD_DRAFT_SUCCESS,
  ADD_DRAFT_FAILURE,
  UPDATE_DRAFT_REQUEST,
  UPDATE_DRAFT_SUCCESS,
  UPDATE_DRAFT_FAILURE,
  UPDATE_POSITIONS_DRAFT_REQUEST,
  UPDATE_POSITIONS_DRAFT_SUCCESS,
  UPDATE_POSITIONS_DRAFT_FAILURE,
  DELETE_DRAFT_REQUEST,
  DELETE_DRAFT_SUCCESS,
  DELETE_DRAFT_FAILURE,
} from "../actionTypes";

const initialState = {
  drafts: [],
  error: "",
};

const draftReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_DRAFT_REQUEST:
      return {
        ...state,
      };
    case DELETE_DRAFT_SUCCESS:
      const unDeletedDrafts = state.drafts.filter(
        (draft) => draft._id !== action.payload
      );

      return {
        ...state,
        drafts: unDeletedDrafts,
        error: "",
      };
    case DELETE_DRAFT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_POSITIONS_DRAFT_REQUEST:
      return {
        ...state,
      };
    case UPDATE_POSITIONS_DRAFT_SUCCESS:
      return {
        ...state,
        error: "",
      };
    case UPDATE_POSITIONS_DRAFT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_DRAFTS_REQUEST:
      return {
        ...state,
      };
    case FETCH_DRAFTS_SUCCESS:
      return {
        ...state,
        drafts: action.payload,
        error: "",
      };
    case FETCH_DRAFTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_DRAFT_REQUEST:
      return {
        ...state,
        error: "",
      };
    case ADD_DRAFT_SUCCESS:
      return {
        ...state,
        drafts: [action.payload, ...state.drafts],
        error: "",
      };

    case ADD_DRAFT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_DRAFT_REQUEST:
      return {
        ...state,
        error: "",
      };
    case UPDATE_DRAFT_SUCCESS:
      const updatedDraftIndex = state.drafts.findIndex(
        (draft) => draft._id === action.payload._id
      );
      const updatedDrafts = [...state.drafts];
      updatedDrafts[updatedDraftIndex] = action.payload;

      return {
        ...state,
        drafts: updatedDrafts,
        error: "",
      };

    case UPDATE_DRAFT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default draftReducer;
