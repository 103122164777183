import React from "react";
import { Row, Col, Tab } from "react-bootstrap";
import BarChart from "./charts/BarChart";
import ShapeBar from "./charts/ShapeBar";
import PieChart from "./charts/PieChart";
import AreaChart from "./charts/AreaChart";

const Fads = ({ fb_insight }) => {
  return (
    <>
      <div className="card medium-card mb-4 pb-2 purple-border">
        <div className="card-header">
          <h6>
            Impression : {fb_insight.lastMounthData[0].impressions}{" "}
            <small className="text-uppercase  text-xs font-weight-bolder  broun-text">
              (30 derniers jours)
            </small>
          </h6>
          <p className="text-sm broun-text mb-0">
            Les impressions représentent le nombre total de fois où votre publicité a été
            affichée.
          </p>
        </div>
        <div className="card-body">
          <Row className="mb-3">
            <Col md={12}></Col>
            <Col md={4}>
              <small className="text-uppercase  text-sm">Historique Par mois</small>
              {fb_insight.periode === "monthly" ? (
                <BarChart
                  color="#4267B2"
                  dataType="impressions"
                  data={fb_insight.historicalData}
                />
              ) : (
                <AreaChart
                  color="#4267B2"
                  dataType="impressions"
                  data={fb_insight.historicalData}
                />
              )}
            </Col>
            <Col md={4}>
              <small className="text-uppercase  text-sm">Âge</small>
              <ShapeBar info="age" dataType="impressions" data={fb_insight.ageData} />
            </Col>
            <Col md={4}>
              <small className="text-uppercase  text-sm">Genre</small>
              <PieChart
                info="gender"
                dataType="impressions"
                data={fb_insight.genderData}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="card medium-card mb-4 pb-2 purple-border">
        <div className="card-header">
          <h6>
            Couverture : {fb_insight.lastMounthData[0].reach}{" "}
            <small className="text-uppercase text-xs font-weight-bolder broun-text">
              (30 derniers jours)
            </small>
          </h6>
          <p className="text-sm broun-text mb-0">
            La portée (Reach) indique le nombre de personnes uniques qui ont vu votre
            publicité.
          </p>
        </div>
        <div className="card-body">
          <Row className="mb-3">
            <Col md={12}></Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Historique Par mois</small>
              {fb_insight.periode === "monthly" ? (
                <BarChart
                  color="#4267B2"
                  dataType="reach"
                  data={fb_insight.historicalData}
                />
              ) : (
                <AreaChart
                  color="#4267B2"
                  dataType="reach"
                  data={fb_insight.historicalData}
                />
              )}
            </Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Âge</small>
              <ShapeBar info="age" dataType="reach" data={fb_insight.ageData} />
            </Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Genre</small>
              <PieChart info="gender" dataType="reach" data={fb_insight.genderData} />
            </Col>
          </Row>
        </div>
      </div>
      <div className="card medium-card mb-4 pb-2 purple-border">
        <div className="card-header">
          <h6>
            Clics : {fb_insight.lastMounthData[0].clicks}{" "}
            <small className="text-uppercase text-xs font-weight-bolder broun-text">
              (30 derniers jours)
            </small>
          </h6>
          <p className="text-sm broun-text mb-0">
            Les clics représentent le nombre total de fois où les utilisateurs ont
            interagi avec votre publicité.
          </p>
        </div>
        <div className="card-body">
          <Row className="mb-3">
            <Col md={12}></Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Historique Par mois</small>
              {fb_insight.periode === "monthly" ? (
                <BarChart
                  color="#4267B2"
                  dataType="clicks"
                  data={fb_insight.historicalData}
                />
              ) : (
                <AreaChart
                  color="#4267B2"
                  dataType="clicks"
                  data={fb_insight.historicalData}
                />
              )}
            </Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Âge</small>
              <ShapeBar info="age" dataType="clicks" data={fb_insight.ageData} />
            </Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Genre</small>
              <PieChart info="gender" dataType="clicks" data={fb_insight.genderData} />
            </Col>
          </Row>
        </div>
      </div>
      <Row>
        <Col md={6}>
          {" "}
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header">
              <h6>
                Fréquence :{" "}
                {Number(parseFloat(fb_insight.lastMounthData[0].frequency).toFixed(2))}{" "}
                <small className="text-uppercase text-xs font-weight-bolder broun-text">
                  (30 derniers jours)
                </small>
              </h6>
              <p className="text-sm broun-text mb-0">
                La fréquence représente le nombre moyen de fois qu'un individu a vu votre
                publicité durant la période spécifiée.
              </p>
            </div>
            <div className="card-body">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={4}>
                  <small className="text-uppercase text-sm">Historique Par mois</small>
                  {fb_insight.periode === "monthly" ? (
                    <BarChart
                      color="#4267B2"
                      dataType="frequency"
                      data={fb_insight.historicalData}
                    />
                  ) : (
                    <AreaChart
                      color="#4267B2"
                      dataType="frequency"
                      data={fb_insight.historicalData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {/* CPC Section */}
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header">
              <h6>
                CPC moyen:{" "}
                {Number(parseFloat(fb_insight.lastMounthData[0].cpc).toFixed(3))}
                {"€ "}
                <small className="text-uppercase text-xs font-weight-bolder broun-text">
                  (30 derniers jours)
                </small>
              </h6>
              <p className="text-sm broun-text mb-0">
                Le coût par clic (CPC) représente le coût moyen payé pour chaque clic reçu
                sur votre publicité.
              </p>
            </div>
            <div className="card-body">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={4}>
                  <small className="text-uppercase text-sm">Historique Par mois</small>
                  {fb_insight.periode === "monthly" ? (
                    <BarChart
                      color="#4267B2"
                      dataType="cpc"
                      data={fb_insight.historicalData}
                    />
                  ) : (
                    <AreaChart
                      color="#4267B2"
                      dataType="cpc"
                      data={fb_insight.historicalData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      {/* CTR Section */}
      <div className="card medium-card mb-4 pb-2 purple-border">
        <div className="card-header">
          <h6>
            CTR : {Number(parseFloat(fb_insight.lastMounthData[0].ctr).toFixed(2))}
            {"% "}
            <small className="text-uppercase text-xs font-weight-bolder broun-text">
              (30 derniers jours)
            </small>
          </h6>
          <p className="text-sm broun-text mb-0">
            Le taux de clics (CTR) est le pourcentage de clics reçus par rapport au nombre
            d'impressions.
          </p>
        </div>
        <div className="card-body">
          <Row className="mb-3">
            <Col md={12}></Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Historique Par mois</small>
              {fb_insight.periode === "monthly" ? (
                <BarChart
                  color="#4267B2"
                  dataType="ctr"
                  data={fb_insight.historicalData}
                />
              ) : (
                <AreaChart
                  color="#4267B2"
                  dataType="ctr"
                  data={fb_insight.historicalData}
                />
              )}
            </Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Âge</small>
              <ShapeBar info="age" dataType="ctr" data={fb_insight.ageData} />
            </Col>
            <Col md={4}>
              <small className="text-uppercase text-sm">Genre</small>
              <PieChart info="gender" dataType="ctr" data={fb_insight.genderData} />
            </Col>
          </Row>
        </div>
      </div>{" "}
    </>
  );
};

export default Fads;
