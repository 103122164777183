import { useEffect, useContext, useMemo, useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderById,
  fetchClientById,
} from "../../../../../redux/actions/clientActions";
import { useSearchParams } from "react-router-dom";
import {
  fetchDocuments,
  fetchEmails,
  fetchEmail,
  sendEmail,
} from "../../../../../redux/actions/emailActions";

import { Row, Col, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { NavContext } from "../../../../NavContext";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import OrderNavigation from "../order/orderNavigation";

const OEmailPage = () => {
  const dispatch = useDispatch();
  const { activeNav, setActiveNav } = useContext(NavContext);
  const { clientId, orderId } = useParams();
  const [validEmails, setValidEmails] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    getValues,
  } = useForm();

  const [querySearch, setQuerySearch] = useSearchParams();
  const documents_params = querySearch.get("documents");

  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);

  const emails = useSelector((state) => state.emailReducer.emails);
  const email = useSelector((state) => state.emailReducer.email);
  const email_documents = useSelector((state) => state.emailReducer.documents);
  const isFetching = useSelector((state) => state.emailReducer.isFetchingEmails);
  const order = useSelector((state) => state.clientReducer.order);
  const client = useSelector((state) => state.clientReducer.tiny_client);
  const currentUser = useSelector((state) => state.authReducer.user.user);

  useEffect(() => {
    document.title = "ProdOTop - Emails de la commande";
  }, []);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientById(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (documents_params) {
      dispatch(fetchDocuments(documents_params));
    }
  }, [dispatch, documents_params]);

  const replaceTemplateVariables = useCallback(() => {
    if (!email || !client || !order) return "";
    return email.text
      .replace(/\[CLIENT_NAME\]/g, client.contact.name)
      .replace(/\[PRODUCT_NAME\]/g, order.product.name)
      .replace(/\[ORDER_START_DATE\]/g, moment(order.startDate).format("DD-MM-YYYY"))
      .replace(/\[ORDER_END_DATE\]/g, moment(order.endDate).format("DD-MM-YYYY"))
      .replace(/\[PROD_RESPONSIBLE\]/g, order.team1Responsible?.name)
      .replace(/\[RC_RESPONSIBLE\]/g, order.team2Responsible?.name);
  }, [email, client, order]);

  const text = replaceTemplateVariables;

  useEffect(() => {
    if (email && currentUser) {
      reset({
        from: currentUser.email,
        to: client?.contact?.email,
        subject: email.subject,
        text: text,
        emailId: getValues("emailId"),
      });
    }
  }, [dispatch, client, currentUser, email, getValues, reset, text]);

  useEffect(() => {
    if (!isFetching) {
      dispatch(fetchEmails());
    }
  }, [dispatch, emails, isFetching]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(clientId, orderId));
    }
  }, [dispatch, orderId, clientId]);

  const onSubmit = (data) => {
    dispatch(fetchEmail(data.emailId.value));
  };

  const onSubmitEmail = (data) => {
    const formattedCcEmails = validEmails.join(", ");

    const emailData = {
      ...data,
      cc: formattedCcEmails,
      orderId,
      ...(email_documents ? { email_documents } : {}),
    };

    dispatch(sendEmail(emailData));
    setValidEmails([]);
    reset();
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateEmailList = (emailList) => {
    if (!emailList) return true;
    return emailList.split(";").every((email) => validateEmail(email.trim()));
  };

  const handleCcChange = (event) => {
    const emailList = event.target.value.split(";").map((email) => email.trim());
    const validEmailList = emailList.filter((email) => validateEmail(email));
    setValidEmails(validEmailList);
  };

  const emailsList = useMemo(() => {
    return emails
      ? emails.map((option) => ({
          value: option._id,
          label: option?.title,
        }))
      : [];
  }, [emails]);

  return (
    <>
      {order && (
        <>
          <div className="pb-4">
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  <Link to="/clients">clients</Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/clients/${client?._id}/orders`}>{client?.companyName}</Link>
                </li>
                {order && (
                  <li
                    className="breadcrumb-item text-sm text-dark flex p-0"
                    aria-current="page"
                  >
                    <Dropdown className="dropdown-orders">
                      <Dropdown.Toggle id="dropdown-basic">
                        {order.product?.name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {client?.orders?.map((cl_order) => (
                          <Dropdown.Item
                            as={Link}
                            to={`/clients/${clientId}/orders/${cl_order._id}/emails`}
                            key={cl_order._id}
                          >
                            {cl_order.product?.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                )}
                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  E-Mails
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-container">
            {order && (
              <OrderNavigation order={order} client={client} currentTab="emails" />
            )}
            <div>
              <Row className="mb-3">
                <div className="col-12">
                  <div className="card medium-card mb-4 pb-2 purple-border">
                    <div className="card-header">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                          <Col md={9}>
                            <Controller
                              name="emailId"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={emailsList}
                                  isClearable={true}
                                  placeholder="Sélectionnez un e-mail"
                                />
                              )}
                            />
                          </Col>
                          <Col md={3}>
                            <button
                              type="submit"
                              className="btn bg-gradient-dark w-80 mb-0"
                            >
                              Charger
                            </button>
                          </Col>
                        </Row>
                      </form>
                    </div>
                    <div className="card-body">
                      <div className="flex gap-2 mb-2 ">
                        {email_documents &&
                          email_documents.map((doc) => (
                            <span key={doc._id} className="flex gap-2">
                              <FontAwesomeIcon
                                icon={faPaperclip}
                                color="#b616a9"
                                size="lg"
                              />
                              <h6 className="text-gradient text-primary mt-0">
                                {doc.title}
                              </h6>
                            </span>
                          ))}
                      </div>
                      {email ? (
                        <form onSubmit={handleSubmit(onSubmitEmail)}>
                          <Row className="mb-3">
                            <Col md={6}>
                              <div className="mb-3">
                                <label htmlFor="from" className="form-label">
                                  E-mail de :
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="from"
                                  {...register("from", { required: true })}
                                />
                                {errors.from && (
                                  <div className="text-danger">
                                    Objet de l'email requis
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <label htmlFor="to" className="form-label">
                                  E-mail à :
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="to"
                                  {...register("to", { required: true })}
                                />
                                {errors.to && (
                                  <div className="text-danger">
                                    Destinataire de l'email requis
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <label htmlFor="cc" className="form-label">
                                  {"Copies (CC) : separation avec < ; >"}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="cc"
                                  {...register("cc", {
                                    validate: (value) =>
                                      validateEmailList(value) ||
                                      "Format d'email invalide",
                                  })}
                                  onChange={handleCcChange}
                                />
                                {errors.cc && (
                                  <div className="text-danger">{errors.cc.message}</div>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              {validEmails.length > 0 && (
                                <div className="valid-emails-list">
                                  <label className="form-label">Liste (CC) :</label>
                                  <ul className="flex gap-2">
                                    {validEmails.map((email, index) => (
                                      <li key={index}>{email}</li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </Col>

                            <Col md={12}>
                              <div className="mb-3">
                                <label htmlFor="subject" className="form-label">
                                  Sujet :
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="subject"
                                  {...register("subject", { required: true })}
                                />
                                {errors.subject && (
                                  <div className="text-danger">
                                    Objet de l'email requis
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="mb-3">
                                <label htmlFor="text" className="form-label">
                                  Texte de l'email :
                                </label>
                                <Controller
                                  name={`text`}
                                  control={control}
                                  render={({ field }) => (
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={field.value}
                                      onChange={(event, editorInstance) => {
                                        const data = editorInstance.getData();
                                        field.onChange(data);
                                      }}
                                    />
                                  )}
                                />
                                {errors.text && (
                                  <div className="text-danger">
                                    Texte de l'email requis
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col md={3}>
                              <button
                                type="submit"
                                className="btn bg-gradient-dark w-80 mb-0"
                              >
                                Envoyer
                              </button>
                            </Col>
                          </Row>
                        </form>
                      ) : (
                        <p>
                          Chargez un nouveau template pour envoyer un email au client ...
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Row>
              <Row className="mb-3">
                <div className="col-12">
                  <div className="card medium-card mb-4 pb-2 purple-border">
                    <div className="card-body">
                      <h6>
                        <span className="product-title px-3 text-dark">
                          Historique des envois
                        </span>
                      </h6>
                      {order?.email_history.map(
                        (eh) =>
                          eh?.email && (
                            <div className="flex gap-3 px-3" key={eh._id}>
                              <div>{moment(eh.sentAt).format("DD-MM-YYYY")}</div>
                              <div>{eh?.email?.title}</div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Link to={`/clients/${clientId}/orders`} className="link-action back">
                    Retour
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OEmailPage;
