import { Row, Col } from "react-bootstrap";
import Card from "./card";

const ProductLargeCard = ({ index, db, setData, setLayoutId }) => {
  return (
    <Col
      xs={12}
      key={index}
      className={`${
        db.pendingOrders.length === 0 &&
        db.newTeam1Orders.length === 0 &&
        db.newTeam2Orders.length === 0 &&
        db.newTeam3Orders.length === 0 &&
        db.newTeam4Orders.length === 0 &&
        db.newTeam5Orders.length === 0 &&
        db.inProgressOrders.length === 0 &&
        db.producedOrders.length === 0 &&
        db.completedOrders.length === 0
          ? "display-none"
          : ""
      }`}
    >
      <div className="card purple-border mb-4 ">
        <div className="card-body  px-0 pt-0 pb-0">
          <div
            className="flex gap-3 flex-wrap product-large-card"
            style={{ borderLeft: `8px solid  ${db.product.color}`, borderRadius: "20px" }}
          >
            <div className="text-center mb-0 product-card-item">
              <span className="product-title px-3">{db.product.name}</span>
            </div>
            <Card
              title="Demande d'amélioration"
              data={{
                list: db.pendingOrders,
                status: "Demande d'amélioration",
                code: "progress_pending",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-4`}
              setData={setData}
              setLayoutId={setLayoutId}
            />

            <Card
              title="Nouveau RC"
              data={{
                list: db.newTeam2Orders,
                status: "Nouveau RC",
                code: "new_rc",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-11`}
              setData={setData}
              setLayoutId={setLayoutId}
            />

            <Card
              title="Nouveau Prod"
              data={{
                list: db.newTeam1Orders,
                status: "Nouveau Prod",
                code: "new",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-1`}
              setData={setData}
              setLayoutId={setLayoutId}
            />

            <Card
              title="En production"
              data={{
                list: db.inProgressOrders,
                status: "En production",
                code: "in_progress",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-3`}
              setData={setData}
              setLayoutId={setLayoutId}
            />

            <Card
              title="Produite"
              data={{
                list: db.producedOrders,
                status: "Produite",
                code: "produced",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-2`}
              setData={setData}
              setLayoutId={setLayoutId}
            />
            <Card
              title="Finalisé"
              data={{
                list: db.completedOrders,
                status: "Finalisé",
                code: "completed",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-5`}
              setData={setData}
              setLayoutId={setLayoutId}
            />
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ProductLargeCard;
