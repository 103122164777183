import React from "react";
import { Row, Col, Tab } from "react-bootstrap";
import BarChart from "./charts/BarChart";
import AreaChart from "./charts/AreaChart";

const Ga = ({ ga_insight }) => {
  return (
    <Row>
      <Col md={6}>
        {" "}
        <div className="card medium-card mb-4 pb-2 purple-border">
          <div className="card-header">
            <h6>Sessions :</h6>
            <p className="text-sm broun-text mb-0">
              Période au cours de laquelle un utilisateur est actif sur votre site.
            </p>
          </div>
          <div className="card-body">
            <Row className="mb-3">
              <Col md={12}></Col>
              <Col md={12}>
                <small className="text-uppercase  text-sm">Historique Par mois</small>
                {ga_insight.periode === "monthly" ? (
                  <BarChart
                    color="#4285F4"
                    dataType="sessions"
                    data={ga_insight.processedData}
                    width={600}
                  />
                ) : (
                  <AreaChart
                    color="#4285F4"
                    dataType="sessions"
                    data={ga_insight.processedData}
                    width={600}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col md={6}>
        {" "}
        <div className="card medium-card mb-4 pb-2 purple-border">
          <div className="card-header">
            <h6>Sessions avec engagement :</h6>
            <p className="text-sm broun-text mb-0">Nombre de sessions avec engagement.</p>
          </div>
          <div className="card-body">
            <Row className="mb-3">
              <Col md={12}></Col>
              <Col md={12}>
                <small className="text-uppercase  text-sm">Historique Par mois</small>
                {ga_insight.periode === "monthly" ? (
                  <BarChart
                    color="#DB4437"
                    dataType="engagedSessions"
                    data={ga_insight.processedData}
                    width={600}
                  />
                ) : (
                  <AreaChart
                    color="#DB4437"
                    dataType="engagedSessions"
                    data={ga_insight.processedData}
                    width={600}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col md={6}>
        {" "}
        <div className="card medium-card mb-4 pb-2 purple-border">
          <div className="card-header">
            <h6>Pages par sessions :</h6>
            <p className="text-sm broun-text mb-0">
              Nombre moyen de pages vues au cours d'une session par un utilisateur.
            </p>
          </div>
          <div className="card-body">
            <Row className="mb-3">
              <Col md={12}></Col>
              <Col md={12}>
                <small className="text-uppercase  text-sm">Historique Par mois</small>
                {ga_insight.periode === "monthly" ? (
                  <BarChart
                    color="#F4B400"
                    dataType="pageviewsPerSession"
                    data={ga_insight.processedData}
                    width={600}
                  />
                ) : (
                  <AreaChart
                    color="#F4B400"
                    dataType="pageviewsPerSession"
                    data={ga_insight.processedData}
                    width={600}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col md={6}>
        {" "}
        <div className="card medium-card mb-4 pb-2 purple-border">
          <div className="card-header">
            <h6>Nombre total d'utilisateurs :</h6>
            <p className="text-sm broun-text mb-0">
              Nombre d'utilisateurs uniques qui ont visité le site internet.
            </p>
          </div>
          <div className="card-body">
            <Row className="mb-3">
              <Col md={12}></Col>
              <Col md={12}>
                <small className="text-uppercase  text-sm">Historique Par mois</small>
                {ga_insight.periode === "monthly" ? (
                  <BarChart
                    color="#0F9D58"
                    dataType="totalUsers"
                    data={ga_insight.processedData}
                    width={600}
                  />
                ) : (
                  <AreaChart
                    color="#0F9D58"
                    dataType="totalUsers"
                    data={ga_insight.processedData}
                    width={600}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col md={6}>
        {" "}
        <div className="card medium-card mb-4 pb-2 purple-border">
          <div className="card-header">
            <h6>Nouveaux utilisateurs :</h6>
            <p className="text-sm broun-text mb-0">
              Nombre de nouveaux utilisateurs qui ont visité le site.
            </p>
          </div>
          <div className="card-body">
            <Row className="mb-3">
              <Col md={12}></Col>
              <Col md={12}>
                <small className="text-uppercase  text-sm">Historique Par mois</small>
                {ga_insight.periode === "monthly" ? (
                  <BarChart
                    color="#4285F4"
                    dataType="newUsers"
                    data={ga_insight.processedData}
                    width={600}
                  />
                ) : (
                  <AreaChart
                    color="#4285F4"
                    dataType="newUsers"
                    data={ga_insight.processedData}
                    width={600}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col md={6}>
        {" "}
        <div className="card medium-card mb-4 pb-2 purple-border">
          <div className="card-header">
            <h6>Durée moyenne de la session :</h6>
            <p className="text-sm broun-text mb-0">
              Il s'agit de la durée moyenne d'une session.
            </p>
          </div>
          <div className="card-body">
            <Row className="mb-3">
              <Col md={12}></Col>
              <Col md={12}>
                <small className="text-uppercase  text-sm">Historique Par mois</small>
                {ga_insight.periode === "monthly" ? (
                  <BarChart
                    color="#DB4437"
                    dataType="averageSessionDuration"
                    data={ga_insight.processedData}
                    width={600}
                  />
                ) : (
                  <AreaChart
                    color="#DB4437"
                    dataType="averageSessionDuration"
                    data={ga_insight.processedData}
                    width={600}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col md={6}>
        {" "}
        <div className="card medium-card mb-4 pb-2 purple-border">
          <div className="card-header">
            <h6>Taux d'engagement %:</h6>
            <p className="text-sm broun-text mb-0">
              Taux d'utilisateurs ayant eu de l'engagement sur le site.
            </p>
          </div>
          <div className="card-body">
            <Row className="mb-3">
              <Col md={12}></Col>
              <Col md={12}>
                <small className="text-uppercase  text-sm">Historique Par mois</small>

                {ga_insight.periode === "monthly" ? (
                  <BarChart
                    color="#F4B400"
                    dataType="engagementRate"
                    data={ga_insight.processedData}
                    width={600}
                  />
                ) : (
                  <AreaChart
                    color="#F4B400"
                    dataType="engagementRate"
                    data={ga_insight.processedData}
                    width={600}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col md={6}>
        {" "}
        <div className="card medium-card mb-4 pb-2 purple-border">
          <div className="card-header">
            <h6>Taux de rebond %:</h6>
            <p className="text-sm broun-text mb-0">
              Pourcentage de sessions qui visitent une seule page et ne font aucune
              interaction.
            </p>
          </div>
          <div className="card-body">
            <Row className="mb-3">
              <Col md={12}></Col>
              <Col md={12}>
                <small className="text-uppercase  text-sm">Historique Par mois</small>

                {ga_insight.periode === "monthly" ? (
                  <BarChart
                    color="#0F9D58"
                    dataType="bounceRate"
                    data={ga_insight.processedData}
                    width={600}
                  />
                ) : (
                  <AreaChart
                    color="#0F9D58"
                    dataType="bounceRate"
                    data={ga_insight.processedData}
                    width={600}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col md={12}>
        {" "}
        <div className="card medium-card mb-4 pb-2 purple-border">
          <div className="card-header">
            <h6>Pages les plus visitées:</h6>
            <p className="text-sm broun-text mb-0"></p>
          </div>
          <div className="card-body">
            <Row className="mb-3">
              <Col md={12}>
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Page de destination
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                        Sessions
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                        Utilisateurs
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                        Taux de rebond
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ga_insight.topPages.map((tp, index) => (
                      <tr key={index}>
                        <td>{tp.page.slice(0, 50)}</td>
                        <td className="text-uppercase  font-weight-bolder opacity-7 text-end">
                          {tp.sessions}
                        </td>
                        <td className="text-uppercase  font-weight-bolder opacity-7 text-end">
                          {tp.totalUsers}
                        </td>
                        <td className="text-uppercase  font-weight-bolder opacity-7 text-end">
                          {Number(parseFloat(tp.bounceRate * 100).toFixed(1))}%
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col md={12}>
        {" "}
        <div className="card medium-card mb-4 pb-2 purple-border">
          <div className="card-header">
            <h6>Événements:</h6>
            <p className="text-sm broun-text mb-0"></p>
          </div>
          <div className="card-body">
            <Row className="mb-3">
              <Col md={12}>
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Nom de l'événement
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                        Nombre d'événements
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ga_insight.eventsData.map((ed, index) => (
                      <tr key={index}>
                        <td>{ed.event}</td>
                        <td className="text-uppercase  font-weight-bolder opacity-7 text-end">
                          {ed.eventCount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Ga;
