import axios from "axios";

const BASE_URL = "/api/cli/clients";

export const clientsService = {
  fetchClientEntities: async () => {
    const response = await axios.get(`${BASE_URL}`);
    return response.data;
  },
  fetchCliClientOrders: async (clientId) => {
    const response = await axios.get(`${BASE_URL}/${clientId}`);
    return response.data;
  },
  fetchCliTinyClient: async (clientId) => {
    const response = await axios.get(`${BASE_URL}/${clientId}/tiny`);
    return response.data;
  },
  fetchCliClientNotes: async (clientId) => {
    const response = await axios.get(`${BASE_URL}/${clientId}/notes`);
    return response.data;
  },
  fetchCliClientCalendar: async (clientId) => {
    const response = await axios.get(`${BASE_URL}/${clientId}/calendar`);
    return response.data;
  },
  addNewTicketForCliOrder: async (clientId, orderId, ticketData) => {
    const response = await axios.post(
      `${BASE_URL}/${clientId}/orders/${orderId}/tickets`,
      ticketData
    );
    return response.data;
  },
  uploadDocument: async (clientId, orderId, data) => {
    return axios
      .post(`${BASE_URL}/${clientId}/orders/${orderId}/documents`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((response) => {
        return response.data;
      });
  },
  addClientNote: async (clientId, orderId, data) => {
    return axios
      .post(`${BASE_URL}/${clientId}/orders/${orderId}/notes`, data)
      .then((response) => {
        return response.data;
      });
  },
  downloadDocument: async (clientId, orderId, docId) => {
    return axios.get(`${BASE_URL}/${clientId}/orders/${orderId}/documents/${docId}`, {
      responseType: "blob",
    });
  },
  updateCliTicket: async (clientId, orderId, ticketId, data) => {
    return axios
      .put(`${BASE_URL}/${clientId}/orders/${orderId}/tickets/${ticketId}`, data)
      .then((response) => {
        return response.data;
      });
  },
  fetchCliTicket: async (clientId, orderId, ticketId) => {
    return axios
      .get(`${BASE_URL}/${clientId}/orders/${orderId}/tickets/${ticketId}`)
      .then((response) => {
        return response.data;
      });
  },
};
