import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchCliClientOrders } from "../../../../redux/actions/cli/clientsActions";
import { Link } from "react-router-dom";
import OrderCard from "./orderCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faFolderTree,
  faPenToSquare,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

const OrdersPage = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const client = useSelector((state) => state.cliClientsReducer.client);

  const [show, setShow] = useState(false);

  useEffect(() => {
    document.title = "ProdOTop - Commandes du client " + client?.companyName;
  }, [client]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliClientOrders(clientId));
    }
  }, [dispatch, clientId]);

  return (
    <>
      <div>
        <div className="card mb-3">
          <div className="flex justify-content-between gap-3 ">
            <div className="p-5">
              <h2>
                <span>Bienvenue dans votre espace</span>
              </h2>
              <div>
                <span>Texte à définir.</span>
              </div>
            </div>
            <div>
              <i className="bg-agency img-fluid bg-header" />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="p-5">
            <div className="mb-5">
              <h2>
                <span>Gestion</span>
              </h2>
            </div>
            <div className="flex flex-wrap gap-3">
              <div
                className="card bg-ligth-primary p-5 shadow-none text-center"
                role="button"
                onClick={() => setShow(!show)}
              >
                <div>
                  <FontAwesomeIcon icon={faFolderTree} className="" />
                  <span className="mx-3 text-bolder">Accéder aux commandes</span>
                </div>

                <div className="mt-2">
                  <hr />
                  <p className="text-sm m-0 p-0">
                    Ajoutez des <b>documents</b>, formulez des <b>demandes</b>
                  </p>
                  <p className="text-sm m-0 p-0">
                    à notre équipe et partagez votre avis à travers nos
                  </p>
                  <p className="text-sm m-0 p-0">questionnaires de satisfaction.</p>
                </div>
              </div>

              <div className="card bg-ligth-primary p-5 shadow-none text-center ">
                <Link to={`/client/${clientId}/notes`} className="color">
                  <div>
                    <FontAwesomeIcon icon={faPenToSquare} className="" />
                    <span className="mx-3 text-bolder">Accéder aux notes</span>
                  </div>

                  <div className="mt-2">
                    <hr />
                    <p className="text-sm m-0 p-0">
                      Visualisez les notes de notre équipe et laissez
                    </p>
                    <p className="text-sm m-0 p-0">
                      les vôtres, favorisant une <b>communication efficace</b> et
                    </p>
                    <p className="text-sm m-0 p-0">
                      un échange d'informations pour un meilleur suivi.
                    </p>
                  </div>
                </Link>
              </div>

              <div className="card bg-ligth-primary p-5 shadow-none text-center ">
                <Link to={`/client/${clientId}/calendar`}>
                  <div>
                    <FontAwesomeIcon icon={faCalendarAlt} className="" />
                    <span className="mx-3 text-bolder">Accéder au calendrier</span>
                  </div>
                  <div className="mt-2">
                    <hr />
                    <p className="text-sm m-0 p-0">
                      <b>Consultez le planning</b> de notre
                    </p>
                    <p className="text-sm m-0 p-0">
                      équipe pour un suivi en <b>temps réel</b>
                    </p>
                    <p className="text-sm m-0 p-0">de vos commandes.</p>
                  </div>
                </Link>
              </div>
              <div className="card bg-ligth-primary p-5 shadow-none text-center ">
                <Link to={`/client/meet`}>
                  <div>
                    <FontAwesomeIcon icon={faVideo} className="" />
                    <span className="mx-3 text-bolder">Mes réunions</span>
                  </div>
                  <div className="mt-2">
                    <hr />
                    <p className="text-sm m-0 p-0">
                      Consultez<b> les rendez-vous</b>
                    </p>
                    <p className="text-sm m-0 p-0">prévus avec notre</p>
                    <p className="text-sm m-0 p-0">équipe.</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Row className="mt-3">
          {show && <Orders client={client} clientId={clientId} />}
        </Row>
      </div>
    </>
  );
};

const Orders = ({ client, clientId }) => {
  const [shows, setShows] = useState({ new: true, current: true, completed: false });
  console.log(client.orders);
  const new_orders = client
    ? client.orders.filter((order) => !order.produced && !order.orderCompleted)
    : [];
  const current_orders = client
    ? client.orders.filter((order) => order.produced && !order.orderCompleted)
    : [];
  const completed_orders = client
    ? client.orders.filter((order) => order.orderCompleted)
    : [];

  return (
    <Col md={{ span: 12 }} className="mb-5">
      <Row>
        <Col md={12} className="pt-2 mb-3">
          <div
            onClick={() =>
              setShows((currentShows) => ({
                ...currentShows,
                new: !currentShows.new,
              }))
            }
            className="toogle-done-drafts flex justify-content-between bg-white"
          >
            <span className="mx-2 mt-1">Nouveau</span>
            <span className="prodotop-link">{new_orders.length}</span>
          </div>
        </Col>
        {shows.new &&
          new_orders.map((order) => (
            <OrderCard order={order} clientId={clientId} key={order._id} />
          ))}
      </Row>
      <Row>
        <Col md={12} className="pt-2 mb-3">
          <div
            onClick={() =>
              setShows((currentShows) => ({
                ...currentShows,
                current: !currentShows.current,
              }))
            }
            className="toogle-done-drafts flex justify-content-between bg-white"
          >
            <span className="mx-2 mt-1">En cours</span>
            <span className="prodotop-link">{current_orders.length}</span>
          </div>
        </Col>
        {shows.current &&
          current_orders.map((order) => (
            <OrderCard order={order} clientId={clientId} key={order._id} />
          ))}
      </Row>
      <Row>
        <Col md={12} className="pt-2 mb-3">
          <div
            onClick={() =>
              setShows((currentShows) => ({
                ...currentShows,
                completed: !currentShows.completed,
              }))
            }
            className="toogle-done-drafts flex justify-content-between bg-white"
          >
            <span className="mx-2 mt-1">Clôturer</span>
            <span className="prodotop-link">{completed_orders.length}</span>
          </div>
        </Col>
        {shows.completed &&
          completed_orders.map((order) => (
            <OrderCard order={order} clientId={clientId} key={order._id} />
          ))}
      </Row>
    </Col>
  );
};

export default OrdersPage;
