import {
  LOAD_AGENCY_REQUEST,
  LOAD_AGENCY_SUCCESS,
  LOAD_AGENCY_FAILURE,
} from "../actionTypes";

const initialState = {
  agency: false,
  error: "",
};

const agencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_AGENCY_REQUEST:
      return { ...state };
    case LOAD_AGENCY_SUCCESS:
      return { ...state, agency: action.payload };
    case LOAD_AGENCY_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

export default agencyReducer;
