export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const LOAD_CART = "LOAD_CART";
export const RESET_CART = "RESET_CART";
export const GENERATION_ORDER_DOCUMENT = "GENERATION_ORDER_DOCUMENT";

export const FETCH_CLIENTS_REQUEST = "FETCH_CLIENTS_REQUEST";
export const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS";
export const FETCH_CLIENTS_FAILURE = "FETCH_CLIENTS_FAILURE";

export const FETCH_CLIENT_EMPLOYEES_REQUEST = "FETCH_CLIENT_EMPLOYEES_REQUEST";
export const FETCH_CLIENT_EMPLOYEES_SUCCESS = "FETCH_CLIENT_EMPLOYEES_SUCCESS";
export const FETCH_CLIENT_EMPLOYEES_FAILURE = "FETCH_CLIENT_EMPLOYEES_FAILURE";

export const CREATE_MEETING_REQUEST = "CREATE_MEETING_REQUEST";
export const CREATE_MEETING_SUCCESS = "CREATE_MEETING_SUCCESS";
export const CREATE_MEETING_FAILURE = "CREATE_MEETING_FAILURE";

export const FETCH_MEETING_REQUEST = "FETCH_MEETING_REQUEST";
export const FETCH_MEETING_SUCCESS = "FETCH_MEETING_SUCCESS";
export const FETCH_MEETING_FAILURE = "FETCH_MEETING_FAILURE";

export const FETCH_USER_SURVEY_STATS_REQUEST = "FETCH_USER_SURVEY_STATS_REQUEST";
export const FETCH_USER_SURVEY_STATS_SUCCESS = "FETCH_USER_SURVEY_STATS_SUCCESS";
export const FETCH_USER_SURVEY_STATS_FAILURE = "FETCH_USER_SURVEY_STATS_FAILURE";

export const FETCH_USER_EFFICIENCY_STATS_REQUEST = "FETCH_USER_EFFICIENCY_STATS_REQUEST";
export const FETCH_USER_EFFICIENCY_STATS_SUCCESS = "FETCH_USER_EFFICIENCY_STATS_SUCCESS";
export const FETCH_USER_EFFICIENCY_STATS_FAILURE = "FETCH_USER_EFFICIENCY_STATS_FAILURE";

export const FETCH_USER_WORKLOAD_STATS_REQUEST = "FETCH_USER_WORKLOAD_STATS_REQUEST";
export const FETCH_USER_WORKLOAD_STATS_SUCCESS = "FETCH_USER_WORKLOAD_STATS_SUCCESS";
export const FETCH_USER_WORKLOAD_STATS_FAILURE = "FETCH_USER_WORKLOAD_STATS_FAILURE";

export const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAILURE = "ADD_CLIENT_FAILURE";

export const ADD_ORDER_NOTE_REQUEST = "ADD_ORDER_NOTE_REQUEST";
export const ADD_ORDER_NOTE_SUCCESS = "ADD_ORDER_NOTE_SUCCESS";
export const ADD_ORDER_NOTE_FAILURE = "ADD_ORDER_NOTE_FAILURE";

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const FETCH_PRODUCT_QUALITY_CRITERIONS_REQUEST =
  "FETCH_PRODUCT_QUALITY_CRITERIONS_REQUEST";
export const FETCH_PRODUCT_QUALITY_CRITERIONS_SUCCESS =
  "FETCH_PRODUCT_QUALITY_CRITERIONS_SUCCESS";
export const FETCH_PRODUCT_QUALITY_CRITERIONS_FAILURE =
  "FETCH_PRODUCT_QUALITY_CRITERIONS_FAILURE";

export const ADD_PRODUCT_QUALITY_CRITERION_REQUEST =
  "ADD_PRODUCT_QUALITY_CRITERION_REQUEST";
export const ADD_PRODUCT_QUALITY_CRITERION_SUCCESS =
  "ADD_PRODUCT_QUALITY_CRITERION_SUCCESS";
export const ADD_PRODUCT_QUALITY_CRITERION_FAILURE =
  "ADD_PRODUCT_QUALITY_CRITERION_FAILURE";

export const UPDATE_PRODUCT_QUALITY_CRITERION_REQUEST =
  "UPDATE_PRODUCT_QUALITY_CRITERION_REQUEST";
export const UPDATE_PRODUCT_QUALITY_CRITERION_SUCCESS =
  "UPDATE_PRODUCT_QUALITY_CRITERION_SUCCESS";
export const UPDATE_PRODUCT_QUALITY_CRITERION_FAILURE =
  "UPDATE_PRODUCT_QUALITY_CRITERION_FAILURE";

export const DELETE_PRODUCT_QUALITY_CRITERION_REQUEST =
  "DELETE_PRODUCT_QUALITY_CRITERION_REQUEST";
export const DELETE_PRODUCT_QUALITY_CRITERION_SUCCESS =
  "DELETE_PRODUCT_QUALITY_CRITERION_SUCCESS";
export const DELETE_PRODUCT_QUALITY_CRITERION_FAILURE =
  "DELETE_PRODUCT_QUALITY_CRITERION_FAILURE";

export const FETCH_QUALITY_CHECKS_REQUEST = "FETCH_QUALITY_CHECKS_REQUEST";
export const FETCH_QUALITY_CHECKS_SUCCESS = "FETCH_QUALITY_CHECKS_SUCCESS";
export const FETCH_QUALITY_CHECKS_FAILURE = "FETCH_QUALITY_CHECKS_FAILURE";

export const FETCH_QUALITY_CHECK_REQUEST = "FETCH_QUALITY_CHECK_REQUEST";
export const FETCH_QUALITY_CHECK_SUCCESS = "FETCH_QUALITY_CHECK_SUCCESS";
export const FETCH_QUALITY_CHECK_FAILURE = "FETCH_QUALITY_CHECK_FAILURE";

export const ADD_QUALITY_CHECK_REQUEST = "ADD_QUALITY_CHECK_REQUEST";
export const ADD_QUALITY_CHECK_SUCCESS = "ADD_QUALITY_CHECK_SUCCESS";
export const ADD_QUALITY_CHECK_FAILURE = "ADD_QUALITY_CHECK_FAILURE";

export const UPDATE_QUALITY_CHECK_REQUEST = "UPDATE_QUALITY_CHECK_REQUEST";
export const UPDATE_QUALITY_CHECK_SUCCESS = "UPDATE_QUALITY_CHECK_SUCCESS";
export const UPDATE_QUALITY_CHECK_FAILURE = "UPDATE_QUALITY_CHECK_FAILURE";

export const DELETE_ORDER_QUALITY_CHECK_REQUEST = "DELETE_ORDER_QUALITY_CHECK_REQUEST";
export const DELETE_ORDER_QUALITY_CHECK_SUCCESS = "DELETE_ORDER_QUALITY_CHECK_SUCCESS";
export const DELETE_ORDER_QUALITY_CHECK_FAILURE = "DELETE_ORDER_QUALITY_CHECK_FAILURE";

export const ADD_ORDER_QUALITY_CHECK_REQUEST = "ADD_ORDER_QUALITY_CHECK_REQUEST";
export const ADD_ORDER_QUALITY_CHECK_SUCCESS = "ADD_ORDER_QUALITY_CHECK_SUCCESS";
export const ADD_ORDER_QUALITY_CHECK_FAILURE = "ADD_ORDER_QUALITY_CHECK_FAILURE";

export const DELETE_QUALITY_CHECK_REQUEST = "DELETE_QUALITY_CHECK_REQUEST";
export const DELETE_QUALITY_CHECK_SUCCESS = "DELETE_QUALITY_CHECK_SUCCESS";
export const DELETE_QUALITY_CHECK_FAILURE = "DELETE_QUALITY_CHECK_FAILURE";

export const FETCH_BRIEF_QUESTIONS_REQUEST = "FETCH_BRIEF_QUESTIONS_REQUEST";
export const FETCH_BRIEF_QUESTIONS_SUCCESS = "FETCH_BRIEF_QUESTIONS_SUCCESS";
export const FETCH_BRIEF_QUESTIONS_FAILURE = "FETCH_BRIEF_QUESTIONS_FAILURE";

export const FETCH_BRIEF_QUESTION_REQUEST = "FETCH_BRIEF_QUESTION_REQUEST";
export const FETCH_BRIEF_QUESTION_SUCCESS = "FETCH_BRIEF_QUESTION_SUCCESS";
export const FETCH_BRIEF_QUESTION_FAILURE = "FETCH_BRIEF_QUESTION_FAILURE";

export const ADD_BRIEF_QUESTION_REQUEST = "ADD_BRIEF_QUESTION_REQUEST";
export const ADD_BRIEF_QUESTION_SUCCESS = "ADD_BRIEF_QUESTION_SUCCESS";
export const ADD_BRIEF_QUESTION_FAILURE = "ADD_BRIEF_QUESTION_FAILURE";

export const UPDATE_BRIEF_QUESTION_REQUEST = "UPDATE_BRIEF_QUESTION_REQUEST";
export const UPDATE_BRIEF_QUESTION_SUCCESS = "UPDATE_BRIEF_QUESTION_SUCCESS";
export const UPDATE_BRIEF_QUESTION_FAILURE = "UPDATE_BRIEF_QUESTION_FAILURE";

export const UPDATE_QUALITY_REQUEST = "UPDATE_QUALITY_REQUEST";
export const UPDATE_QUALITY_SUCCESS = "UPDATE_QUALITY_SUCCESS";
export const UPDATE_QUALITY_FAILURE = "UPDATE_QUALITY_FAILURE";

export const DELETE_BRIEF_QUESTION_REQUEST = "DELETE_BRIEF_QUESTION_REQUEST";
export const DELETE_BRIEF_QUESTION_SUCCESS = "DELETE_BRIEF_QUESTION_SUCCESS";
export const DELETE_BRIEF_QUESTION_FAILURE = "DELETE_BRIEF_QUESTION_FAILURE";

export const FETCH_CLIENT_ORDERS_REQUEST = "FETCH_CLIENT_ORDERS_REQUEST";
export const FETCH_CLIENT_ORDERS_SUCCESS = "FETCH_CLIENT_ORDERS_SUCCESS";
export const FETCH_CLIENT_ORDERS_FAILURE = "FETCH_CLIENT_ORDERS_FAILURE";

export const FETCH_CLIENT_REQUEST = "FETCH_CLIENT_REQUEST";
export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS";
export const FETCH_CLIENT_FAILURE = "FETCH_CLIENT_FAILURE";

export const FETCH_CLIENT_ORDER_REQUEST = "FETCH_CLIENT_ORDER_REQUEST";
export const FETCH_CLIENT_ORDER_SUCCESS = "FETCH_CLIENT_ORDER_SUCCESS";
export const FETCH_CLIENT_ORDER_FAILURE = "FETCH_CLIENT_ORDER_FAILURE";

export const ADD_CLIENT_ORDER_REQUEST = "ADD_CLIENT_ORDER_REQUEST";
export const ADD_CLIENT_ORDER_SUCCESS = "ADD_CLIENT_ORDER_SUCCESS";
export const ADD_CLIENT_ORDER_FAILURE = "ADD_CLIENT_ORDER_FAILURE";

export const UPDATE_CLIENT_ORDER_REQUEST = "UPDATE_CLIENT_ORDER_REQUEST";
export const UPDATE_CLIENT_ORDER_SUCCESS = "UPDATE_CLIENT_ORDER_SUCCESS";
export const UPDATE_CLIENT_ORDER_FAILURE = "UPDATE_CLIENT_ORDER_FAILURE";

export const DELETE_CLIENT_ORDER_REQUEST = "DELETE_CLIENT_ORDER_REQUEST";
export const DELETE_CLIENT_ORDER_SUCCESS = "DELETE_CLIENT_ORDER_SUCCESS";
export const DELETE_CLIENT_ORDER_FAILURE = "DELETE_CLIENT_ORDER_FAILURE";

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const FETCH_TICKET_REQUEST = "FETCH_TICKET_REQUEST";
export const FETCH_TICKET_SUCCESS = "FETCH_TICKET_SUCCESS";
export const FETCH_TICKET_FAILURE = "FETCH_TICKET_FAILURE";

export const ADD_CLIENT_TICKET_REQUEST = "ADD_CLIENT_TICKET_REQUEST";
export const ADD_CLIENT_TICKET_SUCCESS = "ADD_CLIENT_TICKET_SUCCESS";
export const ADD_CLIENT_TICKET_FAILURE = "ADD_CLIENT_TICKET_FAILURE";

export const UPDATE_CLIENT_TICKET_REQUEST = "UPDATE_CLIENT_TICKET_REQUEST";
export const UPDATE_CLIENT_TICKET_SUCCESS = "UPDATE_CLIENT_TICKET_SUCCESS";
export const UPDATE_CLIENT_TICKET_FAILURE = "UPDATE_CLIENT_TICKET_FAILURE";

export const FETCH_DASHBOARD_REQUEST = "FETCH_DASHBOARD_REQUEST";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";

export const FETCH_TODOS_REQUEST = "FETCH_TODOS_REQUEST";
export const FETCH_TODOS_SUCCESS = "FETCH_TODOS_SUCCESS";
export const FETCH_TODOS_FAILURE = "FETCH_TODOS_FAILURE";

export const ADD_TODOS_REQUEST = "ADD_TODOS_REQUEST";
export const ADD_TODOS_SUCCESS = "ADD_TODOS_SUCCESS";
export const ADD_TODOS_FAILURE = "ADD_TODOS_FAILURE";

export const UPDATE_TODOS_REQUEST = "UPDATE_TODOS_REQUEST";
export const UPDATE_TODOS_SUCCESS = "UPDATE_TODOS_SUCCESS";
export const UPDATE_TODOS_FAILURE = "UPDATE_TODOS_FAILURE";

export const FETCH_NOTES_REQUEST = "FETCH_NOTES_REQUEST";
export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";
export const FETCH_NOTES_FAILURE = "FETCH_NOTES_FAILURE";

export const ADD_NOTE_REQUEST = "ADD_NOTE_REQUEST";
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";
export const ADD_NOTE_FAILURE = "ADD_NOTE_FAILURE";

export const FETCH_CALENDAR_REQUEST = "FETCH_CALENDAR_REQUEST";
export const FETCH_CALENDAR_SUCCESS = "FETCH_CALENDAR_SUCCESS";
export const FETCH_CALENDAR_FAILURE = "FETCH_CALENDAR_FAILURE";

export const UPDATE_CALENDAR_REQUEST = "UPDATE_CALENDAR_REQUEST";
export const UPDATE_CALENDAR_SUCCESS = "UPDATE_CALENDAR_SUCCESS";
export const UPDATE_CALENDAR_FAILURE = "UPDATE_CALENDAR_FAILURE";

export const FETCH_NOTIFICATION_REQUEST = "FETCH_NOTIFICATION_REQUEST";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAILURE = "FETCH_NOTIFICATION_FAILURE";

export const FETCH_CALENDAR_TODOS_SUCCESS = "FETCH_CALENDAR_TODOS_SUCCESS";
export const DEVIDE_TODOS_SUCCESS = "DEVIDE_TODOS_SUCCESS";

export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILURE = "UPDATE_NOTIFICATION_FAILURE";

export const UPDATE_NOTIFICATIONS_REQUEST = "UPDATE_NOTIFICATIONS_REQUEST";
export const UPDATE_NOTIFICATIONS_SUCCESS = "UPDATE_NOTIFICATIONS_SUCCESS";
export const UPDATE_NOTIFICATIONS_FAILURE = "UPDATE_NOTIFICATIONS_FAILURE";

export const FETCH_PTODOS_REQUEST = "FETCH_PTODOS_REQUEST";
export const FETCH_PTODOS_SUCCESS = "FETCH_PTODOS_SUCCESS";
export const FETCH_PTODOS_FAILURE = "FETCH_PTODOS_FAILURE";

export const ADD_PTODO_REQUEST = "ADD_PTODO_REQUEST";
export const ADD_PTODO_SUCCESS = "ADD_PTODO_SUCCESS";
export const ADD_PTODO_FAILURE = "ADD_PTODO_FAILURE";

export const UPDATE_PTODO_REQUEST = "UPDATE_PTODO_REQUEST";
export const UPDATE_PTODO_SUCCESS = "UPDATE_PTODO_SUCCESS";
export const UPDATE_PTODO_FAILURE = "UPDATE_PTODO_FAILURE";

export const DELETE_PTODO_REQUEST = "DELETE_PTODO_REQUEST";
export const DELETE_PTODO_SUCCESS = "DELETE_PTODO_SUCCESS";
export const DELETE_PTODO_FAILURE = "DELETE_PTODO_FAILURE";

export const ADD_TODO_SUCCESS = "ADD_TODO_SUCCESS";
export const DELETE_CALENDARTODO_SUCCESS = "DELETE_CALENDARTODO_SUCCESS";
export const UNDRAG_CALENDARTODO_SUCCESS = "UNDRAG_CALENDARTODO_SUCCESS";

export const FETCH_ECALENDARS_REQUEST = "FETCH_ECALENDARS_REQUEST";
export const FETCH_ECALENDARS_SUCCESS = "FETCH_ECALENDARS_SUCCESS";
export const FETCH_ECALENDARS_FAILURE = "FETCH_ECALENDARS_FAILURE";

export const ADD_ECALENDAR_REQUEST = "ADD_ECALENDAR_REQUEST";
export const ADD_ECALENDAR_SUCCESS = "ADD_ECALENDAR_SUCCESS";
export const ADD_ECALENDAR_FAILURE = "ADD_ECALENDAR_FAILURE";

export const UPDATE_ECALENDAR_REQUEST = "UPDATE_ECALENDAR_REQUEST";
export const UPDATE_ECALENDAR_SUCCESS = "UPDATE_ECALENDAR_SUCCESS";
export const UPDATE_ECALENDAR_FAILURE = "UPDATE_ECALENDAR_FAILURE";

export const DELETE_ECALENDAR_REQUEST = "DELETE_ECALENDAR_REQUEST";
export const DELETE_ECALENDAR_SUCCESS = "DELETE_ECALENDAR_SUCCESS";
export const DELETE_ECALENDAR_FAILURE = "DELETE_ECALENDAR_FAILURE";

export const FETCH_ORDER_REQUEST = "FETCH_ORDER_REQUEST";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAILURE = "FETCH_ORDER_FAILURE";

export const FETCH_ECALENDAR_REQUEST = "FETCH_ECALENDAR_REQUEST";
export const FETCH_ECALENDAR_SUCCESS = "FETCH_ECALENDAR_SUCCESS";
export const FETCH_ECALENDAR_FAILURE = "FETCH_ECALENDAR_FAILURE";

export const FETCH_POSTS_REQUEST = "FETCH_POSTS_REQUEST";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_FAILURE = "FETCH_POSTS_FAILURE";

export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export const POST_ADD_REQUEST = "POST_ADD_REQUEST";
export const POST_ADD_SUCCESS = "POST_ADD_SUCCESS";
export const POST_ADD_FAILURE = "POST_ADD_FAILURE";

export const POST_UPDATE_REQUEST = "POST_UPDATE_REQUEST";
export const POST_UPDATE_SUCCESS = "POST_UPDATE_SUCCESS";
export const POST_UPDATE_FAILURE = "POST_UPDATE_FAILURE";

export const FETCH_POST_REQUEST = "FETCH_POST_REQUEST";
export const FETCH_POST_SUCCESS = "FETCH_POST_SUCCESS";
export const FETCH_POST_FAILURE = "FETCH_POST_FAILURE";

export const FETCH_COMMUNITY_POSTS_REQUEST = "FETCH_COMMUNITY_POSTS_REQUEST";
export const FETCH_COMMUNITY_POSTS_SUCCESS = "FETCH_COMMUNITY_POSTS_SUCCESS";
export const FETCH_COMMUNITY_POSTS_FAILURE = "FETCH_COMMUNITY_POSTS_FAILURE";

export const DELETE_PRODUCT_TODO_REQUEST = "DELETE_PRODUCT_TODO_REQUEST";
export const DELETE_PRODUCT_TODO_SUCCESS = "DELETE_PRODUCT_TODO_SUCCESS";
export const DELETE_PRODUCT_TODO_FAILURE = "DELETE_PRODUCT_TODO_FAILURE";

export const FETCH_EMAILS_REQUEST = "FETCH_EMAILS_REQUEST";
export const FETCH_EMAILS_SUCCESS = "FETCH_EMAILS_SUCCESS";
export const FETCH_EMAILS_FAILURE = "FETCH_EMAILS_FAILURE";

export const DELETE_EMAIL = "DELETE_EMAIL";

export const FETCH_EMAIL_REQUEST = "FETCH_EMAIL_REQUEST";
export const FETCH_EMAIL_SUCCESS = "FETCH_EMAIL_SUCCESS";
export const FETCH_EMAIL_FAILURE = "FETCH_EMAIL_FAILURE";

export const ADD_EMAIL_REQUEST = "ADD_EMAIL_REQUEST";
export const ADD_EMAIL_SUCCESS = "ADD_EMAIL_SUCCESS";
export const ADD_EMAIL_FAILURE = "ADD_EMAIL_FAILURE";

export const UPDATE_EMAIL_REQUEST = "UPDATE_EMAIL_REQUEST";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_FAILURE = "UPDATE_EMAIL_FAILURE";

export const FETCH_IA_SUGGESTIONS_REQUEST = "FETCH_IA_SUGGESTIONS_REQUEST";
export const FETCH_IA_SUGGESTIONS_SUCCESS = "FETCH_IA_SUGGESTIONS_SUCCESS";
export const FETCH_IA_SUGGESTIONS_FAILURE = "FETCH_IA_SUGGESTIONS_FAILURE";

export const FETCH_IA_SUGGESTION_REQUEST = "FETCH_IA_SUGGESTION_REQUEST";
export const FETCH_IA_SUGGESTION_SUCCESS = "FETCH_IA_SUGGESTION_SUCCESS";
export const FETCH_IA_SUGGESTION_FAILURE = "FETCH_IA_SUGGESTION_FAILURE";

export const FETCH_IA_SUGGESTION_SUBJECT_SUCCESS = "FETCH_IA_SUGGESTION_SUBJECT_SUCCESS";
export const FETCH_IA_SUGGESTION_SUBJECT_REQUEST = "FETCH_IA_SUGGESTION_SUBJECT_REQUEST";
export const FETCH_IA_SUGGESTION_SUBJECT_FAILURE = "FETCH_IA_SUGGESTION_SUBJECT_FAILURE";

export const REMOVE_IA_SUGGESTIONS_SUCCESS = "REMOVE_IA_SUGGESTIONS_SUCCESS";
export const REMOVE_IA_SUGGESTION_SUBJECT_SUCCESS =
  "REMOVE_IA_SUGGESTION_SUBJECT_SUCCESS";

export const FETCH_SURVEY_QUESTIONS_REQUEST = "FETCH_SURVEY_QUESTIONS_REQUEST";
export const FETCH_SURVEY_QUESTIONS_SUCCESS = "FETCH_SURVEY_QUESTIONS_SUCCESS";
export const FETCH_SURVEY_QUESTIONS_FAILURE = "FETCH_SURVEY_QUESTIONS_FAILURE";

export const FETCH_PRODUCT_SURVEY_QUESTIONS_REQUEST =
  "FETCH_PRODUCT_SURVEY_QUESTIONS_REQUEST";
export const FETCH_PRODUCT_SURVEY_QUESTIONS_SUCCESS =
  "FETCH_PRODUCT_SURVEY_QUESTIONS_SUCCESS";
export const FETCH_PRODUCT_SURVEY_QUESTIONS_FAILURE =
  "FETCH_PRODUCT_SURVEY_QUESTIONS_FAILURE";

export const ADD_SURVEY_QUESTION_REQUEST = "ADD_SURVEY_QUESTION_REQUEST";
export const ADD_SURVEY_QUESTION_SUCCESS = "ADD_SURVEY_QUESTION_SUCCESS";
export const ADD_SURVEY_QUESTION_FAILURE = "ADD_SURVEY_QUESTION_FAILURE";

export const UPDATE_SURVEY_QUESTION_REQUEST = "UPDATE_SURVEY_QUESTION_REQUEST";
export const UPDATE_SURVEY_QUESTION_SUCCESS = "UPDATE_SURVEY_QUESTION_SUCCESS";
export const UPDATE_SURVEY_QUESTION_FAILURE = "UPDATE_SURVEY_QUESTION_FAILURE";

export const DELETE_SURVEY_QUESTION_REQUEST = "DELETE_SURVEY_QUESTION_REQUEST";
export const DELETE_SURVEY_QUESTION_SUCCESS = "DELETE_SURVEY_QUESTION_SUCCESS";
export const DELETE_SURVEY_QUESTION_FAILURE = "DELETE_SURVEY_QUESTION_FAILURE";

export const DELETE_SURVEY_QUESTION_FROM_PRODUCT_REQUEST =
  "DELETE_SURVEY_QUESTION_FROM_PRODUCT_REQUEST";
export const DELETE_SURVEY_QUESTION_FROM_PRODUCT_SUCCESS =
  "DELETE_SURVEY_QUESTION_FROM_PRODUCT_SUCCESS";
export const DELETE_SURVEY_QUESTION_FROM_PRODUCT_FAILURE =
  "DELETE_SURVEY_QUESTION_FROM_PRODUCT_FAILURE";

export const ADD_SURVEY_QUESTION_TO_PRODUCT_REQUEST =
  "ADD_SURVEY_QUESTION_TO_PRODUCT_REQUEST";
export const ADD_SURVEY_QUESTION_TO_PRODUCT_SUCCESS =
  "ADD_SURVEY_QUESTION_TO_PRODUCT_SUCCESS";
export const ADD_SURVEY_QUESTION_TO_PRODUCT_FAILURE =
  "ADD_SURVEY_QUESTION_TO_PRODUCT_FAILURE";

export const GENERATE_SURVEY_REQUEST = "GENERATE_SURVEY_REQUEST";
export const GENERATE_SURVEY_SUCCESS = "GENERATE_SURVEY_SUCCESS";
export const GENERATE_SURVEY_FAILURE = "GENERATE_SURVEY_FAILURE";

export const FETCH_TINY_CLIENTS_REQUEST = "FETCH_TINY_CLIENTS_REQUEST";
export const FETCH_TINY_CLIENTS_SUCCESS = "FETCH_TINY_CLIENTS_SUCCESS";
export const FETCH_TINY_CLIENTS_FAILURE = "FETCH_TINY_CLIENTS_FAILURE";

export const FETCH_DASHBOARD_CLIENTS_REQUEST = "FETCH_DASHBOARD_CLIENTS_REQUEST";
export const FETCH_DASHBOARD_CLIENTS_SUCCESS = "FETCH_DASHBOARD_CLIENTS_SUCCESS";
export const FETCH_DASHBOARD_CLIENTS_FAILURE = "FETCH_DASHBOARD_CLIENTS_FAILURE";

export const UPLOAD_ORDER_DOCUMENT_REQUEST = "UPLOAD_ORDER_DOCUMENT_REQUEST";
export const UPLOAD_ORDER_DOCUMENT_SUCCESS = "UPLOAD_ORDER_DOCUMENT_SUCCESS";
export const UPLOAD_ORDER_DOCUMENT_FAILURE = "UPLOAD_ORDER_DOCUMENT_FAILURE";

export const FETCH_MULTI_DOCUMENTS_REQUEST = "FETCH_MULTI_DOCUMENTS_REQUEST";
export const FETCH_MULTI_DOCUMENTS_SUCCESS = "FETCH_MULTI_DOCUMENTS_SUCCESS";
export const FETCH_MULTI_DOCUMENTS_FAILURE = "FETCH_MULTI_DOCUMENTS_FAILURE";

export const FETCH_DRAFTS_REQUEST = "FETCH_DRAFTS_REQUEST";
export const FETCH_DRAFTS_SUCCESS = "FETCH_DRAFTS_SUCCESS";
export const FETCH_DRAFTS_FAILURE = "FETCH_DRAFTS_FAILURE";

export const ADD_DRAFT_REQUEST = "ADD_DRAFT_REQUEST";
export const ADD_DRAFT_SUCCESS = "ADD_DRAFT_SUCCESS";
export const ADD_DRAFT_FAILURE = "ADD_DRAFT_FAILURE";

export const UPDATE_DRAFT_REQUEST = "UPDATE_DRAFT_REQUEST";
export const UPDATE_DRAFT_SUCCESS = "UPDATE_DRAFT_SUCCESS";
export const UPDATE_DRAFT_FAILURE = "UPDATE_DRAFT_FAILURE";

export const UPDATE_POSITIONS_DRAFT_REQUEST = "UPDATE_POSITIONS_DRAFT_REQUEST";
export const UPDATE_POSITIONS_DRAFT_SUCCESS = "UPDATE_POSITIONS_DRAFT_SUCCESS";
export const UPDATE_POSITIONS_DRAFT_FAILURE = "UPDATE_POSITIONS_DRAFT_FAILURE";

export const UPDATE_BRIEF_REQUEST = "UPDATE_BRIEF_REQUEST";
export const UPDATE_BRIEF_SUCCESS = "UPDATE_BRIEF_SUCCESS";
export const UPDATE_BRIEF_FAILURE = "UPDATE_BRIEF_FAILURE";

export const DELETE_DRAFT_REQUEST = "DELETE_DRAFT_REQUEST";
export const DELETE_DRAFT_SUCCESS = "DELETE_DRAFT_SUCCESS";
export const DELETE_DRAFT_FAILURE = "DELETE_DRAFT_FAILURE";

export const GENERATE_QUALITY_CRITERIONS_REQUEST = "GENERATE_QUALITY_CRITERIONS_REQUEST";
export const GENERATE_QUALITY_CRITERIONS_SUCCESS = "GENERATE_QUALITY_CRITERIONS_SUCCESS";
export const GENERATE_QUALITY_CRITERIONS_FAILURE = "GENERATE_QUALITY_CRITERIONS_FAILURE";

export const GENERATE_BRIEF_QUESTIONS_REQUEST = "GENERATE_BRIEF_QUESTIONS_REQUEST";
export const GENERATE_BRIEF_QUESTIONS_SUCCESS = "GENERATE_BRIEF_QUESTIONS_SUCCESS";
export const GENERATE_BRIEF_QUESTIONS_FAILURE = "GENERATE_BRIEF_QUESTIONS_FAILURE";

export const FETCH_GOOGLE_ACCOUNTS_REQUEST = "FETCH_GOOGLE_ACCOUNTS_REQUEST";
export const FETCH_GOOGLE_ACCOUNTS_SUCCESS = "FETCH_GOOGLE_ACCOUNTS_SUCCESS";
export const FETCH_GOOGLE_ACCOUNTS_FAILURE = "FETCH_GOOGLE_ACCOUNTS_FAILURE";

export const FETCH_GOOGLE_MCCS_REQUEST = "FETCH_GOOGLE_MCCS_REQUEST";
export const FETCH_GOOGLE_MCCS_SUCCESS = "FETCH_GOOGLE_MCCS_SUCCESS";
export const FETCH_GOOGLE_MCCS_FAILURE = "FETCH_GOOGLE_MCCS_FAILURE";

export const FETCH_ADS_REPORTS_REQUEST = "FETCH_ADS_REPORTS_REQUEST";
export const FETCH_ADS_REPORTS_SUCCESS = "FETCH_ADS_REPORTS_SUCCESS";
export const FETCH_ADS_REPORTS_FAILURE = "FETCH_ADS_REPORTS_FAILURE";

export const FETCH_CLIENT_ADS_REPORTS_REQUEST = "FETCH_CLIENT_ADS_REPORTS_REQUEST";
export const FETCH_CLIENT_ADS_REPORTS_SUCCESS = "FETCH_CLIENT_ADS_REPORTS_SUCCESS";
export const FETCH_CLIENT_ADS_REPORTS_FAILURE = "FETCH_CLIENT_ADS_REPORTS_FAILURE";

export const FETCH_SURVEY_REQUEST = "FETCH_SURVEY_REQUEST";
export const FETCH_SURVEY_SUCCESS = "FETCH_SURVEY_SUCCESS";
export const FETCH_SURVEY_FAILURE = "FETCH_SURVEY_FAILURE";

export const DOWNLOADING_DOCUMENT_REQUEST = "DOWNLOADING_DOCUMENT_REQUEST";
export const DOWNLOADING_DOCUMENT_SUCCESS = "DOWNLOADING_DOCUMENT_SUCCESS";
export const DOWNLOADING_DOCUMENT_FAILURE = "DOWNLOADING_DOCUMENT_FAILURE";

export const FETCH_CLIENT_ORDER_WITH_NOTES_REQUEST =
  "FETCH_CLIENT_ORDER_WITH_NOTES_REQUEST";
export const FETCH_CLIENT_ORDER_WITH_NOTES_SUCCESS =
  "FETCH_CLIENT_ORDER_WITH_NOTES_SUCCESS";
export const FETCH_CLIENT_ORDER_WITH_NOTES_FAILURE =
  "FETCH_CLIENT_ORDER_WITH_NOTES_FAILURE";

export const FETCH_CALENDAR_POSTS_SUCCESS = "FETCH_CALENDAR_POSTS_SUCCESS";

export const FETCH_IA_ITERPRETATION_REQUEST = "FETCH_IA_ITERPRETATION_REQUEST";
export const FETCH_IA_ITERPRETATION_SUCCESS = "FETCH_IA_ITERPRETATION_SUCCESS";
export const FETCH_IA_ITERPRETATION_FAILURE = "FETCH_IA_ITERPRETATION_FAILURE";

export const LOAD_AGENCY_REQUEST = "LOAD_AGENCY_REQUEST";
export const LOAD_AGENCY_SUCCESS = "LOAD_AGENCY_SUCCESS";
export const LOAD_AGENCY_FAILURE = "LOAD_AGENCY_FAILURE";

export const FETCH_LAST_NOTE_REQUEST = "FETCH_LAST_NOTE_REQUEST";
export const FETCH_LAST_NOTE_SUCCESS = "FETCH_LAST_NOTE_SUCCESS";
export const FETCH_LAST_NOTE_FAILURE = "FETCH_LAST_NOTE_FAILURE";

export const LOAD_DEPARTMENT_LABELS_SUCCESS = "LOAD_DEPARTMENT_LABELS_SUCCESS";
export const LOAD_DEPARTMENT_LABELS_FAILURE = "LOAD_DEPARTMENT_LABELS_FAILURE";

export const CREATE_DEPARTMENT_LABELS_SUCCESS = "CREATE_DEPARTMENT_LABELS_SUCCESS";
export const CREATE_DEPARTMENT_LABELS_FAILURE = "CREATE_DEPARTMENT_LABELS_FAILURE";

export const LOAD_DEPARTMENT_LABEL_SUCCESS = "LOAD_DEPARTMENT_LABEL_SUCCESS";
export const LOAD_DEPARTMENT_LABEL_FAILURE = "LOAD_DEPARTMENT_LABEL_FAILURE";

export const ESTIMATE_ORDER_REQUEST = "ESTIMATE_ORDER_REQUEST";
export const ESTIMATE_ORDER_SUCCESS = "ESTIMATE_ORDER_SUCCESS";
export const ESTIMATE_ORDER_UNAUTHORIZED = "ESTIMATE_ORDER_UNAUTHORIZED";
export const ESTIMATE_ORDER_FAILURE = "ESTIMATE_ORDER_FAILURE";

export const FETCH_MEETING_BY_ID_REQUEST = "FETCH_MEETING_BY_ID_REQUEST";
export const FETCH_MEETING_BY_ID_SUCCESS = "FETCH_MEETING_BY_ID_SUCCESS";
export const FETCH_MEETING_BY_ID_FAILURE = "FETCH_MEETING_BY_ID_FAILURE";

export const FETCH_MEETINGS_REQUEST = "FETCH_MEETINGS_REQUEST";
export const FETCH_MEETINGS_SUCCESS = "FETCH_MEETINGS_SUCCESS";
export const FETCH_MEETINGS_FAILURE = "FETCH_MEETINGS_FAILURE";
