import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import BarChart from "./charts/BarChart";
import AreaChart from "./charts/AreaChart";
import { useTable, useSortBy, usePagination } from "react-table";
import Pagination from "./Pagination";

const Gads = ({ gads_insight }) => {
  const keywordData = gads_insight.keywordData;
  const columns = useMemo(
    () => [
      {
        Header: "Mot clé",
        accessor: "keyword",
      },
      {
        Header: "Impression",
        accessor: "impressions",
        getCellProps: () => ({
          className: "text-uppercase  font-weight-bolder opacity-7 text-end",
        }),
      },
      {
        Header: "Clicks",
        accessor: "clicks",
        getCellProps: () => ({
          className: "text-uppercase  font-weight-bolder opacity-7 text-end",
        }),
      },
      {
        Header: "Cpc",
        accessor: "cpc",
        getCellProps: () => ({
          className: "text-uppercase  font-weight-bolder opacity-7 text-end",
        }),
        Cell: ({ value }) => `${Number(parseFloat(value).toFixed(2))} €`,
        sortType: (rowA, rowB, columnName, desc) => {
          let a = parseFloat(rowA.original.cpc);
          let b = parseFloat(rowB.original.cpc);
          return a > b ? 1 : -1;
        },
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: keywordData,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );
  return (
    <>
      <Row>
        <Col md={6}>
          {" "}
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header">
              <h6>
                Impression : {gads_insight.totalDataThirtyDays.impressions}{" "}
                <small className="text-uppercase  text-xs font-weight-bolder  broun-text">
                  (30 derniers jours)
                </small>
              </h6>
              <p className="text-sm broun-text mb-0">
                Les impressions représentent le nombre total de fois où votre publicité a
                été affichée.
              </p>
            </div>
            <div className="card-body">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase  text-sm">Historique Par mois</small>
                  {gads_insight.periode === "monthly" ? (
                    <BarChart
                      color="#4285F4"
                      dataType="impressions"
                      data={gads_insight.campaignPeriodData}
                    />
                  ) : (
                    <AreaChart
                      color="#4285F4"
                      dataType="impressions"
                      data={gads_insight.campaignPeriodData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {" "}
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header">
              <h6>
                Clics : {gads_insight.totalDataThirtyDays.clicks}{" "}
                <small className="text-uppercase text-xs font-weight-bolder broun-text">
                  (30 derniers jours)
                </small>
              </h6>
              <p className="text-sm broun-text mb-0">
                Les clics représentent le nombre total de fois où les utilisateurs ont
                interagi avec votre publicité.
              </p>
            </div>
            <div className="card-body">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase text-sm">Historique Par mois</small>

                  {gads_insight.periode === "monthly" ? (
                    <BarChart
                      color="#DB4437"
                      dataType="clicks"
                      data={gads_insight.campaignPeriodData}
                    />
                  ) : (
                    <AreaChart
                      color="#DB4437"
                      dataType="clicks"
                      data={gads_insight.campaignPeriodData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {" "}
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header">
              <h6>
                CPC moyen :{" "}
                {Number(
                  parseFloat(gads_insight.totalDataThirtyDays.averageCpc).toFixed(3)
                )}
                {"€ "}
                <small className="text-uppercase text-xs font-weight-bolder broun-text">
                  (30 derniers jours)
                </small>
              </h6>
              <p className="text-sm broun-text mb-0">
                Le coût par clic (CPC) représente le coût moyen payé pour chaque clic reçu
                sur votre publicité.
              </p>
            </div>
            <div className="card-body">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase text-sm">Historique Par mois</small>

                  {gads_insight.periode === "monthly" ? (
                    <BarChart
                      color="#F4B400"
                      dataType="averageCpc"
                      data={gads_insight.campaignPeriodData}
                    />
                  ) : (
                    <AreaChart
                      color="#F4B400"
                      dataType="averageCpc"
                      data={gads_insight.campaignPeriodData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {" "}
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header">
              <h6>
                CTR :{" "}
                {Number(
                  parseFloat(gads_insight.totalDataThirtyDays.averageCtr).toFixed(2)
                )}
                {"% "}
                <small className="text-uppercase text-xs font-weight-bolder broun-text">
                  (30 derniers jours)
                </small>
              </h6>
              <p className="text-sm broun-text mb-0">
                Le taux de clics (CTR) est le pourcentage de clics reçus par rapport au
                nombre d'impressions.
              </p>
            </div>
            <div className="card-body">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase text-sm">Historique Par mois</small>

                  {gads_insight.periode === "monthly" ? (
                    <BarChart
                      color="#0F9D58"
                      dataType="averageCtr"
                      data={gads_insight.campaignPeriodData}
                    />
                  ) : (
                    <AreaChart
                      color="#0F9D58"
                      dataType="averageCtr"
                      data={gads_insight.campaignPeriodData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          {" "}
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header">
              <h6>
                Investissement :{" "}
                {Number(
                  parseFloat(gads_insight.totalDataThirtyDays.totalCost).toFixed(2)
                )}
                {"€ "}
                <small className="text-uppercase text-xs font-weight-bolder broun-text">
                  (30 derniers jours)
                </small>
              </h6>
              <p className="text-sm broun-text mb-0">
                Dépense publicitaire pendant la période.
              </p>
            </div>
            <div className="card-body">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase text-sm">Historique Par mois</small>

                  {gads_insight.periode === "monthly" ? (
                    <BarChart
                      color="#4285F4"
                      dataType="totalCost"
                      data={gads_insight.campaignPeriodData}
                    />
                  ) : (
                    <AreaChart
                      color="#4285F4"
                      dataType="totalCost"
                      data={gads_insight.campaignPeriodData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Col md={12}>
        {" "}
        <div className="card medium-card mb-4 pb-2 purple-border">
          <div className="card-header">
            <h6>Performance des Mots Clés</h6>
            <p className="text-sm broun-text mb-0">
              Cliquez sur le titre de la colonne pour trier les données
            </p>
          </div>
          <div className="card-body">
            <Row className="mb-3">
              <Col md={12}>
                <table {...getTableProps()} className="table align-items-center mb-0">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}

                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  gotoPage={gotoPage}
                  length={keywordData.length}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Gads;
