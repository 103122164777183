import moment from "moment";

export function convertMinutesToHoursAndMinutes(minutes) {
  const duration = moment.duration(minutes, "minutes");
  const totalHours = duration.asHours();
  const hours = Math.floor(totalHours);
  const minutesRemaining = Math.round((totalHours - hours) * 60);

  return `${hours}h${minutesRemaining}`;
}

export function getMinutesDifference(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  const duration = moment.duration(end.diff(start));
  const minutes = duration.asMinutes();

  return minutes;
}

export const dayPropGetter = (date) => {
  const dayStyle = {};

  if (date.getDay() === 0 || date.getDay() === 6) {
    dayStyle.className = "disabled-day";
  }

  return dayStyle;
};

export const eventPropGetter = (event, start, end, isSelected) => {
  const classNames = ["custom-event", event.flexibility];
  const style = {};

  if (event.done) {
    classNames.push("done-event");
  }

  if (event.meet) {
    classNames.push("with-meet");
  }

  classNames.push(event.planned ? "planned-event" : "unplanned-event");

  // if (event.product && event.product.color) {
  //   style.borderColor = event.product.color;
  // }

  // classNames.push(can_manage_calendar ? "isDraggable" : "nonDraggable");

  return {
    className: classNames.join(" "),
    style: style,
  };
};
