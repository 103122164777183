import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Col } from "react-bootstrap";

const Card = ({ undesigned, title, data, layoutId, setData, setLayoutId, xs }) => {
  return (
    <>
      {undesigned ? (
        <UnDesignedCard
          title={title}
          data={data}
          layoutId={layoutId}
          setData={setData}
          setLayoutId={setLayoutId}
          xs={xs}
        />
      ) : (
        <DesignedCard
          title={title}
          data={data}
          layoutId={layoutId}
          setData={setData}
          setLayoutId={setLayoutId}
          xs={xs}
        />
      )}
    </>
  );
};

const DesignedCard = ({ title, data, layoutId, setData, setLayoutId, xs }) => {
  return (
    <AnimatePresence initial={false} mode="wait">
      <Col xs={xs} className={`mb-1 product-card-item `}>
        {/* ${
          data.list.length === 0 && data.code.includes("new") ? "hide" : ""
        } */}
        <motion.div
          layoutId={layoutId}
          className={`card card-plain dashboard-card my-1 primary-hover-card card-${
            data.code
          } ${data.list.length ? "full" : "empty"} `}
          whileHover={{
            scale: 1.025,
            transition: {
              duration: 0.2,
            },
          }}
        >
          <motion.div
            className={`card-body align-items-center flex justify-content-between px-2 ${
              ["completed", "progress_pending"].includes(data.code) ? "py-1" : "py-2"
            }`}
          >
            <motion.div>
              <div className="numbers">
                <p className="text-sm mb-0 mx-1 text-capitalize font-weight-bold text-dark">
                  {title}
                </p>
              </div>
            </motion.div>
            <motion.div className="text-center">
              <div
                className={`icon icon-shape product-status-number ${
                  data.list.length ? "bg-icon-custom" : "bg-icon-nil"
                }  text-center border-radius-2xl`}
                onClick={() => {
                  setData(data);
                  setLayoutId(layoutId);
                }}
              >
                <i className="opacity-10 text-white" aria-hidden="true">
                  {data.list.length}
                </i>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      </Col>
    </AnimatePresence>
  );
};

const UnDesignedCard = ({ title, data, layoutId, setData, setLayoutId, xs }) => {
  return (
    <AnimatePresence initial={false} mode="wait">
      <Col xs={xs} className="mb-1 product-card-item">
        <motion.div
          layoutId={layoutId}
          className={`cursor-pointer ${data.list.length ? "full" : "empty"} `}
          whileHover={{
            scale: 1.025,
            transition: {
              duration: 0.2,
            },
          }}
          onClick={() => {
            setData(data);
            setLayoutId(layoutId);
          }}
        >
          <motion.div>
            <motion.div>
              <div className="numbers">
                <p className="text-sm mb-0 mx-1 text-capitalize font-weight-bold text-dark">
                  {title} <i aria-hidden="true">{data.list.length}</i>
                </p>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      </Col>
    </AnimatePresence>
  );
};

export default Card;
