import { useEffect, useContext, useState, useRef } from "react";
import { NavContext } from "../../NavContext";
import { Col, Overlay, Row, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { fetchMeetings } from "../../../redux/actions/meetingActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faUsers, faVideo } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import moment from "moment";

const Meetings = () => {
  const dispatch = useDispatch();
  const meetings = useSelector((state) => state.meetingReducer.meetings);
  const error = useSelector((state) => state.meetingReducer.error);
  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    document.title = "ProdOTop - Meetings";
  }, []);

  useEffect(() => {
    setActiveNav("meet");
  }, [setActiveNav]);

  useEffect(() => {
    dispatch(fetchMeetings());
  }, [dispatch]);

  return (
    <>
      <div className="pb-3">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              meetings
            </li>
          </ol>
        </nav>
      </div>

      <Row>
        <Col md={12} className="my-3">
          <h6>Réunion à venir</h6>
        </Col>
        <Col md={12} className={`flex flex-wrap gap-4 justify-content-center`}>
          {meetings.map((meet) => (
            <div key={meet.meetId}>
              <div className="card purple-border card-order client justify-content-center">
                <div className="card-body p-3">
                  <div className="text-center">
                    <div className="pt-4">
                      <h6 className="companyName-title">
                        <span className="product-title text-dark ">
                          {meet.clientCompanyName}
                        </span>
                      </h6>

                      <small className="product-title px-2 text-dark">
                        {moment(meet.start).format("DD-MM-YYYY HH:mm")}
                      </small>
                    </div>
                    <hr />

                    <div className="flex gap-3">
                      {meet.message && (
                        <OverlayInfo
                          info={`Message attaché : ${meet.message}`}
                          icon={faMessage}
                        />
                      )}
                      {meet.participants && (
                        <OverlayInfo
                          info={`Participants : ${meet.participants
                            .map((p) => p.name)
                            .join(", ")}`}
                          icon={faUsers}
                        />
                      )}

                      <Link
                        to={`/meet/${meet.meetId}`}
                        className="btn text-primary bg-primary mt-1 mb-0 px-5"
                      >
                        <FontAwesomeIcon icon={faVideo} color="#fff" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Col>

        <Row className="mb-3">
          <Col>
            <Link className="link-action back" to="/">
              Retour
            </Link>
          </Col>
        </Row>
      </Row>
    </>
  );
};

const OverlayInfo = ({ info, icon }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <button
        ref={target}
        onClick={() => setShow(!show)}
        className="btn text-primary bg-primary mt-1 mb-0 px-5"
      >
        <FontAwesomeIcon icon={icon} color="#fff" />
      </button>

      <Overlay target={target.current} show={show} placement="bottom">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {info}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default Meetings;
