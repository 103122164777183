import axios from "axios";

const API_URL = "/api/notification";

const getNotifications = () => {
  return axios.get(API_URL).then((response) => {
    return response.data;
  });
};
const updateNotifications = () => {
  return axios.post(API_URL).then((response) => {
    return response.data;
  });
};

const updateNotification = (notificationId) => {
  console.log(notificationId);
  return axios.put(`${API_URL}/${notificationId}`).then((response) => {
    return response.data;
  });
};

export const notificationdService = {
  getNotifications,
  updateNotifications,
  updateNotification,
};
