import { Row, Col } from "react-bootstrap";
import Card from "./card";
import DepartmentName from "../../shared/departments/departmentName";

const ProductSmallCard = ({ index, db, setData, setLayoutId }) => {
  return (
    <Col
      xxl={3}
      xl={4}
      lg={6}
      md={6}
      sm={12}
      key={index}
      className={`${
        db.pendingOrders.length === 0 &&
        db.newTeam1Orders.length === 0 &&
        db.newTeam2Orders.length === 0 &&
        db.newTeam3Orders.length === 0 &&
        db.newTeam4Orders.length === 0 &&
        db.newTeam5Orders.length === 0 &&
        db.inProgressOrders.length === 0 &&
        db.producedOrders.length === 0 &&
        db.completedOrders.length === 0
          ? "display-none"
          : ""
      }`}
    >
      <div className="card purple-border mb-4 pb-3">
        <div className="card-body  px-0 pt-0 pb-0">
          <h6 className="px-3 mb-0 pt-3">
            <span className="product-title px-3">{db.product.name}</span>
          </h6>
          <div className="">
            <span
              className="small-rect"
              style={{ backgroundColor: `${db.product.color}` }}
            ></span>
          </div>

          <Row className="mx-2">
            <Col md={12}>
              <p className="text-sm mb-0 mx-1 text-capitalize font-weight-bold text-dark">
                Nouveaux
              </p>
            </Col>
            <Card
              title={<DepartmentName code="Team1" />}
              data={{
                list: db.newTeam1Orders,
                status: "Team1",
                code: "new_1",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-1`}
              setData={setData}
              setLayoutId={setLayoutId}
              xs={6}
            />
            <Card
              title={<DepartmentName code="Team2" />}
              data={{
                list: db.newTeam2Orders,
                status: "Team2",
                code: "new_2",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-11`}
              setData={setData}
              setLayoutId={setLayoutId}
              xs={6}
            />
            <Card
              title={<DepartmentName code="Team3" />}
              data={{
                list: db.newTeam3Orders,
                status: "Team3",
                code: "new_3",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-111`}
              setData={setData}
              setLayoutId={setLayoutId}
              xs={6}
            />
            <Card
              title={<DepartmentName code="Team4" />}
              data={{
                list: db.newTeam4Orders,
                status: "Team4",
                code: "new_4",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-1111`}
              setData={setData}
              setLayoutId={setLayoutId}
              xs={6}
            />
            <Card
              title={<DepartmentName code="Team5" />}
              data={{
                list: db.newTeam5Orders,
                status: "Team5",
                code: "new_5",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-11111`}
              setData={setData}
              setLayoutId={setLayoutId}
              xs={6}
            />

            <Col md={12} className="separator-1"></Col>

            <Card
              title="Demande d'amélioration"
              data={{
                list: db.pendingOrders,
                status: "Demande d'amélioration",
                code: "progress_pending",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-4`}
              setData={setData}
              setLayoutId={setLayoutId}
              xs={12}
            />

            <Card
              title="En production"
              data={{
                list: db.inProgressOrders,
                status: "En production",
                code: "in_progress",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-3`}
              setData={setData}
              setLayoutId={setLayoutId}
              xs={6}
            />

            <Card
              title="Produite"
              data={{
                list: db.producedOrders,
                status: "Produite",
                code: "produced",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-2`}
              setData={setData}
              setLayoutId={setLayoutId}
              xs={6}
            />
            <Col md={12} className="separator-1"></Col>
            <Card
              title="Finalisé"
              data={{
                list: db.completedOrders,
                status: "Finalisé",
                code: "completed",
                product_name: db.product.name,
                product_color: db.product.color,
              }}
              layoutId={`${index}-${db.product._id}-5`}
              setData={setData}
              setLayoutId={setLayoutId}
              undesigned={true}
            />
          </Row>
        </div>
      </div>
    </Col>
  );
};

export default ProductSmallCard;
