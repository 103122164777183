import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList } from "recharts";
import moment from "moment";

const BarChartGraph = ({
  dataType,
  data,
  width = 500,
  percent = false,
  color = "#17c3b2",
}) => {
  const formatDate = (date) => {
    return moment(date).format("MM/YYYY");
  };

  const formatValue = (value) => {
    return Number(parseFloat(value[dataType]).toFixed(2));
  };

  const getMaxValue = () => {
    if (percent) return 100;
    const maxVal = Math.max(...data.map((item) => item[dataType]));
    return maxVal;
  };

  const formatYAxisTick = (tickValue) => {
    return Number(parseFloat(tickValue).toFixed(2));
  };

  return (
    <BarChart width={width} height={350} data={data}>
      <CartesianGrid />
      <XAxis dataKey="date_start" tickFormatter={formatDate} />
      <YAxis domain={[0, getMaxValue()]} tickFormatter={formatYAxisTick} />
      <Tooltip
        formatter={(value) => [value, formatDate]}
        labelFormatter={(label) => formatDate(label)}
      />
      <Bar dataKey={dataType} fill={color}>
        <LabelList dataKey={formatValue} position="center" fill="white" />
      </Bar>
    </BarChart>
  );
};

export default BarChartGraph;
