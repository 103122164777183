import {
  FETCH_MEETING_BY_ID_REQUEST,
  FETCH_MEETING_BY_ID_SUCCESS,
  FETCH_MEETING_BY_ID_FAILURE,
  FETCH_MEETINGS_REQUEST,
  FETCH_MEETINGS_SUCCESS,
  FETCH_MEETINGS_FAILURE,
} from "../actionTypes";
import { meetingService } from "../services/meetingService";
import { toast } from "react-toastify";

// Action Creator pour récupérer la liste des produits
export const fetchMeetingById = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_MEETING_BY_ID_REQUEST });
    try {
      const response = await meetingService.fetchMeetingById(id);
      if (response.code === "success") {
        dispatch({ type: FETCH_MEETING_BY_ID_SUCCESS, payload: response.data });
      }

      if (response.code === "error") {
        dispatch({ type: FETCH_MEETING_BY_ID_FAILURE, payload: response.message });
      }
    } catch (err) {
      toast.error("Something goes wrong !!");
    }
  };
};

export const fetchMeetings = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_MEETINGS_REQUEST });
    try {
      const response = await meetingService.fetchMeetings();
      dispatch({ type: FETCH_MEETINGS_SUCCESS, payload: response });
    } catch (err) {
      dispatch({ type: FETCH_MEETINGS_FAILURE, payload: err });
      toast.error("Something goes wrong !!");
    }
  };
};
