import { useMemo } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTable, useSortBy, usePagination } from "react-table";
import Pagination from "../pagination/Pagination";
import moment from "moment";
import { Link } from "react-router-dom";

const ReportsTable = ({ ads_reports, details }) => {
  const calculateDifferenceAndClass = (newValue, oldValue) => {
    if (oldValue === 0) {
      return { diff: "N/A", className: "" };
    }
    const difference = newValue - oldValue;
    return {
      diff: `${difference.toFixed(2)}`,
      className: difference >= 0 ? "positive" : "negative",
    };
  };

  const columns = useMemo(
    () => [
      {
        Header: "Client",
        accessor: "client.companyName",
        Cell: ({ value, row }) => {
          return (
            <div>
              <div>
                {details ? (
                  value
                ) : (
                  <Link to={`/Ads-Reports/${row.original.client._id}`}>{value}</Link>
                )}
              </div>
              {details && (
                <small>{moment(row.original.createdAt).format("DD-MM-YYYY")}</small>
              )}
            </div>
          );
        },
      },
      {
        Header: "Ads",
        accessor: "campaignType",
        Cell: ({ value }) => (
          <img
            src={`/images/photos/${value}.png`}
            alt={value}
            style={{ width: "30px", height: "auto" }}
          />
        ),
      },
      {
        Header: "Diff. Coût",
        id: "costDiff",
        accessor: (data) => {
          const { diff } = calculateDifferenceAndClass(
            data.budgetCurrent,
            data.budgetPrev
          );
          return parseFloat(diff);
        },
        Cell: ({ value, row }) => {
          const { className } = calculateDifferenceAndClass(
            row.original.budgetCurrent,
            row.original.budgetPrev
          );
          return (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="button-tooltip-2">
                  <div>Période Prev : {row.original.budgetPrev} €</div>
                  <div>Période New : {row.original.budgetCurrent} €</div>
                </Tooltip>
              }
            >
              <span className={className}>{value} €</span>
            </OverlayTrigger>
          );
        },
        sortType: (rowA, rowB, id, desc) => {
          return rowA.values[id] > rowB.values[id] ? 1 : -1;
        },
      },
      {
        Header: "Diff. Impressions",
        id: "impressionDiff",
        accessor: (data) => {
          const { diff } = calculateDifferenceAndClass(
            data.impressionCurrent,
            data.impressionPrev
          );
          return parseFloat(diff);
        },
        Cell: ({ value, row }) => {
          const { className } = calculateDifferenceAndClass(
            row.original.impressionCurrent,
            row.original.impressionPrev
          );
          return (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="button-tooltip-2">
                  <div>Période Prev : {row.original.impressionPrev}</div>
                  <div>Période New : {row.original.impressionCurrent}</div>
                </Tooltip>
              }
            >
              <span className={className}>{value}</span>
            </OverlayTrigger>
          );
        },
        sortType: (rowA, rowB, id, desc) => {
          return rowA.values[id] > rowB.values[id] ? 1 : -1;
        },
      },
      {
        Header: "Diff. Clics",
        id: "clickDiff",
        accessor: (data) => {
          const { diff } = calculateDifferenceAndClass(data.clickCurrent, data.clickPrev);
          return parseFloat(diff);
        },
        Cell: ({ value, row }) => {
          const { className } = calculateDifferenceAndClass(
            row.original.clickCurrent,
            row.original.clickPrev
          );
          return (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="button-tooltip-2">
                  <div>Période Prev : {row.original.clickPrev}</div>
                  <div>Période New : {row.original.clickCurrent}</div>
                </Tooltip>
              }
            >
              <span className={className}>{value}</span>
            </OverlayTrigger>
          );
        },
        sortType: (rowA, rowB, id, desc) => {
          return rowA.values[id] > rowB.values[id] ? 1 : -1;
        },
      },
      {
        Header: "Diff. CTR",
        id: "ctrDiff",
        accessor: (data) => {
          const { diff } = calculateDifferenceAndClass(data.ctrCurrent, data.ctrPrev);
          return parseFloat(diff);
        },
        Cell: ({ value, row }) => {
          const { className } = calculateDifferenceAndClass(
            row.original.ctrCurrent,
            row.original.ctrPrev
          );
          return (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="button-tooltip-2">
                  <div>Période Prev : {row.original.ctrPrev}</div>
                  <div>Période New : {row.original.ctrCurrent}</div>
                </Tooltip>
              }
            >
              <span className={className}>{value}</span>
            </OverlayTrigger>
          );
        },
        sortType: (rowA, rowB, id, desc) => {
          return rowA.values[id] > rowB.values[id] ? 1 : -1;
        },
      },
      {
        Header: "Cpc Prev",
        accessor: "cpcPrev",
        getCellProps: () => ({
          className: "text-uppercase  font-weight-bolder opacity-7 text-end",
        }),
        Cell: ({ value }) => `${Number(parseFloat(value).toFixed(2))}€`,
      },
      {
        Header: "Cpc New",
        accessor: "cpcCurrent",
        getCellProps: () => ({
          className: "text-uppercase  font-weight-bolder opacity-7 text-end",
        }),
        Cell: ({ value }) => `${Number(parseFloat(value).toFixed(2))}€`,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: ads_reports,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  return (
    <Row className="mb-3">
      <Col md={12}>
        <table {...getTableProps()} className="table align-items-center mb-0">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}

          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          gotoPage={gotoPage}
          length={ads_reports.length}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Col>
    </Row>
  );
};

export default ReportsTable;
