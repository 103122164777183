import { useEffect, useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDrafts,
  createDraft,
  updateDraft,
  updateDraftsPositions,
  deleteDraft,
  fetchDraftsSuccess,
} from "../../../redux/actions/draftActions";
import { fetchTinyClients } from "../../../redux/actions/clientActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Row, Col, Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";
import { NavContext } from "../../NavContext";

import Select from "react-select";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faTrash } from "@fortawesome/free-solid-svg-icons";

const DraftForm = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const { clientId } = useParams();
  const [doneDrafts, setDoneDrafts] = useState([]);
  const [currentDrafts, setCurrentDrafts] = useState([]);
  const { activeNav, setActiveNav } = useContext(NavContext);
  const drafts = useSelector((state) => state.draftReducer.drafts);
  const clients = useSelector((state) => state.clientReducer.tiny_clients);

  const [showForm, setShowForm] = useState(false);
  const [showDone, setShowDone] = useState(false);

  useEffect(() => {
    document.title = "ProdOTop - NoteBook";
  }, []);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  useEffect(() => {
    setActiveNav("drafts");
  }, [setActiveNav]);

  useEffect(() => {
    dispatch(getAllDrafts());
  }, [dispatch, clientId]);

  useEffect(() => {
    if (drafts) {
      console.log("ixi");
      setDoneDrafts(drafts.filter((draft) => draft.done));
      setCurrentDrafts(drafts.filter((draft) => !draft.done));
    }
  }, [setDoneDrafts, setCurrentDrafts, drafts]);

  const onSubmit = (data) => {
    const newData = { ...data, clientId: data.clientId.value };
    dispatch(createDraft(newData));
    reset();
  };

  useEffect(() => {
    dispatch(fetchTinyClients());
  }, [dispatch]);

  const filterByClient = (e) => {
    const filtred_done_drafts = drafts.filter(
      (draft) => draft?.client?._id === e.value && draft.done
    );
    const filtred_current_drafts = drafts.filter(
      (draft) => draft?.client?._id === e.value && !draft.done
    );
    e.value
      ? setDoneDrafts(filtred_done_drafts)
      : setDoneDrafts(drafts.filter((draft) => draft.done));
    e.value
      ? setCurrentDrafts(filtred_current_drafts)
      : setCurrentDrafts(drafts.filter((draft) => draft.done));
  };

  const clientList = clients
    ? clients.map((option) => ({
        value: option._id,
        label: option?.companyName,
      }))
    : [];

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(currentDrafts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dispatch(fetchDraftsSuccess([...items, ...doneDrafts]));
    const positions = items.map((draft, index) => ({
      _id: draft._id,
      newPosition: index + 1,
    }));

    dispatch(updateDraftsPositions({ positions: positions }));
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              Notebook
            </li>
          </ol>
        </nav>
      </div>
      <div className="text-center mx-5 mb-2">
        <button onClick={toggleForm} className="btn btn-primary w-50">
          <FontAwesomeIcon icon={showForm ? faMinus : faPlus} />
        </button>
      </div>
      <AnimatePresence>
        {showForm && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.5 }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card mb-4 purple-border">
                <div className="card-body">
                  <Row className="xmb-5">
                    <Col md={9}>
                      <div className="mb-3">
                        <label htmlFor="duration" className="form-label">
                          Ajouter une note personnel *
                        </label>
                        <textarea
                          type="text"
                          className="form-control note-textarea"
                          id="text"
                          {...register("text", { required: true })}
                        />
                        {errors?.text && (
                          <div className="text-danger">Champs obligatoire</div>
                        )}
                      </div>
                    </Col>
                    <Col md={3}>
                      <div>
                        <label htmlFor="minutes" className="form-label">
                          <span>Client</span>
                        </label>
                        <Controller
                          name="clientId"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={clientList}
                              isClearable={true}
                              placeholder="Sélectionnez un client"
                            />
                          )}
                        />
                      </div>
                    </Col>
                    <div>
                      <button
                        type="submit"
                        className="btn bg-gradient-dark mt-1 mb-0 px-5"
                      >
                        Ajouter
                      </button>
                    </div>
                  </Row>
                </div>
              </div>
            </form>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        <motion.div
          initial={false}
          animate={{
            y: showForm ? 10 : 0,
          }}
          transition={{ duration: 1 }}
          className="card mb-4 purple-border p-3 card-notebook"
        >
          <Row>
            <Col md={4}>
              <div className="mb-3">
                <Controller
                  name="filteredClientId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={clientList}
                      isClearable={true}
                      placeholder="Filtrer par client"
                      onChange={filterByClient}
                    />
                  )}
                />
              </div>
            </Col>
            <Col md={12}>
              <h6>
                <span className="mx-2">À Faire</span>
              </h6>
            </Col>
            <Col md={12}>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="drafts">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {currentDrafts?.map((draft, index) => {
                        return (
                          <Draggable
                            key={draft._id}
                            draggableId={draft._id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Draft
                                  key={draft._id}
                                  draft={draft}
                                  updateDraft={() => dispatch(updateDraft(draft._id))}
                                  deleteDraft={() => dispatch(deleteDraft(draft._id))}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Col>

            <Col md={12} className="pt-5 mb-3">
              <div
                onClick={() => setShowDone(!showDone)}
                className="toogle-done-drafts flex justify-content-between"
              >
                <span className="mx-2 mt-1">Fait</span>
                <span className="prodotop-link">{doneDrafts.length}</span>
              </div>
            </Col>

            <Col md={12}>
              {showDone &&
                doneDrafts?.map((draft) => (
                  <Draft
                    key={draft._id}
                    draft={draft}
                    updateDraft={() => dispatch(updateDraft(draft._id))}
                    deleteDraft={() => dispatch(deleteDraft(draft._id))}
                  />
                ))}
            </Col>
          </Row>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

const Draft = ({ draft, updateDraft, deleteDraft }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.5 }}
      className="draft-container"
    >
      <div className="card p-3 mb-4 note-card shadow-none">
        <div className="flex justify-content-between">
          <div className={`noteboox ${draft.done ? "done" : ""}`}>
            <div className="form-check">
              <div>
                <input
                  type="checkbox"
                  checked={draft.done}
                  onChange={updateDraft}
                  className="form-check-input"
                />
                <span>{draft.text}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-column p-0">
            <span>{draft.client?.companyName}</span>
            <small>{moment(draft.createdAt).format("DD/MM/YYYY")}</small>
          </div>
        </div>
      </div>
      <span className="delete-link primary-color" onClick={deleteDraft}>
        <FontAwesomeIcon icon={faTrash} />
      </span>
    </motion.div>
  );
};

export default DraftForm;
