import { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addSurveyQuestionToProduct } from "../../../redux/actions/surveyQuestionActions";
import { fetchProducts } from "../../../redux/actions/productActions";
import { Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../NavContext";

const ProductSurveyQuestionForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productId } = useParams();
  const { activeNav, setActiveNav } = useContext(NavContext);
  const products = useSelector((state) => state.productReducer.products);
  const product = products && products.find((product) => product._id === productId);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setActiveNav("products");
  }, [setActiveNav]);

  useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  const onSubmit = (data) => {
    dispatch(addSurveyQuestionToProduct(productId, data));
    navigate(`/products/${productId}`);
    reset();
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          {/* ... same breadcrumb structure ... */}
          <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
            Nouvelle question pour <b>{product?.name}</b>
          </li>
          {/* ... */}
        </nav>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <Row>
            <Col className="d-flex flex-column mx-auto">
              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-header text-left">{/* ... */}</div>
                <div className="card-body">
                  <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                      Titre de la question :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      {...register("title", { required: true })}
                    />
                    {errors.title && (
                      <div className="text-danger">Titre de la question requis</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="responseType" className="form-label">
                      Type de réponse :
                    </label>
                    <select
                      className="form-control"
                      id="responseType"
                      {...register("responseType", { required: true })}
                    >
                      <option value="text">Texte</option>
                      <option value="scale">Échelle</option>
                    </select>
                  </div>
                  <div className="">
                    <button type="submit" className="btn bg-gradient-dark w-30 mt-4 mb-0">
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Link to={`/products/${productId}`} className="link-action back">
                Retour
              </Link>
            </Col>
          </Row>
        </section>
      </form>
    </>
  );
};

export default ProductSurveyQuestionForm;
