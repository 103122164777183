import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USER_SURVEY_STATS_REQUEST,
  FETCH_USER_SURVEY_STATS_SUCCESS,
  FETCH_USER_SURVEY_STATS_FAILURE,
  FETCH_USER_EFFICIENCY_STATS_REQUEST,
  FETCH_USER_EFFICIENCY_STATS_SUCCESS,
  FETCH_USER_EFFICIENCY_STATS_FAILURE,
  FETCH_USER_WORKLOAD_STATS_REQUEST,
  FETCH_USER_WORKLOAD_STATS_SUCCESS,
  FETCH_USER_WORKLOAD_STATS_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from "../actionTypes";

const initialState = {
  users: [],
  userSurveyStats: false,
  userEfficiencyStats: false,
  userWorkloadStats: false,
  isFetching: false,
  errorMessage: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ADD_USER_REQUEST:
    case UPDATE_USER_REQUEST:
    case DELETE_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        isFetching: true,
        users: action.payload,
      };
    case FETCH_USER_SURVEY_STATS_REQUEST:
      return {
        ...state,
      };
    case FETCH_USER_SURVEY_STATS_SUCCESS:
      return {
        ...state,
        userSurveyStats: action.payload,
      };
    case FETCH_USER_SURVEY_STATS_FAILURE:
      return {
        ...state,
      };
    case FETCH_USER_WORKLOAD_STATS_REQUEST:
      return {
        ...state,
      };
    case FETCH_USER_WORKLOAD_STATS_SUCCESS:
      return {
        ...state,
        userWorkloadStats: action.payload,
      };
    case FETCH_USER_WORKLOAD_STATS_FAILURE:
      return {
        ...state,
      };
    case FETCH_USER_EFFICIENCY_STATS_REQUEST:
      return {
        ...state,
      };
    case FETCH_USER_EFFICIENCY_STATS_SUCCESS:
      return {
        ...state,
        userEfficiencyStats: action.payload,
      };
    case FETCH_USER_EFFICIENCY_STATS_FAILURE:
      return {
        ...state,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: [...state.users, action.payload],
        errorMessage: "",
      };
    case UPDATE_USER_SUCCESS:
      const updatedUser = action.payload;
      return {
        ...state,
        isFetching: false,
        users: state.users.map((user) =>
          user._id === updatedUser._id ? updatedUser : user
        ),
      };
    case DELETE_USER_SUCCESS:
      const userId = action.payload;
      return {
        ...state,
        isFetching: false,
        users: state.users.filter((user) => user._id !== userId),
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: "",
      };
    case ADD_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case UPDATE_USER_FAILURE:
    case DELETE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
