import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileContract,
  faUser,
  faEnvelopeCircleCheck,
  faStar,
  faMedal,
  faUsers,
  faRankingStar,
  faPenToSquare,
  faBuildingUser,
} from "@fortawesome/free-solid-svg-icons";
import { NavContext } from "../NavContext";

const Configuration = () => {
  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    setActiveNav("agency");
  }, [setActiveNav]);

  return (
    <div>
      <div className="card mb-3">
        <div className="flex justify-content-between gap-3 ">
          <div className="p-5">
            <h2>
              <span>Bienvenue sur le tableau de bord professionnel</span>
            </h2>
            <div>
              <span>
                Configuration de vos produits, E-mails, Enquêtes de satisfactions ...
              </span>
            </div>
          </div>
          <div>
            <i className="bg-agency img-fluid bg-header" />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="p-5">
          <div className="mb-5">
            <h2>
              <span>Agence</span>
            </h2>
          </div>
          <div className="flex flex-wrap gap-3">
            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/agency/info">
                <FontAwesomeIcon icon={faPenToSquare} className="" />
                <span className="mx-3">Infos.</span>
              </Link>
            </div>

            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/departments">
                <FontAwesomeIcon icon={faBuildingUser} className="" />
                <span className="mx-3">Départements</span>
              </Link>
            </div>

            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/users">
                <FontAwesomeIcon icon={faUser} className="" />
                <span className="mx-3">Employées</span>
              </Link>
            </div>

            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/users/clients">
                <FontAwesomeIcon icon={faUsers} className="" />
                <span className="mx-3">Accés clients</span>
              </Link>
            </div>
            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link aria-current="page" to="/clients/dashboard">
                <FontAwesomeIcon icon={faRankingStar} className="" />
                <span className="mx-3">rentabilité</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configuration;
