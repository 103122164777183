import {
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAILURE,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  FETCH_CLIENT_ORDERS_REQUEST,
  FETCH_CLIENT_ORDERS_SUCCESS,
  FETCH_CLIENT_ORDERS_FAILURE,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENT_FAILURE,
  FETCH_CLIENT_ORDER_REQUEST,
  FETCH_CLIENT_ORDER_SUCCESS,
  FETCH_CLIENT_ORDER_FAILURE,
  ESTIMATE_ORDER_REQUEST,
  ESTIMATE_ORDER_SUCCESS,
  ESTIMATE_ORDER_UNAUTHORIZED,
  ESTIMATE_ORDER_FAILURE,
  ADD_CLIENT_ORDER_REQUEST,
  ADD_CLIENT_ORDER_SUCCESS,
  ADD_CLIENT_ORDER_FAILURE,
  UPDATE_CLIENT_ORDER_REQUEST,
  UPDATE_CLIENT_ORDER_SUCCESS,
  UPDATE_CLIENT_ORDER_FAILURE,
  DELETE_CLIENT_ORDER_REQUEST,
  DELETE_CLIENT_ORDER_SUCCESS,
  DELETE_CLIENT_ORDER_FAILURE,
  ADD_CLIENT_TICKET_REQUEST,
  ADD_CLIENT_TICKET_SUCCESS,
  ADD_CLIENT_TICKET_FAILURE,
  UPDATE_CLIENT_TICKET_REQUEST,
  UPDATE_CLIENT_TICKET_SUCCESS,
  UPDATE_CLIENT_TICKET_FAILURE,
  FETCH_TICKET_REQUEST,
  FETCH_TICKET_SUCCESS,
  FETCH_TICKET_FAILURE,
  FETCH_TODOS_REQUEST,
  FETCH_TODOS_SUCCESS,
  FETCH_TODOS_FAILURE,
  ADD_TODOS_REQUEST,
  ADD_TODOS_SUCCESS,
  ADD_TODOS_FAILURE,
  UPDATE_TODOS_REQUEST,
  UPDATE_TODOS_SUCCESS,
  UPDATE_TODOS_FAILURE,
  FETCH_NOTES_REQUEST,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_FAILURE,
  ADD_NOTE_REQUEST,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_FAILURE,
  GENERATE_SURVEY_REQUEST,
  GENERATE_SURVEY_SUCCESS,
  GENERATE_SURVEY_FAILURE,
  FETCH_TINY_CLIENTS_REQUEST,
  FETCH_TINY_CLIENTS_SUCCESS,
  FETCH_TINY_CLIENTS_FAILURE,
  FETCH_DASHBOARD_CLIENTS_REQUEST,
  FETCH_DASHBOARD_CLIENTS_SUCCESS,
  FETCH_DASHBOARD_CLIENTS_FAILURE,
  UPLOAD_ORDER_DOCUMENT_REQUEST,
  UPLOAD_ORDER_DOCUMENT_SUCCESS,
  UPLOAD_ORDER_DOCUMENT_FAILURE,
  UPDATE_BRIEF_REQUEST,
  UPDATE_BRIEF_SUCCESS,
  UPDATE_BRIEF_FAILURE,
  UPDATE_QUALITY_REQUEST,
  UPDATE_QUALITY_SUCCESS,
  UPDATE_QUALITY_FAILURE,
  DELETE_ORDER_QUALITY_CHECK_REQUEST,
  DELETE_ORDER_QUALITY_CHECK_SUCCESS,
  DELETE_ORDER_QUALITY_CHECK_FAILURE,
  ADD_ORDER_QUALITY_CHECK_REQUEST,
  ADD_ORDER_QUALITY_CHECK_SUCCESS,
  ADD_ORDER_QUALITY_CHECK_FAILURE,
  FETCH_CLIENT_ORDER_WITH_NOTES_REQUEST,
  FETCH_CLIENT_ORDER_WITH_NOTES_SUCCESS,
  FETCH_CLIENT_ORDER_WITH_NOTES_FAILURE,
  ADD_ORDER_NOTE_REQUEST,
  ADD_ORDER_NOTE_SUCCESS,
  ADD_ORDER_NOTE_FAILURE,
  GENERATE_QUALITY_CRITERIONS_REQUEST,
  GENERATE_QUALITY_CRITERIONS_SUCCESS,
  GENERATE_QUALITY_CRITERIONS_FAILURE,
  GENERATE_BRIEF_QUESTIONS_REQUEST,
  GENERATE_BRIEF_QUESTIONS_SUCCESS,
  GENERATE_BRIEF_QUESTIONS_FAILURE,
  FETCH_GOOGLE_ACCOUNTS_REQUEST,
  FETCH_GOOGLE_ACCOUNTS_SUCCESS,
  FETCH_GOOGLE_ACCOUNTS_FAILURE,
  FETCH_GOOGLE_MCCS_REQUEST,
  FETCH_GOOGLE_MCCS_SUCCESS,
  FETCH_GOOGLE_MCCS_FAILURE,
  FETCH_ADS_REPORTS_REQUEST,
  FETCH_ADS_REPORTS_SUCCESS,
  FETCH_ADS_REPORTS_FAILURE,
  FETCH_CLIENT_ADS_REPORTS_REQUEST,
  FETCH_CLIENT_ADS_REPORTS_SUCCESS,
  FETCH_CLIENT_ADS_REPORTS_FAILURE,
  FETCH_SURVEY_REQUEST,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_FAILURE,
  DOWNLOADING_DOCUMENT_REQUEST,
  DOWNLOADING_DOCUMENT_SUCCESS,
  DOWNLOADING_DOCUMENT_FAILURE,
  FETCH_IA_ITERPRETATION_REQUEST,
  FETCH_IA_ITERPRETATION_SUCCESS,
  FETCH_IA_ITERPRETATION_FAILURE,
  FETCH_LAST_NOTE_REQUEST,
  FETCH_LAST_NOTE_SUCCESS,
  FETCH_LAST_NOTE_FAILURE,
  FETCH_CLIENT_EMPLOYEES_REQUEST,
  FETCH_CLIENT_EMPLOYEES_SUCCESS,
  FETCH_CLIENT_EMPLOYEES_FAILURE,
  FETCH_MEETING_REQUEST,
  FETCH_MEETING_SUCCESS,
  FETCH_MEETING_FAILURE,
} from "../actionTypes";

const initialState = {
  client_employees: [],
  loading_client_employees: false,
  meeting: false,
  loading_meeting: false,
  clients: [],
  client: false,
  tiny_clients: [],
  ads_reports: [],
  client_ads_reports: [],
  fetchingReports: false,
  dashboardClients: [],
  loadingProfitability: false,
  google_mccs: [],
  google_accounts: [],
  fetchingMccs: false,
  fetchingAccounts: false,
  loadingDocument: false,
  downloadingDocument: false,
  generating_checks: false,
  generating_questions: false,
  tiny_client: false,
  totalPages: 1,
  order: false,
  order_with_notes: false,
  ticket: false,
  isFetching: false,
  isFetchingClientOrders: false,
  isFetchingNotes: false,
  loading: false,
  error: null,
  todos: [],
  isFetchingTodos: false,
  fb_insight: false,
  ga_insight: false,
  gads_insight: false,
  loadingFbInsight: false,
  loadingGAInsight: false,
  loadingGAdsInsight: false,
  generatingSurvey: false,
  surveys: [],
  survey: false,
  suggestions: {
    fads: "",
    gads: "",
    ga: "",
  },
  lastNote: {
    fads: {},
    gads: {},
    ga: {},
  },
  order_estimation: {},
  estimation_loading: false,
  estimation_unauthorized: "",
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEETING_REQUEST:
      return {
        ...state,
        loading_meeting: true,
        meeting: false,
      };
    case FETCH_MEETING_SUCCESS:
      return {
        ...state,
        meeting: action.payload,
        loading_meeting: false,
      };
    case FETCH_MEETING_FAILURE:
      return {
        ...state,
        loading_meeting: false,
        meeting: false,
      };
    case FETCH_CLIENT_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading_client_employees: true,
      };
    case FETCH_CLIENT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        client_employees: action.payload,
        loading_client_employees: false,
      };
    case FETCH_CLIENT_EMPLOYEES_FAILURE:
      return {
        ...state,
        loading_client_employees: false,
      };
    case ESTIMATE_ORDER_REQUEST:
      return {
        ...state,
        order_estimation: {},
        estimation_loading: true,
        estimation_unauthorized: "",
      };
    case ESTIMATE_ORDER_SUCCESS:
      return {
        ...state,
        order_estimation: action.payload,
        estimation_loading: false,
        estimation_unauthorized: "",
      };
    case ESTIMATE_ORDER_UNAUTHORIZED:
      return {
        ...state,
        order_estimation: {},
        estimation_loading: false,
        estimation_unauthorized: action.payload,
      };
    case ESTIMATE_ORDER_FAILURE:
      return {
        ...state,
        order_estimation: {},
        estimation_loading: false,
        estimation_unauthorized: "",
        error: action.payload,
      };
    case FETCH_LAST_NOTE_REQUEST:
      return {
        ...state,
      };
    case FETCH_LAST_NOTE_SUCCESS:
      return {
        ...state,
        lastNote: {
          ...state.lastNote,
          [action.payload.plateform]: action.payload.note,
        },
      };
    case FETCH_LAST_NOTE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_IA_ITERPRETATION_REQUEST:
      return {
        ...state,
        loadingInterpretation: true,
      };
    case FETCH_IA_ITERPRETATION_SUCCESS:
      return {
        ...state,
        loadingInterpretation: false,
        suggestions: {
          ...state.suggestions,
          [action.payload.plateform]: action.payload.suggestion,
        },
      };
    case FETCH_IA_ITERPRETATION_FAILURE:
      return {
        ...state,
        loadingInterpretation: false,
        error: action.payload,
      };
    case DOWNLOADING_DOCUMENT_REQUEST:
      return {
        ...state,
        downloadingDocument: true,
        error: null,
      };
    case DOWNLOADING_DOCUMENT_SUCCESS:
      return {
        ...state,
        downloadingDocument: false,
        error: null,
      };
    case DOWNLOADING_DOCUMENT_FAILURE:
      return {
        ...state,
        downloadingDocument: false,
      };
    case FETCH_SURVEY_REQUEST:
      return {
        ...state,
        error: null,
      };
    case FETCH_SURVEY_SUCCESS:
      return {
        ...state,
        survey: action.payload,
        error: null,
      };
    case FETCH_SURVEY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_CLIENT_ADS_REPORTS_REQUEST:
      return {
        ...state,
        fetchingReports: true,
      };
    case FETCH_CLIENT_ADS_REPORTS_SUCCESS:
      return {
        ...state,
        client_ads_reports: action.payload,
        fetchingReports: false,
      };
    case FETCH_CLIENT_ADS_REPORTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetchingReports: false,
      };
    case FETCH_ADS_REPORTS_REQUEST:
      return {
        ...state,
        fetchingReports: true,
      };
    case FETCH_ADS_REPORTS_SUCCESS:
      return {
        ...state,
        ads_reports: action.payload,
        fetchingReports: false,
      };
    case FETCH_ADS_REPORTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetchingReports: false,
      };
    case FETCH_GOOGLE_ACCOUNTS_REQUEST:
      return {
        ...state,
        fetchingAccounts: true,
      };
    case FETCH_GOOGLE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        google_accounts: action.payload,
        fetchingAccounts: false,
      };
    case FETCH_GOOGLE_ACCOUNTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetchingAccounts: false,
      };
    case FETCH_GOOGLE_MCCS_REQUEST:
      return {
        ...state,
        fetchingMccs: true,
      };
    case FETCH_GOOGLE_MCCS_SUCCESS:
      return {
        ...state,
        google_mccs: action.payload,
        fetchingMccs: false,
      };
    case FETCH_GOOGLE_MCCS_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetchingMccs: false,
      };
    case GENERATE_BRIEF_QUESTIONS_REQUEST:
      return {
        ...state,
        generating_questions: true,
      };
    case GENERATE_BRIEF_QUESTIONS_SUCCESS:
      return {
        ...state,
        order: action.payload,
        generating_questions: false,
      };
    case GENERATE_BRIEF_QUESTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
        generating_questions: false,
      };
    case GENERATE_QUALITY_CRITERIONS_REQUEST:
      return {
        ...state,
        generating_checks: true,
      };
    case GENERATE_QUALITY_CRITERIONS_SUCCESS:
      return {
        ...state,
        order: action.payload,
        generating_checks: false,
      };
    case GENERATE_QUALITY_CRITERIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
        generating_checks: false,
      };
    case ADD_ORDER_NOTE_REQUEST:
      return {
        ...state,
      };
    case ADD_ORDER_NOTE_SUCCESS:
      return {
        ...state,
        order_with_notes: {
          ...state.order_with_notes,
          notes: [action.payload, ...state.order_with_notes.notes],
        },
      };
    case ADD_ORDER_NOTE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_CLIENT_ORDER_WITH_NOTES_REQUEST:
      return {
        ...state,
      };
    case FETCH_CLIENT_ORDER_WITH_NOTES_SUCCESS:
      return {
        ...state,
        order_with_notes: action.payload,
      };
    case FETCH_CLIENT_ORDER_WITH_NOTES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_QUALITY_REQUEST:
      return {
        ...state,
      };
    case UPDATE_QUALITY_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_QUALITY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_ORDER_QUALITY_CHECK_REQUEST:
      return {
        ...state,
      };
    case ADD_ORDER_QUALITY_CHECK_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          quality_criterion_checks: [
            ...state.order.quality_criterion_checks,
            action.payload,
          ],
        },
      };
    case ADD_ORDER_QUALITY_CHECK_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_ORDER_QUALITY_CHECK_REQUEST:
      return {
        ...state,
      };
    case DELETE_ORDER_QUALITY_CHECK_SUCCESS:
      const quality_criterion_checks = state.order.quality_criterion_checks.filter(
        (check) => check._id !== action.payload
      );

      return {
        ...state,
        order: {
          ...state.order,
          quality_criterion_checks: quality_criterion_checks,
        },
      };
    case DELETE_ORDER_QUALITY_CHECK_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_BRIEF_REQUEST:
      return {
        ...state,
      };
    case UPDATE_BRIEF_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_BRIEF_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPLOAD_ORDER_DOCUMENT_REQUEST:
      return {
        ...state,
        loadingDocument: true,
      };
    case UPLOAD_ORDER_DOCUMENT_SUCCESS:
      return {
        ...state,
        loadingDocument: false,
        order: {
          ...state.order,
          documents: [...state.order.documents, action.payload],
        },
      };
    case UPLOAD_ORDER_DOCUMENT_FAILURE:
      return {
        ...state,
        loadingDocument: false,
      };
    case FETCH_DASHBOARD_CLIENTS_REQUEST:
      return {
        ...state,
        loadingProfitability: true,
        error: null,
      };
    case FETCH_DASHBOARD_CLIENTS_SUCCESS:
      return {
        ...state,
        dashboardClients: action.payload,
        loadingProfitability: false,
        error: null,
      };
    case FETCH_DASHBOARD_CLIENTS_FAILURE:
      return {
        ...state,
        loadingProfitability: false,
        error: action.payload,
      };
    case GENERATE_SURVEY_REQUEST:
      return {
        ...state,
        generatingSurvey: true,
      };
    case GENERATE_SURVEY_SUCCESS:
      return {
        ...state,
        generatingSurvey: false,
        order: {
          ...state.order,
          surveys: [...state.order.surveys, action.payload],
        },
      };
    case GENERATE_SURVEY_FAILURE:
      return {
        ...state,
        generatingSurvey: false,
      };

    case FETCH_CLIENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_TINY_CLIENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ADD_CLIENT_REQUEST:
    case ADD_NOTE_REQUEST:
    case UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case DELETE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NOTES_REQUEST:
      return {
        ...state,
        isFetchingNotes: false,
        error: null,
      };
    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload.clients,
        totalPages: action.payload.totalPages,
        isFetching: false,
        loading: false,
        error: null,
      };
    case FETCH_TINY_CLIENTS_SUCCESS:
      return {
        ...state,
        tiny_clients: action.payload,
        isFetching: false,
        loading: false,
        error: null,
      };
    case FETCH_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload,
        isFetchingNotes: true,
        error: null,
      };
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        clients: [...state.clients, action.payload],
        loading: false,
        error: null,
      };
    case ADD_NOTE_SUCCESS:
      return {
        ...state,
        notes: [action.payload, ...state.notes],
        loading: false,
        error: null,
      };
    case UPDATE_CLIENT_SUCCESS:
      const updatedClients = state.clients.map((client) =>
        client._id === action.payload._id ? action.payload : client
      );
      return {
        ...state,
        clients: updatedClients,
        loading: false,
        error: null,
        isFetching: false,
      };
    case DELETE_CLIENT_SUCCESS:
      const filteredClients = state.clients.filter(
        (client) => client._id !== action.payload
      );
      return {
        ...state,
        clients: filteredClients,
        loading: false,
        error: null,
      };
    case FETCH_CLIENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FETCH_TINY_CLIENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FETCH_NOTES_FAILURE:
      return {
        ...state,
        isFetchingNotes: false,
        error: action.payload,
      };
    case ADD_CLIENT_FAILURE:
    case UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case DELETE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_CLIENT_ORDERS_REQUEST:
      return {
        ...state,
        isFetchingClientOrders: true,
        error: null,
      };
    case FETCH_CLIENT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case ADD_CLIENT_ORDER_REQUEST:
    case UPDATE_CLIENT_ORDER_REQUEST:
      return {
        ...state,
        isFetchingClientOrders: true,
        error: null,
      };
    case DELETE_CLIENT_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CLIENT_ORDERS_SUCCESS:
      return {
        ...state,
        client: action.payload,
        isFetchingClientOrders: false,
        loading: false,
        error: null,
      };
    case FETCH_CLIENT_SUCCESS:
      return {
        ...state,
        tiny_client: action.payload,
        error: null,
      };
    case FETCH_CLIENT_ORDER_REQUEST:
      return {
        ...state,
        isFetchingClientOrders: true,
        error: null,
      };
    case FETCH_CLIENT_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        isFetchingClientOrders: false,
        error: null,
      };
    case FETCH_CLIENT_ORDER_FAILURE:
      return {
        ...state,
        isFetchingClientOrders: false,
        error: action.payload,
      };
    case ADD_NOTE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case ADD_CLIENT_ORDER_SUCCESS:
      return {
        ...state,
        isFetchingClientOrders: false,
        loading: false,
        error: null,
      };
    case UPDATE_CLIENT_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isFetchingClientOrders: false,
      };
    case DELETE_CLIENT_ORDER_SUCCESS:
      const filteredClientOrders = state.client.orders.filter(
        (order) => order._id !== action.payload
      );

      return {
        ...state,
        client: { ...state.client, orders: filteredClientOrders },
        isFetchingClientOrders: false,
        loading: false,
        error: null,
      };
    case FETCH_CLIENT_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isFetchingClientOrders: false,
      };
    case FETCH_CLIENT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_CLIENT_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isFetchingClientOrders: false,
      };
    case UPDATE_CLIENT_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isFetchingClientOrders: false,
      };
    case DELETE_CLIENT_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isFetchingClientOrders: false,
      };
    case ADD_CLIENT_TICKET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ADD_CLIENT_TICKET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    case ADD_CLIENT_TICKET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case UPDATE_CLIENT_TICKET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case UPDATE_CLIENT_TICKET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    case UPDATE_CLIENT_TICKET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FETCH_TICKET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_TICKET_SUCCESS:
      return {
        ...state,
        ticket: action.payload,
        isFetching: false,
        error: null,
      };
    case FETCH_TICKET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FETCH_TODOS_REQUEST:
      return {
        ...state,
        isFetchingTodos: true,
        error: null,
      };
    case FETCH_TODOS_SUCCESS:
      return {
        ...state,
        todos: action.payload,
        isFetchingTodos: false,
        error: null,
      };
    case FETCH_TODOS_FAILURE:
      return {
        ...state,
        isFetchingTodos: false,
        error: action.payload,
      };
    case ADD_TODOS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case ADD_TODOS_SUCCESS:
      return {
        ...state,
        todos: action.payload,
        error: null,
      };
    case ADD_TODOS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_TODOS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case UPDATE_TODOS_SUCCESS:
      return {
        ...state,
        todos: action.payload,
        error: null,
      };
    case UPDATE_TODOS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case "FETCH_FB_INSIGHT_REQUEST":
      return {
        ...state,
        error: null,
        loadingFbInsight: true,
      };
    case "FETCH_FB_INSIGHT_SUCCESS":
      return {
        ...state,
        fb_insight: action.payload,
        loadingFbInsight: false,
        error: null,
      };
    case "FETCH_FB_INSIGHT_FAILURE":
      return {
        ...state,
        loadingFbInsight: false,
        fb_insight: false,
        error: action.payload,
      };
    case "FETCH_GA_INSIGHT_REQUEST":
      return {
        ...state,
        error: null,
        loadingGAInsight: true,
      };
    case "FETCH_GA_INSIGHT_SUCCESS":
      return {
        ...state,
        ga_insight: action.payload,
        loadingGAInsight: false,
        error: null,
      };
    case "FETCH_GA_INSIGHT_FAILURE":
      return {
        ...state,
        loadingGAInsight: false,
        ga_insight: false,
        error: action.payload,
      };
    case "FETCH_GADS_INSIGHT_REQUEST":
      return {
        ...state,
        error: null,
        loadingGAdsInsight: true,
      };
    case "FETCH_GADS_INSIGHT_SUCCESS":
      return {
        ...state,
        gads_insight: action.payload,
        loadingGAdsInsight: false,
        error: null,
      };
    case "FETCH_GADS_INSIGHT_FAILURE":
      return {
        ...state,
        loadingGAdsInsight: false,
        gads_insight: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default clientReducer;
