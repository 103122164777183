import React, { useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import BarChart from "./charts/BarChart";
import AreaChart from "./charts/AreaChart";
import { useTable, useSortBy, usePagination } from "react-table";
import Pagination from "./Pagination";
import { createPdfWithTemplateAndContent } from "../../../../utils/generatePdf";
import { Bars } from "react-loader-spinner";
import NoteForm from "./NoteForm";
import Note from "./note";
import { useSelector } from "react-redux";

const Gads = ({
  onSubmitNote,
  gads_insight,
  title,
  subTitle,
  dates,
  fetchIaIterpretationGads,
  lastNote,
}) => {
  const suggestion = useSelector((state) => state.clientReducer.suggestions.gads);
  const [isLoading, setIsLoading] = useState(false);
  const keywordData = gads_insight.keywordData;
  const columns = useMemo(
    () => [
      {
        Header: "Mot clé",
        accessor: "keyword",
      },
      {
        Header: "Impression",
        accessor: "impressions",
        getCellProps: () => ({
          className: "text-uppercase  font-weight-bolder opacity-7 text-end",
        }),
      },
      {
        Header: "Clicks",
        accessor: "clicks",
        getCellProps: () => ({
          className: "text-uppercase  font-weight-bolder opacity-7 text-end",
        }),
      },
      {
        Header: "Cpc",
        accessor: "cpc",
        getCellProps: () => ({
          className: "text-uppercase  font-weight-bolder opacity-7 text-end",
        }),
        Cell: ({ value }) => `${Number(parseFloat(value).toFixed(2))} €`,
        sortType: (rowA, rowB, columnName, desc) => {
          let a = parseFloat(rowA.original.cpc);
          let b = parseFloat(rowB.original.cpc);
          return a > b ? 1 : -1;
        },
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: keywordData,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  const handleDownloadPdf = async (area) => {
    setIsLoading(true);

    const selector = `[area-number][aria="${area}"]`;
    const areas = Array.from(document.querySelectorAll(selector))
      .sort(
        (a, b) =>
          parseInt(a.getAttribute("area-number")) -
          parseInt(b.getAttribute("area-number"))
      )
      .map((el) => el.id);

    await createPdfWithTemplateAndContent("/template.pdf", areas, title, subTitle, dates);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className="ia-loader">
          <Bars
            height={100}
            width={100}
            radius={5}
            color="#cb0c9f"
            ariaLabel="bars-loading"
            wrapperClass={"triangle-loader"}
            wrapperStyle=""
            visible={true}
          />
        </div>
      )}
      <Row>
        <Col md={12}>
          <button
            onClick={() => handleDownloadPdf("gads")}
            className="prodotop-link mb-3"
          >
            Télécharger le rapport PDF
          </button>
        </Col>
      </Row>

      <Row
        aria="gads"
        area-number="1"
        id="gads-area1"
        data-width="550"
        className="bg-white p-3"
      >
        <Col md={6}>
          <div className="card-header ">
            <h1 className="text-black text-end ">Impression </h1>

            <div className="text-uppercase  text-2xl font-weight-bolder  broun-text text-end">
              {gads_insight.totalDataThirtyDays.impressions} (30 derniers jours)
            </div>
            <p className="text-2xl broun-text mb-0 text-end ">
              Les impressions représentent le nombre <br />
              total de fois où votre publicité a été affichée.
            </p>
          </div>
        </Col>
        <Col md={6}>
          <div className="card medium-card mb-4 pb-2 purple-border bg-white border-2">
            <div className=" card-body bg-white py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase  text-sm">Historique Par mois</small>
                  {gads_insight.periode === "monthly" ? (
                    <BarChart
                      width={600}
                      color="#4285F4"
                      dataType="impressions"
                      data={gads_insight.campaignPeriodData}
                    />
                  ) : (
                    <AreaChart
                      color="#4285F4"
                      dataType="impressions"
                      data={gads_insight.campaignPeriodData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row
        aria="gads"
        area-number="2"
        id="gads-area2"
        data-width="550"
        className="bg-white p-3"
      >
        <Col md={6}>
          {" "}
          <div className="card medium-card mb-4 pb-2 purple-border bg-white border-2">
            <div className=" card-body bg-white py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase text-sm">Historique Par mois</small>

                  {gads_insight.periode === "monthly" ? (
                    <BarChart
                      width={600}
                      color="#DB4437"
                      dataType="clicks"
                      data={gads_insight.campaignPeriodData}
                    />
                  ) : (
                    <AreaChart
                      color="#DB4437"
                      dataType="clicks"
                      data={gads_insight.campaignPeriodData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="card-header">
            <h1 className="text-black ">Clics </h1>

            <div className="text-uppercase text-2xl font-weight-bolder broun-text">
              {gads_insight.totalDataThirtyDays.clicks} (30 derniers jours)
            </div>
            <p className="text-2xl broun-text mb-0">
              Les clics représentent le nombre total de fois
              <br /> où les utilisateurs ont interagi avec votre publicité.
            </p>
          </div>
        </Col>
      </Row>

      <Row
        aria="gads"
        area-number="3"
        id="gads-area3"
        data-width="550"
        className="bg-white p-3"
      >
        <Col md={6}>
          <div className="card-header">
            <h1 className="text-black text-end ">CPC Moyen</h1>
            <div className="text-uppercase text-2xl font-weight-bolder broun-text text-end">
              {Number(parseFloat(gads_insight.totalDataThirtyDays.averageCpc).toFixed(3))}
              € (30 derniers jours)
            </div>
            <p className="text-2xl broun-text mb-0 text-end">
              Le coût par clic (CPC) représente le coût moyen
              <br /> payé pour chaque clic reçu sur votre publicité.
            </p>
          </div>
        </Col>
        <Col md={6}>
          <div className="card medium-card mb-4 pb-2 purple-border bg-white border-2">
            <div className="card-body bg-white py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase text-sm">Historique Par mois</small>
                  {gads_insight.periode === "monthly" ? (
                    <BarChart
                      width={600}
                      color="#F4B400"
                      dataType="averageCpc"
                      data={gads_insight.campaignPeriodData}
                    />
                  ) : (
                    <AreaChart
                      color="#F4B400"
                      dataType="averageCpc"
                      data={gads_insight.campaignPeriodData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row
        aria="gads"
        area-number="4"
        id="gads-area4"
        data-width="550"
        className="bg-white p-3"
      >
        <Col md={6}>
          <div className="card medium-card mb-4 pb-2 purple-border bg-white border-2">
            <div className="card-body bg-white py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase text-sm">Historique Par mois</small>
                  {gads_insight.periode === "monthly" ? (
                    <BarChart
                      width={600}
                      color="#0F9D58"
                      dataType="averageCtr"
                      data={gads_insight.campaignPeriodData}
                    />
                  ) : (
                    <AreaChart
                      color="#0F9D58"
                      dataType="averageCtr"
                      data={gads_insight.campaignPeriodData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="card-header">
            <h1 className="text-black ">CTR</h1>
            <div className="text-uppercase text-2xl font-weight-bolder broun-text">
              {Number(parseFloat(gads_insight.totalDataThirtyDays.averageCtr).toFixed(2))}
              % (30 derniers jours)
            </div>
            <p className="text-2xl broun-text mb-0">
              Le taux de clics (CTR) est le pourcentage de clics reçus
              <br /> par rapport au nombre d'impressions.
            </p>
          </div>
        </Col>
      </Row>

      <Row
        aria="gads"
        area-number="5"
        id="gads-area5"
        data-width="550"
        className="bg-white p-3 mb-3"
      >
        <Col md={6}>
          <div className="card-header">
            <h1 className="text-black text-end ">Investissement</h1>
            <div className="text-uppercase text-2xl font-weight-bolder broun-text text-end">
              {Number(parseFloat(gads_insight.totalDataThirtyDays.totalCost).toFixed(2))}€
              (30 derniers jours)
            </div>
            <p className="text-2xl broun-text mb-0 text-end">
              Dépense publicitaire pendant la période.
            </p>
          </div>
        </Col>
        <Col md={6}>
          <div className="card medium-card mb-4 pb-2 purple-border bg-white border-2">
            <div className="card-body bg-white py-0">
              <Row className="mb-3">
                <Col md={12}></Col>
                <Col md={12}>
                  <small className="text-uppercase text-sm">Historique Par mois</small>
                  {gads_insight.periode === "monthly" ? (
                    <BarChart
                      width={600}
                      color="#4285F4"
                      dataType="totalCost"
                      data={gads_insight.campaignPeriodData}
                    />
                  ) : (
                    <AreaChart
                      color="#4285F4"
                      dataType="totalCost"
                      data={gads_insight.campaignPeriodData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {" "}
          <div
            className="card medium-card mb-4 pb-2 purple-border"
            aria="gads"
            area-number="6"
            id="gads-area6"
            data-width="550"
          >
            <div className="card-header">
              <h1 className="text-black">Performance des Mots Clés</h1>
              <p className="text-2xl broun-text mb-0">
                Cliquez sur le titre de la colonne pour trier les données
              </p>
            </div>
            <div className=" card-body bg-white py-0">
              <Row className="mb-3">
                <Col md={12}>
                  <table
                    {...getTableProps()}
                    className="table align-items-center mb-0 text-2xl"
                  >
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}

                    <tbody {...getTableBodyProps()}>
                      {page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Pagination
                    gotoPage={gotoPage}
                    length={keywordData.length}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Note note={lastNote} aria="gads" areaNumber="7" id="gads-area7" />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <NoteForm
            defaultTitle={`Rapport - ${subTitle}`}
            plateform="gads"
            onSubmitNote={onSubmitNote}
            suggestion={suggestion}
            fetchIaIterpretation={fetchIaIterpretationGads}
          />
        </Col>
      </Row>
    </>
  );
};

export default Gads;
