import { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBriefQuestion,
  addBriefQuestion,
  updateBriefQuestion,
} from "../../../redux/actions/briefQuestionActions";
import { fetchProducts } from "../../../redux/actions/productActions";
import { Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../NavContext";
import Select from "react-select";

const BriefQuestionForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { activeNav, setActiveNav } = useContext(NavContext);
  useEffect(() => {
    setActiveNav("briefQuestions");
  }, [setActiveNav]);
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const products = useSelector((state) => state.productReducer.products);
  const isFetching = useSelector((state) => state.productReducer.isFetching);

  const briefQuestion = useSelector((state) => state.briefQuestionReducer.briefQuestion);

  useEffect(() => {
    if (id) {
      dispatch(fetchBriefQuestion(id));
    } else {
      console.log("ixi");
      setSelectedProducts([]);
      reset({ question: "" });
    }
  }, [dispatch, setSelectedProducts, reset, id]);

  useEffect(() => {
    if (!isFetching) {
      dispatch(fetchProducts());
    }
    if (id && briefQuestion) {
      console.log("ixi auxi");
      setSelectedProducts(briefQuestion.products);
      reset({
        question: briefQuestion.question,
        products: briefQuestion.products,
      });
    }
  }, [dispatch, id, briefQuestion, reset, isFetching, products]);

  const handleProductAddition = (productId) => {
    const productToAdd = products.find((product) => product._id === productId);
    if (productToAdd && !selectedProducts.some((product) => product._id === productId)) {
      setSelectedProducts((prevProducts) => [...prevProducts, productToAdd]);
    }
  };

  const handleProductRemoval = (productId) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.filter((product) => product._id !== productId)
    );
  };
  const productList = products.map((option) => ({
    value: option._id,
    label: option.name,
  }));

  const onSubmit = (data) => {
    if (briefQuestion && id) {
      dispatch(
        updateBriefQuestion(briefQuestion._id, { ...data, products: selectedProducts })
      );
    } else {
      dispatch(addBriefQuestion({ ...data, products: selectedProducts }));
    }
    navigate("/brief-questions");
    reset();
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/brief-questions`}>cahier des charges</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              {briefQuestion ? "Modification de la question" : " Nouvelle question"}
            </li>
          </ol>
        </nav>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <Row>
            <Col className="d-flex flex-column mx-auto">
              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-header text-left">
                  <Col md={6}>
                    {briefQuestion ? (
                      <h6>
                        <span className="product-title px-3 text-white">
                          Modification de la question : {briefQuestion.question}
                        </span>
                      </h6>
                    ) : (
                      <h6>
                        <span className="product-title px-3 text-white">
                          Nouvelle question
                        </span>
                      </h6>
                    )}
                  </Col>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label htmlFor="question" className="form-label">
                      Question :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="question"
                      {...register("question", { required: true })}
                    />
                    {errors.question && (
                      <div className="text-danger">Question requise</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="productSelect" className="form-label">
                      Ajouter un produit :
                    </label>
                    <Select
                      options={productList}
                      isClearable={true}
                      placeholder="Sélectionnez un produit"
                      onChange={(e) => handleProductAddition(e.value)}
                    />

                    <ul className="mt-3">
                      {selectedProducts.map((product) => (
                        <li key={product._id} className="d-flex align-items-center">
                          {product.name}
                          <span
                            className="ml-2 cursor-pointer"
                            onClick={() => handleProductRemoval(product._id)}
                          >
                            ✖️
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="">
                    <button type="submit" className="btn bg-gradient-dark w-30 mt-4 mb-0">
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Link to={`/brief-questions`} className="link-action back">
                Retour
              </Link>
            </Col>
          </Row>
        </section>
      </form>
    </>
  );
};

export default BriefQuestionForm;
