import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isUserLoggedIn = useSelector((state) => state.authReducer.isUserLoggedIn);
  const currentUser = useSelector((state) => state.authReducer.user?.user);

  useEffect(() => {
    if (!isUserLoggedIn || currentUser?.user_type !== "employee") {
      // navigate("/login?origin=" + location.pathname);
      navigate("/login");
    }
  }, [currentUser, isUserLoggedIn, navigate]);

  return isUserLoggedIn ? children : null;
};

export default ProtectedRoute;
