// src/redux/services/surveyQuestionService.js

import axios from "axios";

const API_URL = "/api/survey-questions";

const getAllSurveyQuestions = () => {
  return axios.get(API_URL).then((response) => {
    return response.data;
  });
};

const getSurveyQuestionById = (id) => {
  return axios.get(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};
const getSurveyQuestionsByProduct = (id) => {
  return axios.get(`${API_URL}/products/${id}`).then((response) => {
    return response.data;
  });
};
const deleteSurveyQuestionFromProduct = (productId, questionId) => {
  return axios
    .put(`${API_URL}/products/${productId}/questions/${questionId}`)
    .then((response) => {
      return response.data;
    });
};

const createSurveyQuestion = (data) => {
  return axios.post(API_URL, data).then((response) => {
    return response.data;
  });
};

const addSurveyQuestionToProduct = (productId, data) => {
  return axios
    .post(`${API_URL}/products/${productId}/questions/new`, data)
    .then((response) => {
      return response.data;
    });
};

const updateSurveyQuestion = (id, data) => {
  return axios.put(`${API_URL}/${id}`, data).then((response) => {
    return response.data;
  });
};

const deleteSurveyQuestion = (id) => {
  return axios.delete(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

export const surveyQuestionService = {
  getAllSurveyQuestions,
  getSurveyQuestionById,
  createSurveyQuestion,
  updateSurveyQuestion,
  deleteSurveyQuestion,
  getSurveyQuestionsByProduct,
  deleteSurveyQuestionFromProduct,
  addSurveyQuestionToProduct,
};
