import { toast } from "react-toastify";
import { saveAs } from "file-saver";

import {
  FETCH_LAST_NOTE_REQUEST,
  FETCH_LAST_NOTE_SUCCESS,
  FETCH_LAST_NOTE_FAILURE,
  FETCH_IA_ITERPRETATION_REQUEST,
  FETCH_IA_ITERPRETATION_SUCCESS,
  FETCH_IA_ITERPRETATION_FAILURE,
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAILURE,
  FETCH_CLIENT_EMPLOYEES_REQUEST,
  FETCH_CLIENT_EMPLOYEES_SUCCESS,
  FETCH_CLIENT_EMPLOYEES_FAILURE,
  FETCH_MEETING_REQUEST,
  FETCH_MEETING_SUCCESS,
  FETCH_MEETING_FAILURE,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  FETCH_CLIENT_ORDERS_REQUEST,
  FETCH_CLIENT_ORDERS_SUCCESS,
  FETCH_CLIENT_ORDERS_FAILURE,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENT_FAILURE,
  FETCH_CLIENT_ORDER_REQUEST,
  FETCH_CLIENT_ORDER_SUCCESS,
  FETCH_CLIENT_ORDER_FAILURE,
  ADD_CLIENT_ORDER_REQUEST,
  ADD_CLIENT_ORDER_SUCCESS,
  ADD_CLIENT_ORDER_FAILURE,
  UPDATE_CLIENT_ORDER_REQUEST,
  UPDATE_CLIENT_ORDER_SUCCESS,
  UPDATE_CLIENT_ORDER_FAILURE,
  DELETE_CLIENT_ORDER_REQUEST,
  DELETE_CLIENT_ORDER_SUCCESS,
  DELETE_CLIENT_ORDER_FAILURE,
  FETCH_TICKET_REQUEST,
  FETCH_TICKET_SUCCESS,
  FETCH_TICKET_FAILURE,
  FETCH_NOTES_REQUEST,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_FAILURE,
  ADD_NOTE_REQUEST,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_FAILURE,
  ADD_CLIENT_TICKET_REQUEST,
  ADD_CLIENT_TICKET_SUCCESS,
  ADD_CLIENT_TICKET_FAILURE,
  UPDATE_CLIENT_TICKET_REQUEST,
  UPDATE_CLIENT_TICKET_SUCCESS,
  UPDATE_CLIENT_TICKET_FAILURE,
  FETCH_TODOS_REQUEST,
  FETCH_TODOS_SUCCESS,
  FETCH_TODOS_FAILURE,
  ADD_TODOS_REQUEST,
  ADD_TODOS_SUCCESS,
  ADD_TODOS_FAILURE,
  UPDATE_TODOS_REQUEST,
  UPDATE_TODOS_SUCCESS,
  UPDATE_TODOS_FAILURE,
  GENERATE_SURVEY_REQUEST,
  GENERATE_SURVEY_SUCCESS,
  GENERATE_SURVEY_FAILURE,
  FETCH_TINY_CLIENTS_REQUEST,
  FETCH_TINY_CLIENTS_SUCCESS,
  FETCH_TINY_CLIENTS_FAILURE,
  FETCH_DASHBOARD_CLIENTS_REQUEST,
  FETCH_DASHBOARD_CLIENTS_SUCCESS,
  FETCH_DASHBOARD_CLIENTS_FAILURE,
  UPLOAD_ORDER_DOCUMENT_REQUEST,
  UPLOAD_ORDER_DOCUMENT_SUCCESS,
  UPLOAD_ORDER_DOCUMENT_FAILURE,
  UPDATE_BRIEF_REQUEST,
  UPDATE_BRIEF_SUCCESS,
  UPDATE_BRIEF_FAILURE,
  UPDATE_QUALITY_REQUEST,
  UPDATE_QUALITY_SUCCESS,
  UPDATE_QUALITY_FAILURE,
  FETCH_CLIENT_ORDER_WITH_NOTES_REQUEST,
  FETCH_CLIENT_ORDER_WITH_NOTES_SUCCESS,
  FETCH_CLIENT_ORDER_WITH_NOTES_FAILURE,
  ADD_ORDER_NOTE_REQUEST,
  ESTIMATE_ORDER_REQUEST,
  ESTIMATE_ORDER_SUCCESS,
  ESTIMATE_ORDER_UNAUTHORIZED,
  ESTIMATE_ORDER_FAILURE,
  ADD_ORDER_NOTE_FAILURE,
  GENERATE_QUALITY_CRITERIONS_REQUEST,
  GENERATE_QUALITY_CRITERIONS_SUCCESS,
  GENERATE_QUALITY_CRITERIONS_FAILURE,
  GENERATE_BRIEF_QUESTIONS_REQUEST,
  GENERATE_BRIEF_QUESTIONS_SUCCESS,
  GENERATE_BRIEF_QUESTIONS_FAILURE,
  FETCH_GOOGLE_MCCS_REQUEST,
  FETCH_GOOGLE_MCCS_SUCCESS,
  FETCH_GOOGLE_MCCS_FAILURE,
  FETCH_GOOGLE_ACCOUNTS_REQUEST,
  FETCH_GOOGLE_ACCOUNTS_SUCCESS,
  FETCH_GOOGLE_ACCOUNTS_FAILURE,
  DELETE_ORDER_QUALITY_CHECK_REQUEST,
  DELETE_ORDER_QUALITY_CHECK_SUCCESS,
  DELETE_ORDER_QUALITY_CHECK_FAILURE,
  ADD_ORDER_QUALITY_CHECK_REQUEST,
  ADD_ORDER_QUALITY_CHECK_SUCCESS,
  ADD_ORDER_QUALITY_CHECK_FAILURE,
  FETCH_ADS_REPORTS_REQUEST,
  FETCH_ADS_REPORTS_SUCCESS,
  FETCH_ADS_REPORTS_FAILURE,
  FETCH_CLIENT_ADS_REPORTS_REQUEST,
  FETCH_CLIENT_ADS_REPORTS_SUCCESS,
  FETCH_CLIENT_ADS_REPORTS_FAILURE,
  FETCH_SURVEY_REQUEST,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_FAILURE,
  DOWNLOADING_DOCUMENT_REQUEST,
  DOWNLOADING_DOCUMENT_SUCCESS,
  DOWNLOADING_DOCUMENT_FAILURE,
  CREATE_MEETING_REQUEST,
  CREATE_MEETING_SUCCESS,
  CREATE_MEETING_FAILURE,
} from "../actionTypes";

import { clientService } from "../services/clientService";

// ------ CLIENT

export const UpdateDraggedEvent = (eventId, updatedData) => {
  return {
    type: "UPDATE_DRAGGED_EVENT",
    payload: {
      eventId,
      updatedData,
    },
  };
};

// Action Creator pour récupérer la liste des clients
export const fetchClients = (page, limit, data = {}) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CLIENTS_REQUEST });
    try {
      const response = await clientService.getAllClients(page, limit, data);
      dispatch({ type: FETCH_CLIENTS_SUCCESS, payload: response });
    } catch (err) {
      dispatch({ type: FETCH_CLIENTS_FAILURE, payload: err.message });
    }
  };
};

export const fetchClientEmployees = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CLIENT_EMPLOYEES_REQUEST });
    try {
      const response = await clientService.fetchClientEmployees(clientId);
      dispatch({ type: FETCH_CLIENT_EMPLOYEES_SUCCESS, payload: response });
    } catch (err) {
      dispatch({ type: FETCH_CLIENT_EMPLOYEES_FAILURE, payload: err.message });
    }
  };
};

export const fetchMeeting = (clientId, todoId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_MEETING_REQUEST });
    try {
      const response = await clientService.fetchMeeting(clientId, todoId);
      dispatch({ type: FETCH_MEETING_SUCCESS, payload: response });
    } catch (err) {
      dispatch({ type: FETCH_MEETING_FAILURE, payload: err.message });
    }
  };
};

export const createMeeting = (clientId, data) => {
  return async (dispatch) => {
    // dispatch({ type: CREATE_MEETING_REQUEST });
    try {
      const response = await clientService.createMeeting(clientId, data);

      console.log(data.todoId, response._id);
      dispatch(UpdateDraggedEvent(data.todoId, { meet: response._id }));

      dispatch({ type: FETCH_MEETING_SUCCESS, payload: response });
    } catch (err) {
      // dispatch({ type: CREATE_MEETING_FAILURE, payload: err.message });
    }
  };
};

export const fetchTinyClients = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_TINY_CLIENTS_REQUEST });
    try {
      const response = await clientService.fetchTinyClients();
      dispatch({ type: FETCH_TINY_CLIENTS_SUCCESS, payload: response });
    } catch (err) {
      dispatch({ type: FETCH_TINY_CLIENTS_FAILURE, payload: err.message });
    }
  };
};

export const fetchDashboardClients = (data) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_DASHBOARD_CLIENTS_REQUEST });
    try {
      const response = await clientService.fetchDashboardClients(data);
      dispatch({ type: FETCH_DASHBOARD_CLIENTS_SUCCESS, payload: response });
    } catch (err) {
      dispatch({ type: FETCH_DASHBOARD_CLIENTS_FAILURE, payload: err.message });
    }
  };
};

export const fetchClientNotes = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_NOTES_REQUEST });
    try {
      const notes = await clientService.getclientsNotes(clientId);
      dispatch({ type: FETCH_NOTES_SUCCESS, payload: notes });
    } catch (err) {
      dispatch({ type: FETCH_NOTES_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour ajouter un client
export const addClient = (clientData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_CLIENT_REQUEST });
    try {
      const newClient = await clientService.createClient(clientData);
      toast.success("Client ajouté avec succès");
      dispatch({ type: ADD_CLIENT_SUCCESS, payload: newClient });
    } catch (err) {
      dispatch({ type: ADD_CLIENT_FAILURE, payload: err.message });
    }
  };
};

export const addClientNote = (clientId, orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: ADD_NOTE_REQUEST });
    try {
      const newNote = await clientService.addClientNote(clientId, orderId, data);
      dispatch({ type: ADD_NOTE_SUCCESS, payload: newNote });
      dispatch({ type: FETCH_NOTES_REQUEST });
    } catch (err) {
      dispatch({ type: ADD_NOTE_FAILURE, payload: err.message });
    }
  };
};

export const genrateQualityCriterions = (clientId, orderId) => {
  return async (dispatch) => {
    dispatch({ type: GENERATE_QUALITY_CRITERIONS_REQUEST });
    try {
      const order = await clientService.genrateQualityCriterions(clientId, orderId);
      dispatch({ type: GENERATE_QUALITY_CRITERIONS_SUCCESS, payload: order });
    } catch (err) {
      dispatch({ type: GENERATE_QUALITY_CRITERIONS_FAILURE, payload: err.message });
    }
  };
};

export const genrateBriefQuestions = (clientId, orderId) => {
  return async (dispatch) => {
    dispatch({ type: GENERATE_BRIEF_QUESTIONS_REQUEST });
    try {
      const order = await clientService.genrateBriefQuestions(clientId, orderId);
      dispatch({ type: GENERATE_BRIEF_QUESTIONS_SUCCESS, payload: order });
    } catch (err) {
      dispatch({ type: GENERATE_BRIEF_QUESTIONS_FAILURE, payload: err.message });
    }
  };
};

export const addOrderNote = (clientId, orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: ADD_ORDER_NOTE_REQUEST });
    try {
      const note = await clientService.addClientNote(clientId, orderId, data);
      // dispatch({ type: ADD_ORDER_NOTE_SUCCESS, payload: newNote });
      toast.success("Note ajouté avec succès");
      dispatch(fetchLastNoteSuccess(data.plateform, note));
    } catch (err) {
      dispatch({ type: ADD_ORDER_NOTE_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour modifier un client
export const updateClient = (clientId, clientData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CLIENT_REQUEST });
    try {
      const updatedClient = await clientService.updateClient(clientId, clientData);
      toast.success("Client mis à jours avec succès");
      dispatch({ type: UPDATE_CLIENT_SUCCESS, payload: updatedClient.client });
    } catch (err) {
      dispatch({ type: UPDATE_CLIENT_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour supprimer un client
export const deleteClient = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CLIENT_REQUEST });
    try {
      await clientService.deleteClient(clientId);
      toast.success("Client supprimé avec succès");
      dispatch({ type: DELETE_CLIENT_SUCCESS, payload: clientId });
    } catch (err) {
      dispatch({ type: DELETE_CLIENT_FAILURE, payload: err.message });
    }
  };
};

// ------ CLIENT ORDER

export const estimateOrderCost = (clientId, orderId) => {
  return async (dispatch) => {
    dispatch({ type: ESTIMATE_ORDER_REQUEST });
    try {
      const response = await clientService.estimateOrderCost(clientId, orderId);
      console.log(response);
      if (response.authorized) {
        dispatch({ type: ESTIMATE_ORDER_SUCCESS, payload: response.orderEstimation });
      } else {
        dispatch({ type: ESTIMATE_ORDER_UNAUTHORIZED, payload: response.message });
      }
    } catch (err) {
      dispatch({ type: ESTIMATE_ORDER_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour récupérer la liste des orders d'un client
export const fetchAdsReports = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_ADS_REPORTS_REQUEST });
    try {
      const ads_reports = await clientService.fetchAdsReports();
      dispatch({ type: FETCH_ADS_REPORTS_SUCCESS, payload: ads_reports });
    } catch (err) {
      dispatch({ type: FETCH_ADS_REPORTS_FAILURE, payload: err.message });
    }
  };
};

export const fetchClientAdsReports = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CLIENT_ADS_REPORTS_REQUEST });
    try {
      const ads_reports = await clientService.fetchClientAdsReports(clientId);
      dispatch({ type: FETCH_CLIENT_ADS_REPORTS_SUCCESS, payload: ads_reports });
    } catch (err) {
      dispatch({ type: FETCH_CLIENT_ADS_REPORTS_FAILURE, payload: err.message });
    }
  };
};

export const fetchClientOrders = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CLIENT_ORDERS_REQUEST });
    try {
      const client = await clientService.getAllOrdersByClientId(clientId);
      dispatch({ type: FETCH_CLIENT_ORDERS_SUCCESS, payload: client });
    } catch (err) {
      dispatch({ type: FETCH_CLIENT_ORDERS_FAILURE, payload: err.message });
    }
  };
};

export const fetchClientById = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CLIENT_REQUEST });
    try {
      const client = await clientService.getClientById(clientId);
      dispatch({ type: FETCH_CLIENT_SUCCESS, payload: client });
    } catch (err) {
      dispatch({ type: FETCH_CLIENT_FAILURE, payload: err.message });
    }
  };
};

export const fetchOrderById = (clientId, orderId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CLIENT_ORDER_REQUEST });
    try {
      const order = await clientService.getOrderById(clientId, orderId);
      dispatch({ type: FETCH_CLIENT_ORDER_SUCCESS, payload: order });
    } catch (err) {
      dispatch({ type: FETCH_CLIENT_ORDER_FAILURE, payload: err.message });
    }
  };
};

export const fetchOrderWithNotesById = (clientId, orderId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CLIENT_ORDER_WITH_NOTES_REQUEST });
    try {
      const order = await clientService.fetchOrderWithNotesById(clientId, orderId);
      dispatch({ type: FETCH_CLIENT_ORDER_WITH_NOTES_SUCCESS, payload: order });
    } catch (err) {
      dispatch({ type: FETCH_CLIENT_ORDER_WITH_NOTES_FAILURE, payload: err.message });
    }
  };
};

export const fetchOrderFbInsight = (clientId, orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_FB_INSIGHT_REQUEST" });
    try {
      const insight = await clientService.getOrderFbInsight(clientId, orderId, data);
      dispatch({ type: "FETCH_FB_INSIGHT_SUCCESS", payload: insight });
    } catch (err) {
      dispatch({ type: "FETCH_FB_INSIGHT_FAILURE", payload: err.message });
    }
  };
};

export const fetchOrderGAInsight = (clientId, orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_GA_INSIGHT_REQUEST" });
    try {
      const insight = await clientService.getOrderGAInsight(clientId, orderId, data);
      dispatch({ type: "FETCH_GA_INSIGHT_SUCCESS", payload: insight });
    } catch (err) {
      dispatch({ type: "FETCH_GA_INSIGHT_FAILURE", payload: err.message });
    }
  };
};
export const fetchOrderGAdsInsight = (clientId, orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_GADS_INSIGHT_REQUEST" });
    try {
      const insight = await clientService.getOrderGAdsInsight(clientId, orderId, data);
      dispatch({ type: "FETCH_GADS_INSIGHT_SUCCESS", payload: insight });
    } catch (err) {
      dispatch({ type: "FETCH_GADS_INSIGHT_FAILURE", payload: err.message });
    }
  };
};

// Suggestion
export const fetchIaIterpretationRequest = () => ({
  type: FETCH_IA_ITERPRETATION_REQUEST,
});

export const fetchIaIterpretationSuccess = (plateform, suggestion) => ({
  type: FETCH_IA_ITERPRETATION_SUCCESS,
  payload: { plateform, suggestion },
});

export const fetchIaIterpretationFailure = (error) => ({
  type: FETCH_IA_ITERPRETATION_FAILURE,
  payload: error,
});

export const fetchIaIterpretationFads = (clientId, orderId, insight) => {
  return async (dispatch) => {
    dispatch(fetchIaIterpretationRequest());
    try {
      const suggestion = await clientService.fetchIaIterpretationFads(
        clientId,
        orderId,
        insight
      );
      dispatch(fetchIaIterpretationSuccess("fads", suggestion));
    } catch (err) {
      dispatch(fetchIaIterpretationFailure(err.message));
    }
  };
};
export const fetchIaIterpretationGads = (clientId, orderId, insight) => {
  return async (dispatch) => {
    dispatch(fetchIaIterpretationRequest());
    try {
      const suggestion = await clientService.fetchIaIterpretationgGads(
        clientId,
        orderId,
        insight
      );
      dispatch(fetchIaIterpretationSuccess("gads", suggestion));
    } catch (err) {
      dispatch(fetchIaIterpretationFailure(err.message));
    }
  };
};
export const fetchIaIterpretationGa = (clientId, orderId, insight) => {
  return async (dispatch) => {
    dispatch(fetchIaIterpretationRequest());
    try {
      const suggestion = await clientService.fetchIaIterpretationGa(
        clientId,
        orderId,
        insight
      );
      dispatch(fetchIaIterpretationSuccess("ga", suggestion));
    } catch (err) {
      dispatch(fetchIaIterpretationFailure(err.message));
    }
  };
};

// Last note

export const fetchLastNoteRequest = () => ({
  type: FETCH_LAST_NOTE_REQUEST,
});

export const fetchLastNoteSuccess = (plateform, note) => ({
  type: FETCH_LAST_NOTE_SUCCESS,
  payload: { plateform, note },
});

export const fetchLastNoteFailure = (error) => ({
  type: FETCH_LAST_NOTE_FAILURE,
  payload: error,
});

export const fetchLastNote = (clientId, orderId, noteTitle, plateform) => {
  return async (dispatch) => {
    dispatch(fetchLastNoteRequest());
    try {
      const note = await clientService.fetchLastNote(clientId, orderId, noteTitle);

      dispatch(fetchLastNoteSuccess(plateform, note));
    } catch (err) {
      dispatch(fetchLastNoteFailure(err.message));
    }
  };
};

export const addClientOrder = (clientId, orderData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_CLIENT_ORDER_REQUEST });
    try {
      await clientService.createClientOrder(clientId, orderData);
      dispatch({ type: ADD_CLIENT_ORDER_SUCCESS });
      toast.success("Commande ajouté avec succès");
      dispatch(fetchClientOrders(clientId));
    } catch (err) {
      console.log(err);
      dispatch({ type: ADD_CLIENT_ORDER_FAILURE, payload: err.message });
    }
  };
};

export const updateClientOrder = (clientId, orderId, orderData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CLIENT_ORDER_REQUEST });
    try {
      await clientService.updateClientOrder(clientId, orderId, orderData);
      dispatch({ type: UPDATE_CLIENT_ORDER_SUCCESS });
      toast.success("Commande mise à jours avec succès");
      dispatch(fetchClientOrders(clientId));
      dispatch(fetchOrderById(clientId, orderId));
    } catch (err) {
      dispatch({ type: UPDATE_CLIENT_ORDER_FAILURE, payload: err.message });
    }
  };
};

export const updateBriefResponses = (clientId, orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_BRIEF_REQUEST });
    try {
      await clientService.updateBriefResponses(clientId, orderId, data);
      toast.success("Critére de qualité mis à jours avec succès");
      dispatch({ type: UPDATE_BRIEF_SUCCESS });
    } catch (err) {
      dispatch({ type: UPDATE_BRIEF_FAILURE, payload: err.message });
    }
  };
};

export const updateQualityCriterionChecks = (clientId, orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_QUALITY_REQUEST });
    try {
      await clientService.updateQualityCriterionChecks(clientId, orderId, data);
      toast.success("Cahier de charge mis à jours avec succès");
      dispatch({ type: UPDATE_QUALITY_SUCCESS });
    } catch (err) {
      dispatch({ type: UPDATE_QUALITY_FAILURE, payload: err.message });
    }
  };
};

export const deleteOrderQualityCheck = (clientId, orderId, qualityId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_ORDER_QUALITY_CHECK_REQUEST });
    try {
      await clientService.deleteOrderQualityCheck(clientId, orderId, qualityId);
      toast.success("Supression avec succès");
      dispatch({ type: DELETE_ORDER_QUALITY_CHECK_SUCCESS, payload: qualityId });
    } catch (err) {
      dispatch({ type: DELETE_ORDER_QUALITY_CHECK_FAILURE, payload: err.message });
    }
  };
};

export const addQualityCriterionChecks = (clientId, orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: ADD_ORDER_QUALITY_CHECK_REQUEST });
    try {
      const quality = await clientService.addQualityCriterionChecks(
        clientId,
        orderId,
        data
      );
      toast.success("Ajoue avec succès");
      dispatch({ type: ADD_ORDER_QUALITY_CHECK_SUCCESS, payload: quality });
    } catch (err) {
      dispatch({ type: ADD_ORDER_QUALITY_CHECK_FAILURE, payload: err.message });
    }
  };
};

export const uploadDocument = (clientId, orderId, documentData) => {
  return async (dispatch) => {
    dispatch({ type: UPLOAD_ORDER_DOCUMENT_REQUEST });
    try {
      const document = await clientService.uploadDocument(
        clientId,
        orderId,
        documentData
      );
      dispatch({ type: UPLOAD_ORDER_DOCUMENT_SUCCESS, payload: document });
      toast.success("Document ajouté avec succès");
    } catch (err) {
      console.log(err);
      dispatch({ type: UPLOAD_ORDER_DOCUMENT_FAILURE, payload: err.message });
    }
  };
};

export function downloadDocument(clientId, orderId, docId) {
  return async (dispatch) => {
    dispatch({ type: DOWNLOADING_DOCUMENT_REQUEST });
    clientService
      .downloadDocument(clientId, orderId, docId)
      .then((response) => {
        dispatch({ type: DOWNLOADING_DOCUMENT_SUCCESS });
        const contentType =
          response.headers["content-type"] || "application/octet-stream";
        const contentDisposition = response.headers["content-disposition"];
        let filename = "telechargement";

        if (contentDisposition) {
          const matches = contentDisposition.match(/filename="([^"]+)"/);
          if (matches.length === 2) {
            filename = matches[1];
          }
        }

        const blob = new Blob([response.data], { type: contentType });
        saveAs(blob, filename);
      })
      .catch((error) => {
        dispatch({ type: DOWNLOADING_DOCUMENT_FAILURE });
        console.error("Error downloading document:", error);
        // Handle the error here
      });
  };
}

export const deleteClientOrder = (clientId, orderId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CLIENT_ORDER_REQUEST });
    try {
      await clientService.deleteClientOrder(clientId, orderId);
      dispatch({ type: DELETE_CLIENT_ORDER_SUCCESS, payload: orderId });
      toast.success("Commande supprimé avec succès");
    } catch (err) {
      dispatch({ type: DELETE_CLIENT_ORDER_FAILURE, payload: err.message });
    }
  };
};

// ------ CLIENT ORDER TICKET

export function downloadPdf(clientId, orderId) {
  return async (dispatch) => {
    clientService.downloadPdf(clientId, orderId).then((res) => {
      const blob = new Blob([res], { type: "application/pdf" });
      saveAs(blob, "Cahier_de_charge.pdf");
    });
  };
}

// Action Creator pour créer un nouveau ticket pour une commande
export const addNewTicketForOrder = (clientId, orderId, ticketData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_CLIENT_TICKET_REQUEST });
    try {
      await clientService.createNewTicketForOrder(clientId, orderId, ticketData);
      dispatch({ type: ADD_CLIENT_TICKET_SUCCESS });
      dispatch(fetchOrderById(clientId, orderId));
      toast.success("Demande crée avec succès");
    } catch (err) {
      dispatch({ type: ADD_CLIENT_TICKET_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour modifier un ticket d'une commande
export const updateTicket = (clientId, orderId, ticketId, ticketData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CLIENT_TICKET_REQUEST });
    try {
      await clientService.updateTicket(clientId, orderId, ticketId, ticketData);
      dispatch({ type: UPDATE_CLIENT_TICKET_SUCCESS });
      dispatch(fetchOrderById(clientId, orderId));
      dispatch(fetchTicket(clientId, orderId, ticketId));
      toast.success("Demande mise à jours avec succès");
    } catch (err) {
      dispatch({ type: UPDATE_CLIENT_TICKET_FAILURE, payload: err.message });
    }
  };
};

export const convertToTicket = (clientId, orderId, ticketId, data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CLIENT_TICKET_REQUEST });
    try {
      await clientService.convertToTicket(clientId, orderId, ticketId, data);
      dispatch({ type: UPDATE_CLIENT_TICKET_SUCCESS });
      dispatch(fetchOrderById(clientId, orderId));
      toast.success("Demande convertis en TODO avec succès");
    } catch (err) {
      dispatch({ type: UPDATE_CLIENT_TICKET_FAILURE, payload: err.message });
    }
  };
};

export const fetchTicket = (clientId, orderId, ticketId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_TICKET_REQUEST });
    try {
      const ticket = await clientService.fetchTicket(clientId, orderId, ticketId);
      dispatch({ type: FETCH_TICKET_SUCCESS, payload: ticket });
    } catch (err) {
      dispatch({ type: FETCH_TICKET_FAILURE, payload: err.message });
    }
  };
};

export const fetchTodosOrder = (clientId, orderId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_TODOS_REQUEST });
    try {
      const todos = await clientService.fetchTodosOrder(clientId, orderId);
      dispatch({ type: FETCH_TODOS_SUCCESS, payload: todos });
    } catch (err) {
      dispatch({ type: FETCH_TODOS_FAILURE, payload: err.message });
    }
  };
};
export const createTodosOrder = (clientId, orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: ADD_TODOS_REQUEST });
    try {
      const todos = await clientService.createTodosOrder(clientId, orderId, data);
      dispatch({ type: ADD_TODOS_SUCCESS, payload: todos });
    } catch (err) {
      dispatch({ type: ADD_TODOS_FAILURE, payload: err.message });
    }
  };
};

// devide task
export const devideTask = (clientId, orderId, id, data) => {
  return async (dispatch) => {
    try {
      const todos = await clientService.devideTask(clientId, orderId, id, data);

      dispatch({ type: ADD_TODOS_SUCCESS, payload: todos });
    } catch (err) {
      dispatch({ type: ADD_CLIENT_TICKET_FAILURE, payload: err.message });
    }
  };
};

export const updateTodosOrder = (clientId, orderId, id, data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_TODOS_REQUEST });
    try {
      const todos = await clientService.updateTodosOrder(clientId, orderId, id, data);
      console.log(todos);

      dispatch({ type: UPDATE_TODOS_SUCCESS, payload: todos });
    } catch (err) {
      dispatch({ type: UPDATE_TODOS_FAILURE, payload: err.message });
    }
  };
};

export const connectGoogle = (clientId) => {
  return async (dispatch) => {
    try {
      const response = await clientService.connectGoogle(clientId);
      if (response.url) {
        window.location.href = response.url;
      }
    } catch (err) {
      console.log("toto", err);
    }
  };
};

export const logoutGoogle = (clientId) => {
  return async (dispatch) => {
    try {
      await clientService.logoutGoogle(clientId);
      dispatch(fetchClientById(clientId));
    } catch (err) {
      console.log("toto", err);
    }
  };
};

export const fetchGoogleMccs = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_GOOGLE_MCCS_REQUEST });
    try {
      const mccs = await clientService.fetchGoogleMccs(clientId);
      dispatch({ type: FETCH_GOOGLE_MCCS_SUCCESS, payload: mccs });
    } catch (err) {
      dispatch({ type: FETCH_GOOGLE_MCCS_FAILURE, payload: err.message });
      console.log("toto", err);
    }
  };
};

export const fetchGoogleAccounts = (clientId, data) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_GOOGLE_ACCOUNTS_REQUEST });
    try {
      const accounts = await clientService.fetchGoogleAccounts(clientId, data);
      dispatch({ type: FETCH_GOOGLE_ACCOUNTS_SUCCESS, payload: accounts });
    } catch (err) {
      dispatch({ type: FETCH_GOOGLE_ACCOUNTS_FAILURE, payload: err.message });
      console.log("toto", err);
    }
  };
};

// ------ survey

export const generateSurvey = (clientId, orderId) => {
  return async (dispatch) => {
    dispatch({ type: GENERATE_SURVEY_REQUEST });
    try {
      await clientService.generateSurvey(clientId, orderId).then((survey) => {
        dispatch({ type: GENERATE_SURVEY_SUCCESS, payload: survey });
        toast.success("L'enquête de satisfaction a été générée avec succès.");
      });
    } catch (err) {
      console.log("toto", err);
      dispatch({ type: GENERATE_SURVEY_FAILURE });
    }
  };
};
export const fetchSurveyById = (clientId, orderId, surveyId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SURVEY_REQUEST });
    try {
      clientService.fetchSurveyById(clientId, orderId, surveyId).then((data) => {
        dispatch({ type: FETCH_SURVEY_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: FETCH_SURVEY_FAILURE, payload: err.message });
    }
  };
};
