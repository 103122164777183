import axios from "axios";

const BASE_URL = "/api/cli/orders";

export const eCalendarService = {
  createECalendar: async (orderId, eCalendarData) => {
    const response = await axios.post(`${BASE_URL}/${orderId}/eCalendars`, eCalendarData);
    return response.data;
  },

  getECalendars: async (orderId) => {
    const response = await axios.get(`${BASE_URL}/${orderId}/eCalendars`);
    return response.data;
  },

  getECalendar: async (orderId, eCalendarId) => {
    const response = await axios.get(`${BASE_URL}/${orderId}/eCalendars/${eCalendarId}`);
    return response.data;
  },

  updateECalendar: async (orderId, eCalendarId, eCalendarData) => {
    const response = await axios.put(
      `${BASE_URL}/${orderId}/eCalendars/${eCalendarId}`,
      eCalendarData
    );
    return response.data;
  },
  getOrderById: async (orderId) => {
    const response = await axios.get(`${BASE_URL}/${orderId}`);
    return response.data;
  },

  deleteECalendar: async (orderId, eCalendarId) => {
    await axios.delete(`${BASE_URL}/${orderId}/eCalendars/${eCalendarId}`);
  },

  getPosts: async (orderId, eCalendarId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/${orderId}/eCalendars/${eCalendarId}/posts`
      );
      return response.data;
    } catch (error) {
      console.error("Error while fetching posts", error);
      throw error;
    }
  },
  getPostById: async (orderId, eCalendarId, postId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/${orderId}/eCalendars/${eCalendarId}/posts/${postId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error while fetching posts", error);
      throw error;
    }
  },
  deletePost: async (orderId, eCalendarId, postId) => {
    try {
      await axios.delete(
        `${BASE_URL}/${orderId}/eCalendars/${eCalendarId}/posts/${postId}`
      );
    } catch (error) {
      console.error("Error while deleting post", error);
      throw error;
    }
  },
  addPost: async (orderId, eCalendarId, postData) => {
    const response = await axios.post(
      `${BASE_URL}/${orderId}/eCalendars/${eCalendarId}/posts`,
      postData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  },
  deleteImagePost: async (orderId, eCalendarId, postId, photoId) => {
    const response = await axios.delete(
      `${BASE_URL}/${orderId}/eCalendars/${eCalendarId}/posts/${postId}/image/${photoId}`
    );
    return response.data;
  },

  updatePost: async (orderId, eCalendarId, postId, postData) => {
    const response = await axios.put(
      `${BASE_URL}/${orderId}/eCalendars/${eCalendarId}/posts/${postId}`,
      postData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  },
  notifyCommunity: async (orderId, eCalendarId, postId) => {
    const response = await axios.post(
      `${BASE_URL}/${orderId}/eCalendars/${eCalendarId}/posts/${postId}/notify`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  },
  updatePriorities: async (orderId, eCalendarId, data) => {
    const response = await axios.post(
      `${BASE_URL}/${orderId}/eCalendars/${eCalendarId}/posts/priorities`,
      data
    );
    return response.data;
  },
};
