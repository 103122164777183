import { useState } from "react";
import { Col, ProgressBar } from "react-bootstrap";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faChartBar } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import { differenceInDays, isBefore } from "date-fns";
import { responsiblesFormat } from "../../../utils/screen";

const OrderCard = ({ order, clientId }) => {
  const RatingStars = ({ rating }) => {
    const totalStars = 5;
    let stars = [];

    for (let i = 0; i < totalStars; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<span className="star full" key={`star_${i}`}></span>);
      } else if (i < rating) {
        stars.push(<span className="star half" key={`star_${i}`}></span>);
      } else {
        stars.push(<span className="star empty" key={`star_${i}`}></span>);
      }
    }

    return <div className="rating-stars mx-3">{stars}</div>;
  };

  return (
    <>
      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} key={order._id}>
        <Link to={`/client/${clientId}/orders/${order._id}/tickets`}>
          <div className="card purple-border mb-4 card-order">
            <div className="card-body py-2 px-3">
              <div className="text-center py-5">
                <div>
                  <h6 className="mt-3">
                    <span className="product-title px-1 text-dark">
                      {order.product.name}
                    </span>

                    {order.with_open_tickets && (
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="px-2 pt-1"
                        color="#FF0000"
                      />
                    )}
                  </h6>
                </div>

                <div>
                  <small>{responsiblesFormat(order)}</small>
                </div>
                <div>
                  <RatingStars rating={order.averageRating} />
                </div>
              </div>

              <hr />
              <div className="flex flex-wrap gap-3 justify-content-around mb-2">
                <ul className="order-steps ">
                  <li className={order.team1Accepted ? "active" : ""}>
                    <span>Validé</span>
                  </li>
                  <li className={order.team1Accepted ? "active" : ""}>
                    <span>En cours</span>
                  </li>
                  <li className={order.produced ? "active" : ""}>
                    <span>Produit</span>
                  </li>
                  <li className={order.orderCompleted ? "active" : ""}>
                    <span>Finalisé</span>
                  </li>
                </ul>
              </div>
              {order.startDate && (
                <div className="date-status">
                  <h6 className="">
                    <small className="product-title px-2 text-dark">
                      {moment(order.startDate).format("DD-MM-YYYY")}{" "}
                    </small>
                  </h6>
                  <OrderProgress startDate={order.startDate} endDate={order.endDate} />
                  <h6 className="">
                    <small className="product-title px-2 text-dark">
                      {moment(order.endDate).format("DD-MM-YYYY")}{" "}
                    </small>
                  </h6>
                </div>
              )}
            </div>
          </div>
        </Link>
      </Col>
    </>
  );
};

const OrderProgress = ({ startDate, endDate }) => {
  const currentDate = moment();

  if (isBefore(new Date(endDate), new Date())) {
    // Si la date de fin est passée
    return <ProgressBar now={100} label="100%" />;
  }

  if (isBefore(new Date(), new Date(startDate))) {
    // Si la date de début n'est pas encore atteinte
    return <ProgressBar now={0} label="0%" />;
  }

  const totalDays = differenceInDays(new Date(endDate), new Date(startDate));
  const daysPassed = differenceInDays(new Date(currentDate), new Date(startDate));

  const progressPercentage = (daysPassed / totalDays) * 100;

  return (
    <ProgressBar
      now={progressPercentage}
      label={`${Math.round(progressPercentage)}%`}
      min-width="300px"
      className="status-progress"
    />
  );
};

export default OrderCard;
