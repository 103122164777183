export const CLI_FETCH_CLIENTS_REQUEST = "CLI_FETCH_CLIENTS_REQUEST";
export const CLI_FETCH_CLIENTS_SUCCESS = "CLI_FETCH_CLIENTS_SUCCESS";
export const CLI_FETCH_CLIENTS_FAILURE = "CLI_FETCH_CLIENTS_FAILURE";

export const CLI_FETCH_CLIENT_ORDERS_REQUEST = "CLI_FETCH_CLIENT_ORDERS_REQUEST";
export const CLI_FETCH_CLIENT_ORDERS_SUCCESS = "CLI_FETCH_CLIENT_ORDERS_SUCCESS";
export const CLI_FETCH_CLIENT_ORDERS_FAILURE = "CLI_FETCH_CLIENT_ORDERS_FAILURE";

export const CLI_FETCH_TINY_CLIENT_REQUEST = "CLI_FETCH_TINY_CLIENT_REQUEST";
export const CLI_FETCH_TINY_CLIENT_SUCCESS = "CLI_FETCH_TINY_CLIENT_SUCCESS";
export const CLI_FETCH_TINY_CLIENT_FAILURE = "CLI_FETCH_TINY_CLIENT_FAILURE";

export const CLI_FETCH_SURVEY_REQUEST = "CLI_FETCH_SURVEY_REQUEST";
export const CLI_FETCH_SURVEY_SUCCESS = "CLI_FETCH_SURVEY_SUCCESS";
export const CLI_FETCH_SURVEY_FAILURE = "CLI_FETCH_SURVEY_FAILURE";

export const CLI_FETCH_ECALENDARS_REQUEST = "CLI_FETCH_ECALENDARS_REQUEST";
export const CLI_FETCH_ECALENDARS_SUCCESS = "CLI_FETCH_ECALENDARS_SUCCESS";
export const CLI_FETCH_ECALENDARS_FAILURE = "CLI_FETCH_ECALENDARS_FAILURE";

export const CLI_ADD_ECALENDAR_REQUEST = "CLI_ADD_ECALENDAR_REQUEST";
export const CLI_ADD_ECALENDAR_SUCCESS = "CLI_ADD_ECALENDAR_SUCCESS";
export const CLI_ADD_ECALENDAR_FAILURE = "CLI_ADD_ECALENDAR_FAILURE";

export const CLI_UPDATE_ECALENDAR_REQUEST = "CLI_UPDATE_ECALENDAR_REQUEST";
export const CLI_UPDATE_ECALENDAR_SUCCESS = "CLI_UPDATE_ECALENDAR_SUCCESS";
export const CLI_UPDATE_ECALENDAR_FAILURE = "CLI_UPDATE_ECALENDAR_FAILURE";

export const CLI_DELETE_ECALENDAR_REQUEST = "CLI_DELETE_ECALENDAR_REQUEST";
export const CLI_DELETE_ECALENDAR_SUCCESS = "CLI_DELETE_ECALENDAR_SUCCESS";
export const CLI_DELETE_ECALENDAR_FAILURE = "CLI_DELETE_ECALENDAR_FAILURE";

export const CLI_FETCH_ORDER_REQUEST = "CLI_FETCH_ORDER_REQUEST";
export const CLI_FETCH_ORDER_SUCCESS = "CLI_FETCH_ORDER_SUCCESS";
export const CLI_FETCH_ORDER_FAILURE = "CLI_FETCH_ORDER_FAILURE";

export const CLI_FETCH_ORDER_WITH_DOCUMENTS_REQUEST =
  "CLI_FETCH_ORDER_WITH_DOCUMENTS_REQUEST";
export const CLI_FETCH_ORDER_WITH_DOCUMENTS_SUCCESS =
  "CLI_FETCH_ORDER_WITH_DOCUMENTS_SUCCESS";
export const CLI_FETCH_ORDER_WITH_DOCUMENTS_FAILURE =
  "CLI_FETCH_ORDER_WITH_DOCUMENTS_FAILURE";

export const CLI_FETCH_ORDER_WITH_SURVEYS_REQUEST =
  "CLI_FETCH_ORDER_WITH_SURVEYS_REQUEST";
export const CLI_FETCH_ORDER_WITH_SURVEYS_SUCCESS =
  "CLI_FETCH_ORDER_WITH_SURVEYS_SUCCESS";
export const CLI_FETCH_ORDER_WITH_SURVEYS_FAILURE =
  "CLI_FETCH_ORDER_WITH_SURVEYS_FAILURE";

export const CLI_FETCH_ORDER_WITH_TICKETS_REQUEST =
  "CLI_FETCH_ORDER_WITH_TICKETS_REQUEST";
export const CLI_FETCH_ORDER_WITH_TICKETS_SUCCESS =
  "CLI_FETCH_ORDER_WITH_TICKETS_SUCCESS";
export const CLI_FETCH_ORDER_WITH_TICKETS_FAILURE =
  "CLI_FETCH_ORDER_WITH_TICKETS_FAILURE";

export const CLI_FETCH_ECALENDAR_REQUEST = "CLI_FETCH_ECALENDAR_REQUEST";
export const CLI_FETCH_ECALENDAR_SUCCESS = "CLI_FETCH_ECALENDAR_SUCCESS";
export const CLI_FETCH_ECALENDAR_FAILURE = "CLI_FETCH_ECALENDAR_FAILURE";

export const CLI_FETCH_POSTS_REQUEST = "CLI_FETCH_POSTS_REQUEST";
export const CLI_FETCH_POSTS_SUCCESS = "CLI_FETCH_POSTS_SUCCESS";
export const CLI_FETCH_POSTS_FAILURE = "CLI_FETCH_POSTS_FAILURE";

export const CLI_DELETE_POST_REQUEST = "CLI_DELETE_POST_REQUEST";
export const CLI_DELETE_POST_SUCCESS = "CLI_DELETE_POST_SUCCESS";
export const CLI_DELETE_POST_FAILURE = "CLI_DELETE_POST_FAILURE";

export const CLI_POST_ADD_REQUEST = "CLI_POST_ADD_REQUEST";
export const CLI_POST_ADD_SUCCESS = "CLI_POST_ADD_SUCCESS";
export const CLI_POST_ADD_FAILURE = "CLI_POST_ADD_FAILURE";

export const CLI_POST_UPDATE_REQUEST = "CLI_POST_UPDATE_REQUEST";
export const CLI_POST_UPDATE_SUCCESS = "CLI_POST_UPDATE_SUCCESS";
export const CLI_POST_UPDATE_FAILURE = "CLI_POST_UPDATE_FAILURE";

export const CLI_FETCH_POST_REQUEST = "CLI_FETCH_POST_REQUEST";
export const CLI_FETCH_POST_SUCCESS = "CLI_FETCH_POST_SUCCESS";
export const CLI_FETCH_POST_FAILURE = "CLI_FETCH_POST_FAILURE";

export const CLI_UPLOAD_ORDER_DOCUMENT_REQUEST = "CLI_UPLOAD_ORDER_DOCUMENT_REQUEST";
export const CLI_UPLOAD_ORDER_DOCUMENT_SUCCESS = "CLI_UPLOAD_ORDER_DOCUMENT_SUCCESS";
export const CLI_UPLOAD_ORDER_DOCUMENT_FAILURE = "CLI_UPLOAD_ORDER_DOCUMENT_FAILURE";

export const CLI_FETCH_CLIENT_NOTES_REQUEST = "CLI_FETCH_CLIENT_NOTES_REQUEST";
export const CLI_FETCH_CLIENT_NOTES_SUCCESS = "CLI_FETCH_CLIENT_NOTES_SUCCESS";
export const CLI_FETCH_CLIENT_NOTES_FAILURE = "CLI_FETCH_CLIENT_NOTES_FAILURE";

export const CLI_ADD_NOTE_REQUEST = "CLI_ADD_NOTE_REQUEST";
export const CLI_ADD_NOTE_SUCCESS = "CLI_ADD_NOTE_SUCCESS";
export const CLI_ADD_NOTE_FAILURE = "CLI_ADD_NOTE_FAILURE";

export const CLI_FETCH_CLIENT_CALENDAR_REQUEST = "CLI_FETCH_CLIENT_CALENDAR_REQUEST";
export const CLI_FETCH_CLIENT_CALENDAR_SUCCESS = "CLI_FETCH_CLIENT_CALENDAR_SUCCESS";
export const CLI_FETCH_CLIENT_CALENDAR_FAILURE = "CLI_FETCH_CLIENT_CALENDAR_FAILURE";

export const CLI_ADD_CLIENT_TICKET_REQUEST = "CLI_ADD_CLIENT_TICKET_REQUEST";
export const CLI_ADD_CLIENT_TICKET_SUCCESS = "CLI_ADD_CLIENT_TICKET_SUCCESS";
export const CLI_ADD_CLIENT_TICKET_FAILURE = "CLI_ADD_CLIENT_TICKET_FAILURE";

export const CLI_UPDATE_CLIENT_TICKET_REQUEST = "CLI_UPDATE_CLIENT_TICKET_REQUEST";
export const CLI_UPDATE_CLIENT_TICKET_SUCCESS = "CLI_UPDATE_CLIENT_TICKET_SUCCESS";
export const CLI_UPDATE_CLIENT_TICKET_FAILURE = "CLI_UPDATE_CLIENT_TICKET_FAILURE";

export const CLI_FETCH_TICKET_REQUEST = "CLI_FETCH_TICKET_REQUEST";
export const CLI_FETCH_TICKET_SUCCESS = "CLI_FETCH_TICKET_SUCCESS";
export const CLI_FETCH_TICKET_FAILURE = "CLI_FETCH_TICKET_FAILURE";

export const CLI_FETCH_ORDER_WITH_NOTES_REQUEST = "CLI_FETCH_ORDER_WITH_NOTES_REQUEST";
export const CLI_FETCH_ORDER_WITH_NOTES_SUCCESS = "CLI_FETCH_ORDER_WITH_NOTES_SUCCESS";
export const CLI_FETCH_ORDER_WITH_NOTES_FAILURE = "CLI_FETCH_ORDER_WITH_NOTES_FAILURE";

export const CLI_DOWNLOADING_DOCUMENT_REQUEST = "CLI_DOWNLOADING_DOCUMENT_REQUEST";
export const CLI_DOWNLOADING_DOCUMENT_SUCCESS = "CLI_DOWNLOADING_DOCUMENT_SUCCESS";
export const CLI_DOWNLOADING_DOCUMENT_FAILURE = "CLI_DOWNLOADING_DOCUMENT_FAILURE";

export const CLI_FETCH_NOTES_REQUEST = "CLI_FETCH_NOTES_REQUEST";
export const CLI_FETCH_CALENDAR_POSTS_SUCCESS = "CLI_FETCH_CALENDAR_POSTS_SUCCESS";
