import axios from "axios";

const API_URL = "/api/project-brief-questions";

const getAllBriefQuestions = () => {
  return axios.get(API_URL).then((response) => {
    return response.data;
  });
};

const getBriefQuestionById = (id) => {
  return axios.get(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

const createBriefQuestion = (data) => {
  return axios.post(API_URL, data).then((response) => {
    return response.data;
  });
};

const updateBriefQuestion = (id, data) => {
  return axios.put(`${API_URL}/${id}`, data).then((response) => {
    return response.data;
  });
};

const deleteBriefQuestion = (id) => {
  return axios.delete(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

export const briefQuestionService = {
  getAllBriefQuestions,
  getBriefQuestionById,
  createBriefQuestion,
  updateBriefQuestion,
  deleteBriefQuestion,
};
