import {
  FETCH_PTODOS_REQUEST,
  FETCH_PTODOS_SUCCESS,
  FETCH_PTODOS_FAILURE,
  ADD_PTODO_REQUEST,
  ADD_PTODO_SUCCESS,
  ADD_PTODO_FAILURE,
  UPDATE_PTODO_REQUEST,
  UPDATE_PTODO_SUCCESS,
  UPDATE_PTODO_FAILURE,
  DELETE_PTODO_REQUEST,
  DELETE_PTODO_SUCCESS,
  DELETE_PTODO_FAILURE,
} from "../actionTypes";

const initialState = {
  todos: [],
  isFetching: false,
  error: "",
};

const todoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PTODOS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ADD_PTODO_REQUEST:
    case UPDATE_PTODO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_PTODO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PTODOS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        todos: action.payload,
        error: "",
      };
    case ADD_PTODO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        todos: [...state.todos, action.payload],
        error: "",
      };
    case UPDATE_PTODO_SUCCESS:
      const updatedTodo = state.todos.map((todo) =>
        todo._id === action.payload._id ? action.payload : todo
      );
      return {
        ...state,
        isFetching: false,
        todos: updatedTodo,
        error: "",
      };
    case DELETE_PTODO_SUCCESS:
      const filteredTodo = state.todos.filter((todo) => todo._id !== action.payload);
      return {
        ...state,
        isFetching: false,
        todos: filteredTodo,
        error: "",
      };
    case FETCH_PTODOS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case ADD_PTODO_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case UPDATE_PTODO_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case DELETE_PTODO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default todoReducer;
