import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authRegister } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.authReducer.user);
  const errorMessage = useSelector((state) => state.authReducer.errorMessage);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(authRegister(data)).then((res) => {
      navigate("/");
    });
  };

  useEffect(() => {
    if (currentUser?.user) {
      currentUser.user?.user_type === "employee"
        ? navigate("/")
        : navigate("/client/dashboard");
    }
  }, [navigate, currentUser]);

  return (
    <Container className="py-5">
      <Row>
        <Col>
          <div>
            <h2>Créer un espace agence</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label htmlFor="agency_name" className="form-label">
                  Nom de l'agence
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="agency_name"
                  name="agency_name"
                  {...register("agency_name", { required: true })}
                />
                {errors.agency_name && (
                  <div className="invalid-feedback">Ce champ est obligatoire</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nom
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <div className="invalid-feedback">Ce champ est obligatoire</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <div className="invalid-feedback">Ce champ est obligatoire</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Mot de passe
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  {...register("password", { required: true })}
                />
                {errors.password && (
                  <div className="invalid-feedback">Ce champ est obligatoire</div>
                )}
              </div>
              <button type="submit" className="btn btn-primary">
                Se connecter
              </button>
            </form>
            {errorMessage && <div>{errorMessage}</div>}
          </div>
        </Col>
      </Row>

      <footer className="Prodotop-footer">
        <div className="flex justify-content-between">
          <div>
            <div className="underline"></div>
            <h4>ProdOTop</h4>
            <div>21 tontelerwee, 8552 Oberpallen, Luxembourg</div>
            <div>Developped by Bennani Ayoub</div>
            <div>00352 661 161673</div>
          </div>
          <div>
            <a href="legal.html">Legal</a>
          </div>
        </div>
      </footer>
    </Container>
  );
};

export default Login;
