import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_TODO_REQUEST,
  DELETE_PRODUCT_TODO_SUCCESS,
  DELETE_PRODUCT_TODO_FAILURE,
} from "../actionTypes";

const initialState = {
  products: [],
  isFetching: false,
  loading: false,
  error: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ADD_PRODUCT_REQUEST:
    case UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        isFetching: true,
        loading: false,
        error: null,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
        loading: false,
        error: null,
      };
    case UPDATE_PRODUCT_SUCCESS:
      const updatedProducts = state.products.map((product) =>
        product._id === action.payload._id ? action.payload : product
      );
      return {
        ...state,
        products: updatedProducts,
        loading: false,
        error: null,
        isFetching: false,
      };
    case DELETE_PRODUCT_SUCCESS:
      const filteredProducts = state.products.filter(
        (product) => product._id !== action.payload
      );
      return {
        ...state,
        products: filteredProducts,
        loading: false,
        error: null,
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case ADD_PRODUCT_FAILURE:
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PRODUCT_TODO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_PRODUCT_TODO_SUCCESS:
      // change later update state product
      return {
        ...state,
        isFetching: false,
        error: "",
      };
    case DELETE_PRODUCT_TODO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default productReducer;
