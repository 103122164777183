import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import { motion, AnimatePresence } from "framer-motion";

export default function Chart({ data, closeModal, clientName }) {
  // Fonction pour calculer les valeurs des barres du graphique
  const calculateChartData = () => {
    if (!data) {
      return [];
    }

    const totalMinutes = data.defaultTeam1Created
      ? data.minutes
      : data.todos
          .filter((todo) => !todo.archived)
          .reduce((acc, todo) => acc + todo.minutes, 0);

    const plannedAndDoneMinutes = data.todos
      .filter((todo) => !todo.archived && todo.planned && todo.done)
      .reduce((acc, todo) => acc + todo.minutes, 0);

    const plannedAndNotDoneMinutes = data.todos
      .filter((todo) => !todo.archived && todo.planned && !todo.done)
      .reduce((acc, todo) => acc + todo.minutes, 0);

    const notPlannedAndDoneMinutes = data.todos
      .filter((todo) => !todo.archived && !todo.planned && todo.done)
      .reduce((acc, todo) => acc + todo.minutes, 0);

    const notPlannedAndNotDoneMinutes = data.todos
      .filter((todo) => !todo.archived && !todo.planned && !todo.done)
      .reduce((acc, todo) => acc + todo.minutes, 0);

    return [
      {
        name: "Expectation",
        total: totalMinutes,
        plannedAndDone: 0,
        plannedAndNotDone: 0,
        notPlannedAndDone: 0,
        notPlannedAndNotDone: 0,
      },
      {
        name: "Reality",
        total: 0,
        plannedAndDone: plannedAndDoneMinutes,
        plannedAndNotDone: plannedAndNotDoneMinutes,
        notPlannedAndDone: notPlannedAndDoneMinutes,
        notPlannedAndNotDone: notPlannedAndNotDoneMinutes,
      },
    ];
  };

  const chartData = calculateChartData();

  return (
    <AnimatePresence key={1}>
      <motion.div
        key={1}
        onClick={closeModal}
        className="modal-bg"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.7,
        }}
      ></motion.div>
      <motion.div
        key={2}
        className="expanded-modal chart-modal"
        layout
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.7,
        }}
      >
        <BarChart width={600} height={500} data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="total" fill="#3d405b" name="Expectation" stackId="minutes">
            <LabelList dataKey="total" position="inside" fill="#fff" />
          </Bar>
          <Bar
            dataKey="plannedAndDone"
            fill="#57cc99"
            name="Temps prévu - réalisé"
            stackId="minutes"
          >
            <LabelList dataKey="plannedAndDone" position="inside" fill="#000" />
          </Bar>
          <Bar
            dataKey="plannedAndNotDone"
            fill="#ff9770"
            name="Temps prévu - planifié"
            stackId="minutes"
          >
            <LabelList dataKey="plannedAndNotDone" position="inside" fill="#000" />
          </Bar>
          <Bar
            dataKey="notPlannedAndDone"
            fill="#c4d6b0"
            name="Temps imprévu - réalisé"
            stackId="minutes"
          >
            <LabelList dataKey="notPlannedAndDone" position="inside" fill="#000" />
          </Bar>
          <Bar
            dataKey="notPlannedAndNotDone"
            fill="#f8dda4"
            name="Temps imprévu - planifié"
            stackId="minutes"
          >
            <LabelList dataKey="notPlannedAndNotDone" position="inside" fill="#000" />
          </Bar>
        </BarChart>
      </motion.div>
    </AnimatePresence>
  );
}
