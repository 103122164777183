import {
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATIONS_REQUEST,
  UPDATE_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_FAILURE,
} from "../actionTypes";
import { notificationdService } from "../services/notificationdService";

export const fetchNotificationsRequest = () => {
  return {
    type: FETCH_NOTIFICATION_REQUEST,
  };
};

export const fetchNotificationsSuccess = (data) => {
  return {
    type: FETCH_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const fetchNotificationsFailure = (error) => {
  return {
    type: FETCH_NOTIFICATION_FAILURE,
    payload: error,
  };
};

export const updateNotificationsRequest = () => {
  return {
    type: UPDATE_NOTIFICATIONS_REQUEST,
  };
};

export const updateNotificationsSuccess = (data) => {
  return {
    type: UPDATE_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

export const updateNotificationsFailure = (error) => {
  return {
    type: UPDATE_NOTIFICATIONS_FAILURE,
    payload: error,
  };
};

export const updateNotificationRequest = () => {
  return {
    type: UPDATE_NOTIFICATION_REQUEST,
  };
};

export const updateNotificationSuccess = (data) => {
  return {
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const updateNotificationFailure = (error) => {
  return {
    type: UPDATE_NOTIFICATION_FAILURE,
    payload: error,
  };
};

export const fetchNotifications = () => {
  return (dispatch) => {
    dispatch(fetchNotificationsRequest());
    notificationdService
      .getNotifications()
      .then((data) => {
        dispatch(fetchNotificationsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchNotificationsFailure(error));
      });
  };
};

export const updateNotifications = () => {
  return (dispatch) => {
    dispatch(updateNotificationsRequest());
    notificationdService
      .updateNotifications()
      .then((data) => {
        dispatch(updateNotificationsSuccess(data));
      })
      .catch((error) => {
        dispatch(updateNotificationsFailure(error));
      });
  };
};

export const updateNotification = (notificationId) => {
  return (dispatch) => {
    //dispatch(updateNotificationRequest());
    notificationdService
      .updateNotification(notificationId)
      .then((data) => {
        //dispatch(updateNotificationSuccess(data));
      })
      .catch((error) => {
        //dispatch(updateNotificationFailure(error));
      });
  };
};
