import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { communityPosts } from "../../../redux/actions/eCalendarActions";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faImage } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import moment from "moment-timezone";
// AYB This should b in a shared component
import ExpandedContent from "../clients/orders/tickets/ExpandedContent";
import ExpandedPhotos from "../../shared/ExpandedPhotos";
import { NavContext } from "../../NavContext";
import "react-datepicker/dist/react-datepicker.css";

const Posts = () => {
  const dispatch = useDispatch();
  const [showComments, setShowComments] = useState(false);
  const [layoutId, setLayoutId] = useState(null);
  const [data, setData] = useState(null);
  const [photosLayoutId, setPhotosLayoutId] = useState(null);
  const [photosData, setPhotosData] = useState(null);
  const [weekDates, setWeekDates] = useState({
    start: moment().startOf("day").toDate(),
    end: moment().add(7, "days").endOf("day").toDate(),
  });

  const posts = useSelector((state) => state.eCalendarReducer.communityPosts);
  const fetchingPosts = useSelector(
    (state) => state.eCalendarReducer.fetchingCommunityPosts
  );

  useEffect(() => {
    document.title = "ProdOTop - posts de la semaine des Calendrier editoriaux";
  }, []);

  // je doit trouver une solution a ça car ça se load deux fois, c'est évident
  useEffect(() => {
    if (!fetchingPosts) {
      dispatch(communityPosts(weekDates));
    }
  }, [dispatch, fetchingPosts, weekDates]);

  useEffect(() => {
    if (fetchingPosts) {
      dispatch(communityPosts(weekDates));
    }
  }, [dispatch, weekDates]);

  const { activeNav, setActiveNav } = useContext(NavContext);
  useEffect(() => {
    setActiveNav("community");
  }, [setActiveNav]);

  const handlePhotos = (post) => {
    setPhotosData(post.photo.filter((x) => x));
    setPhotosLayoutId(post._id);
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setWeekDates({ end: end, start: start });
  };

  return (
    <>
      <div className="pb-2">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/clients">Community</Link>
            </li>
          </ol>
        </nav>
      </div>

      <Row className="mb-3">
        <Col xs={6} onClick={() => setShowComments(!showComments)} role="button">
          {showComments ? "Cacher les commentaires" : "Afficher les commentaire"}
        </Col>
        <Col md={6}>
          <DatePicker
            selected={weekDates.start}
            onChange={onChange}
            startDate={weekDates.start}
            endDate={weekDates.end}
            selectsRange
            className="form-control block"
            dateFormat="dd/MM/yyyy"
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {posts.map((post, index) => {
            const photos = post.photo.filter((photo) => photo);
            return (
              <div key={post._id} className="mb-4 post-container">
                <div
                  className={`card purple-border card-order post justify-content-center `}
                >
                  {console.log(post)}
                  <div className="card-body py-3 px-3">
                    <div className="flex gap-3 full ">
                      <div>
                        <div>
                          <div
                            className={`img-post`}
                            onClick={() => photos && handlePhotos(post)}
                          >
                            {photos && photos[0] ? (
                              <img
                                src={photos[0]}
                                alt={post.theme}
                                style={{ width: "140px" }}
                              />
                            ) : (
                              <img
                                src="/images/pastille.png"
                                alt={post.theme}
                                style={{ width: "140px" }}
                              />
                            )}
                            {photos.length > 1 && (
                              <>
                                <span>
                                  {" "}
                                  + {photos.length} <FontAwesomeIcon icon={faImage} />
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="community-card-content">
                        <Row>
                          <Col xs={12}>
                            <div className="flex justify-content-between">
                              <h6 className="post-title mb-0 ">
                                <span className={`product-title text-dark `}>
                                  {post.editorialCalendar?.order?.client?.companyName}{" "}
                                  {"|"} {post.theme}
                                </span>
                              </h6>
                              <div>
                                {post.date && (
                                  <div className="px-2 white-text bg-gradient-dark-inverse">
                                    {moment(post.date).format("DD-MM-YYYY")}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div
                              className={`post-text ${
                                post.yellow_remark || post.customer_remark
                                  ? "with-comment"
                                  : "without-comment"
                              }`}
                              role="button"
                              onClick={() => {
                                setData(post.texte);
                                setLayoutId(post._id);
                              }}
                            >
                              {parse(post.texte)}
                            </div>
                          </Col>
                          {showComments && (
                            <>
                              {post.yellow_remark && (
                                <Col lg={6} md={12} className="comments-block">
                                  <h6 className="post-title mb-0 ">
                                    <span className="product-title text-secondary">
                                      Commentaire Yellow
                                    </span>
                                  </h6>
                                  <div className="comment-section">
                                    {parse(post.yellow_remark)}
                                  </div>
                                </Col>
                              )}
                              {post.customer_remark && (
                                <Col lg={6} md={12} className="comments-block">
                                  <h6 className="post-title mb-0 ">
                                    <span className="product-title text-secondary ">
                                      Commentaire Client
                                    </span>
                                  </h6>
                                  <div className="comment-section">
                                    {parse(post.customer_remark)}
                                  </div>
                                </Col>
                              )}
                            </>
                          )}

                          <div className="">
                            <div className="flex gap-3">
                              {post.validated ? (
                                <div>
                                  <div className="border px-3 py-1 rounded-1 validated-border">
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      className="px-2"
                                      color="#839e2f"
                                    />
                                    <span className="pl-2 text-validated">
                                      texte validé
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {post.validated_img ? (
                                <div>
                                  <div className="border px-3 py-1 rounded-1 validated-border">
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      className="px-2"
                                      color="#839e2f"
                                    />
                                    <span className="pl-2 text-validated">
                                      image validé
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {post.shared ? (
                                <div>
                                  <div className="border px-3 py-1 rounded-1 validated-border">
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      className="px-2"
                                      color="#839e2f"
                                    />
                                    <span className="pl-2 text-validated">
                                      post partagé
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Col>
      </Row>
      {layoutId && (
        <ExpandedContent
          key={layoutId}
          layoutId={layoutId}
          data={data}
          setData={setData}
        />
      )}
      {photosLayoutId && (
        <ExpandedPhotos
          layoutId={photosLayoutId}
          data={photosData}
          setData={setPhotosData}
        />
      )}
    </>
  );
};

export default Posts;
