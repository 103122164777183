import {
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATIONS_REQUEST,
  UPDATE_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_FAILURE,
} from "../actionTypes";

const initialState = {
  notifications: [],
  isFetching: false,
  error: null,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        error: null,
      };
    case FETCH_NOTIFICATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    case UPDATE_NOTIFICATION_REQUEST:
      const updatedNotifications = state.notifications.map((notification) => {
        if (notification.id === action.payload.id) {
          // Ici, je suppose que votre notification a une propriété 'id'. Si ce n'est pas le cas, utilisez une autre propriété unique.
          return action.payload;
        }
        return notification;
      });
      return {
        ...state,
        notifications: updatedNotifications,
        isFetching: true,
        error: null,
      };
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        error: null,
      };
    case UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case UPDATE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        error: null,
      };
    case UPDATE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;
