import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addPost,
  fetchPosts,
  deletePost,
  fetchECalendarById,
  fetchPostsSuccess,
  updatePriorities,
  fetchOrderById,
  updatePost,
  iaSuggestions,
  iaSuggestionSubjects,
  setIaSuggestions,
  setIaSuggestionSubjects,
  removeSuggestion,
  removeSuggestionSubject,
} from "../../../../redux/actions/eCalendarActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faPen,
  faCheck,
  faImage,
  faBahai,
  faRectangleXmark,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import moment from "moment-timezone";
// AYB This should b in a shared component
import ExpandedContent from "../../clients/orders/tickets/ExpandedContent";
import ExpandedPhotos from "../../../shared/ExpandedPhotos";
import { Bars } from "react-loader-spinner";
import { motion, AnimatePresence } from "framer-motion";

const PostPage = () => {
  const dispatch = useDispatch();
  const { eCalendarId, clientId, orderId } = useParams();
  const [showComments, setShowComments] = useState(true);
  const [layoutId, setLayoutId] = useState(null);
  const [data, setData] = useState(null);
  const [photosLayoutId, setPhotosLayoutId] = useState(null);
  const [photosData, setPhotosData] = useState(null);
  const [dragStart, setDragStart] = useState(null);

  const posts = useSelector((state) => state.eCalendarReducer.posts);
  const fetchingPosts = useSelector((state) => state.eCalendarReducer.fetchingPosts);
  const order = useSelector((state) => state.eCalendarReducer.order);
  const eCalendar = useSelector((state) => state.eCalendarReducer.eCalendar);

  const fetchingSuggestions = useSelector(
    (state) => state.eCalendarReducer.fetchingSuggestions
  );
  const ia_suggestions = useSelector((state) => state.eCalendarReducer.iaSuggestions);
  const ia_suggestionSubjects = useSelector(
    (state) => state.eCalendarReducer.iaSuggestionSubjects
  );

  useEffect(() => {
    document.title = "ProdOTop - posts du Calendrier editorial";
  }, []);

  useEffect(() => {
    // if (!fetchingPosts) {
    dispatch(fetchPosts(orderId, eCalendarId));
    // }
  }, [dispatch, eCalendarId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    if (eCalendarId) {
      dispatch(fetchECalendarById(orderId, eCalendarId));
    }
  }, [dispatch, eCalendarId, orderId]);

  const handleDelete = (postId) => {
    dispatch(deletePost(orderId, eCalendarId, postId));
  };

  const handleOnDragEnd = async (result) => {
    setDragStart(false);
    if (!result.destination) return;
    const items = Array.from(posts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dispatch(fetchPostsSuccess(items));
    const priorities = items.map((post, index) => ({
      _id: post._id,
      newPriority: index + 1,
    }));

    dispatch(updatePriorities(orderId, eCalendarId, { priorities: priorities })); // Vous devrez implémenter cette fonction pour faire une requête à votre API
  };

  const updateShared = (id) => {
    let formData = new FormData();
    formData.append("shared", true);
    dispatch(updatePost(orderId, eCalendarId, id, formData));
  };

  const handlePhotos = (post) => {
    setPhotosData(post.photo.filter((x) => x));
    setPhotosLayoutId(post._id);
  };

  const handleIaSuggestion = () => {
    dispatch(iaSuggestions(orderId, eCalendarId));
  };
  const handleIaSuggestionSubjects = () => {
    dispatch(iaSuggestionSubjects(orderId, eCalendarId));
  };

  const handleCloseSuggestions = () => {
    dispatch(setIaSuggestions(false));
  };
  const handleCloseSuggestionSubjects = () => {
    dispatch(setIaSuggestionSubjects(false));
  };

  const handleCreatePost = (index, iaS) => {
    dispatch(addPost(orderId, eCalendarId, { theme: "IA suggestion", texte: iaS }));
    dispatch(removeSuggestion(index));
  };

  const handleCreatePostSubjects = (index, iaS) => {
    dispatch(addPost(orderId, eCalendarId, { theme: iaS, texte: "IA suggestion" }));
    dispatch(removeSuggestionSubject(index));
  };

  return (
    <>
      {fetchingSuggestions && (
        <div className="ia-loader">
          <Bars
            height={100}
            width={100}
            radius={5}
            color="#cb0c9f"
            ariaLabel="bars-loading"
            wrapperClass={"triangle-loader"}
            wrapperStyle=""
            visible={true}
          />
        </div>
      )}
      <div className="pb-2">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/clients">clients</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/clients/${order?.client?._id}/orders`}>
                {order?.client?.companyName}
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {order?.product?.name}
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to={`/clients/${clientId}/orders/${orderId}/editorial_calendars`}>
                Calendriers éditoriaux
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {eCalendar.title}
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              liste des posts
            </li>
          </ol>
        </nav>
      </div>
      <Row>
        <Row className="mb-3">
          <Col xs={4} onClick={() => setShowComments(!showComments)} role="button">
            {showComments ? "Cacher les commentaires" : "Afficher les commentaire"}
          </Col>
          <Col className="text-end" xs={8}>
            {/* <span
              className="btn btn-outline-primary mb-0 mx-3"
              onClick={handleIaSuggestion}
            >
              <FontAwesomeIcon icon={faBahai} className="px-2" color="#cb0c9f" />
              AI suggestion posts
            </span> */}
            <span
              className="btn btn-outline-primary mb-0 mx-3"
              onClick={handleIaSuggestionSubjects}
            >
              <FontAwesomeIcon icon={faBahai} className="px-2" color="#cb0c9f" />
              AI suggestion sujets
            </span>
            <Link
              to={`/clients/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts/new`}
              className="btn bg-gradient-dark-inverse mb-0 "
            >
              Ajouter un post
            </Link>

            <Link
              to={`/clients/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts_calendar`}
              className="prodotop-link py-2 ms-2"
            >
              <FontAwesomeIcon icon={faCalendarDays} className="px-2" />
            </Link>
          </Col>
        </Row>
      </Row>
      <Row>
        <Col md={4} className="mb-3" key={eCalendar._id}>
          <div>
            <div className="card purple-border card-order eCalendar justify-content-center">
              <div className="card-header py-3 flex justify-content-between">
                <h6 className="eCalendar-title">
                  <span className="product-title text-dark ">{eCalendar.title}</span>
                </h6>
                <div className="text-end flex">
                  <Link
                    to={`/clients/${clientId}/orders/${orderId}/editorial_calendars/${eCalendar._id}`}
                    className="btn btn-link text-white text-gradient px-1 mb-0 py-0"
                  >
                    <FontAwesomeIcon icon={faPen} className="px-2" color="#ffb300" />
                  </Link>
                </div>
              </div>
              <div className="card-body py-4 px-3">
                <div className="flex flex-wrap gap-3">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <label htmlFor="objectives" className="form-label">
                          Objectifs :
                        </label>
                        <p>{eCalendar.objectives}</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <label htmlFor="tone_of_voice" className="form-label">
                          Tone of voice :
                        </label>
                        <p>{eCalendar.tone_of_voice}</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <label htmlFor="target" className="form-label">
                          Cible :
                        </label>
                        <p>{eCalendar.target}</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <label htmlFor="communication_plan" className="form-label">
                          Plan de communication :
                        </label>
                        <p>{eCalendar.communication_plan}</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <label htmlFor="strategy" className="form-label">
                          Strategy :
                        </label>
                        <p>{eCalendar.strategy}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={8} className={dragStart ? "dragStart" : ""}>
          <DragDropContext
            onDragEnd={handleOnDragEnd}
            onDragStart={() => setDragStart(true)}
          >
            <Droppable droppableId="posts">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {posts.map((post, index) => {
                    const photos = post.photo.filter((photo) => photo);
                    return (
                      <Draggable key={post._id} draggableId={post._id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div key={post._id} className="mb-4 post-container">
                              <div
                                className={`card purple-border card-order post justify-content-center `}
                              >
                                <div className="card-body py-3 px-3">
                                  <div className="flex gap-3 full ">
                                    <div>
                                      <div>
                                        <div
                                          className={`img-post`}
                                          onClick={() => photos && handlePhotos(post)}
                                        >
                                          {photos && photos[0] ? (
                                            <img
                                              src={photos[0]}
                                              alt={post.theme}
                                              style={{ width: "140px" }}
                                            />
                                          ) : (
                                            <img
                                              src="/images/pastille.png"
                                              alt={post.theme}
                                              style={{ width: "140px" }}
                                            />
                                          )}
                                          {photos.length > 1 && (
                                            <>
                                              <span>
                                                {" "}
                                                + {photos.length}{" "}
                                                <FontAwesomeIcon icon={faImage} />
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="community-card-content">
                                      <Row>
                                        <Col xs={12}>
                                          <div className="flex justify-content-between">
                                            <h6 className="post-title mb-0 ">
                                              <span
                                                className={`product-title text-dark `}
                                              >
                                                {index + 1} {"|"} {post.theme}
                                              </span>
                                            </h6>
                                            <div>
                                              {post.date && (
                                                <div className="px-2 white-text bg-gradient-dark-inverse">
                                                  {moment(post.date).format("DD-MM-YYYY")}
                                                </div>
                                              )}

                                              <div className="flex text-end mb-2">
                                                <Link
                                                  to={`/clients/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts/${post._id}`}
                                                  className="btn btn-link text-white text-gradient px-1 mb-0 py-0"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faPen}
                                                    className="px-2"
                                                    color="#ffb300"
                                                  />
                                                </Link>
                                                <button
                                                  className="btn btn-link text-danger text-gradient px-1 mb-0 py-0"
                                                  type="button"
                                                  onClick={() => handleDelete(post._id)}
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faTrashCan}
                                                    className="px-2"
                                                  />
                                                </button>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className={`post-text ${
                                              post.yellow_remark || post.customer_remark
                                                ? "with-comment"
                                                : "without-comment"
                                            }`}
                                            role="button"
                                            onClick={() => {
                                              setData(post.texte);
                                              setLayoutId(post._id);
                                            }}
                                          >
                                            {parse(post.texte)}
                                          </div>
                                        </Col>
                                        {showComments && (
                                          <>
                                            {post.yellow_remark && (
                                              <Col
                                                lg={6}
                                                md={12}
                                                className="comments-block"
                                              >
                                                <h6 className="post-title mb-0 ">
                                                  <span className="product-title text-secondary">
                                                    Commentaire Yellow
                                                  </span>
                                                </h6>
                                                <div className="comment-section">
                                                  {parse(post.yellow_remark)}
                                                </div>
                                              </Col>
                                            )}
                                            {post.customer_remark && (
                                              <Col
                                                lg={6}
                                                md={12}
                                                className="comments-block"
                                              >
                                                <h6 className="post-title mb-0 ">
                                                  <span className="product-title text-secondary ">
                                                    Commentaire Client
                                                  </span>
                                                </h6>
                                                <div className="comment-section">
                                                  {parse(post.customer_remark)}
                                                </div>
                                              </Col>
                                            )}
                                          </>
                                        )}

                                        <div className="">
                                          <div className="flex gap-3">
                                            {post.validated ? (
                                              <div>
                                                <div className="border px-3 py-1 rounded-1 validated-border">
                                                  <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className="px-2"
                                                    color="#839e2f"
                                                  />
                                                  <span className="pl-2 text-validated">
                                                    texte validé
                                                  </span>
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {post.validated_img ? (
                                              <div>
                                                <div className="border px-3 py-1 rounded-1 validated-border">
                                                  <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className="px-2"
                                                    color="#839e2f"
                                                  />
                                                  <span className="pl-2 text-validated">
                                                    image validé
                                                  </span>
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            {post.shared ? (
                                              post.date && moment().isAfter(post.date) ? (
                                                <div>
                                                  <div className="border px-3 py-1 rounded-1 validated-border">
                                                    <FontAwesomeIcon
                                                      icon={faCheck}
                                                      className="px-2"
                                                      color="#839e2f"
                                                    />
                                                    <span className="pl-2 text-validated">
                                                      post partagé
                                                    </span>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div>
                                                  <div className="border px-3 py-1 rounded-1 programmed-border">
                                                    <FontAwesomeIcon
                                                      icon={faCheck}
                                                      className="px-2"
                                                      color="#9e522f"
                                                    />
                                                    <span className="pl-2 text-programmed">
                                                      post programmé
                                                    </span>
                                                  </div>
                                                </div>
                                              )
                                            ) : (
                                              <div
                                                className="border px-3 py-1 rounded-1"
                                                role="button"
                                                onClick={() => updateShared(post._id)}
                                              >
                                                Programmer le post
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>

        <Row className="mb-3">
          <Col>
            <Link
              className="link-action back"
              to={`/clients/${clientId}/orders/${orderId}/editorial_calendars`}
            >
              Retour
            </Link>
          </Col>
        </Row>
      </Row>
      {layoutId && (
        <ExpandedContent
          key={layoutId}
          layoutId={layoutId}
          data={data}
          setData={setData}
        />
      )}
      {photosLayoutId && (
        <ExpandedPhotos
          layoutId={photosLayoutId}
          data={photosData}
          setData={setPhotosData}
        />
      )}
      {ia_suggestions && (
        <SuggestionModal
          ia_suggestions={ia_suggestions}
          handleCloseSuggestions={handleCloseSuggestions}
          handleCreatePost={handleCreatePost}
        />
      )}
      {ia_suggestionSubjects && (
        <SuggestionModal
          ia_suggestions={ia_suggestionSubjects}
          handleCloseSuggestions={handleCloseSuggestionSubjects}
          handleCreatePost={handleCreatePostSubjects}
        />
      )}
    </>
  );
};

const SuggestionModal = ({
  handleCloseSuggestions,
  ia_suggestions,
  handleCreatePost,
}) => {
  return (
    <AnimatePresence key={1}>
      <motion.div
        key={2}
        className="expanded-modal"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.7,
        }}
      >
        <button className="close" onClick={handleCloseSuggestions}>
          <FontAwesomeIcon icon={faRectangleXmark} color="#344866" />
        </button>
        <motion.div
          key={1}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.7,
          }}
        >
          <motion.div className="expanded-content">
            <div className="container-fluid py-4">
              <div className="row">
                <div className="col-12">
                  <div className=" mb-4">
                    <div className="card-body px-0 pt-0 pb-2">
                      <div className="table-responsive p-0">
                        <table className="table align-items-center mb-0 wrap-table">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Suggestions
                              </th>
                              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {ia_suggestions.map((iaS, index) => (
                              <tr key={index}>
                                <td className="align-middle text-sm px-4">{iaS}</td>
                                <td className="align-middle px-2 text-sm text-end">
                                  <span
                                    className="btn btn-primary text-white px-3 mb-0"
                                    onClick={() => handleCreatePost(index, iaS)}
                                  >
                                    Créer post
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PostPage;
