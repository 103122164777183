import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import {
  fetchClientEmployees,
  createMeeting,
  fetchMeeting,
} from "../../../redux/actions/clientActions";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { getGroupedOptions } from "../../utils/employees";
import { Link } from "react-router-dom";

const MeetForm = ({ clientId, employees, todoId }) => {
  const dispatch = useDispatch();
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [message, setMessage] = useState("");
  const client_employees = useSelector((state) => state.clientReducer.client_employees);
  const meeting = useSelector((state) => state.clientReducer.meeting);

  const loading_meeting = useSelector((state) => state.clientReducer.loading_meeting);
  const loading_client_employees = useSelector(
    (state) => state.clientReducer.loading_client_employees
  );

  useEffect(() => {
    dispatch(fetchClientEmployees(clientId));
    dispatch(fetchMeeting(clientId, todoId));
  }, [dispatch, clientId, todoId]);

  useEffect(() => {
    if (meeting) {
      setSelectedParticipants(
        meeting.participants?.map((participant) => ({
          value: participant._id,
          label: participant.name,
        }))
      );
      setMessage(meeting.message);
    }
  }, [meeting]);

  const groupedOptions = getGroupedOptions(employees, client_employees);

  const handleParticipantsChange = (selectedOptions) => {
    setSelectedParticipants(selectedOptions || []);
  };

  const handleCreateMeeting = () => {
    if (selectedParticipants.length < 2) {
      return;
    }
    const participantsIds = selectedParticipants.map((participant) => participant.value);
    const meetingData = {
      participants: participantsIds,
      message,
      todoId,
    };

    dispatch(createMeeting(clientId, meetingData));
  };
  if (loading_meeting || loading_client_employees) {
    return <span>Loading ...</span>;
  }

  return (
    <div>
      <div className="flex flex-column gap-3">
        {meeting ? (
          <div>
            <h5 className="mb-4">Réunion</h5>
            <div>
              <strong>Participants</strong>
              <div className="flex gap-2">
                {meeting.participants?.map((participant) => (
                  <span
                    key={participant._id}
                    className="bg-gray-500 rounded-5 py-1 px-2 text-sm text-white"
                  >
                    {participant.name}
                  </span>
                ))}
              </div>
            </div>
            <div>
              <strong>Message</strong>
              <div className="mb-5">
                {meeting.message ? (
                  meeting.message
                ) : (
                  <small className="text-black-50">Aucun message n'a été joint</small>
                )}
              </div>
              <Link to={`/meet/${meeting._id}`} className="prodotop-link ">
                <FontAwesomeIcon icon={faVideo} className="px-1" />
                Accéder à la réunion
              </Link>
            </div>
          </div>
        ) : (
          <>
            <div>
              <label htmlFor="participantsSelect" className="form-label">
                Participants
              </label>
              <Select
                defaultValue={[]}
                isMulti
                options={groupedOptions}
                isClearable={true}
                placeholder="Sélectionnez des participants"
                onChange={handleParticipantsChange}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {selectedParticipants.length < 2 && (
                <small className="text-black-50">
                  Vous devez sélectionner au moins deux participants.
                </small>
              )}
            </div>
            <div>
              <label htmlFor="messageInput" className="form-label">
                Joindre un message
              </label>
              <textarea
                id="messageInput"
                className="form-control"
                placeholder="Entrez un message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div>
              <button
                className="btn btn-primary"
                onClick={handleCreateMeeting}
                disabled={selectedParticipants.length < 2}
              >
                <FontAwesomeIcon icon={faVideo} color="#fff" className="px-1" />
                Créer la réunion
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MeetForm;
