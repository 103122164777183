import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import {
  faTrashCan,
  faCheck,
  faGear,
  faScissors,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";

const TodoCard = ({ todo, updateTodosOrder, can_manage, devideTask }) => {
  const [show, setShow] = useState(false);
  const [showDevide, setShowDevide] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    devideTask(todo._id, data);
    reset();
  };

  const handleClick = () => {
    setShowDevide(!showDevide);
  };
  return (
    <AnimatePresence initial={false} mode="wait">
      <motion.li
        key={todo._id}
        layout
        className={`mx-2 my-2 list-group-item border-0 d-flex px-4 py-2 mb-2 bg-gray-300 border-radius-lg todo-${todo.flexibility}`}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.5,
        }}
      >
        <div className="d-flex flex-column">
          <h6 className="mb-1 text-sm">
            {todo.task}{" "}
            <small className="px-2 text-primary text-xs">
              {moment(todo.start).format("DD-MM-YYYY")}
            </small>
          </h6>
          <span className="text-xs ">{todo.minutes} minutes</span>
        </div>

        <div className="ms-auto text-end px-3 flex">
          {console.log(todo)}
          {todo.dragged && (
            <button
              className="btn btn-link text-dark text-gradient px-1 mb-0"
              type="button"
              onClick={() => updateTodosOrder(todo._id, { dragged: false })}
            >
              <FontAwesomeIcon icon={faRotateLeft} className="px-2" />
            </button>
          )}
          {!showDevide && (
            <button
              onClick={handleClick}
              className="btn btn-link text-primary text-gradient px-1 mb-0"
            >
              <FontAwesomeIcon icon={faScissors} />
            </button>
          )}

          {showDevide && (
            <form onSubmit={handleSubmit(onSubmit)} className="flex gap-3">
              <div className="mb-1 flex gap-3 p-0">
                <input
                  type="number"
                  className="form-control devid-input"
                  id="question"
                  min={15}
                  max={todo.minutes - 1}
                  step={15}
                  {...register("minutes", { required: true })}
                  placeholder="15, 30, 45"
                />
                {errors?.minutes && <div className="text-danger">temps requis !</div>}
              </div>
              <div className="">
                <button type="submit" className="btn bg-gradient-dark mb-0">
                  Diviser
                </button>
              </div>
            </form>
          )}

          {can_manage && !show && (
            <button
              className="btn btn-link text-primary text-gradient px-1 mb-0"
              type="button"
              onClick={() => setShow(true)}
            >
              <FontAwesomeIcon icon={faGear} className="px-2" />
            </button>
          )}
          {can_manage && show && (
            <>
              <button
                className="btn btn-link text-success text-gradient px-1 mb-0"
                type="button"
                onClick={() => updateTodosOrder(todo._id, { done: true })}
              >
                <FontAwesomeIcon icon={faCheck} className="px-2" />
              </button>
              <button
                className="btn btn-link text-danger text-gradient px-1 mb-0"
                type="button"
                onClick={() => updateTodosOrder(todo._id, { archived: true })}
              >
                <FontAwesomeIcon icon={faTrashCan} className="px-2" />
              </button>
            </>
          )}
        </div>
      </motion.li>
    </AnimatePresence>
  );
};

export default TodoCard;
