import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./componants/Layout";
import ClientLayout from "./componants/ClientLayout";

import Configuration from "./componants/admin/configuration";

import NoMatch from "./componants/NoMatch";
import Login from "./componants/auth/Login";
import Register from "./componants/auth/Register";

import FacebookAuth from "./componants/admin/auths/facebook";

import ProtectedRoute from "./ProtectedRoute";
import ProtectedClientRoute from "./ProtectedClientRoute";

import Index from "./componants/admin/";
import Calendar from "./componants/admin/calendar";
import Agency from "./componants/admin/agency";

import AgencyInfo from "./componants/admin/agency/infoPage";

import Dashboard from "./componants/admin/dashboard";
import Notification from "./componants/admin/notification";

import EmailPage from "./componants/admin/emails/emailPage";
import EmailForm from "./componants/admin/emails/emailForm";

import AdsReports from "./componants/admin/ads_reports/reports";
import ClientAdsReports from "./componants/admin/ads_reports/client_reports";

import ClientPage from "./componants/admin/clients/clientPage";
import ClientDashboard from "./componants/admin/clients/clientDashboard";
import ClientForm from "./componants/admin/clients/clientForm";

import DepartmentsPage from "./componants/admin/departments/departmentsPage";

import DraftForm from "./componants/admin/drafts/draftForm";

import ECalendarsPage from "./componants/admin/eCalendar/eCalendarPage";
import ECalendarsForm from "./componants/admin/eCalendar/eCalendarForm";

import PostPage from "./componants/admin/eCalendar/posts/postPage";
import PostCalendar from "./componants/admin/eCalendar/posts/postCalendar";
import PostForm from "./componants/admin/eCalendar/posts/postForm";

import Posts from "./componants/admin/community/posts";

import CliECalendarsPage from "./componants/cli/eCalendar/eCalendarPage";
import CliSurveys from "./componants/cli/clients/survey/surveyPage";
import CliSurveyForm from "./componants/cli/clients/survey/surveyForm";

import CliPostPage from "./componants/cli/eCalendar/posts/postPage";
import CliPostCalendar from "./componants/cli/eCalendar/posts/postCalendar";
import CliPostForm from "./componants/cli/eCalendar/posts/postForm";

import CliCalendar from "./componants/cli/clients/calendar/calendar";
import CliInsightPage from "./componants/cli/clients/orders/insightPage";

import UserPage from "./componants/admin/users/employees/userPage";
import UserForm from "./componants/admin/users/employees/userForm";

import ClientUserPage from "./componants/admin/users/clients/userPage";
import ClientUserForm from "./componants/admin/users/clients/userForm";

import UserStats from "./componants/admin/users/userStats";

import OrdersPage from "./componants/admin/clients/orders/ordersPage";
import OrderForm from "./componants/admin/clients/orders/orderForm";

import OEmailPage from "./componants/admin/clients/orders/emails/emailPage";
import DocumentPage from "./componants/admin/clients/orders/documents/documentPage";
import SurveyPage from "./componants/admin/clients/orders/surveys/surveyPage";
import SurveyDetails from "./componants/admin/clients/orders/surveys/surveyDetails";

import InsightPage from "./componants/admin/clients/orders/insights/insightPage";

import NoteForm from "./componants/admin/clients/notes/noteForm";
import OrderNoteForm from "./componants/admin/clients/orders/notes/orderNoteForm";
import BriefReponsesForm from "./componants/admin/clients/orders/briefResponses/briefReponsesForm";
import QualityChecksForm from "./componants/admin/clients/orders/qualityChecks/qualityChecksForm";
import TodosForm from "./componants/admin/clients/orders/todos/todosForm";

import TicketsPage from "./componants/admin/clients/orders/tickets/ticketsPage";
import NewTicketForm from "./componants/admin/clients/orders/tickets/newTicketForm";
import EditTicketForm from "./componants/admin/clients/orders/tickets/editTicketForm";

import ProductPage from "./componants/admin/products/productPage";
import ProductForm from "./componants/admin/products/productForm";
import QualityCriterionForm from "./componants/admin/products/qualityCriterionForm";
import ProductSurveyQuestionForm from "./componants/admin/products/ProductSurveyQuestionForm";
import TodoForm from "./componants/admin/products/todoForm";

import Meetings from "./componants/admin/meet";
import MeetRoom from "./componants/admin/meet/meetRoom";

import BriefQuestionPage from "./componants/admin/briefQuestions/BriefQuestionPage";
import BriefQuestionForm from "./componants/admin/briefQuestions/BriefQuestionForm";

import QualityCheckForm from "./componants/admin/qualityChecks/QualityCheckForm";
import QualityCheckPage from "./componants/admin/qualityChecks/QualityCheckPage";

import CliHome from "./componants/cli/clients/clientsPage";

import CliMeetings from "./componants/cli/meet";
import CliMeetRoom from "./componants/cli/meet/meetRoom";

import CliClientOrders from "./componants/cli/clients/orders/ordersPage";
import CliDocumentsPage from "./componants/cli/clients/documents/documentPage";
import CliNoteForm from "./componants/cli/clients/notes/noteForm";
import CliTicketPage from "./componants/cli/clients/tickets/ticketsPage";
import CliNewTicketForm from "./componants/cli/clients/tickets/newTicketForm";
import CliEditTicketForm from "./componants/cli/clients/tickets/editTicketForm";

import SurveyQuestionPage from "./componants/admin/surveyQuestions/SurveyQuestionPage";
import SurveyQuestionForm from "./componants/admin/surveyQuestions/SurveyQuestionForm";
import { NavContext } from "./componants/NavContext";
import { PanelContext } from "./componants/panelContext";

function App(props) {
  const [activeNav, setActiveNav] = useState("dashboard");
  const [panelMessage, setPanelMessage] = useState("Welcome back !");
  return (
    <NavContext.Provider value={{ activeNav, setActiveNav }}>
      <PanelContext.Provider value={{ panelMessage, setPanelMessage }}>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/client/"
              element={
                <ProtectedClientRoute>
                  <ClientLayout />
                </ProtectedClientRoute>
              }
            >
              <Route path="dashboard" element={<CliHome />} />
              <Route path=":clientId/orders" element={<CliClientOrders />} />
              <Route path=":clientId/notes" element={<CliNoteForm />} />
              <Route path=":clientId/calendar" element={<CliCalendar />} />
              <Route path=":clientId/orders/:orderId/surveys" element={<CliSurveys />} />
              <Route path="meet/">
                <Route index element={<CliMeetings />} />
                <Route path=":meetId" element={<CliMeetRoom />} />
              </Route>
              <Route
                path=":clientId/orders/:orderId/tickets"
                element={<CliTicketPage />}
              />
              <Route
                path=":clientId/orders/:orderId/insight"
                element={<CliInsightPage />}
              />
              <Route
                path=":clientId/orders/:orderId/tickets/:ticketId"
                element={<CliEditTicketForm />}
              />
              <Route
                path=":clientId/orders/:orderId/tickets/new"
                element={<CliNewTicketForm />}
              />
              <Route
                path=":clientId/orders/:orderId/documents"
                element={<CliDocumentsPage />}
              />
              <Route
                path=":clientId/orders/:orderId/surveys/:surveyId"
                element={<CliSurveyForm />}
              />
              <Route
                path=":clientId/orders/:orderId/editorial_calendars"
                element={<CliECalendarsPage />}
              />

              <Route
                path=":clientId/orders/:orderId/editorial_calendars/:eCalendarId/posts"
                element={<CliPostPage />}
              />
              <Route
                path=":clientId/orders/:orderId/editorial_calendars/:eCalendarId/posts_calendar"
                element={<CliPostCalendar />}
              />
              <Route
                path=":clientId/orders/:orderId/editorial_calendars/:eCalendarId/posts/new"
                element={<CliPostForm />}
              />
              <Route
                path=":clientId/orders/:orderId/editorial_calendars/:eCalendarId/posts/:postId"
                element={<CliPostForm />}
              />
            </Route>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Layout />
                </ProtectedRoute>
              }
            >
              <Route path="configuration/">
                <Route index element={<Configuration />} />
              </Route>

              <Route path="agency/">
                <Route index element={<Agency />} />
                <Route path="info" element={<AgencyInfo />} />
              </Route>

              <Route index element={<Index />} />

              <Route path="dashboard" element={<Dashboard />} />
              <Route path="auths/facebook" element={<FacebookAuth />} />
              <Route path="drafts" element={<DraftForm />} />

              <Route path="calendar" element={<Calendar />} />
              <Route path="calendar/:newTicket" element={<Calendar />} />
              <Route path="notification" element={<Notification />} />
              <Route path="community" element={<Posts />} />
              <Route path="emails/">
                <Route index element={<EmailPage />} />
                <Route path="new" element={<EmailForm />} />
                <Route path=":id" element={<EmailForm />} />
              </Route>

              <Route path="Ads-Reports/">
                <Route index element={<AdsReports />} />
                <Route path=":clientId" element={<ClientAdsReports />} />
              </Route>

              <Route path="meet/">
                <Route index element={<Meetings />} />
                <Route path=":meetId" element={<MeetRoom />} />
              </Route>

              <Route path="clients/">
                <Route index element={<ClientPage />} />
                <Route path="new" element={<ClientForm />} />
                <Route path=":id" element={<ClientForm />} />
                <Route path="dashboard" element={<ClientDashboard />} />

                <Route path=":clientId/orders" element={<OrdersPage />} />
                <Route path=":clientId/notes" element={<NoteForm />} />
                <Route path=":clientId/orders/new" element={<OrderForm />} />
                <Route path=":clientId/orders/:orderId" element={<OrderForm />} />
                <Route
                  path=":clientId/orders/:orderId/editorial_calendars"
                  element={<ECalendarsPage />}
                />
                <Route path=":clientId/orders/:orderId/emails" element={<OEmailPage />} />
                <Route
                  path=":clientId/orders/:orderId/emails/:docId"
                  element={<OEmailPage />}
                />
                <Route
                  path=":clientId/orders/:orderId/documents"
                  element={<DocumentPage />}
                />
                <Route path=":clientId/orders/:orderId/survey" element={<SurveyPage />} />
                <Route
                  path=":clientId/orders/:orderId/survey/:surveyId"
                  element={<SurveyDetails />}
                />
                <Route
                  path=":clientId/orders/:orderId/notes"
                  element={<OrderNoteForm />}
                />
                <Route
                  path=":clientId/orders/:orderId/insight"
                  element={<InsightPage />}
                />

                <Route
                  path=":clientId/orders/:orderId/editorial_calendars/new"
                  element={<ECalendarsForm />}
                />
                <Route
                  path=":clientId/orders/:orderId/editorial_calendars/:id"
                  element={<ECalendarsForm />}
                />
                <Route
                  path=":clientId/orders/:orderId/editorial_calendars/:eCalendarId/posts"
                  element={<PostPage />}
                />
                <Route
                  path=":clientId/orders/:orderId/editorial_calendars/:eCalendarId/posts_calendar"
                  element={<PostCalendar />}
                />
                <Route
                  path=":clientId/orders/:orderId/editorial_calendars/:eCalendarId/posts/new"
                  element={<PostForm />}
                />
                <Route
                  path=":clientId/orders/:orderId/editorial_calendars/:eCalendarId/posts/:postId"
                  element={<PostForm />}
                />
                <Route
                  path=":clientId/orders/:orderId/brief_responses"
                  element={<BriefReponsesForm />}
                />
                <Route
                  path=":clientId/orders/:orderId/quality_checks"
                  element={<QualityChecksForm />}
                />
                <Route path=":clientId/orders/:orderId/todos" element={<TodosForm />} />
                <Route
                  path=":clientId/orders/:orderId/tickets"
                  element={<TicketsPage />}
                />
                <Route
                  path=":clientId/orders/:orderId/tickets/new"
                  element={<NewTicketForm />}
                />
                <Route
                  path=":clientId/orders/:orderId/tickets/:ticketId"
                  element={<EditTicketForm />}
                />
              </Route>

              <Route path="departments" element={<DepartmentsPage />} />

              <Route path="users/">
                <Route index element={<UserPage />} />
                <Route path="new" element={<UserForm />} />
                <Route path=":id" element={<UserForm />} />
                <Route path=":id/stats" element={<UserStats />} />

                <Route path="clients/">
                  <Route index element={<ClientUserPage />} />
                  <Route path="new" element={<ClientUserForm />} />
                  <Route path=":id" element={<ClientUserForm />} />
                </Route>
              </Route>

              <Route path="products/">
                <Route index element={<ProductPage />} />
                <Route path="new" element={<ProductForm />} />
                <Route path=":id/" element={<ProductForm />} />
                <Route
                  path=":productId/quality_criterions/new"
                  element={<QualityCriterionForm />}
                />

                <Route
                  path=":productId/quality_criterions/:id"
                  element={<QualityCriterionForm />}
                />
                <Route
                  path=":productId/survey-questions/new"
                  element={<ProductSurveyQuestionForm />}
                />
                <Route path=":productId/todos/new" element={<TodoForm />} />
                <Route path=":productId/todos/:id" element={<TodoForm />} />
              </Route>
              <Route path="brief-questions/">
                <Route index element={<BriefQuestionPage />} />
                <Route path="new" element={<BriefQuestionForm />} />
                <Route path=":id" element={<BriefQuestionForm />} />
              </Route>
              <Route path="quality-checks/">
                <Route index element={<QualityCheckPage />} />
                <Route path="new" element={<QualityCheckForm />} />
                <Route path=":id" element={<QualityCheckForm />} />
              </Route>

              <Route path="survey-questions/">
                <Route index element={<SurveyQuestionPage />} />
                <Route path="new" element={<SurveyQuestionForm />} />
                <Route path=":id" element={<SurveyQuestionForm />} />
              </Route>
              {/* <Route path="admin/">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
            </Route> */}
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </Router>
      </PanelContext.Provider>
    </NavContext.Provider>
  );
}

export default App;
