import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useParams } from "react-router-dom";
import {
  fetchCalendarPosts,
  fetchECalendarById,
  addToDraggedPosts,
  removeFromUndraggedPosts,
  updatePost,
} from "../../../../redux/actions/cli/eCalendarActions";
import { fetchOrderById } from "../../../../redux/actions/cli/orderActions";
import { fetchCliTinyClient } from "../../../../redux/actions/cli/clientsActions";
import OrderNavigation from "../../clients/orderNavigation";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faList } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import UnDraggedPosts from "./undraggedPosts";
// AYB This should b in a shared component
import ExpandedContent from "../../../admin/clients/orders/tickets/ExpandedContent";
import { useWindowDimensions } from "./useWindowDimensions";

import moment from "moment";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CliPostCalendar = () => {
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const { eCalendarId, clientId, orderId } = useParams();
  const [layoutId, setLayoutId] = useState(null);
  const [data, setData] = useState(null);
  const [showPosts, setShowPosts] = useState([]);
  const [draggedPosts, setDraggedPosts] = useState([]);
  const [draggedPost, setDraggedPost] = useState(false);

  const dragged_posts = useSelector((state) => state.cliECalendarReducer.dragged_posts);
  const undragged_posts = useSelector(
    (state) => state.cliECalendarReducer.undragged_posts
  );

  const fetchingPosts = useSelector((state) => state.cliECalendarReducer.fetchingPosts);
  const order = useSelector((state) => state.cliClientsReducer.order);
  const eCalendar = useSelector((state) => state.cliECalendarReducer.eCalendar);
  const client = useSelector((state) => state.cliClientsReducer.tiny_client);
  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliTinyClient(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    document.title = "ProdOTop - posts du Calendrier editorial";
  }, []);

  useEffect(() => {
    dispatch(fetchCalendarPosts(orderId, eCalendarId));
  }, [dispatch, fetchingPosts, eCalendarId, orderId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    if (eCalendarId) {
      dispatch(fetchECalendarById(orderId, eCalendarId));
    }
  }, [dispatch, eCalendarId, orderId]);

  const transformPostsToEvents = (posts) => {
    return posts.map((post) => {
      const startDate = new Date(post.date);
      const endDate = new Date(post.date);

      return {
        ...post,
        start: startDate,
        end: endDate,
      };
    });
  };

  useEffect(() => {
    if (dragged_posts) {
      const calendarEvents = transformPostsToEvents(dragged_posts);
      if (Array.isArray(calendarEvents)) {
        setDraggedPosts(calendarEvents);
      } else {
        console.error("Les données des événements ne sont pas un tableau");
        setDraggedPosts([]);
      }
    }
  }, [dragged_posts]);

  const dayPropGetter = (date) => {
    const dayStyle = {};
    if (date.getDay() === 0 || date.getDay() === 6) {
      dayStyle.className = "disabled-day";
    }
    return dayStyle;
  };

  const onDropFromOutside = ({ start, end, allDay }) => {
    console.log("onDropFromOutside", draggedPost);

    const theme = draggedPost.theme;
    const priority = draggedPost.priority;

    const event = {
      _id: draggedPost._id,
      theme,
      start,
      end,
      priority,
      date: start,
    };

    setDraggedPost(null);
    newPost(event);
  };

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    console.log("moveEvent");

    const idx = draggedPosts.indexOf(event);

    const updatedPost = { ...event, start, end, allDay: false };

    const nextEvents = [...draggedPosts];
    nextEvents.splice(idx, 1, updatedPost);

    setDraggedPosts(nextEvents);
    dispatch(updatePost(orderId, eCalendarId, event._id, { date: start }));
  };

  const newPost = (post) => {
    dispatch(addToDraggedPosts(post));
    dispatch(removeFromUndraggedPosts(post._id));

    dispatch(updatePost(orderId, eCalendarId, post._id, { date: post.date }));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData("text/plain");
    const theDraggedPost = JSON.parse(data);
    setDraggedPost(theDraggedPost);
  };

  return (
    <>
      <div className="pb-2">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/clients/${order?.client?._id}/orders`}>
                {order?.client?.companyName}
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {order?.product?.name}
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to={`/clients/${clientId}/orders/${orderId}/editorial_calendars`}>
                Calendriers éditoriaux
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {eCalendar.title}
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Vue du calendrier
            </li>
          </ol>
        </nav>
      </div>
      <div className="order-container">
        {order && <OrderNavigation order={order} client={client} currentTab="ce" />}
        <>
          <Row>
            <Row className="mb-3">
              <Col className="text-end" xs={12}>
                <Link
                  to={`/client/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts`}
                  className="prodotop-link py-2 ms-2"
                >
                  <FontAwesomeIcon icon={faList} className="px-2" />
                </Link>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col md={12}>
              <div className="task-calendar-container">
                <UnDraggedPosts
                  setShowPosts={setShowPosts}
                  showPosts={showPosts}
                  undraggedPosts={undragged_posts}
                  height={height}
                />

                <div
                  className="calendar-container  rounded-3 bg-white"
                  style={{ height: `calc(${height}px - 150px)` }}
                >
                  <div className={`myCustomHeight gap-3`} onDrop={handleDrop}>
                    <DnDCalendar
                      selectable
                      localizer={localizer}
                      events={draggedPosts}
                      dragFromOutsideItem={draggedPost}
                      onDropFromOutside={onDropFromOutside}
                      onEventDrop={moveEvent}
                      dayPropGetter={dayPropGetter}
                      views={["month"]}
                      resourceIdAccessor="_id"
                      // eventPropGetter={eventPropGetter} grren if shared
                      titleAccessor={(post) => `${post.priority} | ${post.theme} `}
                      messages={{
                        work_week: "Semaine",
                        day: "Jour",
                        month: "Mois",
                        previous: "Précédent",
                        next: "Suivant",
                        today: "Aujourd'hui",
                      }}
                      tooltipAccessor="theme"
                    />
                  </div>
                </div>
              </div>

              {/* {dragged_posts.map((post, index) => {
            const photos = post.photo.filter((photo) => photo);
            return (
              <div key={post._id} className="mb-4 post-container">
                <div
                  className={`card purple-border card-order post justify-content-center `}
                >
                  <div className="card-body p-2">
                    <div className="flex gap-3 full ">
                      <div>
                        <div>
                          {photos && photos[0] ? (
                            <img
                              src={photos[0]}
                              alt={post.theme}
                              style={{ width: "50px" }}
                            />
                          ) : (
                            <img
                              src="/images/pastille.png"
                              alt={post.theme}
                              style={{
                                width: "50px",
                                backgroundColor: "#afaae3",
                                padding: "3px",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="community-card-content">
                        <Row>
                          <Col xs={12}>
                            <div className="flex justify-content-between">
                              <h6 className="post-title mb-0 ">
                                <span className={`product-title text-dark `}>
                                  {index + 1} {"|"} {post.theme}
                                </span>
                              </h6>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })} */}
            </Col>

            <Row className="my-3">
              <Col>
                <Link
                  className="link-action back"
                  to={`/client/${clientId}/orders/${orderId}/editorial_calendars`}
                >
                  Retour
                </Link>
              </Col>
            </Row>
          </Row>
        </>
      </div>
      {layoutId && (
        <ExpandedContent
          key={layoutId}
          layoutId={layoutId}
          data={data}
          setData={setData}
        />
      )}
    </>
  );
};

export default CliPostCalendar;
