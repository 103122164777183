import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchECalendars } from "../../../redux/actions/cli/eCalendarActions";
import { fetchOrderById } from "../../../redux/actions/cli/orderActions";
import { fetchCliTinyClient } from "../../../redux/actions/cli/clientsActions";
import OrderNavigation from "../clients/orderNavigation";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const ECalendarsPage = () => {
  const dispatch = useDispatch();
  const { orderId, clientId } = useParams();

  const eCalendars = useSelector((state) => state.cliECalendarReducer.eCalendars);
  const order = useSelector((state) => state.cliClientsReducer.order);
  const client = useSelector((state) => state.cliClientsReducer.tiny_client);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(orderId));
      dispatch(fetchECalendars(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliTinyClient(clientId));
    }
  }, [dispatch, clientId]);

  if (!order?.product?.include_e_calendars) {
    return <div>Accés non autorisé...</div>;
  }

  return (
    <>
      <div className="pb-2">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/client/${clientId}/orders`}>{order?.client?.companyName}</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {order?.product?.name}
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Calendriers éditoriaux
            </li>
          </ol>
        </nav>
      </div>
      <div className="order-container">
        {order && <OrderNavigation order={order} client={client} currentTab="ce" />}
        <Row>
          <Col md={12} className="mb-5">
            <div className="card purple-border card-order eCalendar justify-content-center">
              <div className="card-body py-4 px-3">
                <div className="px-3 pb-3">
                  <p className="mb-1">
                    <b>Bienvenue sur votre espace dédié aux calendriers éditoriaux.</b>
                  </p>
                  <p className="mb-1">
                    Cette page a été conçue pour vous donner un aperçu clair et organisé
                    de vos contenus planifiés.
                  </p>
                  <p className="mb-1">
                    Voici les élément clés qui permettront de rendre le calendrier
                    pertinent :
                  </p>
                </div>
                <div className="flex gap-3 flex-wrap px-3">
                  <div className="resume-item">
                    <div>
                      <b className="product-title bg-primary px-3 text-white">
                        Objectifs :
                      </b>
                    </div>
                    Découvrez l'objectif principal de chaque contenu. vous saurez toujours
                    quel est le but visé.
                  </div>
                  <div className="resume-item">
                    <div>
                      <b className="product-title bg-primary px-3 text-white">Cible :</b>
                    </div>
                    Le public visé par chaque contenu. Nous veillons à ce que chaque
                    publication soit adaptée à son audience.
                  </div>
                  <div className="resume-item">
                    <div>
                      <b className="product-title bg-primary px-3 text-white">
                        Tone of Voice :
                      </b>
                    </div>
                    Identifiez le ton et le style de communication choisi. le ton utilisé
                    est essentiel pour toucher votre public cible de la manière la plus
                    efficace.
                  </div>

                  <div className="resume-item">
                    <div>
                      <b className="product-title bg-primary px-3 text-white">
                        Plan de communication :
                      </b>
                    </div>
                    La fréquence de planification et les canaux utilisés.
                  </div>
                  <div className="resume-item">
                    <div>
                      <b className="product-title bg-primary px-3 text-white">
                        Stratégie :
                      </b>
                    </div>
                    Résumé.
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {eCalendars.map((eCalendar) => (
            <Col md={12} className="mb-3" key={eCalendar._id}>
              <div>
                <div className="card purple-border card-order eCalendar justify-content-center">
                  <div className="card-header py-3 flex justify-content-between">
                    <h6 className="eCalendar-title">
                      <span className="product-title text-dark ">{eCalendar.title}</span>
                    </h6>
                  </div>
                  <div className="card-body py-4 px-3">
                    <div className="">
                      <Row>
                        <Col md={3}>
                          <div className="mb-3">
                            <label htmlFor="objectives" className="form-label">
                              Objectifs :
                            </label>
                            <p>{eCalendar.objectives}</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label htmlFor="tone_of_voice" className="form-label">
                              Tone of voice :
                            </label>
                            <p>{eCalendar.tone_of_voice}</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label htmlFor="target" className="form-label">
                              Cible :
                            </label>
                            <p>{eCalendar.target}</p>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <label htmlFor="communication_plan" className="form-label">
                              Plan de communication :
                            </label>
                            <p>{eCalendar.communication_plan}</p>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <label htmlFor="strategy" className="form-label">
                              Strategy :
                            </label>
                            <p>{eCalendar.strategy}</p>
                          </div>
                        </Col>
                      </Row>
                      <div className="z-index-1">
                        <Link
                          to={`/client/${clientId}/orders/${orderId}/editorial_calendars/${eCalendar._id}/posts`}
                          className="btn bg-gradient-dark-inverse mb-0 "
                        >
                          <span className="span-btn">Posts</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default ECalendarsPage;
