import {
  FETCH_BRIEF_QUESTIONS_REQUEST,
  FETCH_BRIEF_QUESTIONS_SUCCESS,
  FETCH_BRIEF_QUESTIONS_FAILURE,
  FETCH_BRIEF_QUESTION_REQUEST,
  FETCH_BRIEF_QUESTION_SUCCESS,
  FETCH_BRIEF_QUESTION_FAILURE,
  ADD_BRIEF_QUESTION_REQUEST,
  ADD_BRIEF_QUESTION_SUCCESS,
  ADD_BRIEF_QUESTION_FAILURE,
  UPDATE_BRIEF_QUESTION_REQUEST,
  UPDATE_BRIEF_QUESTION_SUCCESS,
  UPDATE_BRIEF_QUESTION_FAILURE,
  DELETE_BRIEF_QUESTION_REQUEST,
  DELETE_BRIEF_QUESTION_SUCCESS,
  DELETE_BRIEF_QUESTION_FAILURE,
} from "../actionTypes";

const initialState = {
  briefQuestions: [],
  briefQuestion: false,

  isFetching: false,
  loading: false,
  error: null,
};

const briefQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BRIEF_QUESTION_REQUEST:
      return {
        ...state,
        error: null,
      };
    case FETCH_BRIEF_QUESTION_SUCCESS:
      return {
        ...state,
        briefQuestion: action.payload,
        error: null,
      };
    case FETCH_BRIEF_QUESTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_BRIEF_QUESTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ADD_BRIEF_QUESTION_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case UPDATE_BRIEF_QUESTION_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case DELETE_BRIEF_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
        isFetching: true,
        error: null,
      };
    case FETCH_BRIEF_QUESTIONS_SUCCESS:
      return {
        ...state,
        briefQuestions: action.payload,
        isFetching: true,
        loading: false,
        error: null,
      };
    case ADD_BRIEF_QUESTION_SUCCESS:
      return {
        ...state,
        //briefQuestions: [...state.briefQuestions, action.payload],
        loading: false,
        isFetching: false,
        error: null,
      };
    case UPDATE_BRIEF_QUESTION_SUCCESS:
      const updatedBriefQuestions = state.briefQuestions.map((briefQuestion) =>
        briefQuestion._id === action.payload._id ? action.payload : briefQuestion
      );
      return {
        ...state,
        briefQuestions: updatedBriefQuestions,
        loading: false,
        error: null,
        isFetching: false,
      };
    case DELETE_BRIEF_QUESTION_SUCCESS:
      const filteredBriefQuestions = state.briefQuestions.filter(
        (briefQuestion) => briefQuestion._id !== action.payload
      );
      return {
        ...state,
        briefQuestions: filteredBriefQuestions,
        isFetching: false,
        loading: false,
        error: null,
      };
    case FETCH_BRIEF_QUESTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case ADD_BRIEF_QUESTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case UPDATE_BRIEF_QUESTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case DELETE_BRIEF_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default briefQuestionsReducer;
