import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderById,
  fetchClientById,
  generateSurvey,
} from "../../../../../redux/actions/clientActions";
import { fetchSurveyQuestionsByProduct } from "../../../../../redux/actions/surveyQuestionActions";
import { Bars } from "react-loader-spinner";

import { Row, Col, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { NavContext } from "../../../../NavContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faArrowUpRightFromSquare,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

import config from "../../../../../config";
import OrderNavigation from "../order/orderNavigation";

const SurveyPage = () => {
  const dispatch = useDispatch();
  const { activeNav, setActiveNav } = useContext(NavContext);
  const { clientId, orderId } = useParams();

  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);

  const order = useSelector((state) => state.clientReducer.order);
  const client = useSelector((state) => state.clientReducer.tiny_client);
  const generatingSurvey = useSelector((state) => state.clientReducer.generatingSurvey);
  const currentUser = useSelector((state) => state.authReducer.user.user);
  const surveyQuestions = useSelector(
    (state) => state.surveyQuestionReducer.productSurveyQuestions
  );

  useEffect(() => {
    document.title = "ProdOTop - Enquêtes de satisfactions";
  }, []);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientById(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(clientId, orderId));
    }
  }, [dispatch, orderId, clientId]);

  useEffect(() => {
    if (order) {
      dispatch(fetchSurveyQuestionsByProduct(order.product._id));
    }
  }, [dispatch, order]);

  const handleGenerateSurvey = () => {
    dispatch(generateSurvey(clientId, orderId));
  };

  const RatingStars = ({ rating }) => {
    const totalStars = 5;
    const fullStar = <FontAwesomeIcon icon={faStar} className="text-warning" />;
    const emptyStar = <FontAwesomeIcon icon={faStar} className="text-muted" />;

    const stars = [];
    for (let i = 0; i < totalStars; i++) {
      stars.push(i < rating ? fullStar : emptyStar);
    }
    return <>{stars}</>;
  };

  return (
    <>
      {order && (
        <>
          {generatingSurvey && (
            <div className="ia-loader">
              <Bars
                height={100}
                width={100}
                radius={5}
                color="#cb0c9f"
                ariaLabel="bars-loading"
                wrapperClass={"triangle-loader"}
                wrapperStyle=""
                visible={true}
              />
            </div>
          )}
          <div className="pb-4">
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  <Link to="/clients">clients</Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/clients/${client?._id}/orders`}>{client?.companyName}</Link>
                </li>
                {order && (
                  <li
                    className="breadcrumb-item text-sm text-dark flex p-0"
                    aria-current="page"
                  >
                    <Dropdown className="dropdown-orders">
                      <Dropdown.Toggle id="dropdown-basic">
                        {order.product?.name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {client?.orders?.map((cl_order) => (
                          <Dropdown.Item
                            as={Link}
                            to={`/clients/${clientId}/orders/${cl_order._id}/survey`}
                            key={cl_order._id}
                          >
                            {cl_order.product?.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                )}
                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  Enquêtes de satisfactions
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-container">
            {order && (
              <OrderNavigation order={order} client={client} currentTab="surveys" />
            )}
            <div>
              <Row className="mb-3">
                <div className="col-12">
                  <div className="card medium-card mb-4 pb-2 purple-border">
                    <div className="card-header">
                      <Row>
                        <Col md={9}>
                          <h6>Les questions de l'Enquêtes de satisfaction</h6>
                        </Col>
                        <Col md={3}>
                          {surveyQuestions && surveyQuestions.length > 0 && (
                            <span
                              role="button"
                              className="btn bg-gradient-dark w-80 mb-0"
                              onClick={handleGenerateSurvey}
                            >
                              Générer une enquête
                            </span>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="card-body">
                      {surveyQuestions && surveyQuestions.length > 0 ? (
                        <>
                          <table className="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  question
                                </th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                                  type de réponse
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {surveyQuestions.map((question) => (
                                <tr key={question._id}>
                                  <td>{question.title}</td>
                                  <td className="text-end">{question.responseType}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <>
                          <p>Aucune question n'a été créé pour ce produit</p>
                          <Link
                            to={`/products/${order?.product._id}/survey-questions/new`}
                            className="prodotop-link"
                          >
                            Créer une question
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Row>
              <Row className="mb-3">
                <div className="col-12">
                  <div className="card medium-card mb-4 pb-2 purple-border">
                    <div className="card-body">
                      <h6>
                        <span className="product-title px-3 text-dark">
                          Historique des envois
                        </span>
                      </h6>
                      {order && order.surveys.length > 0 ? (
                        <>
                          <table className="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  enquête
                                </th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                                  Répondu ?
                                </th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.surveys.map((survey) => (
                                <tr key={survey._id}>
                                  <td>
                                    Enquêtes de satisfaction{" - "}
                                    {moment(survey.createdAt).format("DD/MM/YY")}
                                  </td>
                                  <td className="text-end">
                                    {survey.responded ? (
                                      <RatingStars rating={survey.rating} />
                                    ) : (
                                      "Non"
                                    )}
                                  </td>
                                  <td className="text-end">
                                    <small
                                      className="btn copy"
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          `${config.base_url}/client/${clientId}/orders/${orderId}/surveys/${survey._id}`
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faClipboard}
                                        className="px-2"
                                        size="2x"
                                      />
                                    </small>
                                    <Link
                                      to={`${config.base_url}/clients/${clientId}/orders/${orderId}/survey/${survey._id}`}
                                    >
                                      <small className="btn copy mx-2">
                                        <FontAwesomeIcon
                                          icon={faArrowUpRightFromSquare}
                                          className="px-2"
                                          size="2x"
                                        />
                                      </small>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <p className="p-3">
                          Aucune enquêtes de satisfaction n'a été générée
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Link to={`/clients/${clientId}/orders`} className="link-action back">
                    Retour
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SurveyPage;
