import axios from "axios";

const API_URL = "/api/products";

const getAllProducts = () => {
  return axios.get(API_URL).then((response) => {
    return response.data;
  });
};

const getProductById = (id) => {
  return axios.get(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

const createProduct = (data) => {
  return axios.post(API_URL, data).then((response) => {
    return response.data;
  });
};

const updateProduct = (id, data) => {
  return axios.put(`${API_URL}/${id}`, data).then((response) => {
    return response.data;
  });
};

const deleteProduct = (id) => {
  return axios.delete(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

const deleteProductTodo = (id, todoId) => {
  return axios.delete(`${API_URL}/${id}/todos/${todoId}`).then((response) => {
    return response.data;
  });
};

const updatePositions = (positions) => {
  return axios.post(`${API_URL}/positions`, { positions }).then((response) => {
    return response.data;
  });
};

export const productService = {
  getAllProducts,
  getProductById,
  createProduct,
  updateProduct,
  deleteProduct,
  deleteProductTodo,
  updatePositions,
};
