import {
  CLI_FETCH_CLIENTS_REQUEST,
  CLI_FETCH_CLIENTS_SUCCESS,
  CLI_FETCH_CLIENTS_FAILURE,
  CLI_FETCH_CLIENT_ORDERS_REQUEST,
  CLI_FETCH_CLIENT_ORDERS_SUCCESS,
  CLI_FETCH_CLIENT_ORDERS_FAILURE,
  CLI_FETCH_CLIENT_NOTES_REQUEST,
  CLI_FETCH_CLIENT_NOTES_SUCCESS,
  CLI_FETCH_CLIENT_NOTES_FAILURE,
  CLI_UPLOAD_ORDER_DOCUMENT_REQUEST,
  CLI_UPLOAD_ORDER_DOCUMENT_SUCCESS,
  CLI_UPLOAD_ORDER_DOCUMENT_FAILURE,
  CLI_FETCH_CLIENT_CALENDAR_REQUEST,
  CLI_FETCH_CLIENT_CALENDAR_SUCCESS,
  CLI_FETCH_CLIENT_CALENDAR_FAILURE,
  CLI_ADD_CLIENT_TICKET_REQUEST,
  CLI_ADD_CLIENT_TICKET_SUCCESS,
  CLI_ADD_CLIENT_TICKET_FAILURE,
  CLI_FETCH_ORDER_REQUEST,
  CLI_FETCH_ORDER_SUCCESS,
  CLI_FETCH_ORDER_FAILURE,
  CLI_FETCH_ORDER_WITH_DOCUMENTS_REQUEST,
  CLI_FETCH_ORDER_WITH_DOCUMENTS_SUCCESS,
  CLI_FETCH_ORDER_WITH_DOCUMENTS_FAILURE,
  CLI_FETCH_ORDER_WITH_SURVEYS_REQUEST,
  CLI_FETCH_ORDER_WITH_SURVEYS_SUCCESS,
  CLI_FETCH_ORDER_WITH_SURVEYS_FAILURE,
  CLI_FETCH_ORDER_WITH_TICKETS_REQUEST,
  CLI_FETCH_ORDER_WITH_TICKETS_SUCCESS,
  CLI_FETCH_ORDER_WITH_TICKETS_FAILURE,
  CLI_FETCH_ORDER_WITH_NOTES_REQUEST,
  CLI_FETCH_ORDER_WITH_NOTES_SUCCESS,
  CLI_FETCH_ORDER_WITH_NOTES_FAILURE,
  CLI_FETCH_SURVEY_REQUEST,
  CLI_FETCH_SURVEY_SUCCESS,
  CLI_FETCH_SURVEY_FAILURE,
  CLI_ADD_NOTE_REQUEST,
  CLI_ADD_NOTE_SUCCESS,
  CLI_ADD_NOTE_FAILURE,
  CLI_UPDATE_CLIENT_TICKET_REQUEST,
  CLI_UPDATE_CLIENT_TICKET_SUCCESS,
  CLI_UPDATE_CLIENT_TICKET_FAILURE,
  CLI_FETCH_TICKET_REQUEST,
  CLI_FETCH_TICKET_SUCCESS,
  CLI_FETCH_TICKET_FAILURE,
  CLI_FETCH_TINY_CLIENT_REQUEST,
  CLI_FETCH_TINY_CLIENT_SUCCESS,
  CLI_FETCH_TINY_CLIENT_FAILURE,
  CLI_DOWNLOADING_DOCUMENT_REQUEST,
  CLI_DOWNLOADING_DOCUMENT_SUCCESS,
  CLI_DOWNLOADING_DOCUMENT_FAILURE,
} from "../../clientActionTypes";

const initialState = {
  clients: [],
  calendarInfo: {},
  client: false,
  tiny_client: false,
  ticket: false,
  notes: false,
  loadingDocument: false,
  downloadingDocument: false,
  order: false,
  order_documents: false,
  order_surveys: false,
  order_tickets: false,
  order_notes: false,
  survey: false,
  questions: [],
  error: "",
  isFetching: false,
  fb_insight: false,
  loadingFbInsight: false,
  ga_insight: false,
  loadingGAInsight: false,
  gads_insight: false,
  loadingGAdsInsight: false,
};

export default function cliClientsReducer(state = initialState, action) {
  switch (action.type) {
    case CLI_DOWNLOADING_DOCUMENT_REQUEST:
      return {
        ...state,
        downloadingDocument: true,
        error: null,
      };
    case CLI_DOWNLOADING_DOCUMENT_SUCCESS:
      return {
        ...state,
        downloadingDocument: false,
        error: null,
      };
    case CLI_DOWNLOADING_DOCUMENT_FAILURE:
      return {
        ...state,
        downloadingDocument: false,
      };
    case "CLI_FETCH_GADS_INSIGHT_REQUEST":
      return {
        ...state,
        error: null,
        loadingGAdsInsight: true,
      };
    case "CLI_FETCH_GADS_INSIGHT_SUCCESS":
      return {
        ...state,
        gads_insight: action.payload,
        loadingGAdsInsight: false,
        error: null,
      };
    case "CLI_FETCH_GADS_INSIGHT_FAILURE":
      return {
        ...state,
        loadingGAdsInsight: false,
        gads_insight: false,
        error: action.payload,
      };
    case "CLI_FETCH_GA_INSIGHT_REQUEST":
      return {
        ...state,
        error: null,
        loadingGAInsight: true,
      };
    case "CLI_FETCH_GA_INSIGHT_SUCCESS":
      return {
        ...state,
        ga_insight: action.payload,
        loadingGAInsight: false,
        error: null,
      };
    case "CLI_FETCH_GA_INSIGHT_FAILURE":
      return {
        ...state,
        loadingGAInsight: false,
        ga_insight: false,
        error: action.payload,
      };
    case "CLI_FETCH_FB_INSIGHT_REQUEST":
      return {
        ...state,
        error: null,
        loadingFbInsight: true,
      };
    case "CLI_FETCH_FB_INSIGHT_SUCCESS":
      return {
        ...state,
        fb_insight: action.payload,
        loadingFbInsight: false,
        error: null,
      };
    case "CLI_FETCH_FB_INSIGHT_FAILURE":
      return {
        ...state,
        loadingFbInsight: false,
        error: action.payload,
      };
    case CLI_UPDATE_CLIENT_TICKET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    // Add discussion to ticket
    case CLI_UPDATE_CLIENT_TICKET_SUCCESS:
      return {
        ...state,
        ticket: {
          ...state.ticket,
          ticket_discussions: [...state.ticket.ticket_discussions, action.payload],
        },
        isFetching: false,
        error: null,
      };
    case CLI_UPDATE_CLIENT_TICKET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case CLI_FETCH_TICKET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case CLI_FETCH_TICKET_SUCCESS:
      return {
        ...state,
        ticket: action.payload,
        isFetching: false,
        error: null,
      };
    case CLI_FETCH_TICKET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case CLI_ADD_CLIENT_TICKET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case CLI_ADD_CLIENT_TICKET_SUCCESS:
      return {
        ...state,
        order: { ...state.order, tickets: [...state.order.tickets, action.payload] },
        isFetching: false,
        error: null,
      };
    case CLI_ADD_CLIENT_TICKET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case CLI_FETCH_CLIENT_CALENDAR_REQUEST:
      return {
        ...state,
        isFetching: true,
        loading: true,
        error: null,
      };
    case CLI_FETCH_CLIENT_CALENDAR_SUCCESS:
      return {
        ...state,
        calendarInfo: action.payload,
        isFetching: false,
        loading: false,
        error: null,
      };
    case CLI_FETCH_CLIENT_CALENDAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        loading: false,
        error: action.payload,
      };

    case CLI_FETCH_ORDER_WITH_DOCUMENTS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CLI_FETCH_ORDER_WITH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        order_documents: action.payload,
        error: null,
      };
    case CLI_FETCH_ORDER_WITH_DOCUMENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLI_FETCH_ORDER_WITH_TICKETS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CLI_FETCH_ORDER_WITH_TICKETS_SUCCESS:
      return {
        ...state,
        order_tickets: action.payload,
        error: null,
      };
    case CLI_FETCH_ORDER_WITH_TICKETS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLI_FETCH_ORDER_WITH_NOTES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CLI_FETCH_ORDER_WITH_NOTES_SUCCESS:
      return {
        ...state,
        order_notes: action.payload,
        error: null,
      };
    case CLI_FETCH_ORDER_WITH_NOTES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLI_FETCH_ORDER_WITH_SURVEYS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CLI_FETCH_ORDER_WITH_SURVEYS_SUCCESS:
      return {
        ...state,
        order_surveys: action.payload,
        error: null,
      };
    case CLI_FETCH_ORDER_WITH_SURVEYS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLI_FETCH_ORDER_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CLI_FETCH_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        error: null,
      };
    case CLI_FETCH_ORDER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLI_FETCH_SURVEY_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CLI_FETCH_SURVEY_SUCCESS:
      return {
        ...state,
        survey: action.payload.survey,
        questions: action.payload.questions,
        error: null,
      };
    case CLI_FETCH_SURVEY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLI_UPLOAD_ORDER_DOCUMENT_REQUEST:
      return {
        ...state,
        loadingDocument: true,
      };
    case CLI_UPLOAD_ORDER_DOCUMENT_SUCCESS:
      return {
        ...state,
        loadingDocument: false,
        order: {
          ...state.order,
          documents: [...state.order.documents, action.payload],
        },
      };
    case CLI_UPLOAD_ORDER_DOCUMENT_FAILURE:
      return {
        ...state,
        loadingDocument: false,
      };
    case CLI_FETCH_CLIENTS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CLI_FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        error: null,
      };
    case CLI_FETCH_CLIENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLI_FETCH_TINY_CLIENT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CLI_FETCH_TINY_CLIENT_SUCCESS:
      return {
        ...state,
        tiny_client: action.payload,
        error: null,
      };
    case CLI_FETCH_TINY_CLIENT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLI_FETCH_CLIENT_ORDERS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CLI_FETCH_CLIENT_ORDERS_SUCCESS:
      return {
        ...state,
        client: action.payload,
        error: null,
      };
    case CLI_FETCH_CLIENT_ORDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLI_FETCH_CLIENT_NOTES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CLI_FETCH_CLIENT_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload,
        error: null,
      };
    case CLI_FETCH_CLIENT_NOTES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLI_ADD_NOTE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CLI_ADD_NOTE_SUCCESS:
      return {
        ...state,
        notes: [action.payload, ...state.notes],
        error: null,
      };
    case CLI_ADD_NOTE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
