// src/redux/reducers/surveyQuestionReducer.js

import {
  FETCH_SURVEY_QUESTIONS_REQUEST,
  FETCH_SURVEY_QUESTIONS_SUCCESS,
  FETCH_SURVEY_QUESTIONS_FAILURE,
  FETCH_PRODUCT_SURVEY_QUESTIONS_REQUEST,
  FETCH_PRODUCT_SURVEY_QUESTIONS_SUCCESS,
  FETCH_PRODUCT_SURVEY_QUESTIONS_FAILURE,
  ADD_SURVEY_QUESTION_REQUEST,
  ADD_SURVEY_QUESTION_SUCCESS,
  ADD_SURVEY_QUESTION_FAILURE,
  UPDATE_SURVEY_QUESTION_REQUEST,
  UPDATE_SURVEY_QUESTION_SUCCESS,
  UPDATE_SURVEY_QUESTION_FAILURE,
  DELETE_SURVEY_QUESTION_REQUEST,
  DELETE_SURVEY_QUESTION_SUCCESS,
  DELETE_SURVEY_QUESTION_FAILURE,
  DELETE_SURVEY_QUESTION_FROM_PRODUCT_REQUEST,
  DELETE_SURVEY_QUESTION_FROM_PRODUCT_SUCCESS,
  DELETE_SURVEY_QUESTION_FROM_PRODUCT_FAILURE,
} from "../actionTypes";

const initialState = {
  surveyQuestions: [],
  productSurveyQuestions: [],
  isFetching: false,
  loading: false,
  error: null,
};

const surveyQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_SURVEY_QUESTION_FROM_PRODUCT_REQUEST:
      return {
        ...state,
        error: null,
      };

    case DELETE_SURVEY_QUESTION_FROM_PRODUCT_SUCCESS:
      const updatedProductSurveyQuestions = state.productSurveyQuestions.filter(
        (surveyQuestion) => surveyQuestion._id !== action.payload
      );
      return {
        ...state,
        productSurveyQuestions: updatedProductSurveyQuestions,
      };

    case DELETE_SURVEY_QUESTION_FROM_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_PRODUCT_SURVEY_QUESTIONS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case FETCH_PRODUCT_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        productSurveyQuestions: action.payload,
        error: null,
      };
    case FETCH_PRODUCT_SURVEY_QUESTIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_SURVEY_QUESTIONS_REQUEST:
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    case ADD_SURVEY_QUESTION_REQUEST:
    case UPDATE_SURVEY_QUESTION_REQUEST:
    case DELETE_SURVEY_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
        isFetching: true,
        error: null,
      };
    case FETCH_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        surveyQuestions: action.payload,
        isFetching: true,
        loading: false,
        error: null,
      };
    case ADD_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        surveyQuestions: [...state.surveyQuestions, action.payload],
        loading: false,
        isFetching: false,
        error: null,
      };
    case UPDATE_SURVEY_QUESTION_SUCCESS:
      const updatedSurveyQuestions = state.surveyQuestions.map((surveyQuestion) =>
        surveyQuestion._id === action.payload._id ? action.payload : surveyQuestion
      );
      return {
        ...state,
        surveyQuestions: updatedSurveyQuestions,
        loading: false,
        error: null,
        isFetching: false,
      };
    case DELETE_SURVEY_QUESTION_SUCCESS:
      const filteredSurveyQuestions = state.surveyQuestions.filter(
        (surveyQuestion) => surveyQuestion._id !== action.payload
      );
      return {
        ...state,
        surveyQuestions: filteredSurveyQuestions,
        isFetching: false,
        loading: false,
        error: null,
      };
    case FETCH_SURVEY_QUESTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case ADD_SURVEY_QUESTION_FAILURE:
    case UPDATE_SURVEY_QUESTION_FAILURE:
    case DELETE_SURVEY_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default surveyQuestionsReducer;
