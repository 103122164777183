import { Col } from "react-bootstrap";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faUsers } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import { responsiblesFormat } from "../../../../utils/screen";
import DepartmentName from "../../../../shared/departments/departmentName";

const OrderCard = ({ order, clientId }) => {
  return (
    <>
      <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={order._id}>
        <div className="card purple-border mb-4 card-order">
          <div className="card-body py-2 px-3">
            <div className="text-center">
              <div>
                <Link to={`/clients/${clientId}/orders/${order._id}`}>
                  <h6 className="mt-3">
                    <span className="product-title px-1 text-dark">
                      {order.product.name}
                    </span>

                    {order.with_open_tickets && (
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="px-2 pt-1"
                        color="#FF0000"
                      />
                    )}
                  </h6>
                </Link>
              </div>
              <div>
                <small className="product-title px-2 text-dark">
                  {order.shared && (
                    <FontAwesomeIcon icon={faUsers} className="pe-2 purple-color" />
                  )}
                  {moment(order.createdAt).format("DD-MM-YYYY")}{" "}
                </small>
              </div>

              <div>
                <small>{responsiblesFormat(order)}</small>
              </div>
            </div>
            <hr />
            <div className="mb-2">
              <ul className="team-accepted-steps flex flex-wrap justify-content-around">
                <li className={order.team1Accepted ? "active" : ""}>
                  <span>
                    <DepartmentName code="Team1" />
                  </span>
                </li>
                <li className={order.team2Accepted ? "active" : ""}>
                  <span>
                    <DepartmentName code="Team2" />
                  </span>
                </li>
                <li className={order.team3Accepted ? "active" : ""}>
                  <span>
                    <DepartmentName code="Team3" />
                  </span>
                </li>
                <li className={order.team4Accepted ? "active" : ""}>
                  <span>
                    <DepartmentName code="Team4" />
                  </span>
                </li>
                <li className={order.team5Accepted ? "active" : ""}>
                  <span>
                    <DepartmentName code="Team5" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default OrderCard;
