const axios = require("axios");

axios.interceptors.request.use(
  function (config) {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.accessToken) {
      config.headers.Authorization = "Bearer " + user.accessToken;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
