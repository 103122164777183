import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdsReports } from "../../../redux/actions/clientActions";
import { NavContext } from "../../NavContext";
import { Triangle } from "react-loader-spinner";
import moment from "moment";

import ReportsTable from "../../shared/reports/ReportsTable";
import ErrorBoundary from "../../ErrorBoundry";

const AdsReports = () => {
  const dispatch = useDispatch();
  const { activeNav, setActiveNav } = useContext(NavContext);
  useEffect(() => {
    setActiveNav("reports");
  }, [setActiveNav]);

  const ads_reports = useSelector((state) => state.clientReducer.ads_reports);
  const fetchingReports = useSelector((state) => state.clientReducer.fetchingReports);

  useEffect(() => {
    dispatch(fetchAdsReports());
  }, [dispatch]);

  const dateStartCurrent = (date) =>
    moment(date).subtract(10, "days").format("DD-MM-YYYY");
  const dateEndCurrent = (date) => moment(date).subtract(1, "days").format("DD-MM-YYYY");

  const dateStartPrevious = (date) =>
    moment(date).subtract(20, "days").format("DD-MM-YYYY");
  const dateEndPrevious = (date) =>
    moment(date).subtract(11, "days").format("DD-MM-YYYY");

  return (
    <ErrorBoundary fallback="Error code: #001AdsReports001">
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              Ads reports
            </li>
          </ol>
        </nav>
      </div>
      <div className="order-container">
        {fetchingReports && (
          <div className="loading-reports flex">
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#333"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass="primary-color"
            />
            <span className="primary-color">Loading Reports</span>
          </div>
        )}

        <div className="card medium-card mb-4 pb-2 purple-border reports-card">
          <div className="card-header">
            {" "}
            <h6>
              <span>Ads reports</span>
            </h6>
          </div>
          {!fetchingReports && ads_reports && ads_reports.length > 0 && (
            <div className="card-body">
              <p className="mb-2">
                Ce tableau présente les statistiques des différentes campagnes
                publicitaires que nous gérons. Il est mis à jour automatiquement le 1er et
                le 15 de chaque mois pour comparer deux périodes distinctes.{" "}
              </p>
              <p className="mb-5">
                Les statistiques comparent la période précédente <b>Prev</b>, allant du{" "}
                <b className="mx-1">{dateStartPrevious(ads_reports[0].createdAt)}</b> au{" "}
                <b className="mx-1">{dateEndPrevious(ads_reports[0].createdAt)}</b>à la
                nouvelle période <b>New</b>, qui s'étend du{" "}
                <b className="mx-1">{dateStartCurrent(ads_reports[0].createdAt)}</b> au{" "}
                <b className="mx-1">{dateEndCurrent(ads_reports[0].createdAt)}</b>.
              </p>
              <ReportsTable ads_reports={ads_reports} details={false} />
            </div>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default AdsReports;
