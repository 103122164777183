import { useEffect, useContext } from "react";
import { NavContext } from "../../../NavContext";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, fetchUsers } from "../../../../redux/actions/userActions";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPen, faChartBar } from "@fortawesome/free-solid-svg-icons";
import DepartmentName from "../../../shared/departments/departmentName";

const UserPage = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.userReducer.users);
  const currentUser = useSelector((state) => state.authReducer.user.user);
  const isFetching = useSelector((state) => state.userReducer.isFetching);
  const { activeNav, setActiveNav } = useContext(NavContext);
  useEffect(() => {
    document.title = "ProdOTop - Utilisateurs";
  }, []);

  useEffect(() => {
    setActiveNav("users");
  }, [setActiveNav]);

  useEffect(() => {
    if (!isFetching) {
      dispatch(fetchUsers());
    }
  }, [dispatch, users, isFetching]);

  const handleDelete = (userId) => {
    dispatch(deleteUser(userId));
  };

  const canExecActions = (user) => {
    return true;
    // if (currentUser.role === "boss") {
    //   return true;
    // } else if (
    //   currentUser.departement === user.departement ||
    //   user.departement === "client"
    // ) {
    //   return true;
    // }
    // return false;
  };

  const employees = users.filter((user) => user.user_type === "employee");

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Employées
            </li>
          </ol>
        </nav>
      </div>
      <Row className="row">
        <div className="col-12">
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header">
              <Row className="">
                <Col md={6}>
                  <h6>
                    <span className="product-title px-3 text-white">
                      Liste des utilisateurs
                    </span>
                  </h6>
                </Col>
                <Col className="text-end" md={6}>
                  <Link to="/users/new" className="btn bg-gradient-dark-inverse mb-0">
                    Ajouter un utilisateur
                  </Link>
                </Col>
              </Row>
            </div>
            <div className="card-body px-0 pt-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        style={{ width: "40%" }}
                      >
                        Nom et Prénom
                      </th>
                      <th
                        className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        style={{ width: "20%" }}
                      >
                        Pôle
                      </th>
                      <th
                        className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        style={{ width: "20%" }}
                      >
                        Role
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees.map((user) => (
                      <tr key={user._id}>
                        <td className="align-middle text-sm px-4">
                          <div>{user.name}</div>
                          <div>
                            <small>{user.email}</small>
                          </div>
                        </td>
                        <td className="align-middle text-sm px-4">
                          <DepartmentName code={user.departement} />
                        </td>
                        <td className="align-middle text-sm px-4">{user.role}</td>
                        <td className="align-middle px-2 text-sm text-end">
                          {canExecActions(user) && (
                            <>
                              <Link
                                to={`/users/${user._id}/stats`}
                                className="btn btn-link text-info text-gradient px-3 mb-0"
                              >
                                <FontAwesomeIcon icon={faChartBar} className="px-2" />
                                Stats
                              </Link>
                              <Link
                                to={`/users/${user._id}`}
                                className="btn btn-link text-warning text-gradient px-3 mb-0"
                              >
                                <FontAwesomeIcon icon={faPen} className="px-2" />
                                Modifier
                              </Link>
                              <button
                                className="btn btn-link text-danger text-gradient px-3 mb-0"
                                type="button"
                                onClick={() => handleDelete(user._id)}
                              >
                                <FontAwesomeIcon icon={faTrashCan} className="px-2" />
                                Supprimer
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Row className="mb-3">
          <Col>
            <Link className="link-action back" to="/">
              Retour
            </Link>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default UserPage;
