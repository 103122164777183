import { authService } from "../services/authServices";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_REQUEST,
  REGISTER_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "../actionTypes";

// Action Creator pour connecter un utilisateur
export const login = (credentials) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    try {
      const user = await authService.login(credentials);
      if (user.accessToken) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      dispatch({ type: LOGIN_SUCCESS, payload: user });
      return user;
    } catch (err) {
      dispatch({ type: LOGIN_FAILURE, payload: err.response.data.errorLogin });
    }
  };
};
// Action Creator pour connecter un utilisateur
export const authRegister = (credentials) => {
  return async (dispatch) => {
    dispatch({ type: REGISTER_REQUEST });
    try {
      const user = await authService.register(credentials);
      if (user.accessToken) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      dispatch({ type: LOGIN_SUCCESS, payload: user });
      return user;
    } catch (err) {
      dispatch({ type: REGISTER_FAILURE, payload: err.response.data.error_message });
    }
  };
};

// Action Creator pour déconnecter un utilisateur
export const logout = () => {
  return async (dispatch) => {
    dispatch({ type: LOGOUT_REQUEST });
    try {
      await authService.logout();
      dispatch({ type: LOGOUT_SUCCESS });
    } catch (err) {
      dispatch({ type: LOGOUT_FAILURE, payload: err.message });
    }
  };
};
