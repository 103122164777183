import { useEffect, useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrderNote,
  fetchOrderWithNotesById,
  fetchClientById,
} from "../../../../../redux/actions/clientActions";
import { Row, Col, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faCommentDots,
  faFile,
  faEnvelope,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { NavContext } from "../../../../NavContext";
import OrderNavigation from "../order/orderNavigation";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";

const OrderNoteForm = () => {
  const dispatch = useDispatch();
  const [selectedTags, setSelectedTags] = useState([]);
  const [filtredNotes, setFiltredNotes] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();
  const { clientId, orderId } = useParams();
  const { activeNav, setActiveNav } = useContext(NavContext);
  const client = useSelector((state) => state.clientReducer.tiny_client);
  const order = useSelector((state) => state.clientReducer.order_with_notes);
  const tags = [
    { label: "Rapport", value: "Rapport" },
    { label: "Optimisation", value: "Optimisation" },
    { label: "Budget", value: "Budget" },
    { label: "Problémes", value: "Problemes" },
    { label: "Rendez-vous", value: "Rdv" },
  ];

  const handleTagChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedTags([...selectedTags, value]);
    } else {
      setSelectedTags(selectedTags.filter((tag) => tag !== value));
    }
  };

  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientById(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderWithNotesById(clientId, orderId));
    }
  }, [dispatch, orderId, clientId]);

  useEffect(() => {
    if (order) {
      setFiltredNotes(order?.notes);
    }
  }, [setFiltredNotes, order]);

  const onSubmit = (data) => {
    const formData = {
      ...data,
      tags: selectedTags,
    };
    dispatch(addOrderNote(clientId, orderId, formData));
    reset();
    setSelectedTags([]);
  };

  const filterNotes = (e) => {
    let filtered_notes = order.notes;
    console.log(e.target.value);
    console.log(e.target);
    const selectedTag = e.target.value;

    if (selectedTag) {
      filtered_notes = filtered_notes.filter((note) => note.tags.includes(selectedTag));
    }
    setFiltredNotes(filtered_notes);
  };

  return (
    <>
      <div className="pb-3">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/clients">clients</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/clients/${client?._id}/orders`}>{client?.companyName}</Link>
            </li>
            {order && (
              <li
                className="breadcrumb-item text-sm text-dark flex p-0"
                aria-current="page"
              >
                <Dropdown className="dropdown-orders">
                  <Dropdown.Toggle id="dropdown-basic">
                    {order.product?.name}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {client?.orders?.map((cl_order) => (
                      <Dropdown.Item
                        as={Link}
                        to={`/clients/${clientId}/orders/${cl_order._id}/notes`}
                        key={cl_order._id}
                      >
                        {cl_order.product?.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            )}
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              notes
            </li>
          </ol>
        </nav>
      </div>

      <div className="order-container">
        {order && <OrderNavigation order={order} client={client} currentTab="notes" />}
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card mb-4 purple-border">
              <div className="card-body">
                <Row className="xmb-5">
                  <Col md={12}>
                    <input name="orderId" type="hidden" />
                    <div className="mb-3">
                      <label htmlFor="title" className="form-label">
                        Titre
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="text"
                        {...register("title")}
                      />
                      {errors?.text && (
                        <div className="text-danger">Champs obligatoire</div>
                      )}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="flex gap-3 bg-ligth-primary rounded-2 justify-content-around">
                      {tags.map((tag) => (
                        <div key={tag.value} className="form-check">
                          <input
                            type="checkbox"
                            id={tag.value}
                            value={tag.value}
                            onChange={handleTagChange}
                            checked={selectedTags.includes(tag.value)}
                            className="form-check-input"
                          />
                          <label htmlFor={tag.value}>{tag.label}</label>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label htmlFor="duration" className="form-label">
                        Ajouter une note *
                      </label>
                      <Controller
                        name={`text`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <CKEditor
                            editor={ClassicEditor}
                            data={field.value}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              field.onChange(data);
                            }}
                            placeholder="Ajouter une note ..."
                          />
                        )}
                      />
                      {errors?.text && (
                        <div className="text-danger">Champs obligatoire</div>
                      )}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="shared"
                        {...register("shared")}
                      />
                      <label className="form-check-label" htmlFor="shared">
                        Partager avec le client
                      </label>
                    </div>
                  </Col>
                  <div>
                    <button type="submit" className="btn bg-gradient-dark mt-1 mb-0 px-5">
                      Ajouter
                    </button>
                  </div>
                </Row>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="card mb-4 purple-border p-3">
        <Row>
          <Col md={4}>
            <select className="form-control mb-3" onChange={filterNotes} id="tagSelect">
              <option value="">Tag...</option>
              {tags.map((tag) => (
                <option key={tag.value} value={tag.value}>
                  {tag.label}
                </option>
              ))}
            </select>
          </Col>
          <Col md={12}>
            {filtredNotes.map((note) =>
              note.automatique ? (
                <AutomatiqueNote note={note} key={note._id} />
              ) : (
                <ManualNote note={note} key={note._id} />
              )
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const AutomatiqueNote = ({ note }) => {
  const TYPES = {
    NEWSURVEY: {
      url: `/clients/${note.client}/orders/${note.order._id}/surveys`,
      text: "Accéder aux enquettes",
      icon: faStar,
    },
    NEWEMAIL: {
      url: `/clients/${note.client}/orders/${note.order._id}/emails`,
      text: "Accéder aux emails",
      icon: faEnvelope,
    },
    NEWDOCUMENT: {
      url: `/clients/${note.client}/orders/${note.order._id}/documents`,
      text: "Accéder aux documents",
      icon: faFile,
    },
    NEWTICKET: {
      url: `/clients/${note.client}/orders/${note.order._id}/tickets/${note.externalId}`,
      text: "Accéder à la demande",
      icon: faCommentDots,
    },
    SURVEYRESPONDED: {
      url: `/client/${note.client}/orders/${note.order._id}/surveys`,
      text: "Comming Soon",
      icon: faStar,
    },
  };
  return (
    <div className="card px-3 py-1 mb-4 note-card shadow-none" key={note._id}>
      <div className="flex justify-content-between">
        <div>
          <h6 role="button" className="m-0">
            <Link to={TYPES[note.note_type].url} className="purple-link">
              <FontAwesomeIcon
                icon={TYPES[note.note_type].icon}
                className="pe-2 purple-color"
              />

              <span>{note.title}</span>
            </Link>
          </h6>
          <div>
            <small>{note.createdBy?.name}</small>
          </div>
        </div>
        <div className="text-end">
          <div>
            <small className="px-1">{moment(note.createdAt).format("DD-MM-YYYY")}</small>
            {note.shared && (
              <FontAwesomeIcon
                icon={faUsers}
                className="pe-2 purple-color"
                style={{ fontSize: "13px" }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const ManualNote = ({ note }) => {
  return (
    <div className="card p-3 mb-4 note-card shadow-none" key={note._id}>
      <div className="flex justify-content-between">
        <div>
          <h6>
            <span>{note.title}</span>
          </h6>
          <div>{parse(note.text)}</div>
          <div>
            {note.tags.length > 0 && (
              <div className="tags-list">
                <ul className="flex gap-2">
                  {note.tags.map((tag, index) => (
                    <li key={index}>{tag}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="text-end">
          <div>{note?.order?.product?.name}</div>
          <div>
            <small>{note.createdBy?.name}</small>
          </div>
          <div>
            <small>{moment(note.createdAt).format("DD-MM-YYYY")}</small>
          </div>
          <div>
            {note.shared && (
              <FontAwesomeIcon icon={faUsers} className="pe-2 purple-color" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderNoteForm;
