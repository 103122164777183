import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
  fetchCliClientCalendar,
  fetchCliTinyClient,
} from "../../../../redux/actions/cli/clientsActions";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useWindowDimensions } from "./useWindowDimensions";
import UnDraggedEvents from "./unDraggedEvents";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CliCalendar = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { height, width } = useWindowDimensions();
  const [showTasks, setShowTasks] = useState(true);

  const [draggedEvents, setDraggedEvents] = useState([]);
  const [undraggedEvents, setunDraggedEvents] = useState([]);
  const calendarInfo = useSelector((state) => state.cliClientsReducer.calendarInfo);
  const client = useSelector((state) => state.cliClientsReducer.tiny_client);
  const loading = useSelector((state) => state.cliClientsReducer.loading);
  const error = useSelector((state) => state.cliClientsReducer.error);

  useEffect(() => {
    document.title = "ProdOTop - Calendrier";
  }, []);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliClientCalendar(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliTinyClient(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (calendarInfo) {
      const updatedDraggedTasks = calendarInfo?.dragged_tasks?.map((event) => {
        const updatedEvent = { ...event };
        updatedEvent.start = moment(event.start)._d;
        updatedEvent.end = moment(event.end)._d;
        return updatedEvent;
      });

      setDraggedEvents(updatedDraggedTasks);
      setunDraggedEvents(calendarInfo?.undragged_tasks);
    }
  }, [calendarInfo]);

  const dayPropGetter = (date) => {
    const dayStyle = {};

    if (date.getDay() === 0 || date.getDay() === 6) {
      dayStyle.className = "disabled-day";
    }

    return dayStyle;
  };

  const eventPropGetter = (event, start, end, isSelected) => {
    const classNames = ["custom-event", event.flexibility];
    const style = {};

    if (event.done) {
      classNames.push("done-event");
    }

    // if (event.product && event.product.color) {
    //   style.borderColor = event.product.color;
    // }

    // classNames.push(can_manage_calendar ? "isDraggable" : "nonDraggable");

    return {
      className: classNames.join(" "),
      style: style,
    };
  };

  const formats = {
    timeGutterFormat: "HH:mm",
    eventTimeRangeFormat: ({ start, end }, culture, local) => {
      return (
        local.format(start, "HH:mm", culture) +
        " - " +
        local.format(end, "HH:mm", culture)
      );
    },
    agendaTimeFormat: "HH:mm",
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/client/${client?._id}/orders`}>{client?.companyName}</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Calendrier
            </li>
          </ol>
        </nav>
      </div>
      <Row className="mb-3">
        <Col>
          <Link to={`/client/${clientId}/orders`} className="prodotop-link">
            Accéder aux commandes
          </Link>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          <div className="task-calendar-container">
            <UnDraggedEvents
              setShowTasks={setShowTasks}
              showTasks={showTasks}
              undraggedEvents={undraggedEvents}
              height={height}
            />

            <div
              className="calendar-container  rounded-3 bg-white"
              style={{ height: `calc(${height}px - 150px)` }}
            >
              <div
                className={`myCustomHeight gap-3 ${loading ? "loading-effect blur" : ""}`}
              >
                <DnDCalendar
                  formats={formats}
                  selectable
                  localizer={localizer}
                  events={draggedEvents}
                  dayPropGetter={dayPropGetter}
                  views={["month"]}
                  resourceIdAccessor="_id"
                  eventPropGetter={eventPropGetter}
                  titleAccessor={(event) =>
                    `${event.minutes?.toFixed(0)} Min - ${event.product?.name} ${
                      event.shared ? event.task : ""
                    } `
                  }
                  messages={{
                    work_week: "Semaine",
                    day: "Jour",
                    month: "Mois",
                    previous: "Précédent",
                    next: "Suivant",
                    today: "Aujourd'hui",
                  }}
                  tooltipAccessor={(event) =>
                    `${event.minutes?.toFixed(0)} Min - ${event.product?.name} ${
                      event.shared ? event.task : ""
                    } `
                  }
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CliCalendar;
