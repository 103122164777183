import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { Controller } from "react-hook-form";

const Filter = ({
  showGraphs,
  setShowModal,
  control,
  clientList,
  productList,
  register,
  filtred_users,
  BOSS_MANAGER,
}) => {
  return (
    <div className="">
      {showGraphs && (
        <div className="w-20" onClick={() => setShowModal(true)}>
          <div className="chart-icon bg-primary">
            <FontAwesomeIcon icon={faChartBar} size="2x" />
          </div>
        </div>
      )}
      <Controller
        name="clientId"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Select
            {...field}
            options={clientList}
            isClearable={true}
            placeholder="Sélectionnez un client"
            className="mb-3"
          />
        )}
      />
      <Controller
        name="productId"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Select
            {...field}
            options={productList}
            isClearable={true}
            placeholder="Sélectionnez un produit"
            className="mb-3"
          />
        )}
      />

      <select id="product" className="form-control mb-3" {...register("actAs")}>
        {BOSS_MANAGER && <option value="manager">Manager</option>}
        {filtred_users.map((user) => (
          <option key={user._id} value={user._id}>
            {user.name}
          </option>
        ))}
      </select>

      <button type="submit" className="btn bg-gradient-dark  mb-0">
        Filtrer
      </button>
    </div>
  );
};

export default Filter;
