let config = {};

config = {
  base_url:
    process.env.NODE_ENV === "development"
      ? "http://localhost:5003"
      : "https://management.yellow.lu",
};

export default config;
