import React, { useState, useRef } from "react";
import { Button, Overlay, Popover, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import moment from "moment";
import {
  faScissors,
  faPlus,
  faXmark,
  faTriangleExclamation,
  faFilter,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const groupByCompanyNameAndProduct = (events) => {
  return events.reduce((groupedEvents, event) => {
    const companyName = event.client?.companyName;
    const productName = event.product?.name; // Assurez-vous que le chemin est correct
    const withIssue = event.client?.with_issue;
    const issue = event.client?.issue;

    if (!groupedEvents[companyName]) {
      groupedEvents[companyName] = { products: {}, withIssue: false, issue: null };
    }

    if (withIssue) {
      groupedEvents[companyName].withIssue = true;
      groupedEvents[companyName].issue = issue;
    }

    if (!groupedEvents[companyName].products[productName]) {
      groupedEvents[companyName].products[productName] = [];
    }

    groupedEvents[companyName].products[productName].push(event);

    return groupedEvents;
  }, {});
};

const ExternalItem = ({
  minutes,
  task,
  _id,
  team1Responsible,
  client,
  order,
  flexibility,
  devideTask,
  can_manage_calendar,
  team4Responsible,
  startEvent,
  setSelectedTodos,
  selectedTodos,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const handleDragStart = (event) => {
    event.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        minutes,
        task,
        _id,
        team1Responsible,
        client,
        flexibility,
        team4Responsible,
        order,
      })
    );
  };

  const handleStartEvent = () => {
    const start = moment()._d;
    const event = {
      _id: _id,
      task: task,
      allDay: false,
      start: start,
      end: moment(start).add(minutes, "minutes"),
      minutes: minutes,
      order: order,
      client: client,
    };
    startEvent(event);
  };

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleSelectEvent = () => {
    if (selectedTodos.includes(_id)) {
      setSelectedTodos(selectedTodos.filter((id) => id !== _id));
    } else {
      setSelectedTodos([...selectedTodos, _id]);
    }
  };

  const onSubmit = (data) => {
    dispatch(devideTask(_id, data));
    setShow(false);
  };

  return (
    <div
      key={_id}
      className={`card card-body px-2 py-1 bg-gray-300 mb-3 ${
        selectedTodos.includes(_id) ? "border-2 border-primary" : ""
      }`}
      draggable={can_manage_calendar}
      onDragStart={handleDragStart}
      role="button"
      onDoubleClick={handleStartEvent}
      onClick={handleSelectEvent}
    >
      <div className="flex gap-1 justify-content-between">
        <div className="task-name">
          <div>{task}</div>

          <div>
            <small>
              <b>{Math.round(minutes)} minutes</b>
            </small>
          </div>
        </div>

        <div className="" ref={ref}>
          <Button onClick={handleClick} className={`mb-0 px-3 ${flexibility}`}>
            <FontAwesomeIcon icon={faScissors} />
          </Button>

          <Overlay
            show={show}
            target={target}
            placement="left"
            container={ref}
            containerPadding={20}
          >
            <Popover id={_id}>
              <Popover.Header as="h3">Diviser la tâche</Popover.Header>
              <Popover.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-1">
                    <label htmlFor="question" className="form-label">
                      Minutes :
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="question"
                      min={1}
                      max={minutes - 1}
                      {...register("minutes", { required: true })}
                    />
                    {errors?.minutes && <div className="text-danger">temps requis !</div>}
                  </div>
                  <div className="">
                    <button type="submit" className="btn bg-gradient-dark mb-0">
                      Diviser
                    </button>
                  </div>
                </form>
              </Popover.Body>
            </Popover>
          </Overlay>
        </div>
      </div>
    </div>
  );
};

const UndraggedEvents = ({
  setShowTasks,
  showTasks,
  undraggedEvents,
  can_manage_calendar,
  devideTask,
  onCreateTodo,
  authorized,
  height,
  startEvent,
  calendarStep,
  setCalendarStep,
  setShowFilter,
  showFilter,
  schedule_tasks,
  selectedTodos,
  setSelectedTodos,
  selectedDate,
  setSelectedDate,
}) => {
  const [openAccordions, setOpenAccordions] = useState({});

  const groupedEvents = groupByCompanyNameAndProduct(undraggedEvents);

  const toggleAccordion = (companyName) => {
    setOpenAccordions((prevOpenAccordions) => ({
      ...prevOpenAccordions,
      [companyName]: !prevOpenAccordions[companyName],
    }));
  };
  return (
    <div
      className={`task-container d-grid bg-white p-3 rounded-3 ${
        showTasks ? "show" : ""
      } `}
      style={{ height: `calc(${height}px - 23px)` }}
    >
      {!showTasks && (
        <>
          <div className="d-flex flex-column gap-1 calendar-steps">
            <span
              onClick={() => setShowTasks(!showTasks)}
              className="mb-5 p-2 text-end"
              role="button"
            >
              {<FontAwesomeIcon icon={faListUl} color="#121f3a" />}
            </span>

            <div
              className="calendar-step mb-2"
              onClick={() => setShowFilter(!showFilter)}
              role="button"
            >
              <FontAwesomeIcon icon={faFilter} className="mt-2" color="#121f3a" />
            </div>
            <hr />
            <div
              className={`calendar-step mb-2 ${calendarStep === 5 ? "active" : ""}`}
              onClick={() => setCalendarStep(5)}
              role="button"
            >
              5
            </div>

            <div
              className={`calendar-step mb-2 ${calendarStep === 15 ? "active" : ""}`}
              onClick={() => setCalendarStep(15)}
              role="button"
            >
              15
            </div>
            <div
              className={`calendar-step mb-2 ${calendarStep === 30 ? "active" : ""}`}
              onClick={() => setCalendarStep(30)}
              role="button"
            >
              30
            </div>
          </div>
        </>
      )}
      {showTasks && (
        <>
          <div className="tasks">
            <div className="mb-4">
              {showTasks && (
                <div
                  onClick={() => setShowTasks(!showTasks)}
                  className="px-2 text-end"
                  role="button"
                >
                  {<FontAwesomeIcon icon={faXmark} />}
                </div>
              )}
              <h6 className="flex gap-1">
                <span
                  className="product-title bg-primary px-2 flex align-item-center text-white rounded-1"
                  role="button"
                >
                  Tâches
                </span>
                {authorized && (
                  <span
                    className={`calendar-add-todo rounded-1`}
                    onClick={onCreateTodo}
                    role="button"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                )}
              </h6>
            </div>
            <div className="mb-5">
              <div className="mb-5">
                {Object.keys(groupedEvents).map((companyName) => {
                  const totalEvents = Object.values(
                    groupedEvents[companyName].products
                  ).reduce((sum, productEvents) => sum + productEvents.length, 0);
                  return (
                    <div key={companyName}>
                      <div
                        onClick={() => toggleAccordion(companyName)}
                        className="accordion-toggle flex justify-content-between"
                        role="button"
                      >
                        <span>
                          {groupedEvents[companyName].withIssue && (
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  {groupedEvents[companyName].issue}
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                className="me-1"
                                color="#be3c4e"
                              />
                            </OverlayTrigger>
                          )}
                          {companyName}
                        </span>

                        <span className="badge-accordion">{totalEvents}</span>
                      </div>
                      <div
                        className={`py-2 accordion-content ${
                          openAccordions[companyName]?.events ? "open" : ""
                        }`}
                      >
                        {openAccordions[companyName] &&
                          Object.keys(groupedEvents[companyName].products).map(
                            (productName) => (
                              <div key={productName}>
                                <div className="product-name  m-2">{productName}</div>
                                <div className="product-events">
                                  {groupedEvents[companyName].products[productName].map(
                                    (event) => (
                                      <ExternalItem
                                        key={event._id}
                                        {...event}
                                        can_manage_calendar={can_manage_calendar}
                                        devideTask={devideTask}
                                        startEvent={startEvent}
                                        setSelectedTodos={setSelectedTodos}
                                        selectedTodos={selectedTodos}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="task-explanation py-2">
            <div>
              <span className="box-task done-task"></span>
              <span>Double cliques pour commencer une tâche</span>
            </div>
          </div>
          {selectedTodos.length > 0 && (
            <div className="fixed-bottom  w-full bg-white py-3 px-5 border-top">
              <div className="flex align-items-center justify-content-center gap-5">
                <div>
                  <label className="">Tâches sélectionnées</label>
                  <div className="bg-primary p-2 text-center text-white rounded-1 fw-bold">
                    {selectedTodos.length}
                  </div>
                </div>

                <div className="">
                  <label className="flex-1">Placer chaque mois à partir de</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                </div>
                <div className="">
                  <label className="flex-1">Programmer</label>
                  <button
                    className="btn btn-primary mb-0"
                    onClick={schedule_tasks}
                    disabled={selectedTodos.length === 0 || !selectedDate}
                  >
                    Valider
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UndraggedEvents;
