import { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderById,
  fetchOrderFbInsight,
  fetchOrderGAInsight,
  fetchOrderGAdsInsight,
  fetchClientById,
  addOrderNote,
  fetchIaIterpretationFads,
  fetchIaIterpretationGads,
  fetchIaIterpretationGa,
  fetchLastNote,
} from "../../../../../redux/actions/clientActions";

import DatePicker from "react-datepicker";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../../../NavContext";
import Fads from "./fads";
import Ga from "./ga";
import Gads from "./gads";
import OrderNavigation from "../order/orderNavigation";
import { Triangle, Bars } from "react-loader-spinner";
import moment from "moment";

const InsightPage = () => {
  const dispatch = useDispatch();
  const initDates = {
    start: moment().subtract(3, "months").toDate(),
    end: moment().toDate(),
  };
  const { clientId, orderId } = useParams();
  const [dates, setDates] = useState(initDates);
  const [periodes, setPeriodes] = useState("monthly");
  const [activeTab, setActiveTab] = useState();
  const { activeNav, setActiveNav } = useContext(NavContext);

  const order = useSelector((state) => state.clientReducer.order);
  const client = useSelector((state) => state.clientReducer.tiny_client);
  const fb_insight = useSelector((state) => state.clientReducer.fb_insight);
  const loadingFbInsight = useSelector((state) => state.clientReducer.loadingFbInsight);
  const ga_insight = useSelector((state) => state.clientReducer.ga_insight);
  const loadingGAInsight = useSelector((state) => state.clientReducer.loadingGAInsight);
  const gads_insight = useSelector((state) => state.clientReducer.gads_insight);
  const lastNote = useSelector((state) => state.clientReducer.lastNote);
  const loadingGAdsInsight = useSelector(
    (state) => state.clientReducer.loadingGAdsInsight
  );
  const loadingInterpretation = useSelector(
    (state) => state.clientReducer.loadingInterpretation
  );

  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientById(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(clientId, orderId));
    }
  }, [dispatch, orderId, clientId]);

  useEffect(() => {
    if (order && client) {
      dispatch(
        fetchOrderFbInsight(clientId, orderId, {
          ...dates,
          periode: periodes,
        })
      );
      dispatch(
        fetchOrderGAInsight(clientId, orderId, {
          ...dates,
          periode: periodes,
        })
      );
      dispatch(
        fetchOrderGAdsInsight(clientId, orderId, {
          ...dates,
          periode: periodes,
        })
      );
    }
  }, [dispatch, order, client, clientId, orderId]);

  useEffect(() => {
    if (!loadingGAInsight && !activeTab) {
      setActiveTab(gads_insight ? "gads" : "fb");
    }
  }, [dispatch, gads_insight, loadingGAInsight]);

  useEffect(() => {
    if (gads_insight) {
      dispatch(fetchLastNote(clientId, orderId, "Rapport - Google ADS", "gads"));
    }
  }, [dispatch, gads_insight, clientId, orderId]);

  useEffect(() => {
    if (fb_insight) {
      dispatch(fetchLastNote(clientId, orderId, "Rapport - Facebook ADS", "fads"));
    }
  }, [dispatch, fb_insight, clientId, orderId]);

  useEffect(() => {
    if (ga_insight) {
      dispatch(fetchLastNote(clientId, orderId, "Rapport - Google Analytics", "ga"));
    }
  }, [dispatch, ga_insight, clientId, orderId]);

  const onChangeDates = (dates) => {
    const [start, end] = dates;

    setDates({ start, end });
  };

  const onValidateAds = () => {
    if (gads_insight) {
      dispatch(
        fetchOrderGAdsInsight(clientId, orderId, {
          end: moment(dates.end).toDate(),
          start: moment(dates.start).toDate(),
          periode: periodes,
        })
      );
    }

    dispatch(
      fetchOrderFbInsight(clientId, orderId, {
        end: moment(dates.end).toDate(),
        start: moment(dates.start).toDate(),
        periode: periodes,
      })
    );

    dispatch(
      fetchOrderGAInsight(clientId, orderId, {
        end: moment(dates.end).toDate(),
        start: moment(dates.start).toDate(),
        periode: periodes,
      })
    );
  };

  const formated_dates = (theDates) => {
    return [
      moment(theDates.start).format("DD/MM/YYYY"),
      moment(theDates.end).format("DD/MM/YYYY"),
    ].join(" - ");
  };

  const onSubmitNote = (data) => {
    const formData = {
      ...data,
      tags: ["rapport"],
    };
    dispatch(addOrderNote(clientId, orderId, formData));
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/clients">clients</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/clients/${client?._id}/orders`}>{client?.companyName}</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {order?.product?.name}
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Rapport
            </li>
          </ol>
        </nav>
      </div>
      <div className="order-container">
        {order && <OrderNavigation order={order} client={client} currentTab="insight" />}

        {loadingInterpretation && (
          <div className="ia-loader">
            <Bars
              height={100}
              width={100}
              radius={5}
              color="#cb0c9f"
              ariaLabel="bars-loading"
              wrapperClass={"triangle-loader"}
              wrapperStyle=""
              visible={true}
            />
          </div>
        )}

        {loadingFbInsight || loadingGAInsight || loadingGAdsInsight ? (
          <div className="loading-reports flex">
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#333"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass="primary-color"
            />
            <span className="primary-color">Loading Reports</span>
          </div>
        ) : (
          <>
            <Row className="my-2 bg-white py-3 rounded-2 card flex-row">
              <Col md={4}>
                <DatePicker
                  startDate={dates.start}
                  endDate={dates.end}
                  selected={dates.start}
                  monthsShown={2}
                  onChange={onChangeDates}
                  selectsRange
                  className="form-control block"
                  dateFormat="dd/MM/yyyy"
                />
              </Col>
              <Col md={4}>
                <div className="flex gap-3">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="daily"
                      value="daily"
                      onChange={() => setPeriodes("daily")}
                      checked={periodes === "daily"}
                      className="form-check-input"
                    />
                    <label htmlFor="daily">Par jour</label>
                  </div>

                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="monthly"
                      value="monthly"
                      onChange={() => setPeriodes("monthly")}
                      checked={periodes === "monthly"}
                      className="form-check-input"
                    />
                    <label htmlFor="monthly">Par mois</label>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <button onClick={onValidateAds} className="btn btn-primary">
                  {" "}
                  Valider{" "}
                </button>
              </Col>
            </Row>
            <Tabs
              // defaultActiveKey={gads_insight ? "gads" : "fb"}
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              {fb_insight && fb_insight.lastMounthData?.length > 0 && (
                <Tab eventKey="fb" title="Facebook">
                  <Fads
                    fb_insight={fb_insight}
                    title={client.companyName}
                    dates={formated_dates(dates)}
                    subTitle="Facebook ADS"
                    onSubmitNote={onSubmitNote}
                    fetchIaIterpretationFads={(iaPrompt) =>
                      dispatch(
                        fetchIaIterpretationFads(clientId, orderId, {
                          insight: fb_insight,
                          iaPrompt,
                        })
                      )
                    }
                    lastNote={lastNote.fads}
                  />
                </Tab>
              )}
              {ga_insight &&
                ga_insight.processedData &&
                ga_insight.processedData.length > 0 && (
                  <Tab eventKey="ga" title="Google Analytics">
                    <Ga
                      ga_insight={ga_insight}
                      title={client.companyName}
                      dates={formated_dates(dates)}
                      subTitle="Google Analytics"
                      onSubmitNote={onSubmitNote}
                      fetchIaIterpretationGa={(iaPrompt) =>
                        dispatch(
                          fetchIaIterpretationGa(clientId, orderId, {
                            insight: ga_insight,
                            iaPrompt,
                          })
                        )
                      }
                      lastNote={lastNote.ga}
                    />
                  </Tab>
                )}

              {gads_insight && (
                <Tab eventKey="gads" title="Google Ads">
                  <Gads
                    gads_insight={gads_insight}
                    title={client.companyName}
                    dates={formated_dates(dates)}
                    subTitle="Google ADS"
                    onSubmitNote={onSubmitNote}
                    fetchIaIterpretationGads={(iaPrompt) =>
                      dispatch(
                        fetchIaIterpretationGads(clientId, orderId, {
                          insight: gads_insight,
                          iaPrompt,
                        })
                      )
                    }
                    lastNote={lastNote.gads}
                  />
                </Tab>
              )}
            </Tabs>
          </>
        )}

        <Row className="mb-3">
          <Col>
            <Link to={`/clients/${clientId}/orders`} className="link-action back">
              Retour
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InsightPage;
