import { useEffect, useContext } from "react";
import { NavContext } from "../../NavContext";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBriefQuestion,
  fetchBriefQuestions,
} from "../../../redux/actions/briefQuestionActions";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPen } from "@fortawesome/free-solid-svg-icons";

const BriefQuestionPage = () => {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.briefQuestionReducer.briefQuestions);
  const isFetching = useSelector((state) => state.briefQuestionReducer.isFetching);

  const currentUser = useSelector((state) => state.authReducer.user.user);
  const BOSS = currentUser.role === "boss";
  const MANAGER = currentUser.role === "manager";
  const { activeNav, setActiveNav } = useContext(NavContext);
  useEffect(() => {
    setActiveNav("briefQuestions");
  }, [setActiveNav]);

  useEffect(() => {
    if (!isFetching) {
      dispatch(fetchBriefQuestions());
    }
  }, [dispatch, questions, isFetching]);

  const handleDelete = (questionId) => {
    dispatch(deleteBriefQuestion(questionId));
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              cahier des charges
            </li>
          </ol>
        </nav>
      </div>
      <Row className="row">
        <div className="col-12">
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header">
              <Row className="xmb-5">
                <Col md={6}>
                  <h6>
                    <span className="product-title px-3 text-white">
                      Gestion des questions du cahier des charges
                    </span>
                  </h6>
                </Col>
                <Col className="text-end" md={6}>
                  <Link
                    to="/brief-questions/new"
                    className="btn bg-gradient-dark-inverse mb-0"
                  >
                    Ajouter une question
                  </Link>
                </Col>
              </Row>
            </div>
            <div className="card-body px-0 pt-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        style={{ width: "70%" }}
                      >
                        Question
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {questions.map((question) => (
                      <tr key={question._id}>
                        <td className="align-middle text-sm px-4">
                          <div>{question.question}</div>
                        </td>
                        <td className="align-middle px-2 text-sm text-end">
                          {(BOSS || MANAGER) && (
                            <>
                              <Link
                                to={`/brief-questions/${question._id}`}
                                className="btn btn-link text-warning text-gradient px-3 mb-0"
                              >
                                <FontAwesomeIcon icon={faPen} className="px-2" />
                                Modifier
                              </Link>
                              <button
                                className="btn btn-link text-danger text-gradient px-3 mb-0"
                                type="button"
                                onClick={() => handleDelete(question._id)}
                              >
                                <FontAwesomeIcon icon={faTrashCan} className="px-2" />
                                Supprimer
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Row className="mb-3">
          <Col>
            <Link className="link-action back" to="/">
              Retour
            </Link>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default BriefQuestionPage;
