import {
  CLI_FETCH_ORDER_REQUEST,
  CLI_FETCH_ORDER_SUCCESS,
  CLI_FETCH_ORDER_FAILURE,
  CLI_FETCH_SURVEY_REQUEST,
  CLI_FETCH_SURVEY_SUCCESS,
  CLI_FETCH_SURVEY_FAILURE,
  CLI_FETCH_ORDER_WITH_DOCUMENTS_REQUEST,
  CLI_FETCH_ORDER_WITH_DOCUMENTS_SUCCESS,
  CLI_FETCH_ORDER_WITH_DOCUMENTS_FAILURE,
  CLI_FETCH_ORDER_WITH_SURVEYS_REQUEST,
  CLI_FETCH_ORDER_WITH_SURVEYS_SUCCESS,
  CLI_FETCH_ORDER_WITH_SURVEYS_FAILURE,
  CLI_FETCH_ORDER_WITH_TICKETS_REQUEST,
  CLI_FETCH_ORDER_WITH_TICKETS_SUCCESS,
  CLI_FETCH_ORDER_WITH_TICKETS_FAILURE,
  CLI_FETCH_ORDER_WITH_NOTES_REQUEST,
  CLI_FETCH_ORDER_WITH_NOTES_SUCCESS,
  CLI_FETCH_ORDER_WITH_NOTES_FAILURE,
} from "../../clientActionTypes";

import { orderService } from "../../services/cli/orderService";
import { toast } from "react-toastify";

export const fetchOrderFbInsight = (orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: "CLI_FETCH_FB_INSIGHT_REQUEST" });
    try {
      const insight = await orderService.getOrderFbInsight(orderId, data);
      dispatch({ type: "CLI_FETCH_FB_INSIGHT_SUCCESS", payload: insight });
    } catch (err) {
      console.log("ixi", err.message);
      dispatch({ type: "CLI_FETCH_FB_INSIGHT_FAILURE", payload: err.message });
    }
  };
};

export const fetchOrderGAInsight = (orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: "CLI_FETCH_GA_INSIGHT_REQUEST" });
    try {
      const insight = await orderService.getOrderGAInsight(orderId, data);
      dispatch({ type: "CLI_FETCH_GA_INSIGHT_SUCCESS", payload: insight });
    } catch (err) {
      dispatch({ type: "CLI_FETCH_GA_INSIGHT_FAILURE", payload: err.message });
    }
  };
};

export const fetchOrderGAdsInsight = (orderId, data) => {
  return async (dispatch) => {
    dispatch({ type: "CLI_FETCH_GADS_INSIGHT_REQUEST" });
    try {
      const insight = await orderService.getOrderGAdsInsight(orderId, data);
      dispatch({ type: "CLI_FETCH_GADS_INSIGHT_SUCCESS", payload: insight });
    } catch (err) {
      dispatch({ type: "CLI_FETCH_GADS_INSIGHT_FAILURE", payload: err.message });
    }
  };
};

export const fetchOrderById = (orderId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_ORDER_REQUEST });
    try {
      orderService.getOrderById(orderId).then((data) => {
        dispatch({ type: CLI_FETCH_ORDER_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_ORDER_FAILURE, payload: err.message });
    }
  };
};
export const fetchOrderWithDocumentsById = (orderId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_ORDER_WITH_DOCUMENTS_REQUEST });
    try {
      orderService.fetchOrderWithDocumentsById(orderId).then((data) => {
        dispatch({ type: CLI_FETCH_ORDER_WITH_DOCUMENTS_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_ORDER_WITH_DOCUMENTS_FAILURE, payload: err.message });
    }
  };
};

export const fetchOrderWithSurveysById = (orderId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_ORDER_WITH_SURVEYS_REQUEST });
    try {
      orderService.fetchOrderWithSurveysById(orderId).then((data) => {
        dispatch({ type: CLI_FETCH_ORDER_WITH_SURVEYS_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_ORDER_WITH_SURVEYS_FAILURE, payload: err.message });
    }
  };
};

export const fetchOrderWithTicketsById = (orderId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_ORDER_WITH_TICKETS_REQUEST });
    try {
      orderService.fetchOrderWithTicketsById(orderId).then((data) => {
        dispatch({ type: CLI_FETCH_ORDER_WITH_TICKETS_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_ORDER_WITH_TICKETS_FAILURE, payload: err.message });
    }
  };
};
export const fetchOrderWithNotesById = (orderId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_ORDER_WITH_NOTES_REQUEST });
    try {
      orderService.fetchOrderWithNotesById(orderId).then((data) => {
        dispatch({ type: CLI_FETCH_ORDER_WITH_NOTES_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_ORDER_WITH_NOTES_FAILURE, payload: err.message });
    }
  };
};

export const fetchSurveyById = (orderId, surveyId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_SURVEY_REQUEST });
    try {
      orderService.fetchSurveyById(orderId, surveyId).then((data) => {
        dispatch({ type: CLI_FETCH_SURVEY_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_SURVEY_FAILURE, payload: err.message });
    }
  };
};

export const updateSurvey = (orderId, surveyId, data) => {
  return async (dispatch) => {
    try {
      orderService.updateSurvey(orderId, surveyId, data).then((data) => {
        toast.success(
          "Vos réponses ont été transmises à notre équipe. Nous vous remercions."
        );
        dispatch({
          type: CLI_FETCH_SURVEY_SUCCESS,
          payload: { survey: data, questions: [] },
        });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_SURVEY_FAILURE, payload: err.message });
    }
  };
};
