import { useEffect, useContext, useState } from "react";
import { NavContext } from "../../NavContext";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  fetchProducts,
  updatePositions,
  fetchProductsSuccess,
} from "../../../redux/actions/productActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPen } from "@fortawesome/free-solid-svg-icons";

const ProductPage = () => {
  const dispatch = useDispatch();
  const [dragStart, setDragStart] = useState(null);
  const products = useSelector((state) => state.productReducer.products);
  const isFetching = useSelector((state) => state.productReducer.isFetching);

  const currentUser = useSelector((state) => state.authReducer.user.user);
  const BOSS = currentUser.role === "boss";
  const MANAGER = currentUser.role === "manager";
  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    document.title = "ProdOTop - Produits";
  }, []);

  useEffect(() => {
    setActiveNav("products");
  }, [setActiveNav]);

  useEffect(() => {
    if (!isFetching) {
      dispatch(fetchProducts());
    }
  }, [dispatch, products, isFetching]);

  const handleDelete = (productId) => {
    dispatch(deleteProduct(productId));
  };

  const handleOnDragEnd = async (result) => {
    setDragStart(false);
    if (!result.destination) return;

    const items = Array.from(products);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dispatch(fetchProductsSuccess(items));
    const positions = items.map((product, index) => ({
      _id: product._id,
      newPosition: index + 1,
    }));

    dispatch(updatePositions(positions));
  };

  const handleDoubleClick = (index) => {
    const items = Array.from(products);
    const selectedItem = items[index];

    items.splice(index, 1);
    items.unshift(selectedItem);

    dispatch(fetchProductsSuccess(items));

    const positions = items.map((product, index) => ({
      _id: product._id,
      newPosition: index + 1,
    }));
    dispatch(updatePositions(positions));
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Produits
            </li>
          </ol>
        </nav>
      </div>
      <Row className="row">
        <div className="col-12">
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header">
              <Row className="">
                <Col md={6}>
                  <h6>
                    <span className="product-title px-3 text-white">
                      Liste des produits
                    </span>
                  </h6>
                </Col>
                <Col className="text-end" md={6}>
                  <Link to="/products/new" className="btn bg-gradient-dark mb-0">
                    Ajouter un produit
                  </Link>
                </Col>
              </Row>
            </div>
            <div className="card-body px-0 pt-0 pb-2">
              <div className="table-responsive p-0">
                <DragDropContext
                  onDragEnd={handleOnDragEnd}
                  onDragStart={() => setDragStart(true)}
                >
                  <Droppable droppableId="posts">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {products.map((product, index) => (
                          <Draggable
                            key={product._id}
                            draggableId={product._id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onDoubleClick={() => handleDoubleClick(index)}
                              >
                                <div
                                  key={product._id}
                                  style={{ borderLeft: `3px solid ${product.color}` }}
                                  className="flex product-card justify-content-between bg-gray-100 m-3 rounded-2"
                                >
                                  <div className="align-middle text-sm px-4 py-3">
                                    <div>{product.name}</div>
                                  </div>
                                  <div className="align-middle px-2 text-sm text-end flex">
                                    <Link
                                      to={`/products/${product._id}`}
                                      className="btn btn-link text-warning text-gradient px-3 mb-0"
                                    >
                                      <FontAwesomeIcon icon={faPen} className="px-2" />
                                      Modifier
                                    </Link>
                                    {(BOSS || MANAGER) && (
                                      <>
                                        <button
                                          className="btn btn-link text-danger text-gradient flex px-3 mb-0"
                                          type="button"
                                          onClick={() => handleDelete(product._id)}
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrashCan}
                                            className="px-2"
                                          />
                                          Supprimer
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>

        <Row className="mb-3">
          <Col>
            <Link className="link-action back" to="/">
              Retour
            </Link>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ProductPage;
