import {
  FETCH_QUALITY_CHECKS_REQUEST,
  FETCH_QUALITY_CHECKS_SUCCESS,
  FETCH_QUALITY_CHECKS_FAILURE,
  FETCH_QUALITY_CHECK_REQUEST,
  FETCH_QUALITY_CHECK_SUCCESS,
  FETCH_QUALITY_CHECK_FAILURE,
  ADD_QUALITY_CHECK_REQUEST,
  ADD_QUALITY_CHECK_SUCCESS,
  ADD_QUALITY_CHECK_FAILURE,
  UPDATE_QUALITY_CHECK_REQUEST,
  UPDATE_QUALITY_CHECK_SUCCESS,
  UPDATE_QUALITY_CHECK_FAILURE,
  DELETE_QUALITY_CHECK_REQUEST,
  DELETE_QUALITY_CHECK_SUCCESS,
  DELETE_QUALITY_CHECK_FAILURE,
} from "../actionTypes";
import { toast } from "react-toastify";
import { qualityCheckService } from "../services/qualityCheckService";

// Action creator to fetch all brief questions
export const fetchQualityChecks = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_QUALITY_CHECKS_REQUEST });
    try {
      const qualityChecks = await qualityCheckService.getAllQualityChecks();
      dispatch({
        type: FETCH_QUALITY_CHECKS_SUCCESS,
        payload: qualityChecks,
      });
    } catch (error) {
      dispatch({
        type: FETCH_QUALITY_CHECKS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchQualityCheck = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_QUALITY_CHECK_REQUEST });
    try {
      const qualityCheck = await qualityCheckService.getQualityCheckById(id);
      dispatch({
        type: FETCH_QUALITY_CHECK_SUCCESS,
        payload: qualityCheck,
      });
    } catch (error) {
      dispatch({
        type: FETCH_QUALITY_CHECK_FAILURE,
        payload: error.message,
      });
    }
  };
};

// Action creator to add a new brief question
export const addQualityCheck = (questionData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_QUALITY_CHECK_REQUEST });
    try {
      const newQuestion = await qualityCheckService.createQualityCheck(questionData);
      dispatch({
        type: ADD_QUALITY_CHECK_SUCCESS,
        payload: newQuestion,
      });
      toast.success("question ajouté avec succès");

      return Promise.resolve(newQuestion);
    } catch (error) {
      dispatch({
        type: ADD_QUALITY_CHECK_FAILURE,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};

// Action creator to update an existing brief question
export const updateQualityCheck = (questionId, questionData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_QUALITY_CHECK_REQUEST });
    try {
      const updatedQuestion = await qualityCheckService.updateQualityCheck(
        questionId,
        questionData
      );
      dispatch({
        type: UPDATE_QUALITY_CHECK_SUCCESS,
        payload: updatedQuestion,
      });
      toast.success("Question mise à jours avec succès");

      return Promise.resolve(updatedQuestion);
    } catch (error) {
      dispatch({
        type: UPDATE_QUALITY_CHECK_FAILURE,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};

// Action creator to delete an existing brief question
export const deleteQualityCheck = (questionId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_QUALITY_CHECK_REQUEST });
    try {
      const deletedQuestion = await qualityCheckService.deleteQualityCheck(questionId);
      dispatch({
        type: DELETE_QUALITY_CHECK_SUCCESS,
        payload: deletedQuestion,
      });
      return Promise.resolve(deletedQuestion);
    } catch (error) {
      dispatch({
        type: DELETE_QUALITY_CHECK_FAILURE,
        payload: error.message,
      });
      return Promise.reject(error);
    }
  };
};
