import { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { fetchOrderWithTicketsById } from "../../../../redux/actions/cli/orderActions";
import { fetchCliTinyClient } from "../../../../redux/actions/cli/clientsActions";

import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { PanelContext } from "../../../panelContext";
import OrderNavigation from "../orderNavigation";

const TicketsPage = () => {
  const dispatch = useDispatch();
  const { clientId, orderId } = useParams();
  const order = useSelector((state) => state.cliClientsReducer.order_tickets);
  const client = useSelector((state) => state.cliClientsReducer.tiny_client);
  const { panelMessage, setPanelMessage } = useContext(PanelContext);

  useEffect(() => {
    if (panelMessage) {
      setPanelMessage(
        "<b>Vous avez une question ?</b> <div>Envoyez-nous votre demande. Nos responsables vous <b>répondront dans les plus brefs délais</b>.</div> "
      );
    }
  }, [setPanelMessage, panelMessage]);

  useEffect(() => {
    document.title =
      "ProdOTop -  demandes de " +
      order?.product?.name +
      " du client " +
      client?.companyName;
  }, [client, order]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliTinyClient(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderWithTicketsById(orderId));
    }
  }, [dispatch, orderId]);

  return (
    <>
      <>
        <div className="pb-4">
          <nav aria-label="breadcrumb" className="breadcrumb">
            <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
              <li className="breadcrumb-item text-sm"></li>

              <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                <Link to={`/client/${client?._id}/orders`}>{client?.companyName}</Link>
              </li>
              <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                {order?.product?.name}
              </li>
              <li
                className="breadcrumb-item text-sm text-dark active"
                aria-current="page"
              >
                Demandes
              </li>
            </ol>
          </nav>
        </div>
        <div className="order-container">
          {order && (
            <OrderNavigation order={order} client={client} currentTab="tickets" />
          )}
          <Row className="row">
            <div className="col-12">
              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-header">
                  <Row className="xmb-5">
                    <Col md={8}>
                      <h6>
                        <span className="product-title px-3 text-white">
                          Liste des demandes
                        </span>
                      </h6>
                    </Col>
                    <Col className="text-end" md={4}>
                      <Link
                        to={`/client/${clientId}/orders/${orderId}/tickets/new`}
                        className="btn bg-gradient-dark mb-0"
                      >
                        Ajouter une demande
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className="card-body px-0 pt-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Sujet
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Statut
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Date de création
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                            action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {order &&
                          order.tickets.map((ticket) => (
                            <tr key={ticket._id}>
                              <td className="align-middle text-sm px-4">
                                <div>{ticket.subject}</div>
                              </td>
                              <td className="align-middle text-sm px-4">
                                <div>
                                  {ticket.closed ? (
                                    <span className="badge badge-sm bg-gradient-danger">
                                      Clôturé
                                    </span>
                                  ) : (
                                    <span className="badge badge-sm bg-gradient-success">
                                      Ouvert
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td className="align-middle text-sm px-4">
                                <div>{moment(ticket.createdAt).format("YYYY-MM-DD")}</div>
                              </td>
                              <td className="align-middle px-2 text-sm text-end">
                                <Link
                                  to={`/client/${clientId}/orders/${order._id}/tickets/${ticket._id}`}
                                  className="btn btn-link text-warning text-gradient px-3 mb-0"
                                >
                                  <FontAwesomeIcon icon={faReply} className="px-2" />
                                  Répondre
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <Row className="mb-3">
              <Col>
                <Link to={`/client/${clientId}/orders`} className="link-action back">
                  Retour
                </Link>
              </Col>
            </Row>
          </Row>
        </div>
      </>
    </>
  );
};

export default TicketsPage;
