import { useEffect, useContext, useState, useCallback } from "react";
import { NavContext } from "../../NavContext";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchClients, fetchTinyClients } from "../../../redux/actions/clientActions";
import { Link, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

const ClientsPage = () => {
  const dispatch = useDispatch();

  const [querySearch, setQuerySearch] = useSearchParams();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const q = querySearch.get("q");
  const page = querySearch.get("page");
  const per_page = querySearch.get("per_page");

  const clients = useSelector((state) => state.clientReducer.clients);
  const tiny_clients = useSelector((state) => state.clientReducer.tiny_clients);
  const totalPages = useSelector((state) => state.clientReducer.totalPages);

  const { control } = useForm();

  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    document.title = "ProdOTop - Clients";
  }, []);

  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);

  useEffect(() => {
    if ((!page && !per_page && !q) || isFirstLoad) {
      dispatch(fetchClients(page, per_page, { clientId: q }));
      dispatch(fetchTinyClients());
      if (!page && !per_page && !q) {
        setQuerySearch({
          q: "",
          page: 1,
          per_page: 40,
        });
      }
      setIsFirstLoad(false);
    } else if (q !== "" && page === 1 && per_page === 40) {
      dispatch(fetchClients(page, per_page, { clientId: q }));
    }
  }, [q, page, per_page, dispatch, setQuerySearch]);

  const handlePageChange = (newPage) => {
    setQuerySearch({ page: newPage.selected + 1, per_page }, { replace: true });
    dispatch(fetchClients(newPage.selected + 1, per_page));
    window.scrollTo(0, 170);
  };

  const handlePerPageChange = (newPerPage) => {
    setQuerySearch({ page: 1, per_page: newPerPage }, { replace: true });
    dispatch(fetchClients(1, newPerPage));
    window.scrollTo(0, 170);
  };

  const handleSearch = (clientId) => {
    dispatch(fetchClients(page, per_page, { clientId: clientId }));
  };

  const RatingStars = ({ rating }) => {
    const totalStars = 5;
    let stars = [];

    for (let i = 0; i < totalStars; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<span className="star full" key={`star_${i}`}></span>);
      } else if (i < rating) {
        stars.push(<span className="star half" key={`star_${i}`}></span>);
      } else {
        stars.push(<span className="star empty" key={`star_${i}`}></span>);
      }
    }

    return <div className="rating-stars">{stars}</div>;
  };

  const clientList = tiny_clients
    ? tiny_clients.map((option) => ({
        value: option._id,
        label: option?.companyName,
      }))
    : [];
  return (
    <>
      <div className="pb-3">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              <Link to="/clients">clients</Link>
            </li>
          </ol>
        </nav>
      </div>
      <Row>
        <div className="col-12">
          <div className="card mb-4 purple-border">
            <div className="card-body  p-0">
              <Row className="m-3">
                <Col md={4}>
                  <Controller
                    name="clientId"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={clientList}
                        isClearable={true}
                        placeholder="Sélectionnez un client"
                        className="mb-3"
                        value={clientList.find((option) => option.value === field.value)}
                        onChange={(e) => {
                          if (e === null) {
                            field.onChange("");
                            setQuerySearch(
                              { page: 1, q: "", per_page: per_page },
                              { replace: true }
                            );
                            handleSearch("");
                          } else {
                            const searchTerm = e.value;
                            field.onChange(searchTerm);
                            setQuerySearch(
                              { page: page, q: searchTerm, per_page: per_page },
                              { replace: true }
                            );
                            handleSearch(searchTerm);
                          }
                        }}
                      />
                    )}
                  />
                </Col>
                <Col md={3}></Col>
                <Col md={2}>
                  {per_page && (
                    <select
                      value={per_page}
                      onChange={(e) => handlePerPageChange(e.target.value)}
                      className="form-select"
                    >
                      <option value="40">40 par page</option>
                      <option value="100">100 par page</option>
                      <option value="200">200 par page</option>
                      <option value="400">400 par page</option>
                    </select>
                  )}
                </Col>
                <Col md={3} className="text-end">
                  <Link to="/clients/new" className="btn bg-gradient-dark-inverse mb-0 ">
                    Ajouter un client
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col md={12} className={`flex flex-wrap gap-4 justify-content-center`}>
          {clients.map((client) => (
            <div key={client._id}>
              <div className="card purple-border card-order client justify-content-center">
                <div className="card-body p-3">
                  <div className="text-center">
                    <div className="pt-4">
                      <Link to={`/clients/${client._id}/orders`} className="">
                        <h6 className="companyName-title">
                          <span className="product-title text-dark ">
                            <span>
                              {client.with_issue && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="button-tooltip-2">
                                      {client.issue}
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faTriangleExclamation}
                                    className="me-1"
                                    color="#be3c4e"
                                  />
                                </OverlayTrigger>
                              )}
                            </span>

                            {client.companyName}
                            {client.client_with_open_tickets && (
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="px-2 pt-1"
                                color="#FF0000"
                              />
                            )}
                          </span>
                        </h6>
                      </Link>
                    </div>
                    <div>
                      {client.globalRating ? (
                        <RatingStars rating={client.globalRating} />
                      ) : (
                        <RatingStars rating={0} />
                      )}
                    </div>
                  </div>
                </div>
                {/* {client.efficiencyPercentage > 0 && (
                  <>
                    <div className="pb-3 px-3 profitability">
                      <ProgressBar
                        now={Math.round(client.efficiencyPercentage)}
                        label={`${Math.round(client.efficiencyPercentage)}%`}
                        min-width="300"
                        className={`status-progress ${
                          client.efficiencyPercentage > 100 ? "danger" : ""
                        }`}
                      />
                    </div>
                  </>
                )} */}
              </div>
            </div>
          ))}
        </Col>

        <Col md={12} className="text-center mt-5">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            previousLabel="< previous"
            onPageChange={handlePageChange}
            pageRangeDisplayed={3}
            pageCount={totalPages}
            renderOnZeroPageCount={page}
            className="pagination justify-content-center"
            pageLinkClassName="page-link"
            activeClassName="active"
            previousClassName="page-link"
            nextClassName="page-link"
            forcePage={parseInt(page) - 1}
          />
        </Col>

        <Row className="mb-3">
          <Col>
            <Link className="link-action back" to="/">
              Retour
            </Link>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ClientsPage;
