import axios from "axios";

const BASE_URL = "/api/products";

// Service pour gérer les critères de qualité
export const todoService = {
  getProductTodos: async (productId) => {
    const response = await axios.get(`${BASE_URL}/${productId}/todos`);
    return response.data;
  },
  // Créer un nouveau critère de qualité pour un produit donné
  createTodo: async (productId, todoData) => {
    const response = await axios.post(`${BASE_URL}/${productId}/todos`, todoData);
    return response.data;
  },

  // Modifier un critère de qualité pour un produit donné
  updateTodo: async (productId, todoId, todoData) => {
    const response = await axios.put(
      `${BASE_URL}/${productId}/todos/${todoId}`,
      todoData
    );
    return response.data;
  },

  // Supprimer un critère de qualité pour un produit donné
  deleteTodo: async (productId, todoId) => {
    await axios.delete(`${BASE_URL}/${productId}/todos/${todoId}`);
  },
};
