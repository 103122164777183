import React, { useEffect, useState, useContext, useCallback } from "react";
import { NavContext } from "../../NavContext";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardInfo } from "../../../redux/actions/dashboardActions";
import { downloadPdf } from "../../../redux/actions/clientActions";
import { fetchUsers } from "../../../redux/actions/userActions";
import { Row, Col, Offcanvas } from "react-bootstrap";
import ProductSmallCard from "./productSmallCard";
import ProductLargeCard from "./productLargeCard";
import ExpandedContent from "./ExpandedCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Bars } from "react-loader-spinner";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [layoutId, setLayoutId] = useState(null);
  const [dashboardSearch, setDashboardSearch] = useState([]);
  const [issuesFilter, setIssuesFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState([]);
  const [retard, setRetard] = useState(0);
  const [productCardView, setProductCardView] = useState("small");

  const [querySearch, setQuerySearch] = useSearchParams({
    q: "",
    status: "",
    actAs: "",
  });
  const q = querySearch.get("q");
  const status = querySearch.get("status");
  const actAs = querySearch.get("actAs");

  const dashboardInfo = useSelector((state) => state.dashboardReducer.dashboardInfo);
  const isFetching = useSelector((state) => state.dashboardReducer.isFetching);
  const error = useSelector((state) => state.dashboardReducer.error);
  const users = useSelector((state) => state.userReducer.users);
  const currentUser = useSelector((state) => state.authReducer.user.user);
  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    document.title = "ProdOTop - Dashboard";
  }, []);

  useEffect(() => {
    if (issuesFilter.length > 0) {
      const filteredProducts = dashboardInfo.map((db) => {
        let dbCopy = { ...db };

        dbCopy.newTeam1Orders = db.newTeam1Orders.filter((order) =>
          order.tickets.some((tk) => issuesFilter.includes(tk.issue))
        );
        dbCopy.newTeam2Orders = db.newTeam2Orders.filter((order) =>
          order.tickets.some((tk) => issuesFilter.includes(tk.issue))
        );
        dbCopy.newTeam3Orders = db.newTeam3Orders.filter((order) =>
          order.tickets.some((tk) => issuesFilter.includes(tk.issue))
        );
        dbCopy.newTeam4Orders = db.newTeam4Orders.filter((order) =>
          order.tickets.some((tk) => issuesFilter.includes(tk.issue))
        );
        dbCopy.newTeam5Orders = db.newTeam5Orders.filter((order) =>
          order.tickets.some((tk) => issuesFilter.includes(tk.issue))
        );

        dbCopy.producedOrders = db.producedOrders.filter((order) =>
          order.tickets.some((tk) => issuesFilter.includes(tk.issue))
        );

        dbCopy.inProgressOrders = db.inProgressOrders.filter((order) =>
          order.tickets.some((tk) => issuesFilter.includes(tk.issue))
        );

        dbCopy.pendingOrders = db.pendingOrders.filter((order) =>
          order.tickets.some((tk) => issuesFilter.includes(tk.issue))
        );

        dbCopy.completedOrders = db.completedOrders.filter((order) =>
          order.tickets.some((tk) => issuesFilter.includes(tk.issue))
        );

        return dbCopy;
      });

      setDashboardSearch(filteredProducts);
    } else {
      setDashboardSearch(dashboardInfo);
    }
  }, [issuesFilter]);

  useEffect(() => {
    setActiveNav("dashboard");
  }, [setActiveNav]);

  useEffect(() => {
    if (currentUser) {
      if (actAs && currentUser.role === "manager") {
        dispatch(
          fetchDashboardInfo({
            actAs: actAs,
          })
        );
      } else {
        dispatch(
          fetchDashboardInfo({
            actAs: currentUser.role === "manager" ? "manager" : currentUser._id,
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleSearch = useCallback(
    (searchTerm) => {
      const filteredProducts = dashboardInfo.map((db) => {
        let dbCopy = { ...db };

        dbCopy.newTeam1Orders = db.newTeam1Orders.filter((order) =>
          order.client.companyName.toLowerCase().includes(searchTerm)
        );
        dbCopy.newTeam2Orders = db.newTeam2Orders.filter((order) =>
          order.client.companyName.toLowerCase().includes(searchTerm)
        );
        dbCopy.newTeam3Orders = db.newTeam3Orders.filter((order) =>
          order.client.companyName.toLowerCase().includes(searchTerm)
        );
        dbCopy.newTeam4Orders = db.newTeam4Orders.filter((order) =>
          order.client.companyName.toLowerCase().includes(searchTerm)
        );
        dbCopy.newTeam5Orders = db.newTeam5Orders.filter((order) =>
          order.client.companyName.toLowerCase().includes(searchTerm)
        );

        dbCopy.producedOrders = db.producedOrders.filter((order) =>
          order.client.companyName.toLowerCase().includes(searchTerm)
        );

        dbCopy.inProgressOrders = db.inProgressOrders.filter((order) =>
          order.client.companyName.toLowerCase().includes(searchTerm)
        );

        dbCopy.pendingOrders = db.pendingOrders.filter((order) =>
          order.client.companyName.toLowerCase().includes(searchTerm)
        );

        dbCopy.completedOrders = db.completedOrders.filter((order) =>
          order.client.companyName.toLowerCase().includes(searchTerm)
        );

        return dbCopy;
      });

      setDashboardSearch(filteredProducts);
    },
    [dashboardInfo]
  );

  const handleRetard = useCallback(
    (retard) => {
      const filteredProducts = dashboardInfo.map((db) => {
        let dbCopy = { ...db };
        const dateLimite = new Date(Date.now() + new Date().getTimezoneOffset() * 60000);
        dateLimite.setUTCMonth(dateLimite.getUTCMonth() - retard);

        dbCopy.newTeam1Orders = db.newTeam1Orders.filter((order) => {
          const createdAtDate = new Date(order.createdAt);
          return createdAtDate < dateLimite;
        });

        dbCopy.newTeam2Orders = db.newTeam2Orders.filter((order) => {
          const createdAtDate = new Date(order.createdAt);
          return createdAtDate < dateLimite;
        });

        dbCopy.newTeam3Orders = db.newTeam3Orders.filter((order) => {
          const createdAtDate = new Date(order.createdAt);
          return createdAtDate < dateLimite;
        });

        dbCopy.newTeam4Orders = db.newTeam4Orders.filter((order) => {
          const createdAtDate = new Date(order.createdAt);
          return createdAtDate < dateLimite;
        });

        dbCopy.newTeam5Orders = db.newTeam5Orders.filter((order) => {
          const createdAtDate = new Date(order.createdAt);
          return createdAtDate < dateLimite;
        });

        dbCopy.inProgressOrders = db.inProgressOrders.filter((order) => {
          const createdAtDate = new Date(order.createdAt);
          return createdAtDate < dateLimite;
        });
        dbCopy.pendingOrders = db.pendingOrders.filter((order) => {
          const createdAtDate = new Date(order.createdAt);
          return createdAtDate < dateLimite && !order.produced;
        });

        dbCopy.producedOrders = [];
        dbCopy.completedOrders = [];

        return dbCopy;
      });
      console.log(filteredProducts);
      setDashboardSearch(filteredProducts);
    },
    [dashboardInfo]
  );

  useEffect(() => {
    if (q) {
      const searchTerm = q.toLowerCase();
      handleSearch(searchTerm);
    } else {
      setDashboardSearch(dashboardInfo);
    }
  }, [q, dashboardInfo, handleSearch]);

  useEffect(() => {
    if (dashboardInfo.length > 0) {
      if (retard !== 0) {
        handleRetard(retard);
      } else {
        setDashboardSearch(dashboardInfo);
      }
    }
  }, [retard, setDashboardSearch]);

  if (error) {
    return <div>Error: {error}</div>;
  }
  const handleActAs = (data) => {
    dispatch(fetchDashboardInfo({ actAs: data }));
  };

  const BOSS = currentUser.role === "boss";
  const MANAGER = currentUser.role === "manager";

  const filtred_users = users.filter(
    (user) =>
      user.role !== "boss" &&
      (user.departement === currentUser.departement || user.departement === "Team4")
  );

  const handleDownload = async (clientId, orderId) => {
    dispatch(downloadPdf(clientId, orderId));
  };

  const filterByOrderEndDate = (idFilter, date) => {
    setDateFilter(idFilter);

    const filteredProducts = dashboardInfo.map((db) => {
      let dbCopy = { ...db };

      // Filtrer les commandes pour chaque type d'ordre
      dbCopy.newTeam1Orders = db.newTeam1Orders.filter((order) =>
        moment(order.endDate).isBefore(date)
      );

      dbCopy.newTeam2Orders = db.newTeam2Orders.filter((order) =>
        moment(order.endDate).isBefore(date)
      );
      dbCopy.newTeam3Orders = db.newTeam3Orders.filter((order) =>
        moment(order.endDate).isBefore(date)
      );

      dbCopy.newTeam4Orders = db.newTeam4Orders.filter((order) =>
        moment(order.endDate).isBefore(date)
      );
      dbCopy.newTeam5Orders = db.newTeam5Orders.filter((order) =>
        moment(order.endDate).isBefore(date)
      );

      dbCopy.producedOrders = db.producedOrders.filter((order) =>
        moment(order.endDate).isBefore(date)
      );

      dbCopy.inProgressOrders = db.inProgressOrders.filter((order) =>
        moment(order.endDate).isBefore(date)
      );

      dbCopy.pendingOrders = db.pendingOrders.filter((order) =>
        moment(order.endDate).isBefore(date)
      );

      dbCopy.completedOrders = db.completedOrders.filter((order) =>
        moment(order.endDate).isBefore(date)
      );

      return dbCopy;
    });

    setDashboardSearch(filteredProducts);
  };

  const endOfDay = moment().endOf("day");
  const endOfNextWeek = moment().add(1, "weeks").endOf("day");
  const endOfNextMonth = moment().add(1, "months").endOf("day");
  const endOfNextTwoMonth = moment().add(2, "months").endOf("day");

  const ISSUES = [
    { REQAM: "Demande d’amélioration" },
    { INFINC: "Information incompléte" },
    { PAYPRO: "Probléme de paiement" },
    { CONMAN: "Contenu manquant" },
    { PROEXT: "Problème externe" },
    { RDV: "Demande de rendez-vous" },
    { DVR: "Divers" },
  ];

  return (
    <>
      {isFetching && (
        <div className="ia-loader">
          <Bars
            height={100}
            width={100}
            radius={5}
            color="#cb0c9f"
            ariaLabel="bars-loading"
            wrapperClass={"triangle-loader"}
            wrapperStyle=""
            visible={true}
          />
        </div>
      )}
      <div className="pb-4 flex justify-content-between">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Dashboard
            </li>
          </ol>
        </nav>

        {/* <div className="mb-0 pb-0 pt-1 px-0 flex">
          <div
            className={`chart-icon productCardView ${
              productCardView === "small" ? "active" : ""
            } `}
            onClick={() => setProductCardView("small")}
          >
            <FontAwesomeIcon icon={faGripLinesVertical} className="mt-2" />
          </div>
          <div
            className={`chart-icon productCardView  ${
              productCardView === "large" ? "active" : ""
            } `}
            onClick={() => setProductCardView("large")}
          >
            <FontAwesomeIcon icon={faGripLines} className="mt-2" />
          </div>
        </div> */}

        <div
          className="mb-0 pb-0 pt-1 px-0 justify-content-lg-end"
          onClick={() => setShowFilter(!showFilter)}
        >
          <div className="chart-icon bg-gradient-dark">
            <FontAwesomeIcon icon={faFilter} className="mt-2" />
          </div>
        </div>
      </div>

      <Offcanvas show={showFilter} onHide={() => setShowFilter(false)} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="flex gap-3 align-items-center">
              <span>Filtrer : </span>
              <span
                className="reset"
                role="button"
                onClick={() => {
                  setDashboardSearch(dashboardInfo);
                  setQuerySearch({ q: "", actAs: "", retard: null }, { replace: true });
                  setDateFilter(false);
                }}
              >
                Reset{" "}
              </span>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AnimatePresence initial={false} mode="wait">
            <Row className="pb-1 justify-content-lg-end">
              {/* <form onSubmit={handleSubmit(onSubmit)}> */}
              {(BOSS || MANAGER) && (
                <>
                  <div className="mb-4">
                    <Col md={12}>
                      <h6 className="text-uppercase  text-xs font-weight-bolder my-3  text-dark">
                        Par employé
                      </h6>
                    </Col>
                    <Col md={12} className="filter-dashboard-manager d-grid gap-3">
                      <select
                        id="actAs"
                        className="form-control mb-0"
                        defaultValue={actAs}
                        onChange={(e) => {
                          const searchTerm = e.target.value;
                          setQuerySearch({ actAs: searchTerm }, { replace: true });
                          handleActAs(searchTerm);
                        }}
                      >
                        <option value="manager">Manager</option>
                        {filtred_users.map((user) => (
                          <option key={user._id} value={user._id}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </div>
                  <hr className="separator" />
                </>
              )}
              {/* </form> */}

              <Col md={12}>
                <div className="mb-4">
                  <h6 className="text-uppercase  text-xs font-weight-bolder my-3  text-dark">
                    Par Client
                  </h6>
                  <input
                    type="text"
                    className="form-control filter-client"
                    name="companyName"
                    placeholder="Filtrer par client ..."
                    value={q}
                    onChange={(e) => {
                      const searchTerm = e.target.value.toLowerCase();
                      setQuerySearch({ q: searchTerm }, { replace: true });
                      handleSearch(searchTerm);
                    }}
                  />
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-4">
                  <h6 className="text-uppercase  text-xs font-weight-bolder my-3  text-dark">
                    Par status dossier
                  </h6>
                  <div className="flex flex-wrap gap-3">
                    <span
                      role="button"
                      className={`order-date-end-filter mb-0 ${
                        status === "new" ? "active" : ""
                      }`}
                      onClick={() =>
                        setQuerySearch(
                          (prev) => {
                            prev.set("status", "new");
                            return prev;
                          },
                          { replace: true }
                        )
                      }
                    >
                      Nouveau
                    </span>
                    <span
                      role="button"
                      className={`order-date-end-filter mb-0 ${
                        status === "inProgress" ? "active" : ""
                      }`}
                      onClick={() =>
                        setQuerySearch(
                          (prev) => {
                            prev.set("status", "inProgress");
                            return prev;
                          },
                          { replace: true }
                        )
                      }
                    >
                      En production
                    </span>
                    <span
                      role="button"
                      className={`order-date-end-filter mb-0 ${
                        status === "pending" ? "active" : ""
                      }`}
                      onClick={() =>
                        setQuerySearch(
                          (prev) => {
                            prev.set("status", "pending");
                            return prev;
                          },
                          { replace: true }
                        )
                      }
                    >
                      Avec demande
                    </span>
                    <span
                      role="button"
                      className={`order-date-end-filter mb-0`}
                      onClick={() =>
                        setQuerySearch(
                          (prev) => {
                            prev.set("status", null);
                            return prev;
                          },
                          { replace: true }
                        )
                      }
                    >
                      Reset
                    </span>
                  </div>
                </div>
              </Col>
              <hr className="separator" />
              <Col md={12}>
                <div className="mb-4">
                  <h6 className="text-uppercase  text-xs font-weight-bolder my-3  text-dark">
                    Retard
                  </h6>
                  <div className="flex flex-wrap gap-3">
                    <span
                      role="button"
                      className={`order-date-end-filter mb-0 ${
                        retard === 1 ? "active" : ""
                      }`}
                      onClick={() => setRetard(1)}
                    >
                      1 mois
                    </span>
                    <span
                      role="button"
                      className={`order-date-end-filter mb-0 ${
                        retard === 2 ? "active" : ""
                      }`}
                      onClick={() => setRetard(2)}
                    >
                      2 mois
                    </span>
                    <span
                      role="button"
                      className={`order-date-end-filter mb-0 ${
                        retard === 3 ? "active" : ""
                      }`}
                      onClick={() => setRetard(3)}
                    >
                      3 mois
                    </span>
                    <span
                      role="button"
                      className={`order-date-end-filter mb-0`}
                      onClick={() => setRetard(0)}
                    >
                      Reset
                    </span>
                  </div>
                </div>
              </Col>
              <hr className="separator" />
              <Col md={12}>
                <div className="mb-4">
                  <h6 className="text-uppercase  text-xs font-weight-bolder my-3  text-dark">
                    Contrat se termine avant
                  </h6>
                  <div className="flex flex-wrap gap-3">
                    <div
                      className={`order-date-end-filter ${
                        dateFilter === 1 ? "active" : ""
                      }`}
                      role="button"
                      onClick={() => filterByOrderEndDate(1, endOfDay._d)}
                    >
                      <div className="big">Aujourd'hui</div>
                      <div className="small">{endOfDay.format("DD-MM-YYYY")}</div>
                    </div>
                    <div
                      className={`order-date-end-filter ${
                        dateFilter === 2 ? "active" : ""
                      }`}
                      role="button"
                      onClick={() => filterByOrderEndDate(2, endOfNextWeek._d)}
                    >
                      <div className="big">une semaine</div>
                      <div className="small">{endOfNextWeek.format("DD-MM-YYYY")}</div>
                    </div>
                    <div
                      className={`order-date-end-filter ${
                        dateFilter === 3 ? "active" : ""
                      }`}
                      role="button"
                      onClick={() => filterByOrderEndDate(3, endOfNextMonth._d)}
                    >
                      <div className="big">un mois</div>
                      <div className="small">{endOfNextMonth.format("DD-MM-YYYY")}</div>
                    </div>
                    <div
                      className={`order-date-end-filter ${
                        dateFilter === 4 ? "active" : ""
                      }`}
                      role="button"
                      onClick={() => filterByOrderEndDate(4, endOfNextTwoMonth._d)}
                    >
                      <div className="big">deux mois</div>
                      <div className="small">
                        {endOfNextTwoMonth.format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <hr className="separator" />

              <Col md={12}>
                <div className="mb-4">
                  <h6 className="text-uppercase  text-xs font-weight-bolder my-3  text-dark">
                    Par status de demande
                  </h6>
                  {ISSUES.map((issue, index) => {
                    const key = Object.keys(issue)[0];
                    const value = Object.values(issue)[0];
                    return (
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={key}
                          id={`checkbox-${key}`}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setIssuesFilter((oldIssues) => oldIssues.concat(key));
                            } else {
                              setIssuesFilter((oldIssues) =>
                                oldIssues.filter((issueKey) => issueKey !== key)
                              );
                            }
                          }}
                          checked={issuesFilter.includes(key)}
                        />
                        <label className="form-check-label" htmlFor={`checkbox-${key}`}>
                          {value}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </AnimatePresence>
        </Offcanvas.Body>
      </Offcanvas>

      <Row>
        {dashboardSearch &&
          dashboardSearch.length > 0 &&
          dashboardSearch
            .filter((db) => {
              if (status === null) {
                return true;
              } else if (status === "new") {
                return (
                  db.newTeam1Orders.length > 0 ||
                  db.newTeam2Orders.length > 0 ||
                  db.newTeam3Orders.length > 0 ||
                  db.newTeam4Orders.length > 0 ||
                  db.newTeam5Orders.length > 0
                );
              } else if (status === "inProgress") {
                return db.inProgressOrders.length > 0;
              } else if (status === "pending") {
                return db.pendingOrders.length > 0;
              }
              return true;
            })
            .map((db, index) =>
              productCardView === "small" ? (
                <ProductSmallCard
                  index={index}
                  db={db}
                  setData={setData}
                  setLayoutId={setLayoutId}
                />
              ) : (
                <ProductLargeCard
                  index={index}
                  db={db}
                  setData={setData}
                  setLayoutId={setLayoutId}
                />
              )
            )}
      </Row>
      {layoutId && (
        <ExpandedContent
          key={layoutId}
          layoutId={layoutId}
          data={data}
          setData={setData}
          handleDownload={handleDownload}
        />
      )}
    </>
  );
};

export default Dashboard;
