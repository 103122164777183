import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderWithNotesById,
  fetchOrderFbInsight,
  fetchOrderGAInsight,
  fetchOrderGAdsInsight,
} from "../../../../redux/actions/cli/orderActions";
import DatePicker from "react-datepicker";
import { fetchCliTinyClient } from "../../../../redux/actions/cli/clientsActions";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Fads from "./insights/fads";
import Ga from "./insights/ga";
import Gads from "./insights/gads";

import OrderNavigation from "../orderNavigation";
import moment from "moment";
import { Triangle } from "react-loader-spinner";

const InsightPage = () => {
  const dispatch = useDispatch();
  const { clientId, orderId } = useParams();

  const initDates = {
    start: moment().subtract(3, "months").toDate(),
    end: moment().toDate(),
  };
  const [dates, setDates] = useState({
    gads: initDates,
    fads: initDates,
    ga: initDates,
  });
  const [periodes, setPeriodes] = useState({
    gads: "monthly",
    fads: "monthly",
    ga: "monthly",
  });

  const order = useSelector((state) => state.cliClientsReducer.order_notes);
  const fb_insight = useSelector((state) => state.cliClientsReducer.fb_insight);
  const loadingFbInsight = useSelector(
    (state) => state.cliClientsReducer.loadingFbInsight
  );
  const client = useSelector((state) => state.cliClientsReducer.tiny_client);

  const ga_insight = useSelector((state) => state.cliClientsReducer.ga_insight);
  const loadingGAInsight = useSelector(
    (state) => state.cliClientsReducer.loadingGAInsight
  );
  const gads_insight = useSelector((state) => state.cliClientsReducer.gads_insight);
  const loadingGAdsInsight = useSelector(
    (state) => state.cliClientsReducer.loadingGAdsInsight
  );

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderWithNotesById(orderId));
    }
  }, [dispatch, orderId, clientId]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliTinyClient(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (order && client) {
      dispatch(
        fetchOrderFbInsight(orderId, {
          ...dates.fads,
          periode: periodes.fads,
        })
      );
      dispatch(
        fetchOrderGAInsight(orderId, {
          ...dates.ga,
          periode: periodes.ga,
        })
      );
      dispatch(
        fetchOrderGAdsInsight(orderId, {
          ...dates.gads,
          periode: periodes.gads,
        })
      );
    }
  }, [dispatch, order, client, orderId]);

  const onChangeGads = (dates) => {
    const [start, end] = dates;
    setDates((currentDates) => ({ ...currentDates, gads: { start, end } }));
  };

  const onValidateGads = () => {
    dispatch(
      fetchOrderGAdsInsight(orderId, {
        end: moment(dates.gads.end).toDate(),
        start: moment(dates.gads.start).toDate(),
        periode: periodes.gads,
      })
    );
  };

  const onChangeFads = (dates) => {
    const [start, end] = dates;

    setDates((currentDates) => ({ ...currentDates, fads: { start, end } }));
  };

  const onValidateFads = () => {
    dispatch(
      fetchOrderFbInsight(orderId, {
        end: moment(dates.fads.end).toDate(),
        start: moment(dates.fads.start).toDate(),
        periode: periodes.fads,
      })
    );
  };

  const onChangeGa = (dates) => {
    const [start, end] = dates;
    setDates((currentDates) => ({ ...currentDates, ga: { start, end } }));
  };

  const onValidateGa = () => {
    dispatch(
      fetchOrderGAInsight(orderId, {
        end: moment(dates.ga.end).toDate(),
        start: moment(dates.ga.start).toDate(),
        periode: periodes.ga,
      })
    );
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>

            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/client/${client?._id}/orders`}>{client?.companyName}</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {order?.product?.name}
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Rapport
            </li>
          </ol>
        </nav>
      </div>
      <div className="order-container">
        {order && <OrderNavigation order={order} client={client} currentTab="insight" />}
        {loadingFbInsight || loadingGAInsight || loadingGAdsInsight ? (
          <div className="loading-reports flex">
            <Triangle
              visible={true}
              height="80"
              width="80"
              color="#333"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClass="primary-color"
            />
            <span className="primary-color">Loading Reports</span>
          </div>
        ) : (
          <Tabs
            defaultActiveKey={gads_insight ? "gads" : "fb"}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            {fb_insight && fb_insight.lastMounthData?.length > 0 && (
              <Tab eventKey="fb" title="Facebook">
                <Row className="mb-2">
                  <Col md={4}>
                    <DatePicker
                      startDate={dates.fads.start}
                      endDate={dates.fads.end}
                      selected={dates.fads.start}
                      monthsShown={2}
                      onChange={onChangeFads}
                      selectsRange
                      className="form-control block"
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                  <Col md={4}>
                    <div className="flex gap-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="fads-daily"
                          value="daily"
                          onChange={() => setPeriodes({ ...periodes, fads: "daily" })}
                          checked={periodes.fads === "daily"}
                          className="form-check-input"
                        />
                        <label htmlFor="fads-daily">Par jour</label>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="fads-monthly"
                          value="monthly"
                          onChange={() => setPeriodes({ ...periodes, fads: "monthly" })}
                          checked={periodes.fads === "monthly"}
                          className="form-check-input"
                        />
                        <label htmlFor="fads-monthly">Par mois</label>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <button onClick={onValidateFads} className="btn btn-primary">
                      {" "}
                      Valider{" "}
                    </button>
                  </Col>
                </Row>
                <Fads fb_insight={fb_insight} />
              </Tab>
            )}
            {ga_insight &&
              ga_insight.processedData &&
              ga_insight.processedData.length > 0 && (
                <Tab eventKey="ga" title="Google Analytics">
                  <Row className="mb-2">
                    <Col md={4}>
                      <DatePicker
                        startDate={dates.ga.start}
                        endDate={dates.ga.end}
                        selected={dates.ga.start}
                        monthsShown={2}
                        onChange={onChangeGa}
                        selectsRange
                        className="form-control block"
                        dateFormat="dd/MM/yyyy"
                      />
                    </Col>
                    <Col md={4}>
                      <div className="flex gap-3">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            id="ga-daily"
                            value="daily"
                            onChange={() => setPeriodes({ ...periodes, ga: "daily" })}
                            checked={periodes.ga === "daily"}
                            className="form-check-input"
                          />
                          <label htmlFor="ga-daily">Par jour</label>
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            id="ga-monthly"
                            value="monthly"
                            onChange={() => setPeriodes({ ...periodes, ga: "monthly" })}
                            checked={periodes.ga === "monthly"}
                            className="form-check-input"
                          />
                          <label htmlFor="ga-monthly">Par mois</label>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <button onClick={onValidateGa} className="btn btn-primary">
                        {" "}
                        Valider{" "}
                      </button>
                    </Col>
                  </Row>
                  <Ga ga_insight={ga_insight} />
                </Tab>
              )}

            {gads_insight && (
              <Tab eventKey="gads" title="Google Ads">
                <Row className="mb-2">
                  <Col md={4}>
                    <DatePicker
                      startDate={dates.gads.start}
                      endDate={dates.gads.end}
                      selected={dates.gads.start}
                      monthsShown={2}
                      onChange={onChangeGads}
                      selectsRange
                      className="form-control block"
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                  <Col md={4}>
                    <div className="flex gap-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="daily"
                          value="daily"
                          onChange={() => setPeriodes({ ...periodes, gads: "daily" })}
                          checked={periodes.gads === "daily"}
                          className="form-check-input"
                        />
                        <label htmlFor="daily">Par jour</label>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="monthly"
                          value="monthly"
                          onChange={() => setPeriodes({ ...periodes, gads: "monthly" })}
                          checked={periodes.gads === "monthly"}
                          className="form-check-input"
                        />
                        <label htmlFor="monthly">Par mois</label>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <button onClick={onValidateGads} className="btn btn-primary">
                      {" "}
                      Valider{" "}
                    </button>
                  </Col>
                </Row>
                <Gads gads_insight={gads_insight} />
              </Tab>
            )}
          </Tabs>
        )}

        {order.notes && order.notes.length > 0 && (
          <div className="card mb-4 purple-border p-3">
            <Row>
              <Col md={12}>
                <h6 className="my-3">
                  <span>Comentaires équipe marketing</span>
                </h6>
              </Col>

              <Col md={12}>
                {order.notes?.map((note) => (
                  <div className="card p-3 mb-4 note-card shadow-none" key={note._id}>
                    <div className="flex justify-content-between">
                      <div>
                        <h6>
                          <span>{note.title}</span>
                        </h6>
                        <div>{note.text}</div>
                        <div>
                          <small>{note.createdBy?.name}</small>
                        </div>
                      </div>
                      <div className="text-end">
                        <div>{note?.order?.product?.name}</div>
                        <div>
                          <small>{moment(note.createdAt).format("DD-MM-YYYY")}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </div>
        )}
        <Row className="mb-3">
          <Col>
            <Link to={`/clients/${clientId}/orders`} className="link-action back">
              Retour
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InsightPage;
