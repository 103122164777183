import axios from "axios";

const API_URL = "/api/departments";

const fetchDepartmentLabels = () => {
  return axios.get(`${API_URL}`).then((response) => {
    return response.data;
  });
};
const fetchDepartmentLabel = (data) => {
  return axios.post(`${API_URL}`, data).then((response) => {
    return response.data;
  });
};

const updateDepartmentsLabels = (data) => {
  return axios.put(`${API_URL}`, data).then((response) => {
    return response.data;
  });
};

export const departmentService = {
  fetchDepartmentLabels,
  updateDepartmentsLabels,
  fetchDepartmentLabel,
};
