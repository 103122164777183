import { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCliTinyClient,
  addClientNote,
  fetchCliClientNotes,
} from "../../../../redux/actions/cli/clientsActions";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { PanelContext } from "../../../panelContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faCommentDots,
  faFile,
  faEnvelope,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";

const NoteForm = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { clientId } = useParams();
  const [filtredNotes, setFiltredNotes] = useState([]);
  const { panelMessage, setPanelMessage } = useContext(PanelContext);

  const notes = useSelector((state) => state.cliClientsReducer.notes);
  const client = useSelector((state) => state.cliClientsReducer.tiny_client);

  useEffect(() => {
    if (panelMessage) {
      setPanelMessage(
        "Visualisez les <b>notes</b> de notre équipe et laissez les vôtres, favorisant une <b>communication efficace</b> et un échange d'informations pour un <b>meilleur suivi</b>."
      );
    }
  }, [setPanelMessage, panelMessage]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliTinyClient(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliClientNotes(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (notes) {
      setFiltredNotes(notes);
    }
  }, [setFiltredNotes, notes]);

  const onSubmit = (data) => {
    dispatch(addClientNote(clientId, data["orderId"], data));
    reset();
  };

  const filterByOrder = (e) => {
    const filtred_notes = notes.filter((note) => note?.order?._id === e.target.value);
    e.target.value ? setFiltredNotes(filtred_notes) : setFiltredNotes(notes);
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>

            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              <Link to={`/client/${client?._id}/orders`}>{client?.companyName}</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              notes
            </li>
          </ol>
        </nav>
      </div>
      <Row className="mb-3">
        <Col>
          <Link to={`/client/${clientId}/orders`} className="prodotop-link">
            Accéder aux commandes
          </Link>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card mb-4 purple-border">
          <div className="card-body">
            <Row className="xmb-5">
              <Col md={9}>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Titre
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="text"
                    {...register("title")}
                  />
                  {errors?.text && <div className="text-danger">Champs obligatoire</div>}
                </div>
              </Col>
              <Col md={3}>
                <div>
                  <label htmlFor="minutes" className="form-label">
                    <span>Commande *</span>
                  </label>
                  <select
                    className="form-control"
                    id="role"
                    {...register("orderId", { required: true })}
                  >
                    <option>Commande ...</option>
                    {client?.orders?.map((order) => (
                      <option key={order._id} value={order._id}>
                        {order?.product?.name}
                        {" ► "}
                        {order.startDate &&
                          moment(order.startDate).format("DD/MM/YY")} -{" "}
                        {order.endDate && moment(order.endDate).format("DD/MM/YY")}
                      </option>
                    ))}
                  </select>

                  {errors.orderId && (
                    <div className="text-danger">Le champ est requis</div>
                  )}
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <label htmlFor="duration" className="form-label">
                    Ajouter une note *
                  </label>
                  <textarea
                    type="text"
                    className="form-control note-textarea"
                    id="text"
                    {...register("text", { required: true })}
                  />
                  {errors?.text && <div className="text-danger">Champs obligatoire</div>}
                </div>
              </Col>
              <Col md={12}>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="hidden"
                    id="shared"
                    {...register("shared")}
                    defaultValue={true}
                  />
                </div>
              </Col>
              <div>
                <button type="submit" className="btn bg-gradient-dark mt-1 mb-0 px-5">
                  Ajouter
                </button>
              </div>
            </Row>
          </div>
        </div>
      </form>

      <div className="card mb-4 purple-border p-3">
        <Row>
          <Col md={4}>
            <div className="mb-3">
              <label htmlFor="minutes" className="form-label">
                Filtrer :
              </label>
              <select className="form-control" id="role" onChange={filterByOrder}>
                <option value="">Commande ...</option>
                {client?.orders?.map((order) => (
                  <option key={order._id} value={order._id}>
                    {order.product.name}
                    {" ► "}
                    {order.startDate && moment(order.startDate).format("DD/MM/YY")} -{" "}
                    {order.endDate && moment(order.endDate).format("DD/MM/YY")}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col md={12}>
            {filtredNotes?.map((note) =>
              note.automatique ? (
                <AutomatiqueNote note={note} key={note._id} />
              ) : (
                <ManualNote note={note} key={note._id} />
              )
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const AutomatiqueNote = ({ note }) => {
  const TYPES = {
    NEWSURVEY: {
      url: `/client/${note.client}/orders/${note.order._id}/surveys`,
      text: "Accéder aux enquettes",
      icon: faStar,
    },
    NEWEMAIL: {
      url: `/client/${note.client}/orders/${note.order._id}/emails`,
      text: "Accéder aux emails",
      icon: faEnvelope,
    },
    NEWDOCUMENT: {
      url: `/client/${note.client}/orders/${note.order._id}/documents`,
      text: "Accéder aux documents",
      icon: faFile,
    },
    NEWTICKET: {
      url: `/client/${note.client}/orders/${note.order._id}/tickets/${note.externalId}`,
      text: "Accéder à la demande",
      icon: faCommentDots,
    },
    SURVEYRESPONDED: {
      url: `/client/${note.client}/orders/${note.order._id}/surveys/${note.externalId}`,
      text: "Accéder à l'enquêtte",
      icon: faStar,
    },
  };
  return (
    <div className="card p-3 mb-4 note-card shadow-none" key={note._id}>
      <div className="flex justify-content-between">
        <div>
          <h6 role="button">
            <FontAwesomeIcon
              icon={TYPES[note.note_type].icon}
              className="pe-2 purple-color"
            />

            <span>{parse(note.title)}</span>
          </h6>
          <div>
            <Link to={TYPES[note.note_type].url} className="purple-link">
              {TYPES[note.note_type].text}
            </Link>
          </div>
          <div>
            <small>{note.createdBy?.name}</small>
          </div>
        </div>
        <div className="text-end">
          <div>{note?.order?.product?.name}</div>
          <div>
            <small>{moment(note.createdAt).format("DD-MM-YYYY")}</small>
          </div>
          <div>
            {note.shared && (
              <FontAwesomeIcon icon={faUsers} className="pe-2 purple-color" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const ManualNote = ({ note }) => {
  return (
    <div className="card p-3 mb-4 note-card shadow-none" key={note._id}>
      <div className="flex justify-content-between">
        <div>
          <h6>
            <span>{note.title}</span>
          </h6>
          <div>{parse(note.text)}</div>
          <div>
            <small>{note.createdBy?.name}</small>
          </div>
        </div>
        <div className="text-end">
          <div>{note?.order?.product?.name}</div>
          <div>
            <small>{moment(note.createdAt).format("DD-MM-YYYY")}</small>
          </div>
          <div>
            {note.shared && (
              <FontAwesomeIcon icon={faUsers} className="pe-2 purple-color" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteForm;
