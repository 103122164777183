import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PanelContext } from "./panelContext";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/authActions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket, faXmark } from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";
import { loadAgencyInfo } from "../redux/actions/agencyActions";

function Layout({}) {
  const dispatch = useDispatch();
  const agency = useSelector((state) => state.agencyReducer.agency);

  useEffect(() => {
    dispatch(loadAgencyInfo());
  }, []);

  return (
    <>
      <ToastContainer autoClose={3000} hideProgressBar />

      <div className="layout-container">
        <Sidebar logout={() => dispatch(logout())} agency={agency} />
        <main className="mt-2 main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
          <div className="container-fluid">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
}

const Sidebar = ({ logout, agency }) => {
  return (
    <div className="left-sidebar">
      <Link to="/client/dashboard">
        {agency.logo ? (
          <img src={agency.logo} alt="agency" className="prodotop-logo" />
        ) : (
          <div className="prodotop-logo logo"></div>
        )}
      </Link>
      <nav id="sidebar">
        <ul className="list-unstyled components">
          <li className="list-item fix-bottom">
            <div
              className="btn-outline-primary me-2 ms-2"
              target="_blank"
              onClick={logout}
              role="button"
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="button-tooltip-2">Se déconnecter</Tooltip>}
              >
                <FontAwesomeIcon icon={faArrowRightToBracket} className="" />
              </OverlayTrigger>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Layout;
