import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  fetchOrderById,
  fetchClientById,
  fetchSurveyById,
} from "../../../../../redux/actions/clientActions";

import OrderNavigation from "../order/orderNavigation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import DepartmentName from "../../../../shared/departments/departmentName";

const SurveyDetails = () => {
  const { clientId, orderId, surveyId } = useParams();
  const dispatch = useDispatch();

  const order = useSelector((state) => state.clientReducer.order);
  const client = useSelector((state) => state.clientReducer.tiny_client);

  const survey = useSelector((state) => state.clientReducer.survey);

  const SATISFACTION = [
    { label: "Très insatisfait", value: "1" },
    { label: "Insatisfait", value: "2" },
    { label: "Neutre", value: "3" },
    { label: "Satisfait", value: "4" },
    { label: "Très satisfait", value: "5" },
  ];

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientById(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(clientId, orderId));
    }
  }, [dispatch, orderId, clientId]);

  useEffect(() => {
    if (orderId && surveyId) {
      dispatch(fetchSurveyById(clientId, orderId, surveyId));
    }
  }, [dispatch, clientId, orderId, surveyId]);

  const RatingStars = ({ rating }) => {
    const totalStars = 5;
    const fullStar = <FontAwesomeIcon icon={faStar} className="text-warning" />;
    const emptyStar = <FontAwesomeIcon icon={faStar} className="text-muted" />;

    const stars = [];
    for (let i = 0; i < totalStars; i++) {
      stars.push(i < rating ? fullStar : emptyStar);
    }
    return <>{stars}</>;
  };

  return (
    <>
      {order && (
        <div className="pb-4">
          <nav aria-label="breadcrumb" className="breadcrumb">
            <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
              <li className="breadcrumb-item text-sm"></li>

              <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                <Link to={`/clients/${clientId}/orders`}>{client.companyName}</Link>
              </li>
              <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                {order?.product.name}
              </li>
              <li
                className="breadcrumb-item text-sm text-dark active"
                aria-current="page"
              >
                Enquêtes de satisfactions
              </li>
            </ol>
          </nav>
        </div>
      )}
      <div className="order-container">
        {order && <OrderNavigation order={order} client={client} currentTab="surveys" />}
        <Row>
          {console.log(survey)}
          <Col md={12} className="my-5">
            <div className="card purple-border card-order eCalendar justify-content-center">
              <div className="card-body py-4 px-3">
                <div className="px-3 pb-3">
                  <p className="mb-1">
                    <b>
                      Enquête de satisfaction{" "}
                      {moment(survey.createdAt).format("DD/MM/YY")}.
                    </b>
                  </p>
                  <p className="mb-1">
                    Votre avis compte beaucoup pour nous! Nous souhaitons constamment
                    évoluer et améliorer la qualité de nos services et produits. Nous vous
                    invitons à remplir ce formulaire afin d'avoir votre feedback. Merci :)
                  </p>
                </div>
                <div className="flex gap-3 flex-wrap px-3">
                  <div className="resume-item">
                    <div>
                      <b className="product-title bg-primary px-3 text-white">
                        Prestation :
                      </b>
                    </div>
                    {order?.product?.name}
                  </div>
                  <div className="resume-item">
                    <div>
                      <b className="product-title bg-primary px-3 text-white">
                        Date de lancement :
                      </b>
                    </div>
                    {moment(order.startDate).format("DD-MM-YYYY")}
                  </div>
                  <div className="resume-item">
                    <div>
                      <b className="product-title bg-primary px-3 text-white">
                        Date de fin :
                      </b>
                    </div>
                    {moment(order.endDate).format("DD-MM-YYYY")}
                  </div>

                  {order?.team1Responsible && (
                    <div className="resume-item">
                      <div>
                        <b className="product-title bg-primary px-3 text-white">
                          Responsable <DepartmentName code="Team1" />
                        </b>
                      </div>
                      {order?.team1Responsible?.name}
                    </div>
                  )}
                  {order?.team2Responsible && (
                    <div className="resume-item">
                      <div>
                        <b className="product-title bg-primary px-3 text-white">
                          Responsable <DepartmentName code="Team2" />
                        </b>
                      </div>
                      {order?.team2Responsible?.name}
                    </div>
                  )}
                  {order?.team3Responsible && (
                    <div className="resume-item">
                      <div>
                        <b className="product-title bg-primary px-3 text-white">
                          Responsable <DepartmentName code="Team3" />
                        </b>
                      </div>
                      {order?.team3Responsible?.name}
                    </div>
                  )}
                  {order?.team4Responsible && (
                    <div className="resume-item">
                      <div>
                        <b className="product-title bg-primary px-3 text-white">
                          Responsable <DepartmentName code="Team4" />
                        </b>
                      </div>
                      {order?.team4Responsible?.name}
                    </div>
                  )}
                  {order?.team5Responsible && (
                    <div className="resume-item">
                      <div>
                        <b className="product-title bg-primary px-3 text-white">
                          Responsable <DepartmentName code="Team5" />
                        </b>
                      </div>
                      {order?.team5Responsible?.name}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <div className="col-12">
            <div className="card medium-card mb-4 pb-2 purple-border">
              <div className="card-body">
                {survey?.responded ? (
                  <div>
                    <p>
                      Merci de nous avoir fait un retour ! Nous allons prendre en compte
                      votre feedback.
                    </p>
                    {survey.responses.map((response, index) => (
                      <div key={index} className="mb-3">
                        <div>
                          <b>{response?.question?.title}</b>
                        </div>
                        <div>
                          {response?.question?.responseType === "scale"
                            ? SATISFACTION.find((item) => item.value === "2").label
                            : response?.answer}
                        </div>
                      </div>
                    ))}
                    <div>
                      <div>
                        <b>Appréciation générale</b>
                      </div>
                      <RatingStars rating={survey.rating} />
                    </div>
                  </div>
                ) : (
                  <p>Le client n'a pas encore fait un retour !</p>
                )}
              </div>
            </div>
          </div>
        </Row>
      </div>
      <Row className="mb-3">
        <Col>
          <Link
            to={`/clients/${clientId}/orders/${orderId}/surveys`}
            className="link-action back"
          >
            Retour
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default SurveyDetails;
