import { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addTodo, updateTodo, fetchTodos } from "../../../redux/actions/todoActions";
import { fetchProducts } from "../../../redux/actions/productActions";
import { Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../NavContext";
import DepartmentName from "../../shared/departments/departmentName";

const TodoForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeNav, setActiveNav } = useContext(NavContext);
  const { productId, id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    setActiveNav("products");
  }, [setActiveNav]);

  const todos = useSelector((state) => state.todoReducer.todos);
  const todo = todos.find((td) => td._id === id);

  const products = useSelector((state) => state.productReducer.products);
  const isFetching = useSelector((state) => state.productReducer.isFetching);
  const product = products && products.find((product) => product._id === productId);

  useEffect(() => {
    if (!isFetching) {
      dispatch(fetchProducts());
    }
  }, [dispatch, product, reset, isFetching]);

  useEffect(() => {
    dispatch(fetchTodos(productId));
  }, []);

  useEffect(() => {
    if (todo) {
      const teamInfo = [
        { key: "for_team2", type: "for_team2" },
        { key: "for_team4", type: "for_team4" },
        { key: "for_team1", type: "for_team1" },
        { key: "for_team3", type: "for_team3" },
        { key: "for_team5", type: "for_team5" },
      ];
      let todoType;
      for (const team of teamInfo) {
        if (todo[team.key]) {
          todoType = team.type;
          break;
        }
      }

      reset({
        task: todo.task,
        minutes: todo.minutes,
        repetitive: todo.repetitive,
        todoType,
      });
    }
  }, [todo, reset]);

  const onSubmit = (data) => {
    if (todo) {
      dispatch(updateTodo(productId, todo._id, data)).then(() => {
        dispatch(fetchProducts());
      });
    } else {
      dispatch(addTodo(productId, data)).then(() => {
        dispatch(fetchProducts());
      });
    }

    navigate(`/products/${productId}`);
    reset();
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/products">Produits</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to={`/products/${productId}`}>{product?.name}</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              {todo ? "Modification du critère de qualité" : "Nouveau critère de qualité"}
            </li>
          </ol>
        </nav>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <Row>
            <Col className="d-flex flex-column mx-auto">
              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-header text-left">
                  <Col md={6}>
                    {todo ? (
                      <h6>
                        <span className="product-title px-3 text-white">
                          Modification de la tâche
                        </span>
                      </h6>
                    ) : (
                      <h6>
                        <span className="product-title px-3 text-white">
                          Nouvelle tâche
                        </span>
                      </h6>
                    )}
                  </Col>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label htmlFor="task" className="form-label">
                      Nom de la tâche :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="task"
                      {...register("task", { required: true })}
                    />
                    {errors.task && <div className="text-danger">champ requis</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="minutes" className="form-label">
                      Minutes :
                    </label>
                    <input
                      className="form-control"
                      id="minutes"
                      type="number"
                      {...register("minutes", { required: true })}
                    />
                    {errors.minutes && (
                      <div className="text-danger">Description requise</div>
                    )}
                  </div>
                  <div className="form-check form-switch mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="repetitive"
                      {...register("repetitive")}
                    />
                    <label className="form-check-label" htmlFor="repetitive">
                      Tâche répétitif ?
                    </label>
                  </div>
                  <hr />
                  <div className="flex gap-5 flex-wrap">
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="todoType" // Nom commun pour le groupe de boutons radio
                        id="for_team1"
                        {...register("todoType", { required: true })}
                        value="for_team1"
                      />
                      <label className="form-check-label" htmlFor="for_team1">
                        Tâche pour {<DepartmentName code="Team1" />}
                      </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="todoType"
                        id="for_team2"
                        {...register("todoType", { required: true })}
                        value="for_team2"
                      />
                      <label className="form-check-label" htmlFor="for_team2">
                        Tâche pour {<DepartmentName code="Team2" />}
                      </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="todoType"
                        id="for_team3"
                        {...register("todoType", { required: true })}
                        value="for_team3"
                      />
                      <label className="form-check-label" htmlFor="for_team3">
                        Tâche pour {<DepartmentName code="Team3" />}
                      </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="todoType"
                        id="for_team4"
                        {...register("todoType", { required: true })}
                        value="for_team4"
                      />
                      <label className="form-check-label" htmlFor="for_team4">
                        Tâche pour {<DepartmentName code="Team4" />}
                      </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="todoType"
                        id="for_team5"
                        {...register("todoType", { required: true })}
                        value="for_team5"
                      />
                      <label className="form-check-label" htmlFor="for_team5">
                        Tâche pour {<DepartmentName code="Team5" />}
                      </label>
                    </div>
                  </div>
                  {errors.todoType && (
                    <p className="text-danger text-gradient">
                      le choix du département est obligatoire !
                    </p>
                  )}

                  <div className="">
                    <button type="submit" className="btn bg-gradient-dark w-30 mt-4 mb-0">
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Link to={`/products/${productId}`} className="link-action back">
                Retour
              </Link>
            </Col>
          </Row>
        </section>
      </form>
    </>
  );
};

export default TodoForm;
