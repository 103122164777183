import { useForm, Controller } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBahai } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

function NoteForm({
  suggestion,
  onSubmitNote,
  defaultTitle,
  fetchIaIterpretation,
  plateform,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {
      title: defaultTitle,
    },
  });
  const [iaPrompt, setIaPrompt] = useState(
    "Fait une analyse de ces données , écrit moi un compte rendu en 20 lignes maximum, puis en conclusion écrit moi des pistes d'optimisation  en 10 lignes maximum."
  );

  const handleNoteSubmit = (data) => {
    onSubmitNote({ ...data, plateform });
    reset();
  };

  useEffect(() => {
    setValue("text", suggestion);
  }, [setValue, suggestion]);

  const handleChange = (e) => {
    setIaPrompt(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit(handleNoteSubmit)}>
      <div className="card mb-4 purple-border">
        <div className="card-body">
          <Row className="xmb-5">
            <Col md={12}>
              <input name="orderId" type="hidden" />
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Titre
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="text"
                  {...register("title")}
                  disabled
                />
                {errors?.text && <div className="text-danger">Champs obligatoire</div>}
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <label htmlFor="duration" className="form-label">
                  Ajouter une note *
                </label>
                <Controller
                  name={`text`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CKEditor
                      editor={ClassicEditor}
                      data={field.value}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        field.onChange(data);
                      }}
                      placeholder="Ajouter une note ..."
                    />
                  )}
                />
                {errors?.text && <div className="text-danger">Champs obligatoire</div>}
              </div>
            </Col>
            <Col md={12}>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="shared"
                  {...register("shared")}
                  defaultChecked={false}
                />
                <label className="form-check-label" htmlFor="shared">
                  Partager avec le client
                </label>
              </div>
            </Col>
            <div className="flex justify-content-between">
              <div>
                <button type="submit" className="btn bg-gradient-dark mt-1 mb-0 px-5">
                  Ajouter
                </button>
              </div>
            </div>
          </Row>
        </div>
      </div>
      <div className="card mb-4 purple-border">
        <div className="card-body">
          <Row className="xmb-5">
            <div className="flex gap-1">
              <textArea className="form-control" onChange={handleChange}>
                {iaPrompt}
              </textArea>
              <span
                className="btn btn-outline-primary mb-0 mx-3"
                onClick={() => fetchIaIterpretation(iaPrompt)}
              >
                <FontAwesomeIcon icon={faBahai} className="px-2" />
                IA interpretation
              </span>
            </div>
          </Row>
        </div>
      </div>
    </form>
  );
}

export default NoteForm;
