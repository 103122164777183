import { useState, useEffect, memo } from "react";
import { usePaginationPages } from "./usePaginationPages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Pagination({ gotoPage, length, pageSize, setPageSize }) {
  const [perPage, setPerPage] = useState(pageSize);

  const { canGo, currentPage, pages, goTo, goNext, goPrev } = usePaginationPages({
    gotoPage,
    length,
    pageSize,
  });

  useEffect(() => {
    setPageSize(Number(perPage));
  }, [perPage, setPageSize]);

  return (
    <div className="m-4 flex items-center justify-center">
      <button
        onClick={goPrev}
        disabled={!canGo.previous}
        className="m-1 px-2 py-1 border rounded-md"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      {pages.map((page, i) => (
        <button
          onClick={() => goTo(page)}
          key={i}
          className={`m-1 px-3 py-1 border rounded-md  ${
            currentPage === page ? "bg-primary" : ""
          }`}
        >
          {page}
        </button>
      ))}
      <button
        onClick={goNext}
        disabled={!canGo.next}
        className="m-1 px-2 py-1 border rounded-md"
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
      <select
        className="px-2 py-[6px] border rounded-md w-30 bg-white"
        value={pageSize}
        onChange={(e) => setPerPage(e.target.value)}
      >
        {[10, 50, 100].map((pageSize) => (
          <option className="py-2" value={pageSize} key={pageSize}>
            {pageSize} / page
          </option>
        ))}
      </select>
    </div>
  );
}

export default memo(Pagination);
