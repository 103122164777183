import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchPosts,
  updatePost,
  fetchECalendarById,
  fetchPostsSuccess,
  updatePriorities,
} from "../../../../redux/actions/cli/eCalendarActions";
import { fetchCliTinyClient } from "../../../../redux/actions/cli/clientsActions";
import { fetchOrderById } from "../../../../redux/actions/cli/orderActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPen,
  faImage,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Offcanvas } from "react-bootstrap";
import { AnimatePresence } from "framer-motion";
import parse from "html-react-parser";
import moment from "moment-timezone";

// AYB This should b in a shared component
import ExpandedContent from "../../../admin/clients/orders/tickets/ExpandedContent";
import ExpandedPhotos from "../../../shared/ExpandedPhotos";
import OrderNavigation from "../../clients/orderNavigation";

const PostPage = () => {
  const dispatch = useDispatch();
  const { eCalendarId, clientId, orderId } = useParams();
  const [showComments, setShowComments] = useState(true);
  const [showStrategy, setShowStrategy] = useState(false);
  const [dragStart, setDragStart] = useState(null);

  const [layoutId, setLayoutId] = useState(null);
  const [data, setData] = useState(null);
  const [photosLayoutId, setPhotosLayoutId] = useState(null);
  const [photosData, setPhotosData] = useState(null);

  const posts = useSelector((state) => state.cliECalendarReducer.posts);
  const order = useSelector((state) => state.cliClientsReducer.order);
  const eCalendar = useSelector((state) => state.cliECalendarReducer.eCalendar);
  const client = useSelector((state) => state.cliClientsReducer.tiny_client);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliTinyClient(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (eCalendarId) {
      dispatch(fetchPosts(orderId, eCalendarId));
    }
  }, [dispatch, posts, eCalendarId, orderId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderById(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    if (eCalendarId) {
      dispatch(fetchECalendarById(orderId, eCalendarId));
    }
  }, [dispatch, eCalendarId, orderId]);

  const handleOnDragEnd = async (result) => {
    setDragStart(false);
    if (!result.destination) return;
    const items = Array.from(posts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dispatch(fetchPostsSuccess(items));
    const priorities = items.map((post, index) => ({
      _id: post._id,
      newPriority: index + 1,
    }));

    dispatch(updatePriorities(orderId, eCalendarId, { priorities: priorities })); // Vous devrez implémenter cette fonction pour faire une requête à votre API
  };

  const updateValidated = (id) => {
    let formData = new FormData();
    formData.append("validated", true);
    dispatch(updatePost(orderId, eCalendarId, id, formData));
  };
  const updateValidatedImg = (id) => {
    let formData = new FormData();
    formData.append("validated_img", true);
    dispatch(updatePost(orderId, eCalendarId, id, formData));
  };

  const handlePhotos = (post) => {
    setPhotosData(post.photo.filter((x) => x));
    setPhotosLayoutId(post._id);
  };

  return (
    <>
      <div className="pb-2">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to={`/client/${clientId}/orders`}>{order?.client?.companyName}</Link>
            </li>

            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {order?.product?.name}
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to={`/client/${clientId}/orders/${orderId}/editorial_calendars`}>
                Calendriers éditoriaux
              </Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              {eCalendar.title}
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              liste des posts
            </li>
          </ol>
        </nav>
      </div>
      <Offcanvas show={showStrategy} onHide={() => setShowStrategy(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Voici les élément clés qui permettront de rendre le calendrier pertinent
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AnimatePresence initial={false} mode="wait">
            <div className="">
              <div className="mb-2">
                <div>
                  <b className="product-title bg-primary px-3 text-white">Objectifs :</b>
                </div>
                Découvrez l'objectif principal de chaque contenu. vous saurez toujours
                quel est le but visé.
              </div>
              <div className="mb-2">
                <div>
                  <b className="product-title bg-primary px-3 text-white">Cible :</b>
                </div>
                Le public visé par chaque contenu. Nous veillons à ce que chaque
                publication soit adaptée à son audience.
              </div>
              <div className="mb-2">
                <div>
                  <b className="product-title bg-primary px-3 text-white">
                    Tone of Voice :
                  </b>
                </div>
                Identifiez le ton et le style de communication choisi. le ton utilisé est
                essentiel pour toucher votre public cible de la manière la plus efficace.
              </div>

              <div className="mb-2">
                <div>
                  <b className="product-title bg-primary px-3 text-white">
                    Plan de communication :
                  </b>
                </div>
                La fréquence de planification et les canaux utilisés.
              </div>
              <div className="mb-2">
                <div>
                  <b className="product-title bg-primary px-3 text-white">Stratégie :</b>
                </div>
                Résumé.
              </div>
            </div>
          </AnimatePresence>
        </Offcanvas.Body>
      </Offcanvas>

      <div className="order-container">
        {order && <OrderNavigation order={order} client={client} currentTab="ce" />}
        <>
          <Row>
            <Row className="mb-3">
              <Col xs={6}>
                <div
                  className="mx-3"
                  xs={6}
                  onClick={() => setShowStrategy(!showStrategy)}
                  role="button"
                >
                  Afficher la stratégie
                </div>
              </Col>
              <Col
                className="text-end"
                xs={6}
                onClick={() => setShowComments(!showComments)}
                role="button"
              >
                {showComments ? "Cacher les commentaires" : "Afficher les commentaires"}
                <Link
                  to={`/client/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts_calendar`}
                  className="prodotop-link py-2 ms-2"
                >
                  <FontAwesomeIcon icon={faCalendarDays} className="px-2" />
                </Link>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col md={4} className="mb-3" key={eCalendar._id}>
              <div>
                <div className="card purple-border card-order eCalendar justify-content-center mb-3">
                  <div className="card-body py-4 px-3 ">
                    <div className="px-3 pb-3 validation-ce">
                      <b>Guide d'Utilisation du Calendrier Éditorial</b>
                      <p className="mb-3">
                        Bienvenue dans l'espace dédié au calendrier éditorial. Cet espace
                        a été pensé pour vous offrir une vue d'ensemble de votre stratégie
                        de contenu, tout en mettant à votre disposition des outils
                        intuitifs pour faciliter sa gestion.
                      </p>
                      <div className="mb-3">
                        <div>
                          <b className="product-title bg-primary px-3 text-white">
                            Validation :
                          </b>
                        </div>{" "}
                        <p>
                          Vous y trouverez deux boutons spécifiques pour la validation
                        </p>
                      </div>
                      <div>
                        <b>Validation de texte :</b>{" "}
                        <p>
                          Permet de confirmer que le contenu textuel du post est prêt et
                          conforme.
                        </p>
                      </div>

                      <div className="mb-3">
                        <b>Validation des images :</b>{" "}
                        <p>
                          Assure que les visuels associés à votre post sont approuvés et
                          adaptés.
                        </p>{" "}
                      </div>

                      <div className="mb-3">
                        <div>
                          <b className="product-title bg-primary px-3 text-white">
                            Organisation des Posts :
                          </b>
                        </div>{" "}
                        <p>
                          Si vous souhaitez réorganiser l'ordre de vos publications,
                          utilisez simplement la fonction de glisser-déposer (drag and
                          drop) pour changer les priorités des posts selon vos besoins.
                        </p>
                      </div>

                      <div className="mb-3">
                        <div>
                          <b className="product-title bg-primary px-3 text-white">
                            Commentaires et Remarques :
                          </b>
                        </div>{" "}
                        <p>
                          Pour ajouter un commentaire ou une remarque à un post
                          spécifique, cliquez sur l'icône de modification. Cela vous
                          dirigera vers la page d'édition du post en question.
                        </p>{" "}
                      </div>

                      <div className="mb-3">
                        <div>
                          <b className="product-title bg-primary px-3 text-white">
                            Planification :
                          </b>
                        </div>{" "}
                        <p>
                          Sur la page d'édition, vous avez la possibilité de définir des
                          dates précises pour la publication de chaque post. Cependant,
                          une fois que vous avez validé une date, faites preuve de
                          prudence car il ne sera plus possible de la modifier par la
                          suite.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8} className={dragStart ? "dragStart" : ""}>
              <DragDropContext
                onDragEnd={handleOnDragEnd}
                onDragStart={() => setDragStart(true)}
              >
                <Droppable droppableId="posts">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {posts.map((post, index) => {
                        const photos = post.photo.filter((photo) => photo);
                        return (
                          <Draggable key={post._id} draggableId={post._id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div key={post._id} className="mb-4 post-container">
                                  <div
                                    className={`card purple-border card-order post justify-content-center`}
                                  >
                                    <div className="card-body py-3 px-3">
                                      <div className="flex gap-3 full ">
                                        <div>
                                          <div>
                                            <div
                                              className={`img-post`}
                                              onClick={() =>
                                                post.photo &&
                                                photos[0] &&
                                                handlePhotos(post)
                                              }
                                            >
                                              {photos && photos[0] ? (
                                                <img
                                                  src={photos[0]}
                                                  alt={post.theme}
                                                  style={{ width: "140px" }}
                                                />
                                              ) : (
                                                <img
                                                  src="/images/pastille.png"
                                                  alt={post.theme}
                                                  style={{ width: "140px" }}
                                                />
                                              )}
                                              {photos.length > 1 && (
                                                <span>
                                                  {" "}
                                                  + {photos.length}{" "}
                                                  <FontAwesomeIcon icon={faImage} />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="community-card-content">
                                          <Row>
                                            <Col xs={12}>
                                              <div className="flex justify-content-between pb-3">
                                                <h6 className="post-title mb-0 ">
                                                  <span className="product-title text-dark ">
                                                    {index + 1} {"|"} {post.theme}
                                                  </span>
                                                </h6>
                                                <div className="flex p-0">
                                                  {post.date && (
                                                    <div className="px-2 white-text bg-gradient-dark-inverse">
                                                      {moment(post.date).format(
                                                        "DD-MM-YYYY"
                                                      )}
                                                    </div>
                                                  )}

                                                  <div className="flex text-end mb-0 p-0">
                                                    <Link
                                                      to={`/client/${clientId}/orders/${orderId}/editorial_calendars/${eCalendarId}/posts/${post._id}`}
                                                      className="btn btn-link text-warning text-gradient  mb-0 p-0 mt-1"
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faPen}
                                                        className="px-2"
                                                        color="#ffb300"
                                                      />
                                                      Modifier
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                className={`post-text ${
                                                  post.yellow_remark ||
                                                  post.customer_remark
                                                    ? "with-comment"
                                                    : "without-comment"
                                                }`}
                                                role="button"
                                                onClick={() => {
                                                  setData(post.texte);
                                                  setLayoutId(post._id);
                                                }}
                                              >
                                                {parse(post.texte)}
                                              </div>
                                            </Col>
                                            {showComments && (
                                              <>
                                                {post.yellow_remark && (
                                                  <Col
                                                    lg={6}
                                                    md={12}
                                                    className="comments-block"
                                                  >
                                                    <h6 className="post-title mb-0 ">
                                                      <span className="product-title text-secondary">
                                                        Commentaire Yellow
                                                      </span>
                                                    </h6>
                                                    <div className="comment-section">
                                                      {parse(post.yellow_remark)}
                                                    </div>
                                                  </Col>
                                                )}
                                                {post.customer_remark && (
                                                  <Col
                                                    lg={6}
                                                    md={12}
                                                    className="comments-block"
                                                  >
                                                    <h6 className="post-title mb-0 ">
                                                      <span className="product-title text-secondary ">
                                                        Commentaire Client
                                                      </span>
                                                    </h6>
                                                    <div className="comment-section">
                                                      {parse(post.customer_remark)}
                                                    </div>
                                                  </Col>
                                                )}
                                              </>
                                            )}
                                            <div className="">
                                              <div className="flex gap-3">
                                                {post.validated ? (
                                                  <div>
                                                    <div className="border px-3 py-1 rounded-1 validated-border">
                                                      <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="px-2"
                                                        color="#839e2f"
                                                      />
                                                      <span className="pl-2 text-validated">
                                                        texte validé
                                                      </span>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="border px-3 py-1 rounded-1"
                                                    role="button"
                                                    onClick={() =>
                                                      updateValidated(post._id)
                                                    }
                                                  >
                                                    Valider le texte
                                                  </div>
                                                )}
                                                {post.validated_img ? (
                                                  <div>
                                                    <div className="border px-3 py-1 rounded-1 validated-border">
                                                      <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="px-2"
                                                        color="#839e2f"
                                                      />
                                                      <span className="pl-2 text-validated">
                                                        image validé
                                                      </span>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="border px-3 py-1 rounded-1"
                                                    role="button"
                                                    onClick={() =>
                                                      updateValidatedImg(post._id)
                                                    }
                                                  >
                                                    Valider l'image
                                                  </div>
                                                )}
                                                {post.shared &&
                                                post.date &&
                                                moment().isAfter(post.date) ? (
                                                  <div>
                                                    <div className="border px-3 py-1 rounded-1 validated-border">
                                                      <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="px-2"
                                                        color="#839e2f"
                                                      />
                                                      <span className="pl-2 text-validated">
                                                        post partagé
                                                      </span>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                            </div>
                                          </Row>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Col>

            <Row className="mb-3">
              <Col>
                <Link
                  className="link-action back"
                  to={`/client/${clientId}/orders/${orderId}/editorial_calendars`}
                >
                  Retour
                </Link>
              </Col>
            </Row>
          </Row>
        </>
      </div>

      {layoutId && (
        <ExpandedContent
          key={layoutId}
          layoutId={layoutId}
          data={data}
          setData={setData}
        />
      )}

      {photosLayoutId && (
        <ExpandedPhotos
          layoutId={photosLayoutId}
          data={photosData}
          setData={setPhotosData}
        />
      )}
    </>
  );
};

export default PostPage;
