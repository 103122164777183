import { useEffect, useContext, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchClientOrders,
  deleteClientOrder,
} from "../../../../redux/actions/clientActions";
import { Link } from "react-router-dom";
import OrderCard from "./order/orderCard";
import NewOrderCard from "./order/newOrderCard";

import { NavContext } from "../../../NavContext";
import ClientNavigation from "../clientNavigation";

const OrdersPage = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const client = useSelector((state) => state.clientReducer.client);
  const { activeNav, setActiveNav } = useContext(NavContext);
  const [shows, setShows] = useState({
    new: true,
    current: true,
    produced: true,
    completed: false,
  });
  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);
  const currentUser = useSelector((state) => state.authReducer.user.user);
  const BOSS = currentUser.role === "boss";
  const MANAGER = currentUser.role === "manager";

  useEffect(() => {
    document.title = "ProdOTop - Commandes du client " + client?.companyName;
  }, [client]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientOrders(clientId));
    }
  }, [dispatch, clientId]);

  const handleDelete = (orderId) => {
    dispatch(deleteClientOrder(clientId, orderId));
  };

  const filterOrders = (order, team) => {
    if (!order.produced && !order.orderCompleted) {
      if (order[`team${team}Accepted`]) {
        return "current";
      } else {
        return "new";
      }
    }
    return null;
  };

  const getOrders = (client, department) => {
    const new_orders = [];
    const current_orders = [];
    const produced_orders = [];
    const completed_orders = [];

    if (client) {
      client.orders.forEach((order) => {
        if (order.produced && !order.orderCompleted) {
          produced_orders.push(order);
        } else if (order.orderCompleted) {
          completed_orders.push(order);
        } else {
          const orderType = filterOrders(order, department.replace("Team", ""));
          if (orderType === "current") {
            current_orders.push(order);
          } else if (orderType === "new") {
            new_orders.push(order);
          }
        }
      });
    }

    return { new_orders, current_orders, produced_orders, completed_orders };
  };

  const { new_orders, current_orders, produced_orders, completed_orders } = getOrders(
    client,
    currentUser.departement
  );

  return (
    <>
      <div className="pb-3">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/clients">clients</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark " aria-current="page">
              {client?.companyName}
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Liste des commandes
            </li>
          </ol>
        </nav>
      </div>
      <div className="order-container">
        {client && <ClientNavigation client={client} currentTab="orders" />}
        <div>
          <Row className="row">
            <div className="col-12 mb-3">
              <Row className="xmb-5">
                <Col className="text-end" md={12}>
                  <Link
                    to={`/clients/${clientId}/orders/new`}
                    className="btn bg-gradient-dark-inverse mb-0"
                  >
                    Ajouter une commande
                  </Link>
                </Col>
              </Row>
              {/* Kan hna */}
            </div>
            <Col md={{ span: 12 }} className="mb-5">
              <Row>
                <Col md={12} className="pt-2 mb-3">
                  <div
                    onClick={() =>
                      setShows((currentShows) => ({
                        ...currentShows,
                        new: !currentShows.new,
                      }))
                    }
                    className="toogle-done-drafts flex justify-content-between bg-white"
                  >
                    <span className="mx-2 mt-1">Nouveaux</span>
                    <span className="prodotop-link">{new_orders.length}</span>
                  </div>
                </Col>
                {shows.new &&
                  new_orders.map((order) => (
                    <NewOrderCard
                      key={order._id}
                      client={client}
                      order={order}
                      clientId={clientId}
                      BOSS={BOSS}
                      MANAGER={MANAGER}
                      handleDelete={handleDelete}
                    />
                  ))}
              </Row>
              <Row>
                <Col md={12} className="pt-2 mb-3">
                  <div
                    onClick={() =>
                      setShows((currentShows) => ({
                        ...currentShows,
                        current: !currentShows.current,
                      }))
                    }
                    className="toogle-done-drafts flex justify-content-between bg-white"
                  >
                    <span className="mx-2 mt-1">En productions</span>
                    <span className="prodotop-link">{current_orders.length}</span>
                  </div>
                </Col>
                {shows.current &&
                  current_orders.map((order) => (
                    <OrderCard
                      key={order._id}
                      client={client}
                      order={order}
                      clientId={clientId}
                      BOSS={BOSS}
                      MANAGER={MANAGER}
                      handleDelete={handleDelete}
                    />
                  ))}
              </Row>
              <Row>
                <Col md={12} className="pt-2 mb-3">
                  <div
                    onClick={() =>
                      setShows((currentShows) => ({
                        ...currentShows,
                        produced: !currentShows.produced,
                      }))
                    }
                    className="toogle-done-drafts flex justify-content-between bg-white"
                  >
                    <span className="mx-2 mt-1">Produites</span>
                    <span className="prodotop-link">{produced_orders.length}</span>
                  </div>
                </Col>
                {shows.produced &&
                  produced_orders.map((order) => (
                    <OrderCard
                      key={order._id}
                      client={client}
                      order={order}
                      clientId={clientId}
                      BOSS={BOSS}
                      MANAGER={MANAGER}
                      handleDelete={handleDelete}
                    />
                  ))}
              </Row>
              <Row>
                <Col md={12} className="pt-2 mb-3">
                  <div
                    onClick={() =>
                      setShows((currentShows) => ({
                        ...currentShows,
                        completed: !currentShows.completed,
                      }))
                    }
                    className="toogle-done-drafts flex justify-content-between bg-white"
                  >
                    <span className="mx-2 mt-1">Clôturés</span>
                    <span className="prodotop-link">{completed_orders.length}</span>
                  </div>
                </Col>
                {shows.completed &&
                  completed_orders.map((order) => (
                    <OrderCard
                      key={order._id}
                      client={client}
                      order={order}
                      clientId={clientId}
                      BOSS={BOSS}
                      MANAGER={MANAGER}
                      handleDelete={handleDelete}
                    />
                  ))}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default OrdersPage;
