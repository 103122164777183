import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderWithSurveysById } from "../../../../redux/actions/cli/orderActions";
import { fetchCliTinyClient } from "../../../../redux/actions/cli/clientsActions";

import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faStar } from "@fortawesome/free-solid-svg-icons";
import config from "../../../../config";
import OrderNavigation from "../orderNavigation";

import { PanelContext } from "../../../panelContext";

const SurveyPage = () => {
  const dispatch = useDispatch();
  const { clientId, orderId } = useParams();
  const order = useSelector((state) => state.cliClientsReducer.order_surveys);
  const { panelMessage, setPanelMessage } = useContext(PanelContext);
  const client = useSelector((state) => state.cliClientsReducer.tiny_client);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliTinyClient(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (panelMessage) {
      if (order && order.surveys.length > 0) {
        setPanelMessage(
          "<div><b>Vous souhaitez partager votre avis ?</b></div> Merci de remplir l'enquête de satisfaction ci-dessous. Vos retours nous aideront à améliorer la qualité de nos services."
        );
      } else {
        setPanelMessage(
          "Cette section présentera <b>les enquêtes de satisfaction dès leur élaboration par notre équipe</b>. Il est normal qu'aucune enquête ne soit disponible actuellement; elles seront mises à disposition en temps voulu."
        );
      }
    }
  }, [order, setPanelMessage, panelMessage]);

  useEffect(() => {
    document.title = "ProdOTop - Enquêtes de satisfactions";
  }, []);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderWithSurveysById(orderId));
    }
  }, [dispatch, orderId]);

  const RatingStars = ({ rating }) => {
    const totalStars = 5;
    const fullStar = <FontAwesomeIcon icon={faStar} className="text-warning" />;
    const emptyStar = <FontAwesomeIcon icon={faStar} className="text-muted" />;

    const stars = [];
    for (let i = 0; i < totalStars; i++) {
      stars.push(i < rating ? fullStar : emptyStar);
    }
    return <>{stars}</>;
  };

  return (
    <>
      {order && (
        <>
          <div className="pb-4">
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>

                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/client/${clientId}/orders`}>
                    {order?.client?.companyName}
                  </Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  {order?.product.name}
                </li>
                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  Enquêtes de satisfactions
                </li>
              </ol>
            </nav>
          </div>

          <div className="order-container">
            {order && (
              <OrderNavigation order={order} client={client} currentTab="surveys" />
            )}
            <Row className="mb-3">
              <div className="col-12">
                <div className="card medium-card mb-4 pb-2 purple-border">
                  <div className="card-body">
                    <h6>
                      <span className="product-title px-3 text-dark">
                        Les enquêtes de satisfactions pour {order?.product.name}
                      </span>
                    </h6>
                    {order && order.surveys.length > 0 ? (
                      <>
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                enquête
                              </th>
                              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                                Répondu ?
                              </th>
                              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-end">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.surveys.map((survey) => (
                              <tr key={survey._id}>
                                <td>
                                  Enquêtes de satisfaction{" - "}
                                  {moment(survey.createdAt).format("DD/MM/YY")}
                                </td>
                                <td className="text-end">
                                  {survey.responded ? (
                                    <RatingStars rating={survey.rating} />
                                  ) : (
                                    "Non"
                                  )}
                                </td>
                                <td className="text-end">
                                  <Link
                                    to={`/client/${clientId}/orders/${orderId}/surveys/${survey._id}`}
                                  >
                                    <small className="btn copy mx-2">
                                      <FontAwesomeIcon
                                        icon={faArrowUpRightFromSquare}
                                        className="px-2"
                                      />
                                    </small>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <p className="p-3">
                        Aucune enquêtes de satisfaction n'a été générée
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Row>

            <Row className="mb-3">
              <Col>
                <Link to={`/client/${clientId}/orders`} className="link-action back">
                  Retour
                </Link>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default SurveyPage;
