import {
  FETCH_ECALENDARS_REQUEST,
  FETCH_ECALENDARS_SUCCESS,
  FETCH_ECALENDARS_FAILURE,
  ADD_ECALENDAR_REQUEST,
  ADD_ECALENDAR_SUCCESS,
  ADD_ECALENDAR_FAILURE,
  UPDATE_ECALENDAR_REQUEST,
  UPDATE_ECALENDAR_SUCCESS,
  UPDATE_ECALENDAR_FAILURE,
  DELETE_ECALENDAR_REQUEST,
  DELETE_ECALENDAR_SUCCESS,
  DELETE_ECALENDAR_FAILURE,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  FETCH_ECALENDAR_REQUEST,
  FETCH_ECALENDAR_SUCCESS,
  FETCH_ECALENDAR_FAILURE,
  FETCH_POSTS_REQUEST,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILURE,
  POST_ADD_REQUEST,
  POST_ADD_SUCCESS,
  POST_ADD_FAILURE,
  POST_UPDATE_REQUEST,
  POST_UPDATE_SUCCESS,
  POST_UPDATE_FAILURE,
  FETCH_POST_REQUEST,
  FETCH_POST_SUCCESS,
  FETCH_POST_FAILURE,
  FETCH_COMMUNITY_POSTS_REQUEST,
  FETCH_COMMUNITY_POSTS_SUCCESS,
  FETCH_COMMUNITY_POSTS_FAILURE,
  FETCH_IA_SUGGESTIONS_REQUEST,
  FETCH_IA_SUGGESTIONS_SUCCESS,
  FETCH_IA_SUGGESTIONS_FAILURE,
  FETCH_IA_SUGGESTION_REQUEST,
  FETCH_IA_SUGGESTION_SUCCESS,
  FETCH_IA_SUGGESTION_FAILURE,
  REMOVE_IA_SUGGESTIONS_SUCCESS,
  FETCH_IA_SUGGESTION_SUBJECT_SUCCESS,
  FETCH_IA_SUGGESTION_SUBJECT_REQUEST,
  FETCH_IA_SUGGESTION_SUBJECT_FAILURE,
  REMOVE_IA_SUGGESTION_SUBJECT_SUCCESS,
  FETCH_CALENDAR_POSTS_SUCCESS,
} from "../actionTypes";

const initialState = {
  eCalendars: [],
  eCalendar: false,
  order: false,
  posts: [],
  dragged_posts: [],
  undragged_posts: [],
  communityPosts: [],
  fetchingCommunityPosts: false,
  post: false,
  isFetching: false,
  errorMessage: "",
  fetchingPosts: false,
  iaSuggestions: false,
  fetchingSuggestions: false,
  iaSuggestion: false,
  fetchingSuggestion: false,
  iaSuggestionSubjects: false,
};

export default function eCalendarReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_TO_DRAGGED_POSTS":
      const new_dragged_posts = state.dragged_posts.concat(action.payload);
      return {
        ...state,
        dragged_posts: new_dragged_posts,
      };
    case "REMOVE_FROM_UNDRAGGED_POSTS":
      const undraggedPosts = state.undragged_posts.filter(
        (event) => event._id !== action.payload
      );
      return {
        ...state,
        undragged_posts: undraggedPosts,
      };

    case FETCH_CALENDAR_POSTS_SUCCESS:
      return {
        ...state,
        dragged_posts: action.payload.dragged_posts,
        undragged_posts: action.payload.undragged_posts,
      };
    case REMOVE_IA_SUGGESTIONS_SUCCESS:
      const newArray = [...state.iaSuggestions];
      newArray.splice(action.payload, 1);
      return {
        ...state,
        iaSuggestions: newArray,
      };
    case REMOVE_IA_SUGGESTION_SUBJECT_SUCCESS:
      const newSubjectsArray = [...state.iaSuggestionSubjects];
      newSubjectsArray.splice(action.payload, 1);
      return {
        ...state,
        iaSuggestionSubjects: newSubjectsArray,
      };

    case FETCH_IA_SUGGESTIONS_REQUEST:
      return {
        ...state,
        fetchingSuggestions: true,
      };
    case FETCH_IA_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        fetchingSuggestions: false,
        iaSuggestions: action.payload,
      };
    case FETCH_IA_SUGGESTIONS_FAILURE:
      return {
        ...state,
        fetchingSuggestions: false,
      };

    case FETCH_IA_SUGGESTION_SUBJECT_REQUEST:
      return {
        ...state,
        fetchingSuggestions: true,
      };
    case FETCH_IA_SUGGESTION_SUBJECT_SUCCESS:
      return {
        ...state,
        fetchingSuggestions: false,
        iaSuggestionSubjects: action.payload,
      };
    case FETCH_IA_SUGGESTION_SUBJECT_FAILURE:
      return {
        ...state,
        fetchingSuggestions: false,
      };

    case FETCH_IA_SUGGESTION_REQUEST:
      return {
        ...state,
        fetchingSuggestion: true,
      };
    case FETCH_IA_SUGGESTION_SUCCESS:
      return {
        ...state,
        fetchingSuggestion: false,
        iaSuggestion: action.payload,
      };
    case FETCH_IA_SUGGESTION_FAILURE:
      return {
        ...state,
        fetchingSuggestion: false,
      };
    case POST_ADD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case POST_ADD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        posts: [...state.posts, action.payload],
      };
    case POST_ADD_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case POST_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case POST_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        posts: state.posts.map((post) =>
          post._id === action.payload._id ? action.payload : post
        ),
      };
    case POST_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case FETCH_POST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_POST_SUCCESS:
      return {
        ...state,
        isFetching: true,
        post: action.payload,
      };
    case FETCH_POST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case FETCH_POSTS_REQUEST:
      return {
        ...state,
        fetchingPosts: false,
      };
    case FETCH_POSTS_SUCCESS:
      return {
        ...state,
        fetchingPosts: true,
        posts: action.payload,
      };
    case FETCH_POSTS_FAILURE:
      return {
        ...state,
        fetchingPosts: false,
        errorMessage: action.payload,
      };
    case FETCH_COMMUNITY_POSTS_REQUEST:
      return {
        ...state,
        fetchingCommunityPosts: true,
      };
    case FETCH_COMMUNITY_POSTS_SUCCESS:
      return {
        ...state,
        fetchingCommunityPosts: true,
        communityPosts: action.payload,
      };
    case FETCH_COMMUNITY_POSTS_FAILURE:
      return {
        ...state,
        fetchingCommunityPosts: false,
        errorMessage: action.payload,
      };
    case FETCH_ECALENDARS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ECALENDARS_SUCCESS:
      return {
        ...state,
        isFetching: true,
        eCalendars: action.payload,
      };
    case FETCH_ECALENDARS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case FETCH_ECALENDAR_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ECALENDAR_SUCCESS:
      return {
        ...state,
        isFetching: true,
        eCalendar: action.payload,
      };
    case FETCH_ECALENDAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case ADD_ECALENDAR_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ADD_ECALENDAR_SUCCESS:
      return {
        ...state,
        isFetching: true,
        eCalendars: [...state.eCalendars, action.payload],
      };
    case ADD_ECALENDAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case UPDATE_ECALENDAR_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_ECALENDAR_SUCCESS:
      return {
        ...state,
        isFetching: true,
        eCalendars: state.eCalendars.map((eCalendar) =>
          eCalendar._id === action.payload._id ? action.payload : eCalendar
        ),
      };
    case UPDATE_ECALENDAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case DELETE_POST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_POST_SUCCESS:
      return {
        ...state,
        isFetching: true,
        posts: state.posts.filter((post) => post._id !== action.payload),
      };
    case DELETE_POST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case DELETE_ECALENDAR_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_ECALENDAR_SUCCESS:
      return {
        ...state,
        isFetching: true,
        eCalendars: state.eCalendars.filter(
          (eCalendar) => eCalendar._id !== action.payload
        ),
      };
    case DELETE_ECALENDAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case FETCH_ORDER_REQUEST:
      return {
        ...state,
        error: null,
      };
    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        error: null,
      };
    case FETCH_ORDER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
