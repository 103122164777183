import { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderById,
  updateBriefResponses,
  fetchClientOrders,
  downloadPdf,
  genrateBriefQuestions,
} from "../../../../../redux/actions/clientActions";
import { Row, Col, Dropdown } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../../../NavContext";
import OrderNavigation from "../order/orderNavigation";

const BriefReponsesForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId, orderId } = useParams();
  const { activeNav, setActiveNav } = useContext(NavContext);
  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);

  const order = useSelector((state) => state.clientReducer.order);
  const currentUser = useSelector((state) => state.authReducer.user.user);
  const client = useSelector((state) => state.clientReducer.client);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleDownload = async () => {
    dispatch(downloadPdf(clientId, orderId));
  };
  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientOrders(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    dispatch(fetchOrderById(clientId, orderId));
  }, [dispatch, clientId, orderId]);

  useEffect(() => {
    if (order) {
      order.project_brief_responses.forEach((response) => {
        setValue(`project_brief_responses.[${response._id}].response`, response.response);
        setValue(`project_brief_responses.[${response._id}].id`, response._id);
      });
    }
  }, [order, setValue]);

  const onSubmit = async (data) => {
    dispatch(updateBriefResponses(clientId, orderId, data));
  };

  const handleGenrateBriefQuestions = () => {
    dispatch(genrateBriefQuestions(clientId, orderId));
  };

  return (
    <>
      {order && (
        <>
          <div className="pb-4">
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  <Link to="/clients">clients</Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/clients/${client?._id}/orders`}>{client?.companyName}</Link>
                </li>
                {order && (
                  <li
                    className="breadcrumb-item text-sm text-dark flex p-0"
                    aria-current="page"
                  >
                    <Dropdown className="dropdown-orders">
                      <Dropdown.Toggle id="dropdown-basic">
                        {order.product?.name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {client?.orders?.map((cl_order) => (
                          <Dropdown.Item
                            as={Link}
                            to={`/clients/${clientId}/orders/${cl_order._id}/brief_responses`}
                            key={cl_order._id}
                          >
                            {cl_order.product?.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                )}
                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  Cahier des charges
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-container">
            {order && (
              <OrderNavigation
                order={order}
                client={client}
                currentTab="brief_responses"
              />
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <section>
                <Row>
                  <Col className="d-flex flex-column mx-auto">
                    <div className="card medium-card mb-4 pb-2 purple-border">
                      <div className="card-header text-left">
                        <Col md={6}>
                          {order ? (
                            <h6>
                              <span className="product-title px-3 text-white">
                                Réponses du cahier des charges : {order?.product?.name}{" "}
                              </span>
                            </h6>
                          ) : (
                            <h6>
                              <span className="product-title px-3 text-white">
                                Nouveau projet
                              </span>
                            </h6>
                          )}
                        </Col>
                        <Col md={6}>
                          {order?.pdfUrl && (
                            <>
                              <div>
                                <label className="form-label">
                                  Cahier de charge actuel
                                </label>
                              </div>
                              <div
                                className="btn  btn-sm mb-0 btn-outline-primary me-2 mt-1"
                                onClick={handleDownload}
                              >
                                Télécharger le PDF
                              </div>
                            </>
                          )}
                        </Col>
                      </div>
                      <div className="card-body">
                        {order?.project_brief_responses?.length > 0 ? (
                          <>
                            {order?.project_brief_responses?.map((response) => (
                              <div key={response._id} className="mb-3">
                                <label
                                  htmlFor={`response-${response._id}`}
                                  className="form-label"
                                >
                                  {response.projectBriefQuestion.question}
                                </label>
                                <input
                                  type="hidden"
                                  className="form-control"
                                  {...register(
                                    `project_brief_responses[${response._id}].id`
                                  )}
                                />
                                <input
                                  type="text"
                                  id={`response-${response._id}`}
                                  className="form-control"
                                  {...register(
                                    `project_brief_responses[${response._id}].response`
                                  )}
                                />
                              </div>
                            ))}
                            <div className="">
                              <button
                                type="submit"
                                className="btn bg-gradient-dark w-30 mt-4 mb-0"
                              >
                                Enregistrer
                              </button>
                            </div>
                          </>
                        ) : (
                          <div>
                            <p>Aucune question n'a été créé pour cette commande</p>
                            {order.product.projectBriefQuestions.length > 0 && (
                              <span
                                className="prodotop-link"
                                onClick={handleGenrateBriefQuestions}
                                role="button"
                              >
                                Génerer le cahier de charge{" "}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Link to={`/clients/${clientId}/orders`} className="link-action back">
                      Retour
                    </Link>
                  </Col>
                </Row>
              </section>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default BriefReponsesForm;
