import axios from "axios";

const API_URL = "/api/emails";

const getAllEmails = () => {
  return axios.get(API_URL).then((response) => {
    return response.data;
  });
};

const getEmailById = (id) => {
  return axios.get(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};
const fetchDocuments = (docIds) => {
  return axios.get(`${API_URL}/documents/${docIds}`).then((response) => {
    return response.data;
  });
};

const createEmail = (data) => {
  return axios.post(API_URL, data).then((response) => {
    return response.data;
  });
};

const sendEmail = (emailData) => {
  return axios.post(`${API_URL}/send_email`, { emailData }).then((response) => {
    return response.data;
  });
};

const updateEmail = (id, data) => {
  return axios.put(`${API_URL}/${id}`, data).then((response) => {
    return response.data;
  });
};

const deleteEmail = (id) => {
  return axios.delete(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

export const emailService = {
  getAllEmails,
  getEmailById,
  createEmail,
  updateEmail,
  deleteEmail,
  sendEmail,
  fetchDocuments,
};
