import axios from "axios";

const API_URL = "/api/clients";

const getAllClients = (page = 1, limit = 20, data) => {
  return axios
    .post(`${API_URL}/all?page=${page}&limit=${limit}`, data)
    .then((response) => {
      return response.data;
    });
};
const fetchTinyClients = () => {
  return axios.get(`${API_URL}/tiny`).then((response) => {
    return response.data;
  });
};
const fetchClientEmployees = (clientId) => {
  return axios.get(`${API_URL}/${clientId}/fetch_employees`).then((response) => {
    return response.data;
  });
};
const createMeeting = (clientId, data) => {
  console.log("ixi");
  return axios.post(`${API_URL}/${clientId}/meeting`, data).then((response) => {
    return response.data;
  });
};

const fetchMeeting = (clientId, todoId) => {
  console.log("ixi");
  return axios.get(`${API_URL}/${clientId}/meeting_todo/${todoId}`).then((response) => {
    return response.data;
  });
};

const fetchAdsReports = () => {
  return axios.get(`${API_URL}/ads_reports`).then((response) => {
    return response.data;
  });
};

const fetchClientAdsReports = (id) => {
  return axios.get(`${API_URL}/${id}/ads_reports`).then((response) => {
    return response.data;
  });
};

const fetchGoogleMccs = (id) => {
  return axios.get(`${API_URL}/${id}/google_mccs`).then((response) => {
    return response.data;
  });
};
const fetchGoogleAccounts = (id, data) => {
  return axios.post(`${API_URL}/${id}/google_accounts`, data).then((response) => {
    return response.data;
  });
};

const fetchDashboardClients = (data) => {
  return axios.post(`${API_URL}/dashboard`, data).then((response) => {
    return response.data;
  });
};

const getClientById = (id) => {
  return axios.get(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};
const getclientsNotes = (id) => {
  return axios.get(`${API_URL}/${id}/notes`).then((response) => {
    return response.data;
  });
};

const addClientNote = (clientId, orderId, data) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/notes`, data)
    .then((response) => {
      return response.data;
    });
};

const estimateOrderCost = (clientId, orderId) => {
  return axios
    .get(`${API_URL}/${clientId}/orders/${orderId}/estimate`)
    .then((response) => {
      return response.data;
    });
};

const createClient = (data) => {
  return axios.post(API_URL, data).then((response) => {
    return response.data;
  });
};

const updateClient = (id, data) => {
  return axios.put(`${API_URL}/${id}`, data).then((response) => {
    return response.data;
  });
};

const deleteClient = (id) => {
  return axios.delete(`${API_URL}/${id}`).then((response) => {
    return response.data;
  });
};

const getAllOrdersByClientId = (clientId) => {
  return axios.get(`${API_URL}/${clientId}/orders`).then((response) => {
    return response.data;
  });
};

const getOrderById = (clientId, orderId) => {
  return axios.get(`${API_URL}/${clientId}/orders/${orderId}`).then((response) => {
    return response.data;
  });
};

const genrateQualityCriterions = (clientId, orderId) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/generate_quality_criterions`)
    .then((response) => {
      return response.data;
    });
};

const genrateBriefQuestions = (clientId, orderId) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/genrate_brief_questions`)
    .then((response) => {
      return response.data;
    });
};

const fetchOrderWithNotesById = (clientId, orderId) => {
  return axios
    .get(`${API_URL}/${clientId}/orders/${orderId}/with_notes`)
    .then((response) => {
      return response.data;
    });
};

const getOrderFbInsight = (clientId, orderId, data) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/fb_insight`, data)
    .then((response) => {
      return response.data;
    });
};
const fetchIaIterpretationFads = (clientId, orderId, data) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/fb_interpretation`, {
      insight: data.insight,
      iaPrompt: data.iaPrompt,
    })
    .then((response) => {
      return response.data;
    });
};

const getOrderGAInsight = (clientId, orderId, data) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/ga_insight`, data)
    .then((response) => {
      return response.data;
    });
};
const fetchIaIterpretationgGads = (clientId, orderId, data) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/gads_interpretation`, {
      insight: data.insight,
      iaPrompt: data.iaPrompt,
    })
    .then((response) => {
      return response.data;
    });
};
const getOrderGAdsInsight = (clientId, orderId, data) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/gads_insight`, data)
    .then((response) => {
      return response.data;
    });
};
const fetchIaIterpretationGa = (clientId, orderId, data) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/ga_interpretation`, {
      insight: data.insight,
      iaPrompt: data.iaPrompt,
    })
    .then((response) => {
      return response.data;
    });
};

const fetchLastNote = (clientId, orderId, noteTitle) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/last_note`, { noteTitle })
    .then((response) => {
      return response.data;
    });
};

const createClientOrder = (clientId, data) => {
  return axios.post(`${API_URL}/${clientId}/orders`, data).then((response) => {
    return response.data;
  });
};

const downloadPdf = (clientId, orderId) => {
  return axios
    .get(`${API_URL}/${clientId}/orders/${orderId}/pdf`, { responseType: "arraybuffer" })
    .then((response) => {
      return response.data;
    });
};

const updateClientOrder = (clientId, orderId, data) => {
  return axios.put(`${API_URL}/${clientId}/orders/${orderId}`, data).then((response) => {
    return response.data;
  });
};

const updateBriefResponses = (clientId, orderId, data) => {
  return axios
    .put(`${API_URL}/${clientId}/orders/${orderId}/brief_responses`, data)
    .then((response) => {
      return response.data;
    });
};

const updateQualityCriterionChecks = (clientId, orderId, data) => {
  return axios
    .put(`${API_URL}/${clientId}/orders/${orderId}/quality_checks`, data)
    .then((response) => {
      return response.data;
    });
};

const deleteOrderQualityCheck = (clientId, orderId, qualityId) => {
  return axios
    .delete(`${API_URL}/${clientId}/orders/${orderId}/quality_checks/${qualityId}`)
    .then((response) => {
      return response.data;
    });
};

const addQualityCriterionChecks = (clientId, orderId, data) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/quality_checks/`, data)
    .then((response) => {
      return response.data;
    });
};

const uploadDocument = (clientId, orderId, data) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/documents`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
};

const downloadDocument = (clientId, orderId, docId) => {
  return axios.get(`${API_URL}/${clientId}/orders/${orderId}/documents/${docId}`, {
    responseType: "blob",
  });
  // .then((response) => {
  //   return response.data;
  // });
};

const deleteClientOrder = (clientId, orderId) => {
  return axios.delete(`${API_URL}/${clientId}/orders/${orderId}`).then((response) => {
    return response.data;
  });
};

const createNewTicketForOrder = (clientId, orderId, data) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/tickets`, data)
    .then((response) => {
      return response.data;
    });
};

const updateTicket = (clientId, orderId, ticketId, data) => {
  return axios
    .put(`${API_URL}/${clientId}/orders/${orderId}/tickets/${ticketId}`, data)
    .then((response) => {
      return response.data;
    });
};

const convertToTicket = (clientId, orderId, ticketId, data) => {
  return axios
    .put(
      `${API_URL}/${clientId}/orders/${orderId}/tickets/${ticketId}/convert_ticket`,
      data
    )
    .then((response) => {
      return response.data;
    });
};

const fetchTicket = (clientId, orderId, ticketId) => {
  return axios
    .get(`${API_URL}/${clientId}/orders/${orderId}/tickets/${ticketId}`)
    .then((response) => {
      return response.data;
    });
};

const fetchTodosOrder = (clientId, orderId) => {
  return axios.get(`${API_URL}/${clientId}/orders/${orderId}/todos`).then((response) => {
    return response.data;
  });
};

const createTodosOrder = (clientId, orderId, data) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/todos`, data)
    .then((response) => {
      return response.data;
    });
};

const devideTask = (clientId, orderId, id, data) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/todos/${id}/devide`, data)
    .then((response) => {
      return response.data;
    });
};

const generateSurvey = (clientId, orderId) => {
  return axios
    .post(`${API_URL}/${clientId}/orders/${orderId}/generate-survey`)
    .then((response) => {
      return response.data;
    });
};
const fetchSurveyById = (clientId, orderId, surveyId) => {
  return axios
    .get(`${API_URL}/${clientId}/orders/${orderId}/surveys/${surveyId}`)
    .then((response) => {
      return response.data;
    });
};

const updateTodosOrder = (clientId, orderId, id, data) => {
  return axios
    .put(`${API_URL}/${clientId}/orders/${orderId}/todos/${id}`, data)
    .then((response) => {
      return response.data;
    });
};
const connectGoogle = (clientId) => {
  return axios.post(`${API_URL}/${clientId}/auth/google`).then((response) => {
    return response.data;
  });
};

const logoutGoogle = (clientId) => {
  return axios.post(`${API_URL}/${clientId}/auth/google/logout`).then((response) => {
    return response.data;
  });
};

export const clientService = {
  getAllClients,
  getClientById,
  createClient,
  updateClient,
  deleteClient,
  getAllOrdersByClientId,
  getOrderById,
  createClientOrder,
  updateClientOrder,
  deleteClientOrder,
  createNewTicketForOrder,
  updateTicket,
  fetchTicket,
  fetchTodosOrder,
  createTodosOrder,
  updateTodosOrder,
  convertToTicket,
  downloadPdf,
  devideTask,
  getclientsNotes,
  addClientNote,
  getOrderFbInsight,
  getOrderGAInsight,
  getOrderGAdsInsight,
  connectGoogle,
  logoutGoogle,
  generateSurvey,
  fetchTinyClients,
  fetchDashboardClients,
  uploadDocument,
  downloadDocument,
  updateBriefResponses,
  updateQualityCriterionChecks,
  fetchOrderWithNotesById,
  genrateQualityCriterions,
  genrateBriefQuestions,
  fetchGoogleMccs,
  fetchGoogleAccounts,
  deleteOrderQualityCheck,
  addQualityCriterionChecks,
  fetchAdsReports,
  fetchSurveyById,
  fetchClientAdsReports,
  fetchIaIterpretationFads,
  fetchIaIterpretationgGads,
  fetchIaIterpretationGa,
  fetchLastNote,
  estimateOrderCost,
  fetchClientEmployees,
  createMeeting,
  fetchMeeting,
};
