import React from "react";
import { faArrowRightFromBracket, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ExternalItem = ({ minutes, _id, product, task }) => {
  return (
    <div key={_id} className={`card card-body px-2 py-1 bg-gray-300 mb-3`} role="button">
      <div className="flex gap-1 justify-content-between">
        <div className="task-name px-3">
          <div>
            <b>{product}</b>
          </div>
          <div>
            <small>{minutes} minutes</small>
          </div>
          <div>{task}</div>
        </div>
      </div>
    </div>
  );
};

const UndraggedEvents = ({ setShowTasks, showTasks, undraggedEvents, height }) => {
  return (
    <div
      className="task-container d-grid bg-white p-3 rounded-3"
      style={{ height: `calc(${height}px - 150px)` }}
    >
      {!showTasks && (
        <span
          onClick={() => setShowTasks(!showTasks)}
          className="p-2 text-end"
          role="button"
        >
          {<FontAwesomeIcon icon={faArrowRightFromBracket} />}
        </span>
      )}
      {showTasks && (
        <>
          <div className="tasks">
            <div className="mb-4">
              {showTasks && (
                <div
                  onClick={() => setShowTasks(!showTasks)}
                  className="px-2 text-end"
                  role="button"
                >
                  {<FontAwesomeIcon icon={faXmark} />}
                </div>
              )}
              <h6 className="flex gap-1">
                <span
                  className="product-title bg-primary px-2 flex align-item-center text-white rounded-1"
                  role="button"
                >
                  Tâches à venir
                </span>
              </h6>
            </div>
            <div className="mb-5">
              <div className="mb-5">
                {undraggedEvents?.map((todoEvent) => (
                  <ExternalItem
                    key={todoEvent._id}
                    product={todoEvent.product?.name}
                    minutes={todoEvent.minutes}
                    task={`${todoEvent.shared ? todoEvent.task : ""} `}
                    _id={todoEvent._id}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="task-explanation py-2">
            <div>
              <span className="box-task todo-task FLHB"></span>
              <span>Flexibilité Hebdomadaire</span>
            </div>
            <div>
              <span className="box-task todo-task FLDY"></span>
              <span>Flexibilité Journalière</span>
            </div>
            <div>
              <span className="box-task todo-task FLZR"></span>
              <span>Flexibilité Zéro</span>
            </div>
            <div>
              <span className="box-task done-task"></span>
              <span>tâche réalisé</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UndraggedEvents;
