import axios from "axios";

const BASE_URL = "/api/products";

// Service pour gérer les critères de qualité
export const qualityCriterionService = {
  getProductQualityCriterions: async (productId) => {
    const response = await axios.get(`${BASE_URL}/${productId}/quality_criterions`);
    return response.data;
  },
  // Créer un nouveau critère de qualité pour un produit donné
  createQualityCriterion: async (productId, qualityCriterionData) => {
    const response = await axios.post(
      `${BASE_URL}/${productId}/quality_criterions`,
      qualityCriterionData
    );
    return response.data;
  },

  // Modifier un critère de qualité pour un produit donné
  updateQualityCriterion: async (productId, qualityCriterionId, qualityCriterionData) => {
    const response = await axios.put(
      `${BASE_URL}/${productId}/quality_criterions/${qualityCriterionId}`,
      qualityCriterionData
    );
    return response.data;
  },

  // Supprimer un critère de qualité pour un produit donné
  deleteQualityCriterion: async (productId, qualityCriterionId) => {
    await axios.delete(
      `${BASE_URL}/${productId}/quality_criterions/${qualityCriterionId}`
    );
  },
};
