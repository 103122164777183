import { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addQualityCriterion,
  updateQualityCriterion,
  fetchQualityCriterions,
} from "../../../redux/actions/qualityCriterionsActions";
import { fetchProducts } from "../../../redux/actions/productActions";
import { Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../NavContext";

const QualityCriterionForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeNav, setActiveNav } = useContext(NavContext);
  const { productId, id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    setActiveNav("products");
  }, [setActiveNav]);

  const qualityCriterions = useSelector(
    (state) => state.qualityCriterionReducer.qualityCriterions
  );
  const qualityCriterion = qualityCriterions.find((criterion) => criterion._id === id);

  const products = useSelector((state) => state.productReducer.products);
  const isFetching = useSelector((state) => state.productReducer.isFetching);
  const product = products && products.find((product) => product._id === productId);

  useEffect(() => {
    if (!isFetching) {
      dispatch(fetchProducts());
    }
  }, [dispatch, product, reset, isFetching]);

  useEffect(() => {
    dispatch(fetchQualityCriterions(productId));
  }, []);

  useEffect(() => {
    if (qualityCriterion) {
      reset({
        name: qualityCriterion.name,
        description: qualityCriterion.description,
      });
    }
  }, [qualityCriterion, reset]);

  const onSubmit = (data) => {
    if (qualityCriterion) {
      dispatch(updateQualityCriterion(productId, qualityCriterion._id, data)).then(() => {
        dispatch(fetchProducts());
      });
    } else {
      dispatch(addQualityCriterion(productId, data)).then(() => {
        dispatch(fetchProducts());
      });
    }

    navigate(`/products/${productId}`);
    reset();
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to="/products">Produits</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark" aria-current="page">
              <Link to={`/products/${productId}`}>{product?.name}</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              {qualityCriterion
                ? "Modification du critère de qualité"
                : "Nouveau critère de qualité"}
            </li>
          </ol>
        </nav>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <Row>
            <Col className="d-flex flex-column mx-auto">
              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-header text-left">
                  <Col md={6}>
                    {qualityCriterion ? (
                      <h6>
                        <span className="product-title px-3 text-white">
                          Modification du critère de qualité
                        </span>
                      </h6>
                    ) : (
                      <h6>
                        <span className="product-title px-3 text-white">
                          Nouveau critère de qualité
                        </span>
                      </h6>
                    )}
                  </Col>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Nom du critère de qualité :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <div className="text-danger">Nom du critère de qualité requis</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description :
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      rows="3"
                      {...register("description", { required: true })}
                    ></textarea>
                    {errors.description && (
                      <div className="text-danger">Description requise</div>
                    )}
                  </div>
                  <div className="">
                    <button type="submit" className="btn bg-gradient-dark w-30 mt-4 mb-0">
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Link to={`/products/${productId}`} className="link-action back">
                Retour
              </Link>
            </Col>
          </Row>
        </section>
      </form>
    </>
  );
};

export default QualityCriterionForm;
