import {
  CLI_FETCH_ECALENDARS_REQUEST,
  CLI_FETCH_ECALENDARS_SUCCESS,
  CLI_FETCH_ECALENDARS_FAILURE,
  CLI_ADD_ECALENDAR_REQUEST,
  CLI_ADD_ECALENDAR_SUCCESS,
  CLI_ADD_ECALENDAR_FAILURE,
  CLI_UPDATE_ECALENDAR_REQUEST,
  CLI_UPDATE_ECALENDAR_SUCCESS,
  CLI_UPDATE_ECALENDAR_FAILURE,
  CLI_DELETE_ECALENDAR_REQUEST,
  CLI_DELETE_ECALENDAR_SUCCESS,
  CLI_DELETE_ECALENDAR_FAILURE,
  CLI_FETCH_ECALENDAR_REQUEST,
  CLI_FETCH_ECALENDAR_SUCCESS,
  CLI_FETCH_ECALENDAR_FAILURE,
  CLI_FETCH_POSTS_REQUEST,
  CLI_FETCH_POSTS_SUCCESS,
  CLI_FETCH_POSTS_FAILURE,
  CLI_DELETE_POST_REQUEST,
  CLI_DELETE_POST_SUCCESS,
  CLI_DELETE_POST_FAILURE,
  CLI_POST_ADD_REQUEST,
  CLI_POST_ADD_SUCCESS,
  CLI_POST_ADD_FAILURE,
  CLI_POST_UPDATE_REQUEST,
  CLI_POST_UPDATE_SUCCESS,
  CLI_POST_UPDATE_FAILURE,
  CLI_FETCH_POST_REQUEST,
  CLI_FETCH_POST_SUCCESS,
  CLI_FETCH_POST_FAILURE,
  CLI_FETCH_CALENDAR_POSTS_SUCCESS,
} from "../../clientActionTypes";

import { eCalendarService } from "../../services/cli/eCalendarService";
import { toast } from "react-toastify";

export const fetchCalendarPostsSuccess = (data) => {
  const dragged_posts = data.filter((post) => post.date);
  const undragged_posts = data.filter((post) => !post.date);
  return {
    type: CLI_FETCH_CALENDAR_POSTS_SUCCESS,
    payload: { dragged_posts, undragged_posts },
  };
};

export const addToDraggedPosts = (data) => {
  return {
    type: "CLI_ADD_TO_DRAGGED_POSTS",
    payload: data,
  };
};
export const removeFromUndraggedPosts = (data) => {
  return {
    type: "CLI_REMOVE_FROM_UNDRAGGED_POSTS",
    payload: data,
  };
};
export const fetchCalendarPosts = (orderId, eCalendarId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_POSTS_REQUEST });
    try {
      eCalendarService.getPosts(orderId, eCalendarId).then((data) => {
        dispatch(fetchCalendarPostsSuccess(data));
      });
    } catch (err) {
      console.log("error", err);
      dispatch({
        type: CLI_FETCH_POSTS_FAILURE,
        payload: err.message,
      });
    }
  };
};

export const fetchECalendars = (orderId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_ECALENDARS_REQUEST });
    try {
      eCalendarService.getECalendars(orderId).then((data) => {
        dispatch({
          type: CLI_FETCH_ECALENDARS_SUCCESS,
          payload: data,
        });
      });
    } catch (err) {
      console.log("error", err);
      dispatch({
        type: CLI_FETCH_ECALENDARS_FAILURE,
        payload: err.message,
      });
    }
  };
};
export const fetchECalendarById = (orderId, eCalendarId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_ECALENDAR_REQUEST });
    try {
      eCalendarService.getECalendar(orderId, eCalendarId).then((data) => {
        dispatch({
          type: CLI_FETCH_ECALENDAR_SUCCESS,
          payload: data,
        });
      });
    } catch (err) {
      console.log("error", err);
      dispatch({
        type: CLI_FETCH_ECALENDAR_FAILURE,
        payload: err.message,
      });
    }
  };
};

export const addECalendar = (orderId, eCalendarData) => {
  return async (dispatch) => {
    dispatch({ type: CLI_ADD_ECALENDAR_REQUEST });
    try {
      const newECalendar = await eCalendarService.createECalendar(orderId, eCalendarData);
      dispatch({ type: CLI_ADD_ECALENDAR_SUCCESS, payload: newECalendar });
    } catch (err) {
      dispatch({ type: CLI_ADD_ECALENDAR_FAILURE, payload: err.message });
    }
  };
};
export const notifyCommunity = (orderId, eCalendarId, postId) => {
  return async (dispatch) => {
    try {
      const notified = await eCalendarService.notifyCommunity(
        orderId,
        eCalendarId,
        postId
      );
      if (notified.status === 200) {
        toast.success("Une notification a été envoyé au Community manager");
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateECalendar = (orderId, eCalendarId, eCalendarData) => {
  return async (dispatch) => {
    dispatch({ type: CLI_UPDATE_ECALENDAR_REQUEST });
    try {
      const updatedECalendar = await eCalendarService.updateECalendar(
        orderId,
        eCalendarId,
        eCalendarData
      );
      dispatch({
        type: CLI_UPDATE_ECALENDAR_SUCCESS,
        payload: updatedECalendar,
      });
    } catch (err) {
      dispatch({ type: CLI_UPDATE_ECALENDAR_FAILURE, payload: err.message });
    }
  };
};

export const deleteECalendar = (orderId, eCalendarId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_DELETE_ECALENDAR_REQUEST });
    try {
      await eCalendarService.deleteECalendar(orderId, eCalendarId);
      dispatch({
        type: CLI_DELETE_ECALENDAR_SUCCESS,
        payload: eCalendarId,
      });
    } catch (err) {
      dispatch({ type: CLI_DELETE_ECALENDAR_FAILURE, payload: err.message });
    }
  };
};

export const deleteImagePost = (orderId, eCalendarId, postId, photoId) => {
  return async (dispatch) => {
    try {
      eCalendarService
        .deleteImagePost(orderId, eCalendarId, postId, photoId)
        .then((data) => {
          dispatch({ type: CLI_POST_UPDATE_SUCCESS, payload: data });
        });
    } catch (err) {}
  };
};

export const fetchPostById = (orderId, eCalendarId, postId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_POST_REQUEST });
    try {
      eCalendarService.getPostById(orderId, eCalendarId, postId).then((data) => {
        dispatch({ type: CLI_FETCH_POST_SUCCESS, payload: data });
      });
    } catch (err) {
      dispatch({ type: CLI_FETCH_POST_FAILURE, payload: err.message });
    }
  };
};

export const fetchPostsSuccess = (data) => {
  return {
    type: CLI_FETCH_POSTS_SUCCESS,
    payload: data,
  };
};

export const fetchPosts = (orderId, eCalendarId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_FETCH_POSTS_REQUEST });
    try {
      eCalendarService.getPosts(orderId, eCalendarId).then((data) => {
        dispatch(fetchPostsSuccess(data));
      });
    } catch (err) {
      console.log("error", err);
      dispatch({
        type: CLI_FETCH_POSTS_FAILURE,
        payload: err.message,
      });
    }
  };
};

export const deletePost = (orderId, eCalendarId, postId) => {
  return async (dispatch) => {
    dispatch({ type: CLI_DELETE_POST_REQUEST });
    try {
      await eCalendarService.deletePost(orderId, eCalendarId, postId);
      dispatch({
        type: CLI_DELETE_POST_SUCCESS,
        payload: postId,
      });
    } catch (err) {
      dispatch({ type: CLI_DELETE_POST_FAILURE, payload: err.message });
    }
  };
};

export const addPost = (orderId, eCalendarId, formData) => {
  return async (dispatch) => {
    dispatch({ type: CLI_POST_ADD_REQUEST });

    eCalendarService
      .addPost(orderId, eCalendarId, formData)
      .then((data) => {
        dispatch({ type: CLI_POST_ADD_SUCCESS, payload: data });
        toast.success("Post créé avec succès");
      })
      .catch((error) => {
        dispatch({
          type: CLI_POST_ADD_FAILURE,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};

export const updatePost = (orderId, eCalendarId, postId, formData) => {
  return async (dispatch) => {
    dispatch({ type: CLI_POST_UPDATE_REQUEST });

    eCalendarService
      .updatePost(orderId, eCalendarId, postId, formData)
      .then((data) => {
        dispatch({ type: CLI_POST_UPDATE_SUCCESS, payload: data });
        toast.success("Post modifié avec succès");
      })
      .catch((error) => {
        dispatch({
          type: CLI_POST_UPDATE_FAILURE,
          payload: error.response?.data?.message || error.message,
        });
      });
  };
};
export const updatePriorities = (orderId, eCalendarId, data) => {
  return async (dispatch) => {
    eCalendarService
      .updatePriorities(orderId, eCalendarId, data)
      .then((data) => {
        console.log("Success");
      })
      .catch((error) => {
        console.log("Failure", error);
      });
  };
};
