import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList } from "recharts";
import {
  fetchsUserSurveyStat,
  fetchsUserEfficiencyStat,
  fetchsUserWorkloadStat,
} from "../../../redux/actions/userActions";
import { Row, Col, ProgressBar } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../NavContext";
const UserStats = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { activeNav, setActiveNav } = useContext(NavContext);
  useEffect(() => {
    setActiveNav("users");
  }, [setActiveNav]);
  const user = useSelector((state) => state.userReducer.userSurveyStats);
  // const user_efficiency = useSelector((state) => state.userReducer.userEfficiencyStats);
  const user_workload = useSelector((state) => state.userReducer.userWorkloadStats);
  const currentUser = useSelector((state) => state.authReducer.user.user);

  const BOSS = currentUser.role === "boss";
  const MANAGER = currentUser.role === "manager";
  const EMPLOYEE = currentUser._id === user._id;
  const AUTHORIZED = BOSS || MANAGER || EMPLOYEE;

  useEffect(() => {
    document.title = "ProdOTop - stats - " + user?.name;
  }, [user]);

  useEffect(() => {
    dispatch(fetchsUserSurveyStat(id));
    // dispatch(fetchsUserEfficiencyStat(id));
    dispatch(fetchsUserWorkloadStat(id));
  }, [dispatch, id]);

  if (!AUTHORIZED) {
    return (
      <h6>
        <span className="product-title px-3 text-dark">Accès à la page non autorisé</span>
      </h6>
    );
  }

  const RatingStars = ({ rating }) => {
    const totalStars = 5;
    let stars = [];

    for (let i = 0; i < totalStars; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<span className="star full" key={`star_${i}`}></span>);
      } else if (i < rating) {
        stars.push(<span className="star half" key={`star_${i}`}></span>);
      } else {
        stars.push(<span className="star empty" key={`star_${i}`}></span>);
      }
    }

    return <div className="rating-stars">{stars}</div>;
  };

  const workloadData = (data) => {
    const workloadData = [
      { name: "Travail planifier", plannedTime: (data.plannedTime / 60).toFixed(1) },
      { name: "Travail dans la Pipe ", pipeTime: (data.pipeTime / 60).toFixed(1) },
      { name: "Travail à valider", predictedTime: (data.predictedTime / 60).toFixed(1) },
    ];
    return workloadData;
  };

  // AYB : add Loading logique

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              <Link to="/users">Utilisateurs</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              Stats
            </li>
          </ol>
        </nav>
      </div>

      {user && (
        <section>
          <Row>
            <Col className="d-flex flex-column mx-auto">
              <div className="card medium-card mb-4 pb-2 purple-border">
                <div className="card-header text-left">
                  <Col md={12}>
                    <h6>Stats {user.name}</h6>
                  </Col>
                </div>
                <div className="card-body">
                  <div className="flex gap-3">
                    <div>
                      <h6>Note client</h6>
                    </div>
                    <div>
                      {user.averageRating ? (
                        <RatingStars rating={user.averageRating} />
                      ) : (
                        <RatingStars rating={0} />
                      )}
                    </div>
                  </div>
                  {/* <div className="flex gap-3">
                    <div>
                      <h6>rentabilité</h6>
                    </div>
                    <div style={{ width: "300px" }}>
                      <div className="pb-3 px-3 profitability">
                        <ProgressBar
                          now={Math.round(user_efficiency.efficiencyPercentage)}
                          label={`${Math.round(user_efficiency.efficiencyPercentage)}%`}
                          min-width="300"
                          className={`status-progress ${
                            user_efficiency.efficiencyPercentage > 100 ? "danger" : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div>
                    <div>
                      <h6>
                        Charge de travail en heure :{" "}
                        {user_workload &&
                          (
                            (user_workload.plannedTime +
                              user_workload.pipeTime +
                              user_workload.predictedTime) /
                            60
                          ).toFixed(1)}{" "}
                      </h6>
                    </div>
                    {user_workload && (
                      <BarChart
                        width={600}
                        height={300}
                        data={workloadData(user_workload)}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Bar
                          dataKey="plannedTime"
                          fill="#57cc99"
                          name="Temps prévu - planifié"
                          stackId="minutes"
                        >
                          <LabelList
                            dataKey="plannedTime"
                            position="inside"
                            fill="#000"
                          />
                        </Bar>
                        <Bar
                          dataKey="pipeTime"
                          fill="#c4d6b0"
                          name="Temps imprévu - réalisé"
                          stackId="minutes"
                        >
                          <LabelList dataKey="pipeTime" position="inside" fill="#000" />
                        </Bar>
                        <Bar
                          dataKey="predictedTime"
                          fill="#f8dda4"
                          name="Temps imprévu - planifié"
                          stackId="minutes"
                        >
                          <LabelList
                            dataKey="predictedTime"
                            position="inside"
                            fill="#000"
                          />
                        </Bar>
                      </BarChart>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      )}
    </>
  );
};

export default UserStats;
