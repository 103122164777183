import React, { useEffect, useContext, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Dropdown } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import {
  updateTicket,
  convertToTicket,
  fetchTicket,
  fetchOrderById,
  fetchClientOrders,
} from "../../../../../redux/actions/clientActions";
import { Link } from "react-router-dom";
import { NavContext } from "../../../../NavContext";
import ExpandedContent from "./ExpandedContent";
import parse from "html-react-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import OrderNavigation from "../order/orderNavigation";
import { useWindowDimensions } from "./useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const EditTicketForm = () => {
  const scrollableRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();

  const { clientId, orderId, ticketId } = useParams();
  const { activeNav, setActiveNav } = useContext(NavContext);
  const ticket = useSelector((state) => state.clientReducer.ticket);
  const order = useSelector((state) => state.clientReducer.order);
  const currentUser = useSelector((state) => state.authReducer.user.user);
  const client = useSelector((state) => state.clientReducer.client);
  const [layoutId, setLayoutId] = useState(null);
  const [data, setData] = useState(null);
  const [showConvert, setShowConvert] = useState(false);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientOrders(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);
  const MANAGER = currentUser.role === "manager";

  const teamPrefixes = ["Team1", "Team2", "Team3", "Team4", "Team5"];
  const checkAuthorization = (team) => {
    return (
      currentUser.departement === team &&
      (MANAGER || currentUser._id === order?.[team.toLowerCase() + "Responsible"]?._id)
    );
  };

  const AUTHORIZED = teamPrefixes.some((team) => checkAuthorization(team));

  useEffect(() => {
    document.title =
      "ProdOTop - demande :" +
      ticket?.subject +
      " de " +
      order?.product?.name +
      " du client " +
      client?.companyName;
  }, [client, order, ticket]);

  useEffect(() => {
    if (clientId && orderId) {
      dispatch(fetchOrderById(clientId, orderId));
    }
  }, [dispatch, clientId, orderId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchTicket(clientId, orderId, ticketId));
    }
  }, [dispatch, clientId, orderId, ticketId]);

  const onSubmit = (data) => {
    if (ticket) {
      dispatch(updateTicket(clientId, orderId, ticket._id, data));
    }
    reset({
      "ticket.new_discussion.question": "",
    });
    //navigate(`/clients/${clientId}/orders/${orderId}/tickets`);
  };

  const onSubmitConvert = (data) => {
    dispatch(convertToTicket(clientId, orderId, ticket._id, data));
    reset();
    navigate(`/calendar/new_ticket`);
  };

  useEffect(() => {
    if (ticket) {
      setValue("ticket.closed", ticket.closed);
    }
  }, [ticket, setValue]);

  const ISSUES = {
    REQAM: "Demande d’amélioration",
    INFINC: "Information incompléte",
    PAYPRO: "Probléme de paiement",
    CONMAN: "Contenu manquant",
    PROEXT: "Problème externe",
    RDV: "Demande de rendez-vous",
    DVR: "Divers",
  };

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }
  }, [scrollableRef.current]);

  return (
    <>
      {order && (
        <>
          <div className="pb-4">
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  <Link to="/clients">clients</Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/clients/${client?._id}/orders`}>{client?.companyName}</Link>
                </li>
                {order && (
                  <li
                    className="breadcrumb-item text-sm text-dark flex p-0"
                    aria-current="page"
                  >
                    <Dropdown className="dropdown-orders">
                      <Dropdown.Toggle id="dropdown-basic">
                        {order.product?.name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {client?.orders?.map((cl_order) => (
                          <Dropdown.Item
                            as={Link}
                            to={`/clients/${clientId}/orders/${cl_order._id}/tickets`}
                            key={cl_order._id}
                          >
                            {cl_order.product?.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                )}
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/clients/${clientId}/orders/${orderId}/tickets`}>
                    Demandes
                  </Link>
                </li>
                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  {ticket?.subject}
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-container">
            {order && (
              <OrderNavigation order={order} client={client} currentTab="tickets" />
            )}
            <Row className="row">
              <Col md={12}>
                <div className="card medium-card mb-4 pb-2 purple-border">
                  <div className="card-header  mb-3">
                    <div className=" flex gap-2">
                      {ticket && (
                        <h6 className="mb-0 text-dark">
                          {" "}
                          sujet : {ticket.subject}
                          {ticket.shared && (
                            <FontAwesomeIcon
                              icon={faUsers}
                              className="px-2 purple-color"
                            />
                          )}
                        </h6>
                      )}
                      {!ticket?.converted && (
                        <div className="flex p-0 gap-3">
                          <div>
                            <span
                              className="badge badge-sm bg-gradient-warning"
                              role="button"
                              onClick={() => setShowConvert(!showConvert)}
                            >
                              convertir en TODO
                            </span>
                          </div>
                          <div className="flex p-0">
                            {showConvert && (
                              <FormConvert onSubmitConvert={onSubmitConvert} />
                            )}
                          </div>
                        </div>
                      )}
                      {ticket?.converted && (
                        <span className="badge badge-sm bg-gradient-success">
                          Dans vos tâches
                        </span>
                      )}
                    </div>
                    <small className="text-dark">{ISSUES[ticket.issue]}</small>
                  </div>
                  <div className="card-body px-4 pt-0 py-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div
                        style={{ height: `calc(${height}px - 560px)` }}
                        className="discussion-section"
                        ref={scrollableRef}
                      >
                        {ticket &&
                          ticket.ticket_discussions.map((td, index) => {
                            return (
                              <Row className="xmb-5" key={td._id}>
                                <Col xs={12} className="mb-4 pull-rigth">
                                  <div
                                    className={`border-left-3  p-4  w-50  h-220 bg-white answer-response ${
                                      td.askedBy?._id === currentUser._id
                                        ? "border-primary"
                                        : "ms-10 border-info"
                                    }`}
                                    role="button"
                                    onClick={() => {
                                      setData(td.question);
                                      setLayoutId(td._id);
                                    }}
                                  >
                                    <div className="mb-2 flex justify-content-between">
                                      <h6>
                                        <span
                                          className={`text-sm text-${
                                            td.askedBy?._id === currentUser._id
                                              ? "primary"
                                              : "info"
                                          } text-gradient`}
                                        >
                                          {td.askedBy?.name}
                                        </span>
                                      </h6>
                                      <span className="text-xs">
                                        {moment(td.createdAt).format("YYYY-MM-DD HH:mm")}
                                      </span>
                                    </div>

                                    <div className="mb-2 text-xs">
                                      {parse(td.question)}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                      </div>

                      <div>
                        <Row>
                          <Col mx={9}>
                            <div>
                              <label htmlFor="subject" className="form-label"></label>
                              <Controller
                                name={`ticket.new_discussion.question`}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={field.value}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      field.onChange(data);
                                    }}
                                    placeholder="Nouveau message ..."
                                    disabled={!AUTHORIZED}
                                  />
                                )}
                              />
                            </div>
                            {errors?.ticket?.new_discussion?.question &&
                              errors?.ticket?.new_discussion?.question.message}
                          </Col>
                          <Col md={3}>
                            <div className="mt-3">
                              {ticket && (
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkbox"
                                    {...register(`ticket.closed`)}
                                  />
                                  <label className="form-check-label" htmlFor="checkbox">
                                    Clôturer ?
                                  </label>
                                </div>
                              )}
                              <div className="">
                                <button
                                  type="submit"
                                  className="btn bg-gradient-dark w-100 mt-2 mb-0"
                                >
                                  Enregistrer
                                </button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  </div>
                </div>
              </Col>
              <Col>
                <Link
                  to={`/clients/${clientId}/orders/${orderId}/tickets`}
                  className="link-action back"
                >
                  Retour
                </Link>
              </Col>
            </Row>
          </div>
          {layoutId && (
            <ExpandedContent
              key={layoutId}
              layoutId={layoutId}
              data={data}
              setData={setData}
            />
          )}
        </>
      )}
    </>
  );
};

const FormConvert = ({ onSubmitConvert }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmitConvert)} className="flex gap-3 p-0">
      <div className="m-0 flex gap-3 p-0">
        <input
          type="number"
          className="form-control devid-input"
          id="question"
          min={15}
          max={480}
          step={15}
          {...register("minutes", { required: true })}
          placeholder="15, 30, 45 ... 480"
        />
        {errors?.minutes && <div className="text-danger">temps requis !</div>}
      </div>
      <div className="">
        <button type="submit" className="btn bg-gradient-dark mb-0">
          Convertir
        </button>
      </div>
    </form>
  );
};

export default EditTicketForm;
