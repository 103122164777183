import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { motion, AnimatePresence } from "framer-motion";

// Cette fonction calcule le pourcentage total de différence pour tous les produits
function calculateTotalPercentageDifference(data) {
  const processedData = {};
  let totalHours = 0;
  let totalWorkHours = 0;

  data.forEach((item) => {
    const product = item.product.name;
    const client = item.client.companyName;
    const workHours = item.product.workHours;
    const hours = calculateHours(item.start, item.end);

    if (!processedData[product]) {
      processedData[product] = {};
    }

    if (!processedData[product][client]) {
      processedData[product][client] = {
        hours: 0,
        workHours: 0,
      };
    }

    processedData[product][client].hours += hours;
    processedData[product][client].workHours = workHours;
  });

  Object.keys(processedData).map((product) => {
    const productData = {
      name: product,
    };
    Object.keys(processedData[product]).forEach((client) => {
      totalHours += processedData[product][client].hours;
      totalWorkHours += processedData[product][client].workHours;
    });
  });

  const totalDifference = totalHours - totalWorkHours;
  const percentageDifference = (totalDifference / totalWorkHours) * 100;

  return percentageDifference;
}

// Cette fonction calcule le nombre d'heures passées sur chaque tâche.
function calculateHours(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  return (endDate - startDate) / (1000 * 60 * 60);
}
// Cette fonction transforme les données brutes en un format utilisable par le graphique.
function processData(data) {
  const processedData = {};

  data.forEach((item) => {
    const product = item.product.name;
    const client = item.client.companyName;
    const workHours = item.product.workHours;
    const hours = calculateHours(item.start, item.end);

    if (!processedData[product]) {
      processedData[product] = {};
    }

    if (!processedData[product][client]) {
      processedData[product][client] = {
        hours: 0,
        workHours: 0,
      };
    }

    processedData[product][client].hours += hours;
    processedData[product][client].workHours = workHours;
  });

  return Object.keys(processedData).map((product) => {
    const productData = {
      name: product,
    };
    Object.keys(processedData[product]).forEach((client) => {
      productData[" heures contrat"] = processedData[product][client].workHours;
      productData[" heures presté"] = processedData[product][client].hours;
    });
    return productData;
  });
}

export default function Chart({ rawData, closeModal, clientName }) {
  // Utilisez cette fonction pour calculer le pourcentage total de différence
  const data = processData(rawData);
  const totalPercentageDifference = calculateTotalPercentageDifference(rawData);
  const colors = ["#82ca9d", "#8884d8"];
  return (
    <AnimatePresence key={1}>
      <motion.div
        key={1}
        onClick={closeModal}
        className="modal-bg"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.7,
        }}
      ></motion.div>
      <motion.div
        key={2}
        className="expanded-modal chart-modal"
        layout
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.7,
        }}
      >
        <BarChart
          width={900}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {Object.keys(data[0])
            .filter((key) => key !== "name")
            .map((key, index) => (
              <Bar key={key} dataKey={key} fill={colors[index % colors.length]} />
            ))}
        </BarChart>
        <div
          style={{
            width: "400px",
            backgroundColor: "#82ca9d",
            padding: "0.5em 5px",
            position: "relative",
            top: "15px",
            margin: "2em 1em",
          }}
        >
          <div
            style={{
              width: `${400 * ((100 + totalPercentageDifference) / 100)}px`,
              backgroundColor: "#8884d8",
              height: "2em",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            {(100 + totalPercentageDifference).toFixed(2)}%
          </div>
        </div>
        <div
          style={{
            margin: "0 1em",
          }}
        >
          {clientName}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
