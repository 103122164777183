export const responsiblesFormat = (order) => {
  const responsibleNames = [
    order?.team1Responsible?.name,
    order?.team3Responsible?.name,
    order?.team2Responsible?.name,
    order?.team4Responsible?.name,
    order?.team5Responsible?.name,
  ];
  const nonEmptyNames = responsibleNames.filter((name) => name);
  return nonEmptyNames.join("/");
};

export const teamFormat = (todo) => {
  if (todo.for_team1) return "Team1";
  if (todo.for_team2) return "Team2";
  if (todo.for_team3) return "Team3";
  if (todo.for_team4) return "Team4";
  if (todo.for_team5) return "Team5";
  return "";
};
