import {
  FETCH_EMAILS_REQUEST,
  FETCH_EMAILS_SUCCESS,
  FETCH_EMAILS_FAILURE,
  DELETE_EMAIL,
  FETCH_EMAIL_REQUEST,
  FETCH_EMAIL_SUCCESS,
  FETCH_EMAIL_FAILURE,
  ADD_EMAIL_REQUEST,
  ADD_EMAIL_SUCCESS,
  ADD_EMAIL_FAILURE,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  FETCH_MULTI_DOCUMENTS_REQUEST,
  FETCH_MULTI_DOCUMENTS_SUCCESS,
  FETCH_MULTI_DOCUMENTS_FAILURE,
} from "../actionTypes";

const initialState = {
  isFetchingEmail: false,
  isFetchingEmails: false,
  emails: [],
  email: false,
  documents: false,
  errorMessage: "",
};

const emailReducer = (state = initialState, action) => {
  // instead of uploading emails every time an email is updated, i have to manage this with state later
  switch (action.type) {
    case FETCH_MULTI_DOCUMENTS_REQUEST:
      return {
        ...state,
        errorMessage: "",
      };
    case FETCH_MULTI_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        errorMessage: "",
      };
    case FETCH_MULTI_DOCUMENTS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case FETCH_EMAILS_REQUEST:
      return {
        ...state,
        isFetchingEmails: true,
      };
    case FETCH_EMAILS_SUCCESS:
      return {
        ...state,
        isFetchingEmails: true,
        emails: action.payload,
        errorMessage: "",
      };
    case FETCH_EMAILS_FAILURE:
      return {
        ...state,
        isFetchingEmails: false,
        emails: [],
        errorMessage: action.payload,
      };
    case DELETE_EMAIL:
      return {
        ...state,
        emails: state.emails.filter((email) => email._id !== action.payload),
      };
    case FETCH_EMAIL_REQUEST:
      return {
        ...state,
        isFetchingEmail: true,
      };
    case ADD_EMAIL_REQUEST:
    case UPDATE_EMAIL_REQUEST:
      return {
        ...state,
        errorMessage: null,
      };
    case FETCH_EMAIL_SUCCESS:
      return {
        ...state,
        isFetchingEmail: true,
        email: action.payload,
        errorMessage: "",
      };
    case ADD_EMAIL_SUCCESS:
    case UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        email: action.payload,
        errorMessage: null,
        isFetchingEmails: false,
      };
    case FETCH_EMAIL_FAILURE:
      return {
        ...state,
        isFetchingEmail: false,
      };
    case ADD_EMAIL_FAILURE:
    case UPDATE_EMAIL_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default emailReducer;
