import { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  updateUser,
  fetchUsers,
  updatePassword,
} from "../../../../redux/actions/userActions";
import { fetchTinyClients } from "../../../../redux/actions/clientActions";
import { Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../../NavContext";
import DepartmentName from "../../../shared/departments/departmentName";

const UserForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editPassword, setEditPassword] = useState(false);
  const { id } = useParams();
  const users = useSelector((state) => state.userReducer.users);
  const isFetching = useSelector((state) => state.userReducer.isFetching);
  const currentUser = useSelector((state) => state.authReducer.user.user);
  const user = users.find((user) => user._id === id);

  const BOSS = currentUser.role === "boss";
  const MANAGER = currentUser.role === "manager";

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(); // Formulaire 1

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    reset: resetPassword,
    formState: { errors: errorsPassword },
  } = useForm();

  const { activeNav, setActiveNav } = useContext(NavContext);
  useEffect(() => {
    setActiveNav("users");
  }, [setActiveNav]);

  useEffect(() => {
    document.title = "ProdOTop - Utilisateur - " + user?.name;
  }, [user]);

  useEffect(() => {
    dispatch(fetchTinyClients());
  }, [dispatch]);

  useEffect(() => {
    if (!isFetching) {
      dispatch(fetchUsers());
    }
    if (user) {
      reset({
        name: user.name,
        email: user.email,
        role: user.role,
        departement: user.departement,
        user_type: user.user_type,
        weekly_hours: user.weekly_hours,
        can_create_todo: user.can_create_todo,
        use_template: user.use_template,
        can_resize: user.can_resize,
        cost: user.cost,
      });
    }
  }, [dispatch, user, reset, isFetching]);

  const onSubmit = (data) => {
    if (user) {
      dispatch(updateUser(user._id, { ...data }));
    } else {
      dispatch(addUser({ ...data }));
    }
    // this navigate should be called only if the user is created or updated with success
    navigate("/users");
    reset();
  };

  const onSubmitPassword = (passwordData) => {
    dispatch(updatePassword(user._id, passwordData));
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              <Link to="/users">Employées</Link>
            </li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              {user ? "Modification de l'utilisateur" : "Nouvel utilisateur"}
            </li>
          </ol>
        </nav>
      </div>
      {user && !editPassword && (
        <span className="btn bg-white" onClick={() => setEditPassword(true)}>
          {" "}
          Changer mot de passe
        </span>
      )}
      {user && editPassword && (
        <span className="btn bg-white" onClick={() => setEditPassword(false)}>
          {" "}
          Changer informations utilisateurs
        </span>
      )}
      {!editPassword && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <section>
            <Row>
              <Col className="d-flex flex-column mx-auto">
                <div className="card medium-card mb-4 pb-2 purple-border">
                  <div className="card-header text-left">
                    <Col md={12}>
                      {user ? (
                        <h6>
                          <span className="client-title px-3 text-white">
                            Modification de l'utilisateur : {user.name}
                          </span>
                        </h6>
                      ) : (
                        <h6>
                          <span className="product-title px-3 text-white">
                            Nouvel utilisateur
                          </span>
                        </h6>
                      )}
                    </Col>
                  </div>
                  <div className="card-body">
                    <input
                      className="form-control"
                      id="role"
                      type="hidden"
                      {...register("user_type", { required: true })}
                      defaultValue={"employee"}
                    />
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Nom :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        {...register("name", { required: true })}
                      />
                      {errors.name && <div className="text-danger">Nom requis</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Heures de travail :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="weekly_hours"
                        {...register("weekly_hours", { required: true })}
                      />
                      {errors.weekly_hours && (
                        <div className="text-danger">Nom requis</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Adresse email :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        {...register("email", { required: true })}
                      />
                      {errors.email && (
                        <div className="text-danger">Adresse email requise</div>
                      )}
                    </div>
                    {user ? (
                      ""
                    ) : (
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Mot de passe :
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          {...register("password", { required: true })}
                        />
                      </div>
                    )}

                    <div className="mb-3">
                      <label htmlFor="role" className="form-label">
                        Rôle :
                      </label>
                      <select
                        className="form-control"
                        id="role"
                        {...register("role", { required: true })}
                      >
                        <option value="">-- Choisir un rôle --</option>
                        {<option value="employee">Collaborateur</option>}
                        {(BOSS || MANAGER) && <option value="manager">Manager</option>}
                      </select>
                      {errors.role && <div className="text-danger">Rôle requis</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="departement" className="form-label">
                        Département :
                      </label>
                      <select
                        className="form-control"
                        id="departement"
                        {...register("departement", { required: true })}
                      >
                        <option value="">-- Choisir un département --</option>

                        <option value="Team1">{<DepartmentName code="Team1" />}</option>
                        <option value="Team2">{<DepartmentName code="Team2" />}</option>
                        <option value="Team3">{<DepartmentName code="Team3" />}</option>
                        <option value="Team4">{<DepartmentName code="Team4" />}</option>
                        <option value="Team5">{<DepartmentName code="Team5" />}</option>
                      </select>
                      {errors.departement && (
                        <div className="text-danger">Département requis</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Coût / heure :
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="cost"
                        {...register("cost")}
                        min={0}
                        max={500}
                      />
                    </div>

                    <div className="mb-3">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox-1"
                          {...register(`can_create_todo`)}
                        />
                        <label className="form-check-label" htmlFor="checkbox-1">
                          Autoriser la création de tâches
                        </label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox"
                          {...register(`can_resize`)}
                        />
                        <label className="form-check-label" htmlFor="checkbox">
                          Autoriser le redimensionner de tâches
                        </label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox-tmplt"
                          {...register(`use_template`)}
                        />
                        <label className="form-check-label" htmlFor="checkbox-tmplt">
                          Utiliser template mail
                        </label>
                      </div>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        className="btn bg-gradient-dark w-30 mt-4 mb-0"
                      >
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Link to={`/users`} className="link-action back">
                  Retour
                </Link>
              </Col>
            </Row>
          </section>
        </form>
      )}
      {user && editPassword && (
        <form onSubmit={handleSubmitPassword(onSubmitPassword)}>
          <section>
            <Row>
              <Col className="d-flex flex-column mx-auto">
                <div className="card medium-card mb-4 pb-2 purple-border">
                  <div className="card-header text-left">
                    <Col md={12}>
                      {user ? (
                        <h6>
                          <span className="product-title px-3 text-white">
                            Modification de l'utilisateur : {user.name}
                          </span>
                        </h6>
                      ) : (
                        <h6>
                          <span className="product-title px-3 text-white">
                            Nouvel utilisateur
                          </span>
                        </h6>
                      )}
                    </Col>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label htmlFor="oldPassword" className="form-label">
                        Ancien mot de passe :
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="oldPassword"
                        {...registerPassword("oldPassword", { required: true })}
                      />
                      {errorsPassword.oldPassword && (
                        <div className="text-danger">mot de passe requis</div>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="newPassword" className="form-label">
                        Nouveau mot de passe :
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        {...registerPassword("newPassword", { required: true })}
                      />
                      {errorsPassword.newPassword && (
                        <div className="text-danger">mot de passe requis</div>
                      )}
                    </div>
                    <div className="">
                      <button
                        type="submit"
                        className="btn bg-gradient-dark w-30 mt-4 mb-0"
                      >
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </section>
        </form>
      )}
    </>
  );
};

export default UserForm;
