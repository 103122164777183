import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { contentBlockVariants } from "./variants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRectangleXmark,
  faPen,
  faNoteSticky,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import moment from "moment";

const AnimatedContentBlock = ({ children }) => (
  <motion.div variants={contentBlockVariants}>{children}</motion.div>
);

export default function ExpandedCard({ data, setData, layoutId, handleDownload }) {
  if (!data) {
    return <></>;
  }

  const new_pending = data.code === "new_pending";
  const in_progress = data.code === "in_progress";
  const progress_pending = data.code === "progress_pending";
  const produced = data.code === "produced";

  const todosCount = (item) => {
    return item.todos.filter((todo) => !todo.archived).length;
  };

  const todosCompletedCount = (item) => {
    return item.todos.filter((todo) => !todo.archived && todo.done).length;
  };

  const todoPercentage = (item) => {
    return (todosCompletedCount(item) / todosCount(item)) * 100 || 0;
  };

  function getEndDateClass(endDate) {
    const today = moment();
    const twoMonthsFromNow = moment().add(2, "months");

    return moment(endDate).isBetween(today, twoMonthsFromNow, "day", "[]")
      ? "close-date"
      : "";
  }

  return (
    <AnimatePresence key={1}>
      <motion.div
        key={1}
        onClick={() => setData(null)}
        className="modal-bg"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.7,
        }}
      ></motion.div>
      <motion.div
        key={2}
        className="expanded-modal"
        layoutId={layoutId}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.7,
        }}
      >
        <button className="close" onClick={() => setData(null)}>
          <FontAwesomeIcon icon={faRectangleXmark} color="#344866" />
        </button>
        <motion.div
          key={1}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.7,
          }}
        >
          <motion.div className="expanded-content">
            <AnimatedContentBlock>
              <div className="container-fluid py-4">
                <div className="row">
                  <div className="col-12">
                    <div className=" mb-4">
                      <div className="card-header">
                        <h6 style={{ background: data.product_color }}>
                          {data.product_name} - <span>{data.status}</span>
                        </h6>
                      </div>
                      <div className="card-body px-0 pt-0 pb-2">
                        <div className="table-responsive p-0">
                          <table className="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Client
                                </th>
                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                  Suivi
                                </th>
                                <th></th>
                                <th className="text-secondary opacity-7"></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.list.map((item) => (
                                <tr key={item._id}>
                                  <td>
                                    <div className="d-flex px-2 py-1">
                                      <div className="d-flex flex-column justify-content-center">
                                        <h6 className="mb-0 text-sm">
                                          {item.client.with_issue && (
                                            <OverlayTrigger
                                              placement="right"
                                              overlay={
                                                <Tooltip id="button-tooltip-2">
                                                  {item.client.issue}
                                                </Tooltip>
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={faTriangleExclamation}
                                                className="me-1"
                                                color="#be3c4e"
                                              />
                                            </OverlayTrigger>
                                          )}
                                          <Link to={`/clients/${item.client._id}/orders`}>
                                            {item.client.companyName}
                                          </Link>
                                          <div>
                                            <small className="text-xs">
                                              {moment(item.createdAt).format(
                                                "DD-MM-YYYY"
                                              )}
                                            </small>
                                          </div>
                                        </h6>
                                      </div>
                                    </div>
                                  </td>

                                  <td className="align-middle text-center text-sm ">
                                    {(new_pending || progress_pending) && (
                                      <Link
                                        to={`/clients/${item.client._id}/orders/${item._id}/tickets`}
                                      >
                                        <span className="badge badge-sm bg-gradient-warning">
                                          Tickets
                                        </span>
                                      </Link>
                                    )}
                                    {(in_progress || progress_pending || produced) && (
                                      <>
                                        <div className="mb-2">
                                          <Link
                                            to={`/clients/${item.client._id}/orders/${item._id}/todos`}
                                          >
                                            <div className="my-1 d-flex align-items-center justify-content-center">
                                              <div className="mx-2 text-xs text-secondary">
                                                TODOs{" "}
                                              </div>
                                              <div>
                                                <div className="progress">
                                                  <div
                                                    className="progress-bar bg-gradient-success"
                                                    role="progressbar"
                                                    aria-valuenow={todoPercentage(item)}
                                                    aria-valuemin="0"
                                                    aria-valuemax={todoPercentage(item)}
                                                    style={{
                                                      width: `${todoPercentage(item)}%`,
                                                    }}
                                                  ></div>
                                                </div>
                                              </div>
                                              <span className="ms-2 text-xs font-weight-bold">
                                                {todosCompletedCount(item)} /{" "}
                                                {todosCount(item)}
                                              </span>
                                            </div>
                                          </Link>
                                        </div>
                                      </>
                                    )}
                                  </td>
                                  <td className={getEndDateClass(item.endDate)}>
                                    <div>
                                      <small>
                                        {item.startDate &&
                                          moment(item.startDate).format("DD/MM/YY")}{" "}
                                      </small>
                                    </div>
                                    <div>
                                      <small>
                                        {item.endDate &&
                                          moment(item.endDate).format("DD/MM/YY")}
                                      </small>
                                    </div>
                                  </td>
                                  <td className="align-middle">
                                    <Link to={`/clients/${item.client._id}/notes`}>
                                      <FontAwesomeIcon
                                        icon={faNoteSticky}
                                        className="px-2"
                                        color="#344767"
                                      />
                                    </Link>
                                  </td>
                                  <td className="align-middle">
                                    <Link
                                      to={`/clients/${item.client._id}/orders/${item._id}`}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPen}
                                        className="px-2"
                                        color="#344767"
                                      />
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AnimatedContentBlock>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}
