import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_TODO_REQUEST,
  DELETE_PRODUCT_TODO_SUCCESS,
  DELETE_PRODUCT_TODO_FAILURE,
} from "../actionTypes";
import { toast } from "react-toastify";
import { productService } from "../services/productService";

// Action Creator pour récupérer la liste des produits
export const fetchProducts = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PRODUCTS_REQUEST });
    try {
      const products = await productService.getAllProducts();
      dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: products });
    } catch (err) {
      dispatch({ type: FETCH_PRODUCTS_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour ajouter un produit
export const addProduct = (productData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_PRODUCT_REQUEST });
    try {
      const newProduct = await productService.createProduct(productData);
      toast.success("Produit ajouté avec succès");

      dispatch({ type: ADD_PRODUCT_SUCCESS, payload: newProduct });
    } catch (err) {
      dispatch({ type: ADD_PRODUCT_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour modifier un produit
export const updateProduct = (productId, productData) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });
    try {
      const updatedProduct = await productService.updateProduct(productId, productData);
      toast.success("Produit mis à jour avec succès");
      dispatch({ type: UPDATE_PRODUCT_SUCCESS, payload: updatedProduct.product });
    } catch (err) {
      dispatch({ type: UPDATE_PRODUCT_FAILURE, payload: err.message });
    }
  };
};

export const fetchProductsSuccess = (data) => {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload: data,
  };
};

// Action Creator pour modifier un produit
export const updatePositions = (data) => {
  return async (dispatch) => {
    productService
      .updatePositions(data)
      .then((data) => {
        console.log("Success");
      })
      .catch((error) => {
        console.log("Failure", error);
      });
  };
};

// Action Creator pour supprimer un produit
export const deleteProduct = (productId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_PRODUCT_REQUEST });
    try {
      await productService.deleteProduct(productId);
      dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: productId });
    } catch (err) {
      dispatch({ type: DELETE_PRODUCT_FAILURE, payload: err.message });
    }
  };
};

// Action Creator pour supprimer un critère de qualité d'un produit
export const deleteTodo = (productId, todoId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_PRODUCT_TODO_REQUEST });
    try {
      await productService.deleteProductTodo(productId, todoId);
      dispatch({
        type: DELETE_PRODUCT_TODO_SUCCESS,
        payload: { productId, todoId },
      });
    } catch (err) {
      dispatch({ type: DELETE_PRODUCT_TODO_FAILURE, payload: err.message });
    }
  };
};
