import { useEffect, useContext } from "react";
import { NavContext } from "../../NavContext";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CallControls,
  CallingState,
  SpeakerLayout,
  StreamCall,
  StreamTheme,
  StreamVideo,
  StreamVideoClient,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { fetchMeetingById } from "../../../redux/actions/cli/meetingActions";

const MeetRoom = () => {
  const dispatch = useDispatch();
  const { meetId } = useParams();
  const currentUser = useSelector((state) => state.authReducer.user.user);
  const meeting = useSelector((state) => state.meetingReducer.meeting);
  const error = useSelector((state) => state.meetingReducer.error);

  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    document.title = "ProdOTop - Meeting";
  }, []);

  useEffect(() => {
    setActiveNav("meet");
  }, [setActiveNav]);

  useEffect(() => {
    dispatch(fetchMeetingById(meetId));
  }, [dispatch, meetId]);

  return (
    <>
      <Row className="row">
        {error && (
          <div className="panel error mt-5">
            <h6>Notification d'erreur</h6>
            {error}
          </div>
        )}
        {meeting && (
          <Meet currentUser={currentUser} token={meeting.token} callId={meeting._id} />
        )}
      </Row>
    </>
  );
};

const Meet = ({ currentUser, token, callId }) => {
  console.log(currentUser);
  const user = {
    id: currentUser._id,
    name: currentUser.name,
    image: currentUser.logo
      ? currentUser.logo
      : "https://getstream.io/random_svg/?id=" + currentUser.name,
  };

  const apiKey = process.env.REACT_APP_STREAM_KEY;

  if (!apiKey || !token) {
    console.error("API key or token is missing");
    return <div>Error: Missing API key or token</div>;
  }

  const client = new StreamVideoClient({ apiKey, user, token });
  const call = client.call("default", callId);

  call.join({ create: true });

  return (
    <StreamVideo client={client}>
      <StreamCall call={call}>
        <MyUILayout />
      </StreamCall>
    </StreamVideo>
  );
};

export const MyUILayout = () => {
  const { useCallCallingState } = useCallStateHooks();
  const callingState = useCallCallingState();

  if (callingState === CallingState.LEFT) {
    return (
      <div className="panel error mt-5">
        <h6>Status</h6>
        <div>Vous avez quitté la réunion</div>
      </div>
    );
  }

  if (callingState !== CallingState.JOINED) {
    return <div>Loading...</div>;
  }

  return (
    <StreamTheme>
      <SpeakerLayout participantsBarPosition="bottom" />
      <CallControls />
    </StreamTheme>
  );
};

export default MeetRoom;
