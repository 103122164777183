import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Text,
} from "recharts";

const CustomShapeBarChart = ({ info, dataType, data }) => {
  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red", "pink"];

  const getPath = (x, y, width, height) => {
    return `M${x},${y + height}
            C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
            ${x + width / 2}, ${y}
            C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
      x + width
    }, ${y + height}
            Z`;
  };

  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  const getMaxValue = () => {
    const maxVal = Math.max(...data.map((item) => item[dataType]));
    return maxVal;
  };
  const formatValue = (value) => {
    return Number(parseFloat(value[dataType]).toFixed(2));
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const numericValue = parseFloat(value);

    const formattedValue = Number.isInteger(numericValue)
      ? numericValue
      : numericValue.toFixed(2);

    return (
      <Text
        x={x + width / 2}
        y={y}
        fill="#686868"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {formattedValue}
      </Text>
    );
  };

  return (
    <BarChart
      width={500}
      height={350}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={info} />

      <YAxis domain={[0, getMaxValue()]} dataKey={formatValue} />
      <Tooltip />
      <Bar dataKey={dataType} shape={<TriangleBar />} label={renderCustomizedLabel}>
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={colors[index % colors.length]}
            dataKey={formatValue}
          />
        ))}
      </Bar>
    </BarChart>
  );
};

export default CustomShapeBarChart;
