import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";

export default function configureStore(initialState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

  const localStorageMiddleware = (store) => (next) => (action) => {
    next(action);
    //const localeJson = store.getState().localeReducer.locale;
    //window.localStorage.setItem("locale", localeJson);
  };

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(thunk, reduxImmutableStateInvariant(), localStorageMiddleware)
    ) // to comunicate with redux dev tools in the browser
  );
}
