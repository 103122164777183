import {
  LOAD_DEPARTMENT_LABELS_SUCCESS,
  LOAD_DEPARTMENT_LABELS_FAILURE,
  LOAD_DEPARTMENT_LABEL_SUCCESS,
  LOAD_DEPARTMENT_LABEL_FAILURE,
} from "../actionTypes";
import { departmentService } from "../services/departmentService";
import { toast } from "react-toastify";

export const loadDepartmentLabels = () => {
  return (dispatch) => {
    try {
      departmentService
        .fetchDepartmentLabels()
        .then((data) => {
          dispatch(dispatch({ type: LOAD_DEPARTMENT_LABELS_SUCCESS, payload: data }));
        })
        .catch((error) => {
          dispatch({ type: LOAD_DEPARTMENT_LABELS_FAILURE, payload: error });
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const loadDepartmentLabel = (data) => {
  return (dispatch) => {
    try {
      departmentService
        .fetchDepartmentLabel(data)
        .then((data) => {
          dispatch(dispatch({ type: LOAD_DEPARTMENT_LABEL_SUCCESS, payload: data }));
        })
        .catch((error) => {
          dispatch({ type: LOAD_DEPARTMENT_LABEL_FAILURE, payload: error });
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateDepartments = (labels) => {
  return async (dispatch) => {
    try {
      departmentService
        .updateDepartmentsLabels(labels)
        .then((data) => {
          toast.success("Noms de departements mis à jour avec succès");
        })
        .catch((error) => {
          toast.error("Something goes wrong !!");
          console.log(error);
        });
    } catch (error) {
      console.error("Erreur lors de la création des labels de département :", error);
    }
  };
};
