import { combineReducers } from "redux";
import authReducer from "./authReducer";
import clientReducer from "./clientReducer";
import productReducer from "./productReducer";
import eCalendarReducer from "./eCalendarReducer";
import draftReducer from "./draftReducer";
import meetingReducer from "./meetingReducer";

import qualityCriterionReducer from "./qualityCriterionReducer";
import qualityCheckReducer from "./qualityCheckReducer";
import todoReducer from "./todoReducer";
import briefQuestionReducer from "./briefQuestionReducer";
import surveyQuestionReducer from "./surveyQuestionReducer";
import userReducer from "./userReducer";
import dashboardReducer from "./dashboardReducer";
import calendarReducer from "./calendarReducer";
import notificationReducer from "./notificationReducer";
import emailReducer from "./emailReducer";
import departmentReducer from "./departmentReducer";
import agencyReducer from "./agencyReducer";

import cliECalendarReducer from "./cli/eCalendarReducer";
import cliClientsReducer from "./cli/clientsReducer";

const rootReducer = combineReducers({
  authReducer: authReducer,
  clientReducer: clientReducer,
  productReducer: productReducer,
  qualityCriterionReducer: qualityCriterionReducer,
  qualityCheckReducer: qualityCheckReducer,
  todoReducer: todoReducer,
  eCalendarReducer: eCalendarReducer,
  briefQuestionReducer: briefQuestionReducer,
  surveyQuestionReducer: surveyQuestionReducer,
  userReducer: userReducer,
  draftReducer: draftReducer,
  dashboardReducer: dashboardReducer,
  calendarReducer: calendarReducer,
  notificationReducer: notificationReducer,
  emailReducer: emailReducer,
  departmentReducer: departmentReducer,
  agencyReducer: agencyReducer,
  cliECalendarReducer: cliECalendarReducer,
  cliClientsReducer: cliClientsReducer,
  meetingReducer: meetingReducer,
});

export default rootReducer;
