import { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderWithDocumentsById } from "../../../../redux/actions/cli/orderActions";
import {
  uploadDocument,
  downloadDocument,
} from "../../../../redux/actions/cli/clientsActions";
import { fetchCliTinyClient } from "../../../../redux/actions/cli/clientsActions";

import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { NavContext } from "../../../NavContext";
import { Bars } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { PanelContext } from "../../../panelContext";
import OrderNavigation from "../orderNavigation";

const DocumentPage = () => {
  const dispatch = useDispatch();
  const { activeNav, setActiveNav } = useContext(NavContext);
  const { clientId, orderId } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { panelMessage, setPanelMessage } = useContext(PanelContext);

  useEffect(() => {
    if (panelMessage) {
      setPanelMessage(
        "<b>Insérez et consultez tous les documents</b> relatifs à la réalisation de votre projet (photos, PDF, Word, etc.)."
      );
    }
  }, [setPanelMessage, panelMessage]);

  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);

  const order = useSelector((state) => state.cliClientsReducer.order_documents);
  const loadingDocument = useSelector((state) => state.cliClientsReducer.loadingDocument);
  const client = useSelector((state) => state.cliClientsReducer.tiny_client);
  const downloadingDocument = useSelector(
    (state) => state.cliClientsReducer.downloadingDocument
  );

  useEffect(() => {
    document.title = "ProdOTop - Documents de la commande";
  }, []);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchCliTinyClient(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderWithDocumentsById(orderId));
    }
  }, [dispatch, orderId, clientId]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("document", data.document[0]);
    formData.append("title", data.title);
    formData.append("shared", true);
    dispatch(uploadDocument(clientId, orderId, formData));

    reset();
  };

  const handleDownload = async (docId) => {
    dispatch(downloadDocument(clientId, orderId, docId));
  };

  return (
    <>
      {order && (
        <>
          <div className="pb-4">
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/client/${order.client?._id}/orders`}>
                    {order.client?.companyName}
                  </Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  {order?.product.name}
                </li>
                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  Documents
                </li>
              </ol>
            </nav>
          </div>
          {(loadingDocument || downloadingDocument) && (
            <div className="ia-loader">
              <Bars
                height={100}
                width={100}
                radius={5}
                color="#cb0c9f"
                ariaLabel="bars-loading"
                wrapperClass={"triangle-loader"}
                wrapperStyle=""
                visible={true}
              />
            </div>
          )}
          <div className="order-container">
            {order && (
              <OrderNavigation order={order} client={client} currentTab="documents" />
            )}
            <>
              <Row className="mb-3">
                <div className="col-12">
                  <div className="card medium-card mb-4 pb-2 purple-border">
                    <div className="card-header">
                      <Row>
                        <Col md={12} className="mb-3">
                          <h6>
                            <span className="product-title px-3 text-dark">
                              Ajouter un document
                            </span>
                          </h6>
                        </Col>
                      </Row>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                          <Col md={12} className="mb-3">
                            <label htmlFor="title" className="form-label">
                              Titre du fichier :
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              {...register("title", { required: true })}
                            />
                            {errors.title && (
                              <div className="text-danger">Le titre est requis</div>
                            )}
                          </Col>

                          <Col md={12} className="mb-3">
                            <label htmlFor="document" className="form-label">
                              Document
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="document"
                              // accept="application/pdf"
                              {...register("document", { required: true })}
                            />
                            {errors.pdf && (
                              <div className="text-danger">Le PDF est requis</div>
                            )}
                          </Col>
                          <Col>
                            <button
                              type="submit"
                              className="btn bg-gradient-dark w-30 mt-4 mb-0"
                            >
                              Charger
                            </button>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </div>
                </div>
              </Row>
              <Row className="mb-3">
                <div className="col-12">
                  <div className="card medium-card mb-4 pb-2 purple-border">
                    <div className="card-body">
                      <h6>
                        <span className="product-title px-3 text-dark">Documents</span>
                      </h6>
                      {order?.documents.map((doc) => (
                        <div className="flex gap-2 px-3" key={doc._id}>
                          <div
                            className="btn-link primary-color text-gradient  text-end"
                            role="button"
                            title="Télécharger"
                            onClick={() => handleDownload(doc._id)}
                          >
                            <FontAwesomeIcon icon={faDownload} className="px-2" />
                          </div>

                          <div>
                            <small>{moment(doc.createdAt).format("DD-MM-YYYY")}</small>
                          </div>
                          <div>{doc?.title}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Row>
            </>
          </div>

          <Row className="mb-3">
            <Col>
              <Link to={`/client/${clientId}/orders`} className="link-action back">
                Retour
              </Link>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default DocumentPage;
