import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { loadAgencyInfo, updateAgencyInfo } from "../../../redux/actions/agencyActions";
import { NavContext } from "../../NavContext";

const DepartmentsPage = () => {
  const dispatch = useDispatch();

  const agency = useSelector((state) => state.agencyReducer.agency);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    setActiveNav("agency-info");
  }, [setActiveNav]);

  useEffect(() => {
    dispatch(loadAgencyInfo());
  }, []);

  useEffect(() => {
    reset({ ...agency });
  }, [reset, agency]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("logo", data.logo[0]);
    formData.append("companyName", data.companyName);
    formData.append("address", data.address);

    dispatch(updateAgencyInfo(formData));
  };

  return (
    <>
      <div className="pb-4">
        <nav aria-label="breadcrumb" className="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm"></li>
            <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
              {"Agence"}
            </li>
          </ol>
        </nav>
      </div>
      <Row>
        <Col className="d-flex flex-column mx-auto">
          <div className="card medium-card mb-4 pb-2 purple-border">
            <div className="card-header text-left">
              <h6>
                <span className="product-title px-3 text-white">Agence</span>
              </h6>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="department-entry mb-2">
                  <label className="form-label">Nom</label>

                  <input
                    type="text"
                    {...register(`companyName`, { required: true })}
                    className="form-control"
                  />
                </div>
                <div className="department-entry mb-2">
                  <label className="form-label">Adresse</label>

                  <input
                    type="text"
                    {...register(`address`, { required: true })}
                    className="form-control"
                  />
                </div>
                <div className="department-entry mb-2">
                  <label className="form-label">Logo</label>

                  <input type="file" {...register(`logo`)} className="form-control" />
                </div>
                {agency.logo && (
                  <img src={agency.logo} alt="agency" className="prodotop-logo" />
                )}
                <div className="">
                  <button type="submit" className="btn bg-gradient-dark w-30 mt-4 mb-0">
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DepartmentsPage;
