import {
  LOAD_AGENCY_REQUEST,
  LOAD_AGENCY_SUCCESS,
  LOAD_AGENCY_FAILURE,
} from "../actionTypes";
import { agencyService } from "../services/agencyService";
import { toast } from "react-toastify";

export const loadAgencyInfo = () => {
  return (dispatch) => {
    dispatch({ type: LOAD_AGENCY_REQUEST });
    try {
      agencyService
        .loadAgencyInfo()
        .then((data) => {
          dispatch({ type: LOAD_AGENCY_SUCCESS, payload: data });
        })
        .catch((error) => {
          dispatch({ type: LOAD_AGENCY_FAILURE, payload: error });
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateAgencyInfo = (data) => {
  return async (dispatch) => {
    try {
      console.log("ixiii");
      agencyService
        .updateAgencyInfo(data)
        .then((data) => {
          toast.success("Informations mis à jour avec succès");
          dispatch({ type: LOAD_AGENCY_SUCCESS, payload: data });
        })
        .catch((error) => {
          toast.error("Something goes wrong !!");
          console.log(error);
        });
    } catch (error) {
      console.error("Erreur lors de la mise à jour", error);
    }
  };
};
