import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faDashboard,
  faCalendar,
  faUsers,
  faMarker,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { NavContext } from "../NavContext";

const Index = () => {
  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    setActiveNav("index");
  }, [setActiveNav]);

  return (
    <div>
      <div className="card mb-3">
        <div className="flex justify-content-between gap-3 ">
          <div className="p-5">
            <h2>
              <span>Bienvenue sur le tableau de bord professionnel</span>
            </h2>
            <div>
              <span>
                Configuration de vos produits, E-mails, Enquêtes de satisfactions ...
              </span>
            </div>
          </div>
          <div>
            <i className="bg-management img-fluid bg-header" />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="p-5">
          <div className="mb-5">
            <h2>
              <span>Dashboard</span>
            </h2>
          </div>
          <div className="flex flex-wrap gap-3">
            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link aria-current="page" to="/dashboard">
                <FontAwesomeIcon icon={faDashboard} className="" />
                <span className="mx-3">Suivis</span>
              </Link>
            </div>

            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/calendar">
                <FontAwesomeIcon icon={faCalendar} className="" />
                <span className="mx-3">Calendrier</span>
              </Link>
            </div>
            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/meet">
                <FontAwesomeIcon icon={faVideo} className="" />
                <span className="mx-3">Mes réunions</span>
              </Link>
            </div>
            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/clients">
                <FontAwesomeIcon icon={faUsers} className="" />
                <span className="mx-3">Clients</span>
              </Link>
            </div>
            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/community">
                <FontAwesomeIcon icon={faMarker} className="" />
                <span className="mx-3">Community</span>
              </Link>
            </div>
            <div className="card bg-ligth-primary p-5 shadow-none">
              <Link to="/Ads-reports">
                <FontAwesomeIcon icon={faChartLine} className="" />
                <span className="mx-3">Rapports</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
