import { useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTodosOrder,
  createTodosOrder,
  devideTask,
  updateTodosOrder,
  fetchClientOrders,
  fetchOrderById,
} from "../../../../../redux/actions/clientActions";
import { Row, Col, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavContext } from "../../../../NavContext";
import TodoCard from "./todoCard";
import OrderNavigation from "../order/orderNavigation";

const TodosForm = () => {
  const dispatch = useDispatch();
  const { clientId, orderId } = useParams();
  const { activeNav, setActiveNav } = useContext(NavContext);
  const [tab, setTab] = useState("new");
  useEffect(() => {
    setActiveNav("clients");
  }, [setActiveNav]);

  const todos = useSelector((state) => state.clientReducer.todos);
  const isFetchingTodos = useSelector((state) => state.clientReducer.isFetchingTodos);
  const order = useSelector((state) => state.clientReducer.order);
  const client = useSelector((state) => state.clientReducer.client);
  const currentUser = useSelector((state) => state.authReducer.user.user);

  useEffect(() => {
    document.title = "ProdOTop - Todos de la commande";
  }, []);

  const PROD_MANAGER =
    currentUser.role === "manager" && currentUser.departement === "Team1";
  const PROD_RESPONSIBLE =
    currentUser.departement === "Team1" && currentUser._id === order?.team1Responsible;
  const can_update = PROD_MANAGER || PROD_RESPONSIBLE;

  const archive_access = currentUser.role === "manager" || currentUser.role === "boss";

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (clientId && orderId && !isFetchingTodos) {
      dispatch(fetchTodosOrder(clientId, orderId));
    }
  }, [dispatch, clientId, orderId]);

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientOrders(clientId));
    }
  }, [dispatch, clientId]);
  useEffect(() => {
    dispatch(fetchOrderById(clientId, orderId));
  }, [dispatch, clientId, orderId]);

  const onSubmit = async (data) => {
    dispatch(createTodosOrder(clientId, orderId, data));
    reset();
  };
  const new_tab = tab === "new";
  const done_tab = tab === "done";
  const archived_tab = tab === "archived";
  const FLEXIBILITY = [
    { FLZR: "Flexibilité Zéro" },
    { FLDY: "Flexibilité Journalière" },
    { FLHB: "Flexibilité Hebdomadaire" },
  ];
  return (
    <>
      {order && (
        <>
          <div className="pb-4">
            <nav aria-label="breadcrumb" className="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm"></li>
                <li className="breadcrumb-item text-sm text-dark" aria-current="page">
                  <Link to="/clients">clients</Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark " aria-current="page">
                  <Link to={`/clients/${client?._id}/orders`}>{client?.companyName}</Link>
                </li>
                {order && (
                  <li
                    className="breadcrumb-item text-sm text-dark flex p-0"
                    aria-current="page"
                  >
                    <Dropdown className="dropdown-orders">
                      <Dropdown.Toggle id="dropdown-basic">
                        {order.product?.name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {client?.orders?.map((cl_order) => (
                          <Dropdown.Item
                            as={Link}
                            to={`/clients/${clientId}/orders/${cl_order._id}/todos`}
                            key={cl_order._id}
                          >
                            {cl_order.product?.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                )}
                <li
                  className="breadcrumb-item text-sm text-dark active"
                  aria-current="page"
                >
                  TODOs
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-container">
            {order && (
              <OrderNavigation order={order} client={client} currentTab="todos" />
            )}
            <section>
              <Row>
                <Col className="d-flex flex-column mx-auto">
                  <div className="card medium-card mb-4 pb-2 purple-border">
                    <div className="card-header text-left">
                      <Col md={6}>
                        {todos && (
                          <h6>
                            <span className="product-title px-3 text-white">
                              Liste des tâches
                            </span>
                          </h6>
                        )}
                      </Col>
                    </div>
                    <div className="card-body">
                      {(PROD_MANAGER || currentUser.can_create_todo) && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Row className="xmb-5">
                            <Col md={9}>
                              <div className="mb-3">
                                <label htmlFor="duration" className="form-label">
                                  Nouvelle tache :
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="duration"
                                  {...register("task", { required: true })}
                                />
                                {errors?.task && (
                                  <div className="text-danger">Champs obligatoire</div>
                                )}
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label htmlFor="minutes" className="form-label">
                                  Minutes :
                                </label>
                                <input
                                  className="form-control"
                                  id="minutes"
                                  type="number"
                                  step={15}
                                  {...register("minutes", { required: true })}
                                  placeholder="15, 30, 45"
                                />
                                {errors?.minutes && (
                                  <div className="text-danger">Minutes requise</div>
                                )}
                              </div>
                            </Col>
                            <Col md={9}>
                              <div className="mb-3">
                                <label htmlFor="minutes" className="form-label">
                                  Flexibilité de la tâche :
                                </label>
                                <select
                                  className="form-control"
                                  id="role"
                                  {...register("flexibility", { required: true })}
                                  defaultValue="FLDY"
                                >
                                  {FLEXIBILITY.map((issue, index) => {
                                    const key = Object.keys(issue)[0];
                                    const value = Object.values(issue)[0];
                                    return (
                                      <option key={key} value={key} className={key}>
                                        {value}
                                      </option>
                                    );
                                  })}
                                </select>

                                {errors.flexibility && (
                                  <div className="text-danger">Le champ est requis</div>
                                )}
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-5">
                                <label className="form-label"></label>
                                <button
                                  type="submit"
                                  className="btn bg-gradient-dark mt-1 w-100 mb-0"
                                >
                                  Ajouter
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      )}

                      <Row className="">
                        <Col>
                          <div className="card bg-white border-1">
                            <div className="nav-wrapper position-relative end-0">
                              <ul
                                className="nav nav-pills nav-fill py-4  bg-transparent"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <div
                                    className={`nav-link mb-0 px-0 py-0 hf-150 ${
                                      new_tab ? "active" : ""
                                    } `}
                                    onClick={() => setTab("new")}
                                  >
                                    <span className="ms-1 badge badge-sm bg-gray-200 text-secondary">
                                      <span className="ms-1">À faire</span>
                                    </span>
                                    <span className="ms-1 badge badge-sm bg-gray-200 text-secondary">
                                      {todos?.active_todos?.length}
                                    </span>
                                  </div>
                                </li>
                                <li className="nav-item">
                                  <div
                                    className={`nav-link mb-0 px-0 py-0 hf-150 ${
                                      done_tab ? "active" : ""
                                    } `}
                                    onClick={() => setTab("done")}
                                  >
                                    <span className="ms-1 badge badge-sm bg-gray-200 text-secondary">
                                      <span className="ms-1">Fait</span>
                                    </span>
                                    <span className="ms-1 badge badge-sm bg-gray-200 text-secondary">
                                      {todos?.done_todos?.length}
                                    </span>
                                  </div>
                                </li>
                                {archive_access && (
                                  <li className="nav-item">
                                    <div
                                      className={`nav-link mb-0 px-0 py-0 hf-150 ${
                                        archived_tab ? "active" : ""
                                      } `}
                                      onClick={() => setTab("archived")}
                                    >
                                      <span className="ms-1 badge badge-sm bg-gray-200 text-secondary">
                                        <span className="ms-1">Archivé</span>
                                      </span>
                                      <span className="ms-1 badge badge-sm bg-gray-200 text-secondary">
                                        {todos?.archived_todos?.length}
                                      </span>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {new_tab && (
                        <Row>
                          <Col>
                            <ul className="list-group">
                              {todos &&
                                todos.active_todos?.map((todo) => (
                                  <TodoCard
                                    key={todo._id}
                                    todo={todo}
                                    updateTodosOrder={(id, data) =>
                                      dispatch(
                                        updateTodosOrder(clientId, orderId, id, data)
                                      )
                                    }
                                    can_manage={can_update}
                                    devideTask={(id, data) =>
                                      dispatch(devideTask(clientId, orderId, id, data))
                                    }
                                  />
                                ))}
                            </ul>
                          </Col>
                        </Row>
                      )}

                      {done_tab && (
                        <Row>
                          <Col>
                            <ul className="list-group">
                              {todos &&
                                todos.done_todos?.map((todo) => (
                                  <TodoCard
                                    key={todo._id}
                                    todo={todo}
                                    updateTodosOrder={(id, data) =>
                                      dispatch(
                                        updateTodosOrder(clientId, orderId, id, data)
                                      )
                                    }
                                    can_manage={false}
                                    devideTask={(id, data) =>
                                      dispatch(devideTask(clientId, orderId, id, data))
                                    }
                                  />
                                ))}
                            </ul>
                          </Col>
                        </Row>
                      )}

                      {archived_tab && (
                        <Row>
                          <Col>
                            <ul className="list-group">
                              {todos &&
                                todos.archived_todos?.map((todo) => (
                                  <TodoCard
                                    key={todo._id}
                                    todo={todo}
                                    updateTodosOrder={(id, data) =>
                                      dispatch(
                                        updateTodosOrder(clientId, orderId, id, data)
                                      )
                                    }
                                    can_manage={false}
                                  />
                                ))}
                            </ul>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Link to={`/clients/${clientId}/orders`} className="link-action back">
                    Retour
                  </Link>
                </Col>
              </Row>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default TodosForm;
