import {
  LOAD_DEPARTMENT_LABELS_SUCCESS,
  LOAD_DEPARTMENT_LABELS_FAILURE,
  LOAD_DEPARTMENT_LABEL_SUCCESS,
  LOAD_DEPARTMENT_LABEL_FAILURE,
} from "../actionTypes";

const initialState = {
  departments: [],
  department: false,
  error: "",
};

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DEPARTMENT_LABELS_SUCCESS:
      return { ...state, departments: action.payload };
    case LOAD_DEPARTMENT_LABELS_FAILURE:
      return { ...state, error: action.payload };
    case LOAD_DEPARTMENT_LABEL_SUCCESS:
      return { ...state, department: action.payload };
    case LOAD_DEPARTMENT_LABEL_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default departmentReducer;
