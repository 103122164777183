import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { NavContext } from "../NavContext";
import { useSelector, useDispatch } from "react-redux";
import {
  updateNotifications,
  updateNotification,
} from "../../redux/actions/notificationActions";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Notification = () => {
  const dispatch = useDispatch();

  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const notifications = useSelector((state) => state.notificationReducer.notifications);
  const error = useSelector((state) => state.notificationReducer.error);
  const { activeNav, setActiveNav } = useContext(NavContext);

  useEffect(() => {
    document.title = "ProdOTop - Notifications";
  }, []);
  useEffect(() => {
    setActiveNav("notification");
  }, [setActiveNav]);

  useEffect(() => {
    dispatch(updateNotifications());
  }, []);

  useEffect(() => {
    setFilteredNotifications(notifications);
  }, [notifications]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);

    const filtered = notifications.filter((notification) =>
      notification.message.toLowerCase().includes(event.target.value.toLowerCase())
    );

    setFilteredNotifications(filtered);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const notificationLink = (notification) => {
    switch (notification.action) {
      case "NEW_CLIENT":
        return `/clients/${notification.client}`;
      case "NEW_ORDER":
        return `/clients/${notification.client}/orders`;
      case "COMPLETED_ORDER":
        return `/clients/${notification.client}/orders/${notification.order}`;
      case "COMPLETED_BRIEF":
        return `/clients/${notification.client}/orders/${notification.order}/brief_responses`;
      case "COMPLETED_QUALITY":
        return `/clients/${notification.client}/orders/${notification.order}/quality_checks`;
      case "DISCUSSION_TICKET":
        return `/clients/${notification.client}/orders/${notification.order}/tickets/${notification.ticket}`;
      case "WEEKLY_HOURS":
        return `/calendar`;
      default:
        console.log("Something goes wrong");
    }
  };

  const newClientNotifications = filteredNotifications.filter(
    (notification) => notification.action === "NEW_CLIENT"
  );

  const NewOrderNotifications = filteredNotifications.filter(
    (notification) => notification.action === "NEW_ORDER"
  );

  const CompletedOrderNotifications = filteredNotifications.filter(
    (notification) => notification.action === "COMPLETED_ORDER"
  );

  const CompletedBriefNotifications = filteredNotifications.filter(
    (notification) => notification.action === "COMPLETED_BRIEF"
  );

  const CompletedQualityNotifications = filteredNotifications.filter(
    (notification) => notification.action === "COMPLETED_QUALITY"
  );

  const DiscussionTicketNotifications = filteredNotifications.filter(
    (notification) => notification.action === "DISCUSSION_TICKET"
  );

  const WeeklyHoursNotifications = filteredNotifications.filter(
    (notification) => notification.action === "WEEKLY_HOURS"
  );

  return (
    <>
      <>
        <div className="pb-4">
          <nav aria-label="breadcrumb" className="breadcrumb">
            <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-0 px-0 me-sm-6 me-5">
              <li className="breadcrumb-item text-sm"></li>
              <li
                className="breadcrumb-item text-sm text-dark active"
                aria-current="page"
              >
                <Link to="/notification">Notifications</Link>
              </li>
            </ol>
          </nav>
        </div>
        <Row>
          <Col md={6}>
            <div className="m-3">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Filtrer ..."
                className="form-control filter-client mx-2"
              />
            </div>
          </Col>
        </Row>
        <Row className="row">
          <Col md={12}>
            <GroupNotifications
              data={DiscussionTicketNotifications}
              notificationLink={notificationLink}
              updateNotification={(id) => dispatch(updateNotification(id))}
              title="Notification Nouvelle demande"
            />
          </Col>
          <Col md={6}>
            <GroupNotifications
              data={newClientNotifications}
              notificationLink={notificationLink}
              updateNotification={(id) => dispatch(updateNotification(id))}
              title="Notification nouveau client"
            />
          </Col>

          <Col md={6}>
            <GroupNotifications
              data={NewOrderNotifications}
              notificationLink={notificationLink}
              updateNotification={(id) => dispatch(updateNotification(id))}
              title="Notification nouvelle commande"
            />
          </Col>
          <Col md={6}>
            <GroupNotifications
              data={WeeklyHoursNotifications}
              notificationLink={notificationLink}
              updateNotification={(id) => dispatch(updateNotification(id))}
              title="Notification planning"
            />
          </Col>
          <Col md={6}>
            <GroupNotifications
              data={CompletedOrderNotifications}
              notificationLink={notificationLink}
              updateNotification={(id) => dispatch(updateNotification(id))}
              title="Notification commande compléter"
            />
          </Col>
          <Col md={6}>
            <GroupNotifications
              data={CompletedBriefNotifications}
              notificationLink={notificationLink}
              updateNotification={(id) => dispatch(updateNotification(id))}
              title="Notification cahier de charge compléter"
            />
          </Col>
          <Col md={6}>
            <GroupNotifications
              data={CompletedQualityNotifications}
              notificationLink={notificationLink}
              updateNotification={(id) => dispatch(updateNotification(id))}
              title="Notification Critére de qualité compléter"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Link className="link-action back" to="/">
              Retour
            </Link>
          </Col>
        </Row>
      </>
    </>
  );
};

const GroupNotifications = ({ data, notificationLink, updateNotification, title }) => {
  return (
    <div className="card notification-card mb-4 pb-2 purple-border">
      <div className="card-header">
        <Row className="">
          <Col md={6}>
            <h6>
              <span className="product-title px-3 text-white">{title}</span>
            </h6>
          </Col>
        </Row>
      </div>
      <div className="card-body  px-0 pt-0 pb-2">
        <div className="table-responsive p-0">
          <table className="table align-items-center mb-0">
            <thead>
              <tr>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Notification
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((notification) => (
                <tr key={notification._id}>
                  <td className="align-middle text-sm px-4">
                    <div className="flex gap-1">
                      <Link
                        to={notificationLink(notification)}
                        className="info-horizontal"
                        onClick={() => updateNotification(notification._id)}
                      >
                        <h6
                          className={`table-h6 ${
                            notification.opened ? "" : "text-gradient text-primary"
                          }`}
                        >
                          {notification.message}
                        </h6>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Notification;
